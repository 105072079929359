<template>
  <!-- 围手术期——术前髋关节组件 -->
  <div class="Preoperative" :disabled="modify">
    <!-- 血常规 -->
    <div class="blood_routine" v-show="next == 1 || showAll">
      <div class="grid-content bg-purple-dark">血常规</div>
      <el-row :gutter="0" v-for="(item, index) in formData.blood" :key="index">
        <el-col :xs="4" :sm="3" :md="3" :lg="3" :xl="3"
          ><div class="grid-content">{{ item.label }}</div></el-col
        >
        <el-col
          :xs="20"
          :sm="21"
          :md="21"
          :lg="21"
          :xl="21"
          v-if="item.type == 'radio'"
          ><div class="grid-content bg-purple-light">
            <el-radio-group v-model="operateForm.blood_routine[item.model]">
              <el-radio
                v-for="(item, index) in item.list"
                :key="index"
                :label="item"
                :disabled="modify"
                >{{ item }}</el-radio
              >
            </el-radio-group>
          </div></el-col
        >
        <el-col
          :xs="20"
          :sm="21"
          :md="21"
          :lg="21"
          :xl="21"
          v-if="item.type == 'input'"
          ><div class="grid-content bg-purple-light">
            <el-input
              v-model="operateForm.blood_routine[item.model]"
              :disabled="modify"
            >
              <template slot="append">{{ item.unit }}</template>
            </el-input>
          </div></el-col
        >
      </el-row>
    </div>
    <!-- 尿常规 -->
    <div class="urine_routine" v-show="next == 2 || showAll">
      <div class="grid-content bg-purple-dark">尿常规</div>
      <el-row>
        <el-col
          :xs="24"
          :sm="12"
          :md="12"
          :lg="8"
          :xl="8"
          v-for="(item, index) in formData.urine"
          :key="index"
        >
          <el-input
            v-model="operateForm.urine_routine[item.model]"
            :disabled="modify"
          >
            <template slot="prepend">{{ item.label }}</template>
            <template slot="append" v-if="item.hasUnit">{{ item.unit }}</template>
          </el-input>
        </el-col>
      </el-row>
    </div>
    <!-- 中段尿细菌涂片 -->
    <div class="smear" v-show="next == 3 || showAll">
      <div class="grid-content bg-purple-dark">中段尿细菌涂片</div>

      <el-row :gutter="0" v-for="(item, index) in formData.smear" :key="index">
        <el-col :xs="4" :sm="3" :md="3" :lg="3" :xl="3"
          ><div class="grid-content">{{ item.label }}</div></el-col
        >
        <el-col :xs="20" :sm="21" :md="21" :lg="21" :xl="21"
          ><div class="grid-content bg-purple-light">
            <el-radio-group v-model="operateForm.smear[item.model]" :disabled="modify">
              <el-radio
                v-for="(item, index) in item.list"
                :key="index"
                :label="item"
                >{{ item }}</el-radio
              >
            </el-radio-group>
          </div></el-col
        >
      </el-row>
    </div>
    <!-- 生化到股骨输入框组 next步数4到11-->
    <div class="bmp" 
        v-for="(item, index) in formData.inputParams" :key="index"
        v-show="next == item.next || showAll">
      <div class="grid-content bg-purple-dark">{{ item.span }}</div>
      <el-row :gutter="5">
        <el-col
          :xs="24"
          :sm="12"
          :md="12"
          :lg="8"
          :xl="6"
          v-for="(_item, _index) in item.opt"
          :key="_index"
        >
          <el-input
            v-model="operateForm[item.obj][_item.model]"
            :disabled="modify"
          >
            <template slot="prepend">{{ _item.label }}</template>
            <template slot="append" v-if="item.showUnit">{{
              _item.unit
            }}</template>
          </el-input>

          <!-- <el-input
            v-else
            placeholder="请输入内容" 
            v-model="operateForm[_item.model]"
          >
            <template slot="prepend">{{ _item.label }}</template>
            <template slot="append">{{ _item.unit }}</template>
          </el-input> -->
        </el-col>
      </el-row>
    </div>

    <!-- X线检查 -->
    <div class="xray-table" v-show="next == 12 || showAll">
      <div class="grid-content bg-purple-dark">X线检查(术前/术后)</div>
      <el-table
        :data="operateForm.xray"
        :header-cell-style="{ 'text-align': 'center' }"
      >
        <el-table-column width="140" prop="name"> </el-table-column>
        <el-table-column label="术前" prop="pre" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.pre"
              :disabled="modify"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column label="术后" prop="after" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.after"
              :disabled="modify"
            ></el-input>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- CT测量 -->
    <div class="ct-table" v-show="next == 13 || showAll">
      <div class="grid-content bg-purple-dark">CT测量</div>
      <el-table
        :data="operateForm.ct"
        :header-cell-style="{ 'text-align': 'center' }"
      >
        <el-table-column width="60" prop="name"> </el-table-column>
        <el-table-column label="左侧" prop="name" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.left"
              :disabled="modify"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column label="右侧" prop="address" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.right"
              :disabled="modify"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column label="手术侧术后" prop="after" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.after"
              :disabled="modify"
            ></el-input>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 彩超 -->
    <div class="colord" v-show="next == 14 || showAll">
      <div class="grid-content bg-purple-dark">彩超</div>
      <el-row>
        <el-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="24"
          :xl="24"
          v-for="(item, index) in formData.cardiac"
          :key="index"
        >
          <el-input
            v-model="operateForm[item.model]"
            :disabled="modify"
          >
            <template slot="prepend">{{ item.label }}</template>
            <template slot="append">{{ item.unit }}</template>
          </el-input>
        </el-col>
      </el-row>
      <el-row 
        :gutter="0"
        ><el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
          <div class="grid-content bg-purple-dark">
            <span>下肢静脉血栓</span>
          </div>
        </el-col>
        <el-col :xs="24" :sm="18" :md="18" :lg="18" :xl="18">
          <div class="grid-content bg-purple-light" style="text-align: left; padding: 8px;">
            <el-radio-group
            v-model="operateForm.dvt"
            :disabled="modify"
            >
              <el-radio :label="'1'">FV</el-radio>
              <el-radio :label="'2'">PFV</el-radio>
              <el-radio :label="'3'">PV</el-radio>
              <el-radio :label="'4'">ATV</el-radio>
              <el-radio :label="'5'">PTV</el-radio>
              <el-radio :label="'6'">PeV</el-radio>
            </el-radio-group>
          </div>
        </el-col>
      </el-row>

    </div>
  </div>
</template>

<script>
export default {
    name:"commonpreoperativehip",
    props:{
        next:Number,
        formData:Object,
        operateForm:Object,
        showAll:Boolean,
        modify:Boolean,
        operation_site:String,
    },
  data () {
    return {

    }
  },
}
</script>

<style scoped>
.Preoperative{
  padding: 3px;
}
.el-row {
  border-bottom: 1px solid #d6dde2;
  padding: 10px 0 5px 0;
}
.el-col {
  border-radius: 4px;
  margin-bottom: 5px;
}
.bg-purple-dark {
  background: #99a9bf;
  color: #fff;
}
.bg-purple {
  background: #d3dce6;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
}
.el-radio {
  margin-bottom: 0;
}

.shape_signal_text{
  font-size: 14px;
}
</style>