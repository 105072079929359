<!-- 视觉模拟评分法 -->
<template>
  <div>
    <el-row>
      <el-col  :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
       <div class="grid-content bg-purple" >VAS评分(视觉模拟评分法)</div>
      </el-col>
      <el-col  :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
       <div class="grid-sliderValue">
        VAS 评分：{{ fenshuvalue[0].sliderValue }} 分 
        <el-radio-group v-model="fenshuvalue[1].radio">
      <el-radio label="左侧髋关节">左侧髋关节</el-radio>
      <el-radio label="右侧髋关节">右侧髋关节</el-radio>
    </el-radio-group>
      </div>
      </el-col>
    <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
      <div class="slider-container">
      <el-slider
        v-model="fenshuvalue[0].sliderValue"
        :max="10"
        :step="1"
        show-stops
        :marks="marks"
        class="custom-slider" 
      ></el-slider>
      <div class="container-wrapper">
    <div class="icon-container">
      <!-- 第一个图标 -->
      <div class="icon-item first-item">
        <img :src="require(`@/assets/icons/1.jpg`)" alt="Icon" class="custom-icon" />
      </div>
      <!-- 其他图标 -->
      <div class="icon-item" v-for="index in 5" :key="index">
        <img :src="require(`@/assets/icons/${index + 1}.jpg`)" alt="Icon" class="custom-icon" />
      </div>
    </div>
  </div>
    </div>
  </el-col>
</el-row>
  </div>
</template>

<script>
export default {
data(){
  return{
    fenshuvalue:[{sliderValue:0},{radio:''}],
    marks: {
          0: '0',
          1: '1',
          2: '2',
          3: '3',
          4: '4',
          5: '5',
          6: '6',
          7: '7',
          8: '8',
          9: '9',
          10: '10',
      },
  }
},
props:{message:[Number, Array, String]},
watch:{
  fenshuvalue:{
    handler(newvalue,oldvalue){
      this.$emit("vasemit",newvalue)
    },deep:true
  },
  message(newvalue){
    for(let i=0;i<newvalue.length;i++){
      let obj = newvalue[i]
      for(let key in obj){
        if(key == 'sliderValue'){
          this.fenshuvalue[i][key] = parseInt(obj[key])
        }else{
          this.fenshuvalue[i][key] = obj[key]
        }
       
           
      }
    }
  }
},
created(){
 
},
mounted(){
 
}

}
</script>

<style scoped>
.slider-container {
  padding: 0px;
}
.icon-container {
  display: flex;
  align-items:flex-start ; 
  width: 100%;
}
.icon-item {
  flex: 0 0 calc(16.666% - 20px);
  max-width: calc(16.666% - 25px);
  margin-right: 30px;
  white-space: nowrap;
  text-align: center;
}
.first-item {
  margin-left: 0px; /* 第一个图标的右间距设为0，使其靠最边边 */
}
.grid-sliderValue{
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 10px;
}

</style>