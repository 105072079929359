import Vue from "vue";
import VueRouter from "vue-router";
import login from "../views/login.vue"
import mainview from "../components/main-view.vue";
import MedicalList from "../components//assembly/Medical-list.vue";
import list from "../components/assembly/list.vue";
import information from "../components/assembly/detailed-information.vue";
import homepag from "../components/assembly/home-page.vue";
import systemreview from "../components/assembly/System-Review-Form.vue";
import physical from "../components/assembly/Specialized-physical.vue";
import familyhistory from "../components/assembly/family-history.vue";
import walking from "../components/assembly/walking-measurement.vue"
import PastHistory from "../components/assembly/PastHistory.vue"
import personhistory from "../components/assembly/person-history.vue"
import oneselfinfomation from '../components/fillin-information/infomation.vue'
import insurgery from "../components/insurgery/insurgery.vue"
import presentHistory from "../components/assembly/PresentHistory.vue";
import imagedata from "../components//imagedata/imagedata.vue"
import ScoringRecords from '../components/scoringrecords/ScoringRecords.vue';
import Preoperative from '../components/assembly/Preoperative.vue';
import Postoperative from '../components/assembly/postoperative.vue';
import insutgerypage from "../components/insurgery2/insurgerypagr.vue"
import jouinview from "../components/hipjointscore/joinview.vue"
import pdasm from "../components/assembly/pdasm.vue"
import modify from "../components/modify/modify.vue"
import preinspdata from "../components/assembly/preInspData.vue"
import preCheckData from '../components/assembly/preCheckData.vue';
Vue.use(VueRouter);
const routes = [
  {
    path: '/',
    name: 'login',
    component: login
  },
  {
    path: "/mainview",
    name: "mainview",
    component: mainview,
    redirect: "/homepag",
    children: [
      { path: "/homepag", name: "homepag", component: homepag },
      {
        path: "/MedicalList",
        component: MedicalList,
        redirect: "/list",
        children: [
          {
            path: "/list",
            name: "list",
            component: list,
          },
          {
            path: "/modify",
            name: "modify",
            component: modify,
          },
          {

            path: "/information",
            name: "information",
            component: information,
            redirect: "/presentHistory",
            children: [
              { path: "/presentHistory", name: "presentHistory", component: presentHistory },
              { path: "/physical", component: physical },
              { path: "/systemreview", component: systemreview },
              { path: "/familyhistory", component: familyhistory },
              { path: "/walking", component: walking },
              { path: "/PastHistory", component: PastHistory },
              { path: "/personhistory", component: personhistory },
              { path: "/insurgery", component: insurgery },
              { path: "/insutgerypage", component: insutgerypage },
              { path: "/presentHistory", component: presentHistory },
              { path: "/imagedata", component: imagedata },
              {
                path: '/ScoringRecords',
                name: 'ScoringRecords',
                component: ScoringRecords
              },
              { path: '/Preoperative', component: Preoperative },
              { path: '/Postoperative', component: Postoperative },
              {path:"/jouinview",component:jouinview},
              {path:'/pdasm',component:pdasm},
              {path:'/preinspdata',component:preinspdata},
              {path:'/preCheckData',component:preCheckData}
            ],
          },
          {
            path: '/oneselfinfomation',
            component: oneselfinfomation
          }
        ],
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});
router.beforeEach((to, from, next) => {
  let tokenstr = localStorage.getItem('token')
  if (tokenstr) {
    next()
  } else {
    if (to.path == "/") {
      next()
    } else {
      next('/')
    }

  }
})
export default router;
