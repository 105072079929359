<template>
  <div>
    <el-button @click="openDialog" type="primary" class="mes">+ 增加影像资料</el-button>
    <!-- 弹窗填写 -->
    <el-dialog width="70%"
      title="影像资料"
      :visible.sync="dialogVisible"
      :before-close="handleBeforeClose"
    >
    <div class="imagedata">
    <el-row :gutter="10">
      <el-col :xs="10" :sm="4" :md="4" :lg="4" :xl="4">
        <div class="grid-content bg-purple">简短的描述</div>
      </el-col>
      <el-col :xs="24" :sm="20" :md="20" :lg="20" :xl="20">
        <el-input style="float: left; width: 100%;"
            placeholder="请进行简短描述"
            v-model="input"
            clearable>
        </el-input>
      </el-col>
    </el-row>

    <el-row :gutter="10">
      <el-col :xs="10" :sm="4" :md="4" :lg="4" :xl="4">
        <div class="grid-content bg-purple">上传时间</div>
      </el-col>
      <el-col :xs="24" :sm="20" :md="20" :lg="20" :xl="20">
        <el-date-picker style="float: left; height: 30px; "
      v-model="date"
      :picker-options="pickerOptions" 
      :size="size"
      type="datetime"
      placeholder="选择日期和时间"
      ></el-date-picker>
      </el-col>
    </el-row>
   

    <el-row :gutter="10" >
      <el-col :xs="10" :sm="4" :md="4" :lg="4" :xl="4" style="margin-top: 10px;" >
        <div class="grid-content bg-purple">上传文件</div>
      </el-col>
      <el-col :xs="24" :sm="20" :md="20" :lg="20" :xl="20" style="margin-top: 10px;">
        <div class="upload-container"  >
          <!-- <el-upload 
             class="upload-demo"
             action="https://jsonplaceholder.typicode.com/posts/"
             drag
             :on-success="handleSuccess" 
             accept="image/jpeg,image/png,image/gif,video/mp4,video/mov"
             @on-change="handleChange"
             multiple 
             >
             <i class="el-icon-upload"></i>
             <div class="el-upload__text">将图片拖到此处，或<em>点击上传</em></div>
             <div class="el-upload__tip" slot="tip">只能上传照片和视频</div>
          </el-upload> -->
          <el-upload
            class="avatar-uploader"
            action="#"
            :show-file-list="false"
            :before-upload="beforeAvatarUpload"
            :http-request="upload"
          >
            <img v-if="imageUrl" :src="imageUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon" ></i>
          </el-upload>
<!-- updateimg
             <div v-for="fileUrl in fileUrls" :key="fileUrl">
             <img v-if="isImage(fileUrl)" :src="fileUrl" alt="照片" width=100% >
             <video v-else :src="fileUrl" controls width=100% ></video>
             </div> -->
        </div>
        
      </el-col>
    </el-row>     
  </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>

  </div>

</template>

<script>
import COS from 'cos-js-sdk-v5' // 引入 cos-js-sdk-v5 包
export default {
  
  data() {
    return {
      dialogVisible: false,
      date: null,
      pickerOptions: {},
      size: '',
      input: '',
      uploadWidth: '90%',
      fileList: [],
      fileUrls: [],
      imageUrl:'',
      cos:'',
      room:''
    };
  },
  mounted() {
    this.room = this.$route.query.room
    this.setPickerWidth(); // 初始化时设置宽度
    // window.innerWidth
    window.addEventListener('resize', this.setPickerWidth); // 监听窗口大小变化
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setPickerWidth); // 移除窗口大小变化监听
  },
  methods: {
    beforeAvatarUpload(file) {
        // const isJPG = file.type === 'image/jpeg';
        const isLt2M = file.size / 1024 / 1024 < 8;//  这一行的作用是判断上传的图片文件的大小是否小于 2MB。file.size 是文件的字节数，除以 1024 得到 KB，再除以 1024 得到 MB。

        // if (!isJPG) {
        //   this.$message.error('上传头像图片只能是 JPG 格式!');
        // }
        if (!isLt2M) {
          this.$message.error('上传头像图片大小不能超过 8MB!');
        }
        return  isLt2M;
      },
    setPickerWidth() {
      const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      if (screenWidth <= 768) {
        // 在屏幕宽度小于等于 768px 时设置宽度为100%
        this.pickerOptions = {
          ...this.pickerOptions,
          width: '100%',
        };
        this.size = 'small'; // 可根据需要设置尺寸
      } else {
        // 其他情况下恢复默认宽度
        this.pickerOptions = {
          ...this.pickerOptions,
          width: '',
        };
        this.size = ''; // 可根据需要设置尺寸
      }
    },
    openDialog() {
      this.dialogVisible = true;
    },
    handleBeforeClose(done) {
      // 执行一些关闭前的操作
      // 调用 done() 方法关闭对话框
      done();
    },
    handleChange(event) {
    // const fileUrl = URL.createObjectURL(file.raw);
    // this.fileUrls.push(fileUrl);
    // console.log(event,'lll')

    },

    handleDrag(event) {
    event.preventDefault();
    event.stopPropagation();

    let fileList = event.dataTransfer.files;
    Array.from(fileList).forEach(file => {
      this.$refs.upload.uploadFiles.push(file);
    });
    },
    handleRemove(file, fileList) {
      // console.log(file, fileList);
    },
    handlePreview(file ) {
      // console.log(file);
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${ file.name }?`);
    },
    
    handleSuccess(response, file, fileList) {
      // console.log(response, file, fileList,'ooo')
    },
    beforeUpload(file) {
      const isImgOrVideo = file.type.startsWith('image/') || file.type.startsWith('video/');
      if (!isImgOrVideo) {
          this.$message.error('只能上传图片或视频文件！');
      }
      return isImgOrVideo;
    },
    async  upload(file){
        console.log(file,file.file.name,'file')
        // const {data:res} = await this.$http.get('http://192.168.1.65:3000/getTempKeys')
        const {data:res} = await this.$http.get('https://seats.kunbocs.cn/getTempKeys')
        console.log(res)
          this.cos = new COS({
            getAuthorization: (options, callback) => {
              callback({
                TmpSecretId: res.credentials.tmpSecretId,
                TmpSecretKey: res.credentials.tmpSecretKey,
                XCosSecurityToken: res.credentials.sessionToken,
                ExpiredTime: res.expiredTime,
              });
            },
          });
          let key = "/其他/"+this.room+Date.now()+'.jpg';
          console.log(key,'key')
          this.cos.putObject({
            Bucket: "file-1257652773", // 存储桶名称
              Region: "ap-guangzhou", // 存储桶所在地域，必须字段
              Key: key, // 图片名称
              StorageClass: "STANDARD",
              Body: file.file, // 上传文件对象
              onHashProgress: (progressData) => {
                console.log("校验中", JSON.stringify(progressData));
              },
              onProgress: (progressData) => {
                const percent = parseInt(progressData.percent * 10000) / 100;
                const speed = parseInt((progressData.speed / 1024 / 1024) * 100) / 100;
                console.log("进度：" + percent + "%; 速度：" + speed + "Mb/s;");
              },
          },(err,data)=>{
            console.log(data,'=-=')
            if (data && data.statusCode == 200) {
                // 如果上传成功，拼接图片链接地址，并赋值给 imageUrl
                let fileUrl ='https://'+data.Location
                this.imageUrl = fileUrl;
                console.log(fileUrl);
              } else {
                console.log(err,'1');
                // 这里可以使用 element-ui 的 Message 组件或者其他方式提示用户上传失败
              }
          }).catch((err) => {
          console.log(err,'2');
          // 这里可以使用 element-ui 的 Message 组件或者其他方式提示用户获取密钥失败
        });
       
        },
  }
};
</script>

<style scoped>

.mes{
  float: left;
  margin: 0 10px;
}

.dialog-footer {
  text-align: right;
}

.imagedata {
position: relative;
height: fit-content;
width: 100%;
box-sizing: border-box;
padding: 10px;
}

.upload-container {
display: flex;
}

@media (max-width: 768px) { /* 在屏幕宽度小于等于 768px 时应用该样式 */
    .upload-container {
      width: 100%; 
    }
    .el-upload-dragger{
      width: 100%; 
    }
}
.avatar-uploader .el-upload {
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    border: 1px dashed gray;
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar-uploader .el-upload {
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    border: 1px dashed gray;
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
.upload-demo{
float: left;
@media (max-width: 768px) {

}
}
</style>
