<template>
    <!-- 检查数据——髋关节组件 -->
    <div class="commonprecheckdatahip" :disabled="modify">
      <!-- 骨密度：正位脊柱到股骨-->
      <div class="bmp" 
          v-for="(item, index) in formData.inputParams" :key="index"
          v-show="next == item.next || showAll">
        <div class="grid-content bg-purple-dark">{{ item.span }}</div>
        <el-row :gutter="5">
          <el-col
            :xs="24"
            :sm="12"
            :md="12"
            :lg="8"
            :xl="6"
            v-for="(_item, _index) in item.opt"
            :key="_index"
          >
            <el-input
              v-model="operateForm[item.obj][_item.model]"
              :disabled="modify"
            >
              <template slot="prepend">{{ _item.label }}</template>
              <template slot="append" v-if="item.showUnit">{{
                _item.unit
              }}</template>
            </el-input>
  
            <!-- <el-input
              v-else
              placeholder="请输入内容" 
              v-model="operateForm[_item.model]"
            >
              <template slot="prepend">{{ _item.label }}</template>
              <template slot="append">{{ _item.unit }}</template>
            </el-input> -->
          </el-col>
        </el-row>
      </div>
  
      <!-- X线检查 -->
      <div class="xray-table" v-show="next == 3 || showAll">
        <div class="grid-content bg-purple-dark">X线检查(术前/术后)</div>
        <el-table
          :data="operateForm.xray"
          :header-cell-style="{ 'text-align': 'center' }"
        >
          <el-table-column width="140" prop="name"> </el-table-column>
          <el-table-column label="术前" prop="pre" show-overflow-tooltip>
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.pre"
                :disabled="modify"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column label="术后" prop="after" show-overflow-tooltip>
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.after"
                :disabled="modify"
              ></el-input>
            </template>
          </el-table-column>
        </el-table>
      </div>
  
      <!-- CT测量 -->
      <div class="ct-table" v-show="next == 4 || showAll">
        <div class="grid-content bg-purple-dark">CT测量</div>
        <el-table
          :data="operateForm.ct"
          :header-cell-style="{ 'text-align': 'center' }"
        >
          <el-table-column width="60" prop="name"> </el-table-column>
          <el-table-column label="左侧" prop="name" show-overflow-tooltip>
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.left"
                :disabled="modify"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column label="右侧" prop="address" show-overflow-tooltip>
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.right"
                :disabled="modify"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column label="手术侧术后" prop="after" show-overflow-tooltip>
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.after"
                :disabled="modify"
              ></el-input>
            </template>
          </el-table-column>
        </el-table>
      </div>
  
      <!-- 彩超 -->
      <div class="colord" v-show="next == 5 || showAll">
        <div class="grid-content bg-purple-dark">彩超</div>
        <el-row>
          <el-col
            :xs="24"
            :sm="24"
            :md="24"
            :lg="24"
            :xl="24"
            v-for="(item, index) in formData.cardiac"
            :key="index"
          >
            <el-input
              v-model="operateForm[item.model]"
              :disabled="modify"
            >
              <template slot="prepend">{{ item.label }}</template>
              <template slot="append">{{ item.unit }}</template>
            </el-input>
          </el-col>
        </el-row>
        <el-row 
          :gutter="0"
          ><el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
            <div class="grid-content bg-purple-dark">
              <span>下肢静脉血栓</span>
            </div>
          </el-col>
          <el-col :xs="24" :sm="18" :md="18" :lg="18" :xl="18">
            <div class="grid-content bg-purple-light" style="text-align: left; padding: 8px;">
              <el-radio-group
              v-model="operateForm.dvt"
              :disabled="modify"
              >
                <el-radio :label="'1'">FV</el-radio>
                <el-radio :label="'2'">PFV</el-radio>
                <el-radio :label="'3'">PV</el-radio>
                <el-radio :label="'4'">ATV</el-radio>
                <el-radio :label="'5'">PTV</el-radio>
                <el-radio :label="'6'">PeV</el-radio>
              </el-radio-group>
            </div>
          </el-col>
        </el-row>
  
      </div>
    </div>
  </template>
  
  <script>
  export default {
      name:"commonprecheckdatahip",
      props:{
          next:Number,
          formData:Object,
          operateForm:Object,
          showAll:Boolean,
          modify:Boolean,
          operation_site:String,
      },
    data () {
      return {
  
      }
    },
  }
  </script>
  
  <style scoped>
  .Preoperative{
    padding: 3px;
  }
  .el-row {
    border-bottom: 1px solid #d6dde2;
    padding: 10px 0 5px 0;
  }
  .el-col {
    border-radius: 4px;
    margin-bottom: 5px;
  }
  .bg-purple-dark {
    background: #99a9bf;
    color: #fff;
  }
  .bg-purple {
    background: #d3dce6;
  }
  
  .grid-content {
    border-radius: 4px;
    min-height: 36px;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
  }
  .el-radio {
    margin-bottom: 0;
  }
  
  .shape_signal_text{
    font-size: 14px;
  }
  </style>