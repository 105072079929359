<template>
  <div class="shouye">
    首页
  </div>
</template>

<script>
export default {
  data() {
    return {
     
    };
  },
  mounted(){
    sessionStorage.setItem('padheight','66px')
  },
  methods: {
    async test() {
      // const { data: res } = await this.$http.get("/operation/getOperationInfo",);
      // if (res.message == "获取成功" && res.length != 0) {
      //   this.$message.success(res.message);
      //   console.table(res.data);
      // }
    },
  },
};
</script>

<style>
.shouye {
  font-size: 45px;
  font-weight: 600;
}
</style>