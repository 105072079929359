<template>
  <div class="aainfomation" @keydown.enter.prevent>
    <el-drawer
      :title="form[drawerindex].name"
      :visible.sync="drawer"
      direction="btt"
      size="35%"
      >
      <div class="drawerdiv">
        <el-form >
          <el-form-item label="拖拽选择">
            <div class="range">
          &nbsp;
          <el-input type="range"  min="0" max="300" :value="value[drawerindex].value" v-model="value[drawerindex].value"/>
         <div class="itemdw">
            &nbsp; {{ value[drawerindex].value }}  &nbsp;{{ form[drawerindex].dw }}
          </div>
        </div>
          </el-form-item>
          <el-form-item label="手动输入">
          <el-input  :value="value[drawerindex].value" v-model="value[drawerindex].value">
            <template slot="append">{{form[drawerindex].dw}} </template>
          </el-input>
          </el-form-item>
        </el-form>
      </div>
    </el-drawer>
    <el-row :gutter="20">
      <el-col :xs="item.xs" :sm="item.sm" :md="item.md" :lg="item.lg" :xl="item.xl" v-for="(item,index) in form" :key="index"  >
        
        <el-form label-position="top" size="medium" v-if="item.type =='input'&&item.name !=='联系方式'" > 
          <el-form-item :label="item.name">
            <el-input v-model="value[index].value"></el-input>
          </el-form-item>
        </el-form>

        <el-form label-position="top" size="medium"  v-if="item.type =='input'&&item.name =='联系方式'" > 
          <el-form-item :label="item.name" >
            <el-input v-model="value[index].value" style="width:33%" placeholder="联系方式1" ></el-input>
            <el-input v-model="value[index].value1" style="width:33%" placeholder="联系方式2" ></el-input>
            <el-input v-model="value[index].value2" style="width:33%" placeholder="联系方式3" ></el-input>
          </el-form-item>
        </el-form>

        <el-form label-position="top" size="medium"  v-if="item.type =='checkbox'" > 
          <el-form-item :label="item.name" >
            <el-radio-group v-model="value[index].value">
              <el-radio-button
                v-for="(checkvalue , index ) in item.value"
                :key="index"
                :label="checkvalue"
                :disabled="item.name =='出生季节'"
              >
                {{ checkvalue }}
              </el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-form>

        <el-form label-position="top" size="medium"  v-if="item.type =='cascader'" > 
          <el-form-item :label="item.name" >
            <el-cascader
                class="cascader"
                    size="middle"
                    :options="place"
                    v-model="value[3].value"
                    @change="tonativapalce"
                    style="width: 100%;"
                >
            </el-cascader>
          </el-form-item>
        </el-form>

        <el-form label-position="top" size="medium"  v-if="item.type =='select'" > 
          <el-form-item :label="item.name" >
            <el-select v-model="value[index].value"  style="width: 100%;">
              <el-option v-for="(select,indexselect) in item.value" :value="select.name" :key="indexselect+'p'">
                {{ select.name }}
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>

        <el-form label-position="top" size="medium"  v-if="item.type =='time'" > 
          <el-form-item :label="item.name" >
            <el-date-picker
                  disabled
                  v-model="value[index].value"
                  type="date"
                  placeholder="选择日期"
                  format="yyyy 年 MM 月 dd 日"
                  value-format="yyyy-MM-dd"
                  style="width: 100%;"
                >
                </el-date-picker>
          </el-form-item>
        </el-form>

        <el-form label-position="top" size="medium"  v-if="item.type =='range'" > 
          <el-form-item :label="item.name" >
            <div class="grid-content ">
                <el-input @focus="checkfocus(index,$event)"  :value="value[index].value" v-model="value[index].value"> 
                  <template slot="append">{{ item.dw }}</template>
                </el-input>
                <!-- <div class="itemdw">
                   {{ value[index].value }} 2
                </div> -->
               
              </div>
            <!-- <div class="grid-content range">
                <el-input type="range"  min="0" max="300" :value="value[index].value" v-model="value[index].value"/>
                <div class="itemdw">
                   {{ value[index].value }} {{ item.dw }}2
                </div>
               
              </div> -->
          </el-form-item>
        </el-form>

      </el-col>

      <el-col  :xs="24" :sm="24" :md="24" :lg="24" :xl="24">

        <el-form label-position="top" > 
          <el-form-item label="家庭地址" >
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" style="padding-left:0;">
              <el-cascader
                  class="cascader"
                      size="middle"
                      :options="options"
                      v-model="value[14].value"
                      @change="address"
                      style="width: 100%;"
                  >
              </el-cascader>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" style="padding-left:0;">
                <el-input v-model="value[14].add" placeholder="请输入地址"></el-input>
            </el-col>    
          </el-form-item>
          <el-form-item>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <!-- <div class="grid-content bg-purple"> -->
            <el-button :disabled="toommit"  @click="tosubmit" type="primary" style="width: 100%; margin-top: 20px;">提交</el-button>
          <!-- </div> -->
        </el-col>
          </el-form-item>
        </el-form>

      </el-col>

    </el-row>
    

    <el-row :gutter="5" v-show="false">
        <el-col :xs="item.xs" :sm="item.sm" :md="item.md" :lg="item.lg" :xl="item.xl" v-for="(item,index) in form" :key="index" >
           <el-row class="testrow" >

            <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8" v-if="item.name!='联系方式' && item.name!='婚姻状况'"><div class="grid-content bg-purple">{{ item.name }}</div></el-col>
            <el-col :xs="8" :sm="8" :md="4" :lg="4" :xl="4" v-if="item.name=='联系方式' || item.name=='婚姻状况'"><div class="grid-content bg-purple">{{ item.name }}</div></el-col>

            <el-col :xs="16" :sm="16" :md="16" :lg="16" :xl="16" v-if="item.type =='input'&&item.name !=='联系方式'">
              <!-- <div class="grid-content bg-purple-light"> -->
                <el-input v-model="value[index].value"/>
              <!-- </div> -->
            </el-col>


            
            <el-col :xs="16" :sm="16" :md="16" :lg="16" :xl="16" v-if="item.type =='range'">
              <div class="grid-content range">
                <el-input type="range"  min="0" max="300" :value="value[index].value" v-model="value[index].value"/>
                <div class="itemdw">
                   {{ value[index].value }} {{ item.dw }}1
                </div>
               
              </div>
            </el-col>
            <el-col :xs="17" :sm="17" :md="20" :lg="20" :xl="20" v-if="item.type =='input'&&item.name =='联系方式'">
              <!-- <div class="grid-content bg-purple-light"> -->
                <div class="lxfs">
                  <div class="lxfscol"> <el-input v-model="value[index].value"/></div>
                  <div class="lxfscol"><el-input v-model="value[index].value1" /></div>
                  <div class="lxfscol"><el-input v-model="value[index].value2" /></div>
                </div>
              <!-- </div> -->
            </el-col>
            <el-col :xs="16" :sm="16" :md="16" :lg="16" :xl="16" v-if="item.type =='checkbox' " style="text-align: left; padding-left: 10px; ">
              <!-- <div class="grid-content bg-purple-light"> -->
                <el-radio-group v-model="value[index].value">
                  <el-radio-button
                    v-for="(checkvalue , index2) in item.value"
                    :key="index2"
                    :label="checkvalue"
                    :disabled="item.name =='出生季节'"
                  >
                    {{checkvalue}}
                  </el-radio-button>
                  
                </el-radio-group>

              <!-- </div> -->
            </el-col>
            <el-col :xs="16" :sm="16" :md="16" :lg="16" :xl="16" v-if="item.type =='cascader'" style="text-align: left;">
              <!-- <div class="grid-content bg-purple-light"> -->
                <el-cascader
                    class="cascader"
                        size="middle"
                        :options="place"
                        v-model="value[3].value"
                        @change="tonativapalce"
                        style="width: 100%;"
                    >
                </el-cascader>
              <!-- </div> -->
            </el-col>

            <el-col :xs="16" :sm="16" :md="16" :lg="16" :xl="16" v-if="item.type =='time'">
              <!-- <div class="grid-content bg-purple-light"> -->
                <el-date-picker
                disabled
                  v-model="value[index].value"
                  type="date"
                  placeholder="选择日期"
                  format="yyyy 年 MM 月 dd 日"
                  value-format="yyyy-MM-dd"
                  style="width: 100%;"
                >
                </el-date-picker>
              <!-- </div> -->
            </el-col>
            <el-col :xs="16" :sm="16" :md="16" :lg="16" :xl="16" v-if="item.type =='select'">
              <!-- <div class="grid-content bg-purple-light"> -->
                <el-select v-model="value[index].value"  style="width: 100%;">
                  <el-option v-for="(select,indexselect) in item.value" :value="select.name" :key="indexselect+'p'">
                    {{ select.name }}
                  </el-option>
                </el-select>
              <!-- </div> -->
            </el-col>
           </el-row>
        </el-col>
        <el-col  :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <div class="grid-content bg-purple">家庭地址</div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                <!-- <div class="grid-content bg-purple"> -->
                    <el-cascader
                    class="cascader"
                        size="middle"
                        :options="options"
                        v-model="value[14].value"
                        @change="address"
                        style="width: 100%;"
                    >
                </el-cascader>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">  
                <el-input v-model="value[14].add" placeholder="请输入地址"></el-input>
                <!-- </div> -->
            </el-col>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <!-- <div class="grid-content bg-purple"> -->
            <el-button :disabled="toommit"  @click="tosubmit" type="primary" style="width: 100%; margin-top: 20px;">提交</el-button>
          <!-- </div> -->
        </el-col>
    </el-row>
  </div>
</template>
<script>
import { regionData, CodeToText ,provinceAndCityData } from "element-china-area-data";
import {getDate} from '../../util/Process'
import {nation} from '../../util/jsData'
import {toinfomation} from '../../util/Process'
export default {
  data() {
    return {
      operationid:'',
      numbering:'',
        options: regionData,
        place:provinceAndCityData,
        nativapalce:[],
        addres:[],
      form: [
        { type: "input", name: "患者姓名",xs:24,sm:24,md:12,lg:12,xl:12 },
        { type: "input", name: "身份证号",xs:24,sm:24,md:12,lg:12,xl:12 },
        { type: "input", name: "联系方式",xs:24,sm:24,md:24,lg:24,xl:24},
        { type: "cascader", name: "籍贯",xs:24,sm:24,md:24,lg:24,xl:24 },
        { type: "checkbox", name: "性别", value: ["男", "女"],xs:24,sm:24,md:12,lg:12,xl:12},
        { type: "checkbox", name: "婚姻状况", value: ["未婚", "已婚", "离婚","丧偶","再婚"],xs:24,sm:24,md:12,lg:12,xl:12},
        { type: "input", name: "工作单位",xs:24,sm:24,md:12,lg:12,xl:12 },
        { type: "input", name: "职业",xs:24,sm:24,md:12,lg:12,xl:12 },
        { type: "select", name: "民族",value:nation,xs:24,sm:24,md:12,lg:12,xl:12 },
        { type: "time", name: "出生年月日",xs:24,sm:24,md:12,lg:12,xl:12 },
        { type: "range", name: "身高",xs:24,sm:24,md:12,lg:12,xl:12,dw:'CM' },
        { type: "range", name: "体重",xs:24,sm:24,md:12,lg:12,xl:12,dw:"KG" },
        { type: "input", name: "出生时天气",xs:24,sm:24,md:12,lg:12,xl:12 },
        
        {type: "checkbox",name: "出生季节",value: ["春", "夏","秋","冬"],xs:24,sm:24,md:12,lg:12,xl:12},
      ],
      toommit:true,
      value: [
        { value: "" },
        { value: "" },
        { value: "",value1:'',value2:'' },
        { value: [] },
        { value: [] },
        { value: [] },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: [] },

        {value:[],add:''}
      ],
      valuename:["patients_name","id_card","phone","native","gender","marital_status","work_unit", "occupation", "nation","stature", "weight","birth_weather","birth_date", "birth_seasons","home_address"],
      drawer:false,
      drawerindex:1
    };
  },
  watch:{
    value:{
      handler(array,old){
        // console.log(array)
        let allValuesNotEmpty = true;
        for (let i = 0; i < array.length; i++) {
            let item = array[i];
            if (Array.isArray(item.value) && !item.value.length) {
              allValuesNotEmpty = false;
              break;
            } else if (typeof item.value === 'string' && !item.value) {
              allValuesNotEmpty = false;
              break;
            }
            if ('add' in item && typeof item.add === 'string' && !item.add) {
              allValuesNotEmpty = false;
              break;
            }
          }
          this.toommit = !allValuesNotEmpty
          let regex = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
          let shenfenid = array[1].value
          if(regex.test(shenfenid)){
            if(shenfenid.length==18){
              let year = shenfenid.substr(6, 4);
              let month = shenfenid.substr(10, 2);
              let day = shenfenid.substr(12, 2);
              let aa = year + '-' + month-1 + '-' + day
              // console.log(aa,'789',year)
              array[9].value = `${year}-${month}-${day}`;
              switch (true) {
                  case (parseInt(month) >= 3 && parseInt(month) <= 5):
                  array[13].value = "春";
                    break;
                  case (parseInt(month) >= 6 && parseInt(month) <= 8):
                  array[13].value = "夏";
                    break;
                  case (parseInt(month) >= 9 && parseInt(month) <= 11):
                  array[13].value = "秋";
                    break;
                  default:
                  array[13].value = "冬";
                }
            }
            
          }else{
            if(shenfenid.length>=18){
              this.$message.error('身份证号错误')
            }
          }
      },deep:true
    },
  },
  created() {
  },
  mounted(){
    let room = this.$route.query.room
    this.operationid =  this.$route.query.room
    this.getinfo(room)
  },
  methods:{
    cancelfocus(e){
      // console.log(e,'cancelfocus')

    },
    checkfocus(value,e){
      this.drawer=true
      this.drawerindex=value
      const inputElement = e.target; // 通过事件对象获取到input元素  
      // console.log(value,e,'focus')
      inputElement.blur();  
    },
    address(value) {
    },
    tonativapalce(value){
    },
    async tosubmit(){    
        const {data:res} =await this.$http.post('/Operation/editOperationInfo/'+this.operationid,{
            // "hospital_id":this.value2[0].label,
            // "serial_number":this.numbering,
            // "pay_type":this.value2[1].label,
            // "operation_site":this.value2[2].label,
            // "operation_name":this.value2[3].label[0],
            // "hospital_id":this.operationid,
            "patients_name":this.value[0].value,
            "id_card":this.value[1].value,
            "phone":this.value[2],
            "native":this.value[3].value,
            "gender":this.value[4].value[0],
            "marital_status":this.value[5].value,
            "work_unit":this.value[6].value,
            "occupation":this.value[7].value,
            "nation":this.value[8].value,
            "birth_date":this.value[9].value,
            "stature":this.value[10].value,
            "weight":this.value[11].value,
            "birth_weather":this.value[12].value,
            "birth_seasons":this.value[13].value[0],
            "home_address":this.value[14],
        })
        if(res.status=="1"){
          this.$store.commit("changeshowbtn", "true");
      this.$store.commit("changemedicalname", "病例信息列表");
      this.$store.commit("showinputmethod",'true')
            this.$router.push("/MedicalList")
        }else{
          this.$message.error('提交出错，请联系管理员')
          // console.log(res)
        }
         },
    async getinfo(value){
      try{
      const { data: res } = await this.$http.get("/Operation/getOperationPatientInfo/"+value
      );
      if (res.status == "1") {
       let arr = toinfomation(res.data)
      //  console.log(arr,';;p')
        for(let i=0;i<arr.length;i++){
          if(i==2|| i==3|| i==14){
            if(i==2){
              for(let key in arr[2]){
                this.value[2][key] = arr[2][key]
              }
            }else if(i==3){
             this.value[3].value = arr[i] == null ? '':Object.values(arr[i])
            }else{
              this.value[14].value=arr[14].value
              this.value[14].add = arr[14].add
            }
          }else{
              this.value[i].value = arr[i]
          }
        }
       
      }else{
      }
    }catch(e){

    }
    },
  }
};
</script>
<style scoped>
.aainfomation{
    height: fit-content;
    width: 100%;
    box-sizing: border-box;
    /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; */
    overflow: hidden;
    /* background-color: red; */
    text-align:left;
}
.aainfomation label{
  padding:0;
}
.newsurgery{
  width: 100%;
  height: fit-content;
}
/* .newsurgery .el-col{
  margin-top: 5px;
} */
.bg-purple-light {
    background: #e5e9f2;
  }
  .testrow{
    /* margin-bottom: 5px; */
  }
  .addres{
    width: 30%;
  }
  .cascader{
    width:70%;
  }
  .lxfs{
    display: flex;
    align-items: center;
    justify-content: space-around;
    /* width: 100%; */
    /* background-color: black; */
  }
  .lxfscol{
    /* border: 1px solid red; */
    height: 100%;
    flex: 1;
    margin-left: 10px;
  }
.leftcss{
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding-left: 15px;
}
.range .el-input__inner{
  /* width: 90%; */
  height: 10px !important;
  padding: 0px !important;
  cursor: pointer;
}
.range{
  width: 100%;
  display: flex;
  box-sizing: border-box;
}
.drawerdiv{
  height: fit-content;
  padding: 0 15px;
}
</style>