<template>
  <div>
    <el-button type="primary" @click="showInputDialog" style="margin-top:20px;">增加评分记录</el-button>
    <el-divider></el-divider>
    <el-dialog
    title="填写信息"
    :visible.sync="dialogVisible"
    @close="resetFormData"
    class="custom-dialog"
    :width="diawid"
  >

    <el-form :model="formData" label-width="80px">
      <el-form-item label="评估医师">
        <el-input v-model="formData.doctorName"></el-input>
      </el-form-item>
      <el-form-item label="评估日期">
        <el-date-picker v-model="formData.registrationDate" type="date" placeholder="选择日期"></el-date-picker>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取消</el-button>
      <el-button type="primary" @click="saveData">保存</el-button>
    </div>
  </el-dialog>
 
  <el-table :data="tableData" style="width: 100%" :default-expand-all="true" >
    <el-table-column prop="assessor" label="评估医师" ></el-table-column>
    <el-table-column prop="registrationDate" width="100" label="评估日期  " ></el-table-column>
    <el-table-column prop="timestamp" width="100" label="评估时间" ></el-table-column>
    <!-- <el-table-column label="得分">
      <template slot-scope="scope">
        {{ scope.row.scoringtotal || '未评分' }}
      </template>
    </el-table-column> -->
    <el-table-column label="操作">
      <template slot-scope="scope">
        <el-button type="warning" @click="showScoreDialog(scope.row)" v-if="!scope.row.whether">
          评分
        </el-button>
        <el-button type="primary" @click="showResultView(scope.row)" size="mini" v-if="scope.row.whether">
          评分记录
        </el-button>
      </template>
    </el-table-column>
    <!-- 自定义展开内容 -->
          <el-table-column label="分数" type="expand" width="80px">
        <template slot-scope="scope">
          <el-descriptions :column="2" border v-show="scope.row.whether">
            <el-descriptions-item>
              <template slot="label">VAS</template>
              {{ scope.row.vas }}
            </el-descriptions-item>

            <el-descriptions-item>
              <template slot="label">AKS</template>
              {{ scope.row.aks }}
            </el-descriptions-item>

            <el-descriptions-item>
              <template slot="label">Womac</template>
              {{ scope.row.womac }}
            </el-descriptions-item>

            <el-descriptions-item>
              <template slot="label">Koos JR</template>
              {{ scope.row.koos_jr_scoring }}
            </el-descriptions-item>

            <el-descriptions-item>
              <template slot="label">Mental(PROMIS)</template>
              {{ scope.row.Mental }}
            </el-descriptions-item>

            <el-descriptions-item>
              <template slot="label">Physical(PROMIS)</template>
              {{ scope.row.Physical}}
            </el-descriptions-item>

            <el-descriptions-item>
              <template slot="label">MCS(SF-12)</template>
              {{ scope.row.sf_MCS_scoring}}
            </el-descriptions-item>

            <el-descriptions-item>
              <template slot="label">PCS(SF-12)</template>
              {{ scope.row.sf_PCS_scoring }}
            </el-descriptions-item>
          </el-descriptions>
        </template>
      </el-table-column>
  </el-table>


    <ScoreDialog :visible="scoreDialogVisible" :scoringId="scoreDialogScoringId" @close="handleCloseScoreDialog" />


    <Resultviewing :visible.sync="showResultViewVisible" :scoringId="showResultViewScoringId"
      @close="handleCloseScoreDialog1" />


  </div>
</template>

<script>
import ScoreDialog from './ScoreDialog.vue';
import Resultviewing from './Resultviewing.vue';
import axios from 'axios'; // Import Axios here
export default {
  data() {
    return {
      operationData: null,
      showResultViewVisible: false,
      dialogVisible1: false,
      dialogVisible: false,
      scoreDialogVisible: false,
      diawid:"90%",
      formData: {
        assessor: '',
        registrationDate: new Date().toISOString().slice(0, 19).replace('T', ' '), // 初始化为当天日期
      },
      tableData: [],
      selectedRow: null,
      operatiom_id: "",
      scoringId: null,
      scoreDialogScoringId: null,
      showResultViewScoringId: null,
      operationid:'',
    };
  },
  created() {
          this.operationid = this.$route.query.room;
          
        },
  methods: {
  async getrom(){
   try {
       const { data: res } = await this.$http.get(
                 "/Operation/getPreOperationInfo/" + this.operationid,
                 {
                   params: {
                     pre_type: "专科体查",
                   },
                 }
               );
   } catch (error) {
    
   }
    },
    resetFormData() {
      // 关闭对话框时重置 formData 对象
      this.formData = {
        doctorName: '',
        registrationDate: new Date().toISOString().slice(0, 19).replace('T', ' '), // 初始化为当天日期
      };
    },
    showInputDialog() {
      this.dialogVisible = !this.dialogVisible;

    },

    saveData() {
      // 构建请求体数据
      const date = new Date(this.formData.registrationDate); // 将字符串日期转换为 Date 对象
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始，需要加1，并且保证两位数格式
      const day = String(date.getDate()).padStart(2, '0'); // 日期需要保证两位数格式
      const formattedDate = `${year}-${month}-${day}`;

      const requestData = {
        assessor: this.formData.doctorName,
        date: formattedDate,
      };

      // 根据 operatiom_id 构建动态的请求 URL
      const requestUrl = `/Scoring/addScoring/${this.operatiom_id}`;

      // 发送 POST 请求到服务器
      axios.post(requestUrl, requestData)
        .then(response => {
          // 处理服务器响应，根据 status 字段进行操作

          if (response.data.status === 1) {
            // 服务器成功响应，可以根据需要处理成功后的逻辑
            //window.location.reload();
            this.fetchScoringData();
            this.dialogVisible = false;
            // console.log('添加成功：', response.data.message);
           
          } else {
            // 服务器返回了错误信息，您可以根据需要进行处理
            // console.error('添加失败：', response.data.message);
          }
        })
        .catch(error => {
          // 处理请求错误，例如网络错误等
          // console.error('请求错误：', error);
          // console.error('添加失败：', error.message); // 使用 error.message 处理错误
        });
      this.dialogVisible = false;
    },

    showScoreDialog(row) {
      this.selectedRow = row;
      this.scoreDialogVisible = !this.scoreDialogVisible;
      // 传递评分的ID给 ScoreDialog 组件
      this.scoreDialogScoringId = row.scoring_id; // 在打开弹窗时设置评分ID
      // 使用 nextTick 确保状态更新后再进行操作
      this.$nextTick(() => {
        // 传递评分的ID给 ScoreDialog 组件
        this.scoreDialogScoringId = row.scoring_id; // 在打开弹窗时设置评分ID
      });
    },
    showResultView(row) {
      this.selectedRow = row;
      this.showResultViewVisible = !this.showResultViewVisible;
      // 传递评分的ID给 ScoreDialog 组件
      this.showResultViewScoringId = row.scoring_id; // 在打开弹窗时设置评分ID
    },

    // handleScore(score) {
    //   this.selectedRow.score = score;
    //   this.scoreDialogVisible = false;
    //   this.selectedRow = null; // 重置 selectedRow
    // },
    handleCloseScoreDialog() {
      this.scoreDialogVisible = !this.scoreDialogVisible; // 关闭对话框时，设置为 false 隐藏对话框
      //window.location.reload();
    },
    handleCloseScoreDialog1() {
      this.showResultViewVisible = !this.showResultViewVisible; // 关闭对话框时，设置为 false 隐藏对话框

    },

      fetchScoringData() {// 根据 operatiom_id 构建动态请求 URL
        const requestUrl = `/Scoring/getScoring/${this.operatiom_id}`;
        // 发送 GET 请求到服务器
        axios
          .get(requestUrl)
          .then((response) => {
            // 根据需要处理服务器响应
            console.log('1111',response.data);
            if (response.data.status === 1) {
              // 服务器成功响应，使用接收的数据更新 tableData
           
              this.tableData = response.data.data.map((item) => ({
                
                assessor: item.assessor,
              registrationDate: item.date,
                whether: item.whether !== '未填写',
                scoring_id: item.scoring_id,
                Mental: item.Mental || '未评分',
                Physical: item.Physical || '未评分',
                koos_jr_scoring: item.koos_jr_scoring || '未评分',
                sf_MCS_scoring: item.sf_MCS_scoring || '未评分',
                sf_PCS_scoring: item.sf_PCS_scoring || '未评分',
                vas: item.vas2?.[0]?.sliderValue || '未评分',
                aks: item.aks_scoring || '未评分',
                womac: item.womac || '未评分',
                timestamp: item.timestamp,
              

              }));
        
            } else {
              // 服务器返回错误消息，根据需要进行处理
              // console.error('服务器返回错误：', response.data.message);
            }
          })
          .catch((error) => {
            // 处理请求错误，例如网络错误
            // console.error('请求错误：', error);
          });
      }



  },

  components: {
    ScoreDialog,
    Resultviewing,
  },
  created() {
    this.operatiom_id = this.$route.query.room;
   
    this.fetchScoringData(); // 在组件创建时获取数据
    this.operationData = this.$route.query.site;
    console.log( this.$route.query.site)
  },
};
</script>
<style scoped>
/* 自定义 el-dialog 样式 */
.custom-dialog {
  border-radius: 10px; /* 设置边框圆角 */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* 添加阴影效果 */
}

/* 自定义 dialog-footer 样式 */
.dialog-footer {
  text-align: center; /* 按钮居中对齐 */
}

/* 自定义 el-input 样式 */
.el-input {
  width: 100%; /* 宽度 100%，占满父容器 */
}

/* 自定义 el-date-picker 样式 */
.el-date-picker {
  width: 100%; /* 宽度 100%，占满父容器 */
}
.el-date-editor.el-input,.el-date-editor.el-input__inner {
  width: 100%; /* 宽度 100%，占满父容器 */
}
</style>