<!-- 术中信息填写第四部分，垫片选择那部分开始往下 -->
<template>
  <div class="part4insurgery">
    <el-row :gutter="10">
        <!-- 垫片选择 -->
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8"><div class="grid-content bg-purple">垫片选择</div></el-col>
            <el-col :xs="16" :sm="16" :md="16" :lg="16" :xl="16"><div class="grid-content " style="border: solid 1px rgba(134, 134, 134, 0.507)">
                <el-radio :disabled="othermodify" v-model="formvalue[0].radiovalue" label="1">旋转平台</el-radio>
                <el-radio :disabled="othermodify" v-model="formvalue[0].radiovalue" label="2">固定平台</el-radio></div>
            </el-col>
        </el-col>     
         <!-- 垫片选择 -->
         <!-- 胫骨平台骨质硬化 -->
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-col :xs="12" :sm="13" :md="6" :lg="4" :xl="4"><div class="grid-content bg-purple">胫骨平台骨质硬化</div></el-col>
            <el-col :xs="12" :sm="11" :md="6" :lg="8" :xl="8">
                <div class="grid-content" style="border: solid 1px rgba(134, 134, 134, 0.507)">
                    <el-radio :disabled="othermodify" v-model="formvalue[1].radiovalue" label="1">无</el-radio>
                    <el-radio :disabled="othermodify" v-model="formvalue[1].radiovalue" label="2">有</el-radio>
                </div>
            </el-col>
                
            <div v-show="formvalue[1].radiovalue==2">   
                <el-col :xs="10" :sm="10" :md="4" :lg="4" :xl="4"><div class="grid-content bg-purple">是否转孔</div></el-col>
                <el-col :xs="14" :sm="14" :md="8" :lg="8" :xl="8"><div class="grid-content" style="border: solid 1px rgba(134, 134, 134, 0.507)">
                    <el-radio :disabled="othermodify" v-model="formvalue[1].radiovalue2" label="1">无</el-radio>
                    <el-radio :disabled="othermodify" v-model="formvalue[1].radiovalue2" label="2">有</el-radio></div>
                </el-col>
            </div>
        </el-col>
            
        
       
        <!-- 胫骨假体旋转 -->
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"><div class="grid-content bg-purple">胫骨假体旋转</div></el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"><div class="grid-content bg-purple">
            <el-checkbox-group v-model="formvalue[2].ckvalue1">
                <el-checkbox :disabled="othermodify" v-for="(item,index) in yingzhihua" :key="index" :label="item">{{ item }}</el-checkbox>
            </el-checkbox-group>
        </div></el-col>
        <el-col :xs="6" :sm="6" :md="4" :lg="4" :xl="4" v-show="formvalue[2].ckvalue1.includes('其他')"><div class="grid-content bg-purple">其他</div></el-col>
        <el-col :xs="18" :sm="18" :md="20" :lg="20" :xl="20"  v-show="formvalue[2].ckvalue1.includes('其他')"><div class="grid-content ">
            <el-input :disabled="othermodify" v-model="formvalue[2].inputvalue" />
        </div></el-col>
         <!-- 胫骨假体旋转 -->
         <!-- 平台骨缺损 -->
         <el-col :xs="8" :sm="8" :md="8" :lg="4" :xl="4"><div class="grid-content bg-purple">平台骨缺损</div></el-col>
         <el-col :xs="16" :sm="16" :md="16" :lg="8" :xl="8">
            <div class="grid-content" style="border: solid 1px rgba(134, 134, 134, 0.507)">
              <el-radio :disabled="othermodify" v-model="formvalue[3].radiovalue" label="1">无</el-radio>
              <el-radio :disabled="othermodify" v-model="formvalue[3].radiovalue" label="2">有</el-radio>
            </div>
        </el-col>

        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"  v-show="formvalue[3].radiovalue==2">
            <el-row>
                <el-col  :xs="24" :sm="24" :md="24" :lg="8" :xl="8" >    
                <div class="grid-content">
                    <el-input :disabled="othermodify" v-model="formvalue[3].inputvalue1"><template slot="append">(ML)X</template></el-input>
                </div></el-col>
                <el-col  :xs="24" :sm="24" :md="24" :lg="8" :xl="8" >    
                <div class="grid-content">
                    <el-input :disabled="othermodify" v-model="formvalue[3].inputvalue2"><template slot="append">(AP)X</template></el-input>

                </div></el-col>
                <el-col  :xs="24" :sm="24" :md="24" :lg="8" :xl="8" >    
                <div class="grid-content">
                    <el-input :disabled="othermodify" v-model="formvalue[3].inputvalue3"><template slot="append">(深)mm</template></el-input>

                </div></el-col>
            </el-row>
            <!-- <el-col  :xs="24" :sm="24" :md="24" :lg="24" :xl="24" >    
                <div class="grid-content">
                    
                </div>
            </el-col> -->
            <el-col  :xs="24" :sm="24" :md="24" :lg="12" :xl="12" > 
                <div class="grid-content" style="border: solid 1px rgba(134, 134, 134, 0.507)">
                    <el-checkbox-group v-model="formvalue[3].checkvalue1">
                        <el-checkbox :disabled="othermodify" v-for="(item,index) in guque" :key="index" :label="item">
                            {{ item }}
                        </el-checkbox>
                    </el-checkbox-group>
                    <!-- <el-input :disabled="othermodify" v-model="formvalue[3].inputvalue4"><template slot="append">mm</template></el-input> -->
                </div>
            </el-col>  
            <el-col :xs="24" :sm="24" :md="24" :lg="11" :xl="11"  v-show="formvalue[3].checkvalue1.includes('垫块')">
                 <el-input :disabled="othermodify" v-model="formvalue[3].inputvalue4"><template slot="prepend">垫块大小</template><template slot="append">mm</template></el-input>
            </el-col>
        </el-col>    
          <!-- 平台骨缺损 -->
          <!-- 髌股关节稳定性 -->
    <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="padding: 0;"> 
        <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12" style="padding: 0;">
            <el-col :xs="24" :sm="8" :md="8" :lg="10" :xl="10"><div class="grid-content bg-purple">髌骨关节稳定性</div></el-col>
            <el-col :xs="24" :sm="16" :md="16" :lg="14" :xl="14"><div class="grid-content " style="border: solid 1px rgba(134, 134, 134, 0.507)">
                <el-radio :disabled="othermodify" v-model="formvalue[4].radiovalue" label="1">髌骨关节轨迹良好</el-radio>
                <el-radio :disabled="othermodify" v-model="formvalue[4].radiovalue" label="2">髌骨不稳</el-radio>
            </div>
            </el-col>
        </el-col>

        <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12" v-show="formvalue[4].radiovalue==2">
            <div class="grid-content">
                <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4"><div class="grid-content bg-purple" >处理</div></el-col>
            <el-col :xs="20" :sm="20" :md="20" :lg="20" :xl="20">
                <div class="grid-content " style="border: solid 1px rgba(134, 134, 134, 0.507)">
                    <el-checkbox-group v-model="formvalue[5].checkvalue1">
                        <el-checkbox :disabled="othermodify" v-for="(item,index) in wending" :key="index" :label="item">{{ item }}</el-checkbox>
                    </el-checkbox-group>
                </div>
            </el-col>
            </div>
        </el-col>
    </el-col>         
          <!-- 髌股关节稳定性 -->
          <!-- 髌骨 -->
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6"><div class="grid-content bg-purple">髌骨</div></el-col>
          <el-col :xs="18" :sm="18" :md="18" :lg="18" :xl="18"><div class="grid-content " style="border: solid 1px rgba(134, 134, 134, 0.507)">
            <el-checkbox-group v-model="formvalue[4].checkvalue1">
                <el-checkbox :disabled="othermodify" label="髌周去神经化"></el-checkbox>
            </el-checkbox-group>
          </div></el-col>
        </el-col>
           <!-- 髌骨 -->
           <!-- 髌骨置换 -->
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12"> 
            <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6"><div class="grid-content bg-purple">髌骨置换</div></el-col>
            <el-col :xs="18" :sm="18" :md="18" :lg="18" :xl="18"><div class="grid-content " style="border: solid 1px rgba(134, 134, 134, 0.507)">
                <el-radio :disabled="othermodify" v-model="formvalue[5].radiovalue" label="1">否</el-radio>
                <el-radio :disabled="othermodify" v-model="formvalue[5].radiovalue" label="2">是</el-radio>
            </div></el-col>
        </el-col>  

        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <!-- 髌骨厚度 -->
            <el-col :xs="6" :sm="6" :md="8" :lg="4" :xl="4" v-show="formvalue[5].radiovalue==2"><div class="grid-content bg-purple">髌骨厚度</div></el-col>
            <el-col :xs="18" :sm="18" :md="4" :lg="4" :xl="4" v-show="formvalue[5].radiovalue==2"><div class="grid-content ">
            <el-input :disabled="othermodify" v-model="formvalue[5].inputvalue1"><template slot="append">mm</template></el-input>
            </div></el-col>
            <!-- 截骨厚度 -->
            <el-col :xs="6" :sm="6" :md="8" :lg="4" :xl="4" v-show="formvalue[5].radiovalue==2"><div class="grid-content bg-purple">截骨厚度</div></el-col>
            <el-col :xs="18" :sm="18" :md="4" :lg="4" :xl="4" v-show="formvalue[5].radiovalue==2"><div class="grid-content ">
            <el-input :disabled="othermodify"  v-model="formvalue[5].inputvalue2"><template slot="append">mm</template></el-input>
            </div></el-col>
            <!-- 假体型号 -->
            <el-col :xs="6" :sm="6" :md="8" :lg="4" :xl="4" v-show="formvalue[5].radiovalue==2"><div class="grid-content bg-purple">假体型号</div></el-col>
            <el-col :xs="18" :sm="18" :md="4" :lg="4" :xl="4" v-show="formvalue[5].radiovalue==2"><div class="grid-content ">
            <el-input :disabled="othermodify"  v-model="formvalue[5].inputvalue3"></el-input>
            </div></el-col>
        </el-col>
           <!-- 髌骨置换 -->
            <!-- 假体型号 -->
            <!-- 骨水泥 -->
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">    
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" ><div class="grid-content bg-purple">骨水泥</div></el-col>
            <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4"><div class="grid-content bg-purple">品牌</div></el-col>
            <el-col :xs="20" :sm="20" :md="20" :lg="8" :xl="8" ><div class="grid-content " style="border: solid 1px rgba(134, 134, 134, 0.507)">
                <el-radio :disabled="othermodify" v-model="formvalue[6].radiovalue" label="1">Depuy</el-radio>
                <el-radio :disabled="othermodify" v-model="formvalue[6].radiovalue" label="2">Bioment</el-radio>
                <el-radio :disabled="othermodify" v-model="formvalue[6].radiovalue" label="3">其他</el-radio>
            </div>
            </el-col>
            <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4" v-show="formvalue[6].radiovalue==3"><div class="grid-content bg-purple">其他</div></el-col>
            <el-col :xs="20" :sm="20" :md="20" :lg="8" :xl="8" v-show="formvalue[6].radiovalue==3"><div class="grid-content ">
                <el-input :disabled="othermodify" v-model="formvalue[6].inputvalue1" />
            </div></el-col>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4"><div class="grid-content bg-purple">类型</div></el-col>
            <el-col :xs="20" :sm="20" :md="20" :lg="8" :xl="8" ><div class="grid-content" style="border: solid 1px rgba(134, 134, 134, 0.507)">
                <el-radio :disabled="othermodify" v-model="formvalue[6].radiovalue2" label="1">抗生素</el-radio>
                <el-radio :disabled="othermodify" v-model="formvalue[6].radiovalue2" label="2">非抗生素</el-radio>
            </div>
            </el-col>
            <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4" v-show="formvalue[6].radiovalue2==1"><div class="grid-content bg-purple">抗生素</div></el-col>
            <el-col :xs="20" :sm="20" :md="20" :lg="8" :xl="8" v-show="formvalue[6].radiovalue2==1"><div class="grid-content " style="border: solid 1px rgba(134, 134, 134, 0.507)">
                <el-radio :disabled="othermodify" v-model="formvalue[6].radiovalue4" label="1">庆大</el-radio>
                <el-radio :disabled="othermodify" v-model="formvalue[6].radiovalue4" label="2">万古</el-radio>
            </div></el-col>
        </el-col>


        <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4"><div class="grid-content bg-purple">粘度</div></el-col>
          <el-col :xs="20" :sm="20" :md="20" :lg="20" :xl="20" ><div class="grid-content " style="border: solid 1px rgba(134, 134, 134, 0.507)">
            <el-radio :disabled="othermodify" v-model="formvalue[6].radiovalue3" label="1">高</el-radio>
            <el-radio :disabled="othermodify" v-model="formvalue[6].radiovalue3" label="2">中</el-radio>
            <el-radio :disabled="othermodify" v-model="formvalue[6].radiovalue3" label="3">低</el-radio>
          </div>
        </el-col>
        
        
        <!-- 骨水泥 -->
        <!-- 垫片厚度 -->
        <el-col :xs="8" :sm="4" :md="4" :lg="4" :xl="4"><div class="grid-content bg-purple">垫片厚度</div></el-col>
        <el-col :xs="16" :sm="20" :md="8" :lg="8" :xl="8"><div class="grid-content">
            <el-input :disabled="othermodify" v-model="formvalue[7].inputvalue1" class="inh"><template slot="append">mm</template></el-input>
        </div></el-col>
        <!-- 引流管放置 -->
        <el-col :xs="8" :sm="4" :md="4" :lg="4" :xl="4"><div class="grid-content bg-purple">引流管放置</div></el-col>
        <el-col :xs="16" :sm="20" :md="8" :lg="8" :xl="8"><div class="grid-content" style="border: solid 1px rgba(134, 134, 134, 0.507)">
            <el-radio :disabled="othermodify" v-model="formvalue[7].radiovalue1" label="1">有</el-radio>
            <el-radio :disabled="othermodify" v-model="formvalue[7].radiovalue1" label="2">无</el-radio>
        </div></el-col>
        <!-- 氨甲环酸 -->
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="padding: 0;">
            <el-col :xs="8" :sm="4" :md="4" :lg="4" :xl="4"><div class="grid-content bg-purple">氨甲环酸</div></el-col>
            <el-col :xs="16" :sm="20" :md="8" :lg="8" :xl="8"><div class="grid-content " style="border: solid 1px rgba(134, 134, 134, 0.507)">
                <el-checkbox :disabled="othermodify" v-model="formvalue[7].checkvalue1">关节内注射</el-checkbox>
            </div></el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" v-show="formvalue[7].checkvalue1"><div class="grid-content ">
                <el-input :disabled="othermodify" v-model="formvalue[7].inputvalue2"><template slot="append">g/</template></el-input>
                <el-input :disabled="othermodify" v-model="formvalue[7].inputvalue3"><template slot="append">ml+</template></el-input>
                <el-input :disabled="othermodify" v-model="formvalue[7].inputvalue4"><template slot="append">ml罗哌卡因</template></el-input>
            </div></el-col>
        </el-col>     
 <!-- 引流管放置 -->
         <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-row :gutter="10">
                <el-col :xs="11" :sm="6" :md="8" :lg="4" :xl="4"><div class="grid-content bg-purple">鸡尾酒局部注射</div></el-col>
                <el-col :xs="13" :sm="18" :md="16" :lg="4" :xl="4"><div class="grid-content" style="border: solid 1px rgba(134, 134, 134, 0.507)">
                    <el-radio :disabled="othermodify" v-model="formvalue[8].radiovalue1" label="1">有</el-radio>
                    <el-radio  :disabled="othermodify" v-model="formvalue[8].radiovalue1" label="2">无</el-radio>
                    </div>
                </el-col>
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="padding: 0;">
                <el-row>
                    <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6" v-show="formvalue[8].radiovalue1==1"><div class="grid-content bg-purple">配方</div></el-col>
                    <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6" v-show="formvalue[8].radiovalue1==1"><div class="grid-content"><el-input :disabled="othermodify" v-model="formvalue[8].inputvalue1">
                            <template slot="prepend">吗啡</template>
                            <template slot="append">支</template>
                        </el-input></div></el-col>
                    <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6" v-show="formvalue[8].radiovalue1==1"><div class="grid-content"><el-input :disabled="othermodify"  v-model="formvalue[8].inputvalue2">
                            <template slot="prepend">得宝松</template>
                            <template slot="append">支</template>
                        </el-input></div></el-col>

                    <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6" v-show="formvalue[8].radiovalue1==1"><div class="grid-content"> <el-input  :disabled="othermodify" v-model="formvalue[8].inputvalue3">
                            <template slot="prepend">罗哌卡因</template>
                            <template slot="append">支</template>
                        </el-input></div></el-col>

                </el-row>
              
              </el-col>    
            </el-row>
       
        </el-col>
         <!-- 皮肤缝合方式 -->
        <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6"><div class="grid-content bg-purple">皮肤缝合方式</div></el-col>
        <el-col :xs="24" :sm="18" :md="18" :lg="18" :xl="18"><div class="grid-content " style="border: solid 1px rgba(134, 134, 134, 0.507);">
           <el-checkbox-group v-model="formvalue[9].checkvalue1" :min="0" :max="1" >
                <el-checkbox :disabled="othermodify" v-for="(item,index) in fenghe" :key="index" :label="item"> {{ item }}</el-checkbox>
           </el-checkbox-group>
        </div></el-col>

        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" v-show="formvalue[9].checkvalue1.includes('美容缝合')"><div class="grid-content" >
            <el-col :xs="11" :sm="6" :md="6" :lg="6" :xl="6" ><div class="grid-content bg-purple">{{formvalue[9].checkvalue1[0]}}</div></el-col>
            <el-col :xs="13" :sm="18" :md="18" :lg="18" :xl="18"><div class="grid-content" style="border: solid 1px rgba(134, 134, 134, 0.507)">
                <el-radio v-model="formvalue[9].radiovalue1" :disabled="othermodify" label="1">抗菌薇乔</el-radio>
                <el-radio  v-model="formvalue[9].radiovalue1" :disabled="othermodify" label="2">倒刺线</el-radio>
                <el-radio  v-model="formvalue[9].radiovalue1" :disabled="othermodify" label="3">其他</el-radio>
            </div></el-col>    
            </div>
        </el-col>
        <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6" v-show="formvalue[9].isshow1 && formvalue[9].checkvalue1.includes('美容缝合')"><div class="grid-content bg-purple">
          {{formvalue[9].radiovalue1 == '1' ? '抗菌薇乔':'倒刺线'}}
        </div>
        </el-col>
        <el-col :xs="24" :sm="18" :md="18" :lg="18" :xl="18" v-show="formvalue[9].isshow1 &&formvalue[9].checkvalue1.includes('美容缝合')"><div class="grid-content ">
            <el-radio v-model="formvalue[9].radiovalue2" :disabled="othermodify" label="1">4-0</el-radio>
                <el-radio  v-model="formvalue[9].radiovalue2" :disabled="othermodify" label="2">3-0</el-radio>
            <!-- <el-input :disabled="othermodify" v-model="formvalue[9].radiovalue2" /> -->
        </div>
    </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" v-show="formvalue[9].radiovalue1=='3' && formvalue[9].checkvalue1.includes('美容缝合')"><div class="grid-content bg-purple">
            <el-input :disabled="othermodify" v-model="formvalue[9].inputvalue1"><template slot="prepend">其他</template></el-input>
            </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" v-show=" formvalue[9].checkvalue1.includes('其他')"><div class="grid-content bg-purple">
            <el-input :disabled="othermodify" v-model="formvalue[9].inputvalue2"><template slot="prepend">其他</template></el-input>
            </div>
        </el-col>
        <!-- 膝关节活动度 -->
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"><div class="grid-content bg-purple">
                膝关节活动度
        </div></el-col>

        <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4"><div class="grid-content bg-purple">
                伸直
        </div></el-col>
         <el-col :xs="20" :sm="20" :md="8" :lg="8" :xl="8"><div class="grid-content">
                <el-input :disabled="othermodify" v-model="formvalue[10].inputvalue1"><template slot="append">°</template></el-input>
        </div></el-col>

        <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4"><div class="grid-content bg-purple">
                屈曲
        </div></el-col>
         <el-col :xs="20" :sm="20" :md="8" :lg="8" :xl="8"><div class="grid-content">
                <el-input :disabled="othermodify" v-model="formvalue[10].inputvalue2"><template slot="append">°</template></el-input>
        </div></el-col>

           <el-col :xs="10" :sm="6" :md="6" :lg="6" :xl="6"><div class="grid-content bg-purple">
                总体平衡情况
        </div></el-col>
           <el-col :xs="14" :sm="6" :md="8" :lg="4" :xl="4"><div class="grid-content" style="border: solid 1px rgba(134, 134, 134, 0.507)">
                 <el-radio :disabled="othermodify" v-model="formvalue[10].radiovalue1" label="1">理想</el-radio>
                 <el-radio :disabled="othermodify" v-model="formvalue[10].radiovalue1" label="2">其他</el-radio>
                </div>
            </el-col>
        
        <el-col :xs="24" :sm="6" :md="8" :lg="5" :xl="5" v-show="formvalue[10].radiovalue1==2">
            <div class="grid-content ">
            <el-input :disabled="othermodify" v-model="formvalue[10].inputvalue3"><template slot="append">°</template></el-input>
            </div>
        </el-col>
        <!-- 膝关节活动度 -->
        <!-- 组织送病理检查 -->
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <!-- <div class="grid-content"> -->
                <el-row >
                    <el-col :xs="10" :sm="6" :md="6" :lg="6" :xl="6">
                        <div class="grid-content bg-purple">
                        组织送病理检查
                        </div>
                    </el-col>
                    <el-col :xs="14" :sm="18" :md="6" :lg="6" :xl="6"><div class="grid-content" style="border: solid 1px rgba(134, 134, 134, 0.507)">
                        <el-radio :disabled="othermodify" v-model="formvalue[11].radiovalue1" label="1">无</el-radio>
                        <el-radio :disabled="othermodify" v-model="formvalue[11].radiovalue1" label="2">有</el-radio>
                        </div>
                    </el-col>
                    <!-- 输血 -->
                    <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
                        <div class="grid-content bg-purple">
                        输血
                        </div>
                    </el-col>
                    <el-col :xs="18" :sm="18" :md="6" :lg="6" :xl="6"><div class="grid-content" style="border: solid 1px rgba(134, 134, 134, 0.507)">
                        <el-radio :disabled="othermodify" v-model="formvalue[11].radiovalue2" label="1">无</el-radio>
                        <el-radio :disabled="othermodify" v-model="formvalue[11].radiovalue2" label="2">有</el-radio>
                        </div>
                    </el-col>
                    
                    <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" v-show="formvalue[11].radiovalue2==2">
                        <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
                            <el-input :disabled="othermodify" v-model="formvalue[11].inputvalue1">
                                <template slot="prepend">悬浮红细胞</template>
                                <template slot="append">U</template>
                            </el-input>
                        </el-col>
                        <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
                            <el-input :disabled="othermodify"  v-model="formvalue[11].inputvalue2">
                                <template slot="prepend">新浆</template>
                                <template slot="append">ml</template>
                            </el-input>
                        </el-col>
                        <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
                            <el-input :disabled="othermodify"  v-model="formvalue[11].inputvalue3">
                                <template slot="prepend">普浆</template>
                                <template slot="append">ml</template>
                            </el-input>
                        </el-col> 
                    </el-col>
                    <!-- 输血 -->
                </el-row>
            <!-- </div> -->
        </el-col>
        <!-- 组织送病理检查 -->
        <!-- 组织送标本库 -->
        <el-col :xs="10" :sm="6" :md="6" :lg="6" :xl="6"><div class="grid-content bg-purple">
                组织送标本库
        </div></el-col>
        <el-col :xs="14" :sm="18" :md="18" :lg="18" :xl="18"><div class="grid-content"  style="border: solid 1px rgba(134, 134, 134, 0.507)">
            <el-radio :disabled="othermodify" v-model="formvalue[12].radiovalue1" label="1">无</el-radio>
            <el-radio :disabled="othermodify" v-model="formvalue[12].radiovalue1" label="2">有</el-radio>
        </div>
        </el-col>
        
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"><div class="grid-content" style="border: solid 1px rgba(134, 134, 134, 0.507)" v-show="formvalue[12].radiovalue1==2">
            <el-checkbox-group v-model="formvalue[12].checkvalue1" >
                <el-checkbox :disabled="othermodify" v-for="city in biaobenku" :label="city" :key="city">{{city}}</el-checkbox>
            </el-checkbox-group>
        </div></el-col>

        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" v-show="formvalue[12].checkvalue1.includes('其他')"><div class="grid-content bg-purple">
            <el-input :disabled="othermodify" v-model="formvalue[12].inputvalue1"><template slot="prepend">其他</template></el-input>
            </div>
        </el-col>
        
        <!-- 组织送标本库 -->
        <!-- 术中并发症 -->
        <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6"><div class="grid-content bg-purple">
            术中并发症
        </div></el-col>
        <el-col :xs="24" :sm="18" :md="18" :lg="18" :xl="18"><div class="grid-content" style="border: solid 1px rgba(134, 134, 134, 0.507)">
            <el-checkbox-group v-model="formvalue[13].checkvalue1" >
                    <el-checkbox :disabled="othermodify" v-for="city in bingfaz" :label="city" :key="city">{{city}}</el-checkbox>
                </el-checkbox-group>
        </div></el-col>
        <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6" v-show="formvalue[13].checkvalue1.includes('骨折')"><div class="grid-content bg-purple">
            (并发症)骨折
        </div></el-col>
        <el-col :xs="24" :sm="24" :md="18" :lg="18" :xl="18" v-show="formvalue[13].checkvalue1.includes('骨折')"><div class="grid-content" style="border: solid 1px rgba(134, 134, 134, 0.507)">
            <el-checkbox-group v-model="formvalue[13].checkvalue2" >
                    <el-checkbox :disabled="othermodify" v-for="city in guz" :label="city" :key="city">{{city}}</el-checkbox>
                </el-checkbox-group>
        </div></el-col>
        <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6" v-show="formvalue[13].checkvalue1.includes('肌腱韧带损伤')"><div class="grid-content bg-purple">
            (并发症)肌腱韧带损伤
        </div></el-col>
        <el-col :xs="24" :sm="24" :md="18" :lg="18" :xl="18" v-show="formvalue[13].checkvalue1.includes('肌腱韧带损伤')"><div class="grid-content " style="border: solid 1px rgba(134, 134, 134, 0.507)">
            <el-checkbox-group v-model="formvalue[13].checkvalue3" >
                    <el-checkbox :disabled="othermodify" v-for="city in rendai" :label="city" :key="city">{{city}}</el-checkbox>
                </el-checkbox-group>
        </div></el-col>
        <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6" v-show="formvalue[13].checkvalue1.includes('其他')"><div class="grid-content bg-purple">
            (并发症)其他
        </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="18" :lg="18" :xl="18" v-show="formvalue[13].checkvalue1.includes('其他')"><div class="grid-content " style="border: solid 1px rgba(134, 134, 134, 0.507)">
            <el-checkbox-group v-model="formvalue[13].checkvalue4" >
                    <el-checkbox :disabled="othermodify" v-for="city in qita" :label="city" :key="city">{{city}}</el-checkbox>
                </el-checkbox-group>
        </div></el-col>
        <!-- 术中并发症 -->
        <!-- 假体类型 -->
        <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6"><div class="grid-content bg-purple">
           假体类型
        </div>
        </el-col>
        <el-col :xs="24" :sm="18" :md="18" :lg="18" :xl="18"><div class="grid-content"  style="border: solid 1px rgba(134, 134, 134, 0.507)">
            <el-checkbox-group v-model="formvalue[14].checkvalue1"  :min="0" :max="1">
                    <el-checkbox :disabled="othermodify" v-for="city in jiati" :label="city" :key="city">{{city}}</el-checkbox>
                </el-checkbox-group>
        </div></el-col>
        <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6" v-show="formvalue[14].checkvalue1.includes('其他')"><div class="grid-content bg-purple">
           其他假体类型
        </div>
        </el-col>
        <el-col :xs="24" :sm="18" :md="18" :lg="18" :xl="18"  v-show="formvalue[14].checkvalue1.includes('其他')"><div class="grid-content ">
                <el-input :disabled="othermodify" v-model="formvalue[14].inputvalue2" />
        </div>
    </el-col>
        <!-- 假体类型 -->
        <!--   厂家 -->
        <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6"><div class="grid-content bg-purple">
           厂家
        </div>
        </el-col>
        <el-col :xs="24" :sm="18" :md="18" :lg="18" :xl="18"><div class="grid-content" style="border: solid 1px rgba(134, 134, 134, 0.507)">
            <el-checkbox-group v-model="formvalue[14].checkvalue2"  :min="0" :max="1">
                    <el-checkbox :disabled="othermodify" v-for="city in changjia" :label="city" :key="city">{{city}}</el-checkbox>
                </el-checkbox-group>
        </div></el-col>
        <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6" v-show="formvalue[14].checkvalue2.includes('其他')"><div class="grid-content bg-purple">
           其他厂家
        </div>
        </el-col>
        <el-col :xs="24" :sm="18" :md="18" :lg="18" :xl="18"  v-show="formvalue[14].checkvalue2.includes('其他')"><div class="grid-content ">
                <el-input :disabled="othermodify" v-model="formvalue[14].inputvalue1" />
        </div>
    </el-col>
    <!--   厂家 -->
    <!-- 类型 -->
    <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6"><div class="grid-content bg-purple">
           类型
        </div>
        </el-col>
        <el-col :xs="24" :sm="18" :md="18" :lg="18" :xl="18"><div class="grid-content" style="border: solid 1px rgba(134, 134, 134, 0.507)">
            <el-checkbox-group v-model="formvalue[15].checkvalue1"  :min="0" :max="1">
                    <el-checkbox :disabled="othermodify" v-for="city in leix" :label="city" :key="city">{{city}}</el-checkbox>
                </el-checkbox-group>
        </div>
    </el-col>
    <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6" v-show="formvalue[15].checkvalue1.includes('其他')"><div class="grid-content bg-purple">
           其他
        </div>
        </el-col>
        <el-col :xs="24" :sm="18" :md="18" :lg="18" :xl="18" v-show="formvalue[15].checkvalue1.includes('其他')"><div class="grid-content ">
            <el-input :disabled="othermodify" v-model="formvalue[15].inputvalue1" />
        </div>
    </el-col>
    <!-- 类型 -->
<!-- 大小 -->
    <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" >
        <el-col :xs="24" :sm="24" :md="24" :lg="4" :xl="4" class="nopaddleft"><div class="grid-content bg-purple ">
           大小 
        </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="20" :xl="20"><div >
            <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
                <el-input :disabled="othermodify" v-model="formvalue[16].inputvalue1">
                    <template slot="prepend">股骨</template>
                    <template slot="append">号</template>
                </el-input>
           </el-col>   
            <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
                <el-input :disabled="othermodify" v-model="formvalue[16].inputvalue2">
                    <template slot="prepend">胫骨</template>
                    <template slot="append">号</template>
                </el-input>
            </el-col>    
            <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
                <el-input :disabled="othermodify"  v-model="formvalue[16].inputvalue3">
                    <template slot="prepend">垫片</template>
                    <template slot="append">mm</template>
                </el-input>
            </el-col>   
            <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
                <el-input :disabled="!this.formvalue[5].isedit"  v-model="formvalue[16].inputvalue4">
                    <template slot="prepend">髌骨</template>
                </el-input>
            </el-col>
        </div>
        </el-col>
    </el-col>
<!-- 大小 -->
<el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6" ><div class="grid-content bg-purple">
           其他
        </div>
        </el-col>
        <el-col :xs="24" :sm="18" :md="18" :lg="18" :xl="18" ><div class="grid-content ">
            <el-input type="textarea" :disabled="othermodify" v-model="formvalue[16].inputvalue5" />
        </div>
    </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
    props:["message","othermodify"],
    data(){
        return{
            // 胫骨平台骨质硬化
            yingzhihua:["胫骨结节","胫骨脊","尽可能靠内","Alagi","动态对位","其他"],
            // 平台骨缺损
            guque:["骨水泥填充","螺钉","植骨","垫块"],
            // 髌股关节稳定性
            wending:["松懈外侧支持带","髌骨置换"],
            // 皮肤缝合方式
            fenghe:["金属钉","美容缝合","其他"],
            // 组织送标本库
            biaobenku:["关节液","软骨","软骨下滑","滑膜","半月板","其他"],
            // 术中并发症
            bingfaz:["无","骨折","肌腱韧带损伤","血管损伤","其他"],
            // 中并发症 骨折
            guz:["股骨","胫骨","髌骨"],
            // 中并发症 韧带
            rendai:["髌韧带","肺栓塞","腘肌腱","LCL","MCL"], 
             // 中并发症 其他
            qita:["低血压","肺栓塞","心律不起","脑血管意外","心肌缺血","死亡"], 
            // 假体类型
            jiati:["PS","CR","CS",'VVC',"RHK",'其他'],
            // 厂家
            changjia:["Depuy","Stryker","Microport",'S&N',"其他"],
            // 类型
            leix:["Attune","PFC","Legion",'eMP',"Triathlon","其他"],
            formvalue:[
                {radiovalue:0},
                {radiovalue:0,radiovalue2:0},
                {ckvalue1:[],inputvalue:''},    
                {radiovalue:0,checkvalue1:[],inputvalue1:'',inputvalue2:'',inputvalue3:'',inputvalue4:''},
                {radiovalue:0,checkvalue1:[]},
                {checkvalue1:[],radiovalue:0,inputvalue1:'',inputvalue2:'',inputvalue3:'',isedit:false},
                {radiovalue:0,radiovalue2:0,radiovalue3:0,radiovalue4:0,inputvalue1:''},
                {radiovalue1:0,checkvalue1:false,inputvalue1:'',inputvalue2:'',inputvalue3:'',inputvalue4:''},
                {radiovalue1:0,inputvalue1:'',inputvalue2:'',inputvalue:''},
                {checkvalue1:[],radiovalue1:0,isshow1:false,inputvalue1:'',radiovalue2:'',inputvalue2:''},
                {radiovalue1:0,inputvalue1:'',inputvalue2:'',inputvalue3:''},
                {radiovalue1:0,radiovalue2:0,inputvalue1:'',inputvalue2:'',inputvalue3:''},
                {radiovalue1:0,checkvalue1:[],inputvalue1:''},
                {checkvalue1:[],checkvalue2:[],checkvalue3:[],checkvalue4:[]},
                {checkvalue1:[],checkvalue2:[],inputvalue1:'',inputvalue2:''},
                {checkvalue1:[],inputvalue1:''},
                {checkvalue1:[],inputvalue1:''},
                {inputvalue1:'',inputvalue2:'',inputvalue3:'',inputvalue4:'',inputvalue5:''}
            ]
        }
    },
    watch:{
        message:{
            handler(newvalue,oldvalue){
          for(let i =0;i<newvalue.length;i++){
            if(i==7){
            let obj  = newvalue[i]
          // console.log(obj,'obj')
          for(let key in obj){
            if(key=="checkvalue1"){
                this.formvalue[i][key]=obj[key] == 1 ? true:false
            }else{
                this.formvalue[i][key]=obj[key]
            }
            // console.log(obj[key],'objkey')
           
          }
            }else{
             let obj  = newvalue[i]
          // console.log(obj,'obj')
          for(let key in obj){
            // console.log(obj[key],'objkey')
            this.formvalue[i][key]=obj[key]
          }
            }
         
        }
            },deep:true
        },
        formvalue:{
            handler(val,oldvalue){
                val[9].isshow1 = val[9].radiovalue1==undefined? false:val[9].radiovalue1=="1" ? true:val[9].radiovalue1=="2" ? true:false
                // if(val[3].radiovalue=='1'){
                //     val[3].checkvalue1=[]
                //     val[3].inputvalue1=''
                //     val[3].inputvalue2=''
                //     val[3].inputvalue3=''
                //     val[3].inputvalue4=''
                // }
                this.$emit('part4',val)
            },deep:true
        },
        formvalue3(newvalue){
            if(newvalue=='1'){
                this.formvalue[3].checkvalue1=[]
                this.formvalue[3].inputvalue1=''
                this.formvalue[3].inputvalue2=''
                this.formvalue[3].inputvalue3=''
                this.formvalue[3].inputvalue4=''
                // console.log('[]')
            }
            // else{
            //     console.log('[pppp]')
            // }
        },
        bingu1(newvalue){
            console.log(newvalue)
            if(newvalue == '2'){
                this.formvalue[5].isedit = true
            }else{
                this.formvalue[5].isedit = false
            }
        }
    },
    computed:{
        formvalue3(){
            return this.formvalue[3].radiovalue
        },
        bingu1(){
            return this.formvalue[5].radiovalue
        }
    },
    mounted(){
        // console.log(window.innerWidth)
    },
    methods:{
        aa(value){
            // console.log(value)
        }
    }
};
</script>

<style scopd>
.inpwid{
    width: 10%;
}
.inputwidth{
    width: 20%;
}
.xuanfu{
width: 15%;
}
.part4insurgery .el-col{
    margin-bottom: 5px;
}
.nopaddleft{
    padding-left: 0px !important;
}
</style>