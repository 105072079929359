<!-- 术中信息表格组件 -->
<template>
  <div class="insurgerytable">
    <el-table
      :data="tableData"
      :header-cell-style="{ textAlign: 'center' }"
      :cell-style="{ 'text-align': 'center' }"
      style="width: 100%"
    >
      <el-table-column prop="title" label="软骨/韧带损伤程度评估" fixed width="100">
      </el-table-column>
      <el-table-column label="股骨远端" >
        <el-table-column prop="checked1" label="内髁">
          <template slot-scope="scope">
            <el-radio v-if="scope.row.title=='边缘骨赘'" :disabled="othermodify" v-model="radiovalue[0].r1" :label="'\'' + scope.$index + '\''">{{c}}</el-radio>
            <el-radio v-else :disabled="othermodify" v-model="radiovalue[0].r" :label="'\'' + scope.$index + '\''">{{c}}</el-radio>
          </template>
        </el-table-column>
        <el-table-column prop="checked2" label="外髁">
          <template slot-scope="scope">
            <el-radio v-if="scope.row.title=='边缘骨赘'" :disabled="othermodify" v-model="radiovalue[1].r1" :label="'\'' + scope.$index + '\''">{{c}}</el-radio>
            <el-radio v-else  :disabled="othermodify" v-model="radiovalue[1].r" :label="'\'' + scope.$index + '\''">{{c}}</el-radio>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="股骨滑车">
        <el-table-column prop="checked3" label="内侧">
          <template slot-scope="scope">
            <el-radio v-if="scope.row.title=='边缘骨赘'" :disabled="othermodify" v-model="radiovalue[2].r1" :label="'\'' + scope.$index + '\''">{{c}}</el-radio>
            <el-radio v-else :disabled="othermodify" v-model="radiovalue[2].r" :label="'\'' + scope.$index + '\''">{{c}}</el-radio>
          </template>
        </el-table-column>
        <el-table-column prop="checked4" label="外侧">
          <template slot-scope="scope">
            <el-radio v-if="scope.row.title=='边缘骨赘'" :disabled="othermodify" v-model="radiovalue[3].r1" :label="'\'' + scope.$index + '\''">{{c}}</el-radio>
            <el-radio v-else :disabled="othermodify" v-model="radiovalue[3].r" :label="'\'' + scope.$index + '\''">{{c}}</el-radio>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="股骨后髁">
        <el-table-column prop="checked5" label="内髁">
          <template slot-scope="scope">
            <el-radio v-if="scope.row.title=='边缘骨赘'" :disabled="othermodify" v-model="radiovalue[4].r1" :label="'\'' + scope.$index + '\''">{{c}}</el-radio>
            <el-radio v-else :disabled="othermodify" v-model="radiovalue[4].r" :label="'\'' + scope.$index + '\''">{{c}}</el-radio> 
            </template
        ></el-table-column>
        <el-table-column prop="checked6" label="外髁"
          ><template slot-scope="scope">
            <el-radio v-if="scope.row.title=='边缘骨赘'" :disabled="othermodify" v-model="radiovalue[5].r1" :label="'\'' + scope.$index + '\''">{{c}}</el-radio>
            <el-radio v-else :disabled="othermodify" v-model="radiovalue[5].r" :label="'\'' + scope.$index + '\''">{{c}}</el-radio>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="髌骨">
        <el-table-column prop="checked7" label="内侧"
          ><template slot-scope="scope">
            <el-radio v-if="scope.row.title=='边缘骨赘'" :disabled="othermodify" v-model="radiovalue[6].r1" :label="'\'' + scope.$index + '\''">{{c}}</el-radio>
            <el-radio v-else :disabled="othermodify" v-model="radiovalue[6].r" :label='"\"" + scope.$index + "\""'>{{c}}</el-radio>
          </template>
        </el-table-column>
        <el-table-column prop="checked8" label="外侧">
          <template slot-scope="scope">
            <el-radio v-if="scope.row.title=='边缘骨赘'" :disabled="othermodify" v-model="radiovalue[7].r1" :label="'\'' + scope.$index + '\''">{{c}}</el-radio>
            <el-radio v-else :disabled="othermodify" v-model="radiovalue[7].r" :label="'\'' + scope.$index + '\''">{{c}}</el-radio>
             </template
        ></el-table-column>
      </el-table-column>
      <el-table-column label="胫骨平台">
        <el-table-column prop="checked9" label="内侧">
          <template slot-scope="scope">
            <el-radio v-if="scope.row.title=='边缘骨赘'" :disabled="othermodify" v-model="radiovalue[8].r1" :label="'\'' + scope.$index + '\''">{{c}}</el-radio>
            <el-radio v-else :disabled="othermodify" v-model="radiovalue[8].r" :label="'\'' + scope.$index + '\''">{{c}}</el-radio> </template
        ></el-table-column>
        <el-table-column prop="checked10" label="外侧">
          <template slot-scope="scope">
            <el-radio v-if="scope.row.title=='边缘骨赘'" :disabled="othermodify" v-model="radiovalue[9].r1" :label="'\'' + scope.$index + '\''">{{c}}</el-radio>
            <el-radio v-else :disabled="othermodify" v-model="radiovalue[9].r" :label="'\'' + scope.$index + '\''">{{c}}</el-radio> </template
        ></el-table-column>
      </el-table-column>
    </el-table>
    <el-row :gutter="10">
      <el-col
        :xs="24"
        :sm="24"
        :md="24"
        :lg="24"
        :xl="12"
        v-for="(item, index) in checkform"
        :key="index"
        class="coldiv"
        >
        
          <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6"><div class="grid-content bg-purple">{{ item.name }}</div></el-col >
          
          <el-col :xs="24" :sm="24" :md="18" :lg="18" :xl="18">
            <div class="grid-content" style="border: solid 1px rgba(134, 134, 134, 0.507)">
              <el-checkbox-group v-model="chechvalue[index].value" :min="0" :max="1">
                <el-checkbox :disabled="othermodify" v-for="(check,index) in item.value" :label="check" :key="index">{{ check }}</el-checkbox>
              </el-checkbox-group>
            </div>
          </el-col>

       
      </el-col>
    </el-row>
  </div>
</template>
  
  <script>
export default {
  props:["message1","message2","othermodify"],
  data() {
    return {
      c:'',
      checkform: [
        { name: "内侧半月板", value: ["完好", "退变", "部分缺", "缺如"] },
        { name: "外侧半月板", value: ["完好", "退变", "部分缺", "缺如"] },
        { name: "前叉", value: ["完整", "退变", "部分断裂", "完全确实"] },
        { name: "后叉", value: ["完整", "退变", "部分断裂", "完全确实"] },
      ],
      chechvalue: [{ value: [] }, { value: [] }, { value: [] }, { value: [] }],
      radiovalue:[{r:'',r1:''},{r:'',r1:''},{r:'',r1:''},{r:'',r1:''},{r:'',r1:''},{r:'',r1:''},{r:'',r1:''},{r:'',r1:''},{r:'',r1:''},{r:'',r1:''}],
      // radiovalue:[{r:''},{r:''},{r:''},{r:''},{r:''},{r:''},{r:''},{r:''},{r:''},{r:''}],
      // tableData2:[
      // {
      //     title: "边缘骨赘",
      //     checked1: false,
      //     checked2: false,
      //     checked3: false,
      //     checked4: false,
      //     checked5: false,
      //     checked6: false,
      //     checked7: false,
      //     checked8: false,
      //     checked9: false,
      //     checked10: false,
      //   },
      // ],
      tableData: [
        {
          title: "基本完整",
          checked1: false,
          checked2: false,
          checked3: false,
          checked4: false,
          checked5: false,
          checked6: false,
          checked7: false,
          checked8: false,
          checked9: false,
          checked10: false,
        },
        {
          title: "轻度退变",
          checked1: false,
          checked2: false,
          checked3: false,
          checked4: false,
          checked5: false,
          checked6: false,
          checked7: false,
          checked8: false,
          checked9: false,
          checked10: false,
        },
        {
          title: "部分脱落",
          checked1: false,
          checked2: false,
          checked3: false,
          checked4: false,
          checked5: false,
          checked6: false,
          checked7: false,
          checked8: false,
          checked9: false,
          checked10: false,
        },
        {
          title: "大面积脱落骨外漏硬化",
          checked1: false,
          checked2: false,
          checked3: false,
          checked4: false,
          checked5: false,
          checked6: false,
          checked7: false,
          checked8: false,
          checked9: false,
          checked10: false,
        },
        {
          title: "边缘骨赘",
          checked1: false,
          checked2: false,
          checked3: false,
          checked4: false,
          checked5: false,
          checked6: false,
          checked7: false,
          checked8: false,
          checked9: false,
          checked10: false,
        },
      ],
    };
  },
  mounted(){
     this.$emit('insurgerytable',this.radiovalue)
       this.$emit('insurgery',this.radiovalue)
  },
  watch: {
    radiovalue: {
      handler(newvalue, oldvalue) {
        this.$emit('insurgerytable',newvalue)
      },
      deep: true,
    },
    chechvalue:{
      handler(value,old){
        this.$emit('insurgery',value)
      },deep:true
    },
    message1:{
      handler(value,old){
        for(let i=0;i<value.length;i++){
          let obj = value[i]
          // console.log(obj)
          for(let key in obj){
             this.chechvalue[i][key]=obj[key]
          }
         
        }
        // console.log(value,"message1")
      },deep:true
    },
    message2:{
      handler(value,old){
        for(let i=0;i<value.length;i++){
          let obj = value[i]
          for(let key in obj){
            this.radiovalue[i][key] = obj[key]
          }
        }
      },deep:true
    }
  },
};
</script>
<style scoped>
.coldiv .el-col{
  margin-top: 2px; 
  margin-bottom: 0px;
}
</style>