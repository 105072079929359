<!-- 家族史填写 -->
<template>
  <div class="familyhistory">
    <!-- 弹窗步骤填写 -->
    <el-dialog title="提示" :visible.sync="dialogVisible" :width="dialogwid">
      <el-progress :percentage="count * 20" :color="customColors"></el-progress>
      
      <div v-for="(item, index) in radioform" :key="index" v-show="index + 1 == count" style="border-bottom: 1px solid rgb(223 221 221);">
        <div class="grid-content-left" >
          <div class="from-topic">{{ item.name }} :</div>
          <el-checkbox-group
              v-model="watchvalue[index].radiovalue"
              size="medium"
            >
              <el-checkbox 
              v-for="(checkbox, checkboxindex) in item.value"
              :key="checkboxindex"
              :label="checkbox.label" 
              @change="changecheckbox(item, checkbox)"
              border 
              size="medium" 
              style="margin: 0 5px 10px 0; padding:7px;"
              >{{ checkbox.label }}</el-checkbox>
            </el-checkbox-group>
        </div>

        <div v-for="(createinput, inputindex) in item.value" :key="inputindex">

          <div  class="selcol" v-if="createinput.isinput" v-show="createinput.checked">

            <div class="grid-content-left" v-show="createinput.label == '肿瘤'"> 
              <div class="from-topic">肿瘤：</div>
              <el-input v-model="watchvalue[index].inputvalue"></el-input>
            </div>

            <div class="grid-content-left" v-show="createinput.label == '自身免疫性疾病'"> 
              <div class="from-topic">自身免疫性疾病：</div>
              <el-input v-model="watchvalue[index].inputvalue2"></el-input>
            </div>
          </div>
        </div>
      </div>





      <el-row
        :gutter="10"
        v-for="(item, index) in radioform"
        :key="index"
        v-show="false"
      >
        <el-col :xs="24" :sm="24" :md="2" :lg="3" :xl="3">
          <div class="grid-content bg-purple">{{ item.name }}</div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="2" :lg="21" :xl="21">
          <div class="grid-content checkboxdiv">
            <el-checkbox-group v-model="watchvalue[index].radiovalue">
              <el-checkbox
                v-for="(checkbox, checkboxindex) in item.value"
                :key="checkboxindex"
                :label="checkbox.label"
                @change="changecheckbox(item, checkbox)"
              >
                {{ checkbox.label }}
              </el-checkbox>
            </el-checkbox-group>
          </div>
        </el-col>
        <el-col
          :xs="24"
          :sm="24"
          :md="2"
          :lg="24"
          :xl="24"
          v-for="(createinput, inputindex) in item.value"
          :key="inputindex"
        >
          <el-col
            class="selcol"
            :xs="24"
            :sm="24"
            :md="2"
            :lg="3"
            :xl="3"
            v-if="createinput.isinput"
            v-show="createinput.checked"
          >
            <div class="grid-content bg-purple">{{createinput.label}}：</div>
          </el-col>
          <el-col
            class="selcol"
            :xs="24"
            :sm="24"
            :md="2"
            :lg="21"
            :xl="21"
            v-if="createinput.isinput"
            v-show="createinput.checked"
          >
            <div class="grid-content checkboxdiv" v-show="createinput.label == '肿瘤'"> 
              <el-input v-model="watchvalue[index].inputvalue"></el-input>
            </div>
            <div class="grid-content checkboxdiv" v-show="createinput.label == '自身免疫性疾病'"> 
              <el-input v-model="watchvalue[index].inputvalue2"></el-input>
            </div>
          </el-col>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="backstep" :disabled="this.count == 1" type="warning"
          >上一步</el-button
        >
        <el-button
          @click="nextstep"
          :disabled="this.count == this.radioform.length"
          type="primary"
          >下一步</el-button
        >
        <el-button type="danger" @click="exitfillin">确 定</el-button>
      </span>
    </el-dialog>



    <div style="width: 100%; overflow: auto;" v-show="tofillin">
      <div v-for="(item, index) in radioform" :key="index" style="border-bottom: 1px solid rgb(223 221 221);">
        <div class="grid-content-left" >
          <div class="from-topic">{{ item.name }} :</div>
          <el-checkbox-group
              v-model="watchvalue[index].radiovalue"
              size="medium"
            >
              <el-checkbox 
              v-for="(checkbox, checkboxindex) in item.value"
              :key="checkboxindex"
              :label="checkbox.label" 
              @change="changecheckbox(item, checkbox)"
              :disabled="modify"
              border 
              size="medium" 
              style="margin: 0 5px 10px 0; padding:7px;"
              >{{ checkbox.label }}</el-checkbox>
            </el-checkbox-group>
        </div>

        <div v-for="(createinput, inputindex) in item.value" :key="inputindex">

          <div  class="selcol" v-if="createinput.isinput" v-show="createinput.checked">

            <div class="grid-content-left" v-show="createinput.label == '肿瘤'"> 
              <div class="from-topic">肿瘤：</div>
              <el-input v-model="watchvalue[index].inputvalue"></el-input>
            </div>

            <div class="grid-content-left" v-show="createinput.label == '自身免疫性疾病'"> 
              <div class="from-topic">自身免疫性疾病：</div>
              <el-input v-model="watchvalue[index].inputvalue2"></el-input>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 旧的 -->
    <div class="allradioshow" v-show="false">
      <el-row>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <div class="grid-content bg-purple">家族史</div>
        </el-col>
      </el-row>
      <el-row :gutter="10" v-for="(item, index) in radioform" :key="index">
        <el-col :xs="24" :sm="6" :md="2" :lg="3" :xl="3">
          <div class="grid-content bg-purple">{{ item.name }}</div>
        </el-col>
        <el-col :xs="24" :sm="14" :md="14" :lg="21" :xl="21">
          <div class="grid-content checkboxdiv">
            <el-checkbox-group v-model="watchvalue[index].radiovalue">
              <el-checkbox
                v-for="(checkbox, checkboxindex) in item.value"
                :key="checkboxindex"
                :label="checkbox.label"
                @change="changecheckbox(item, checkbox)"
                :disabled="modify"
              >
                {{ checkbox.label }}
              </el-checkbox>
            </el-checkbox-group>
          </div>
        </el-col>
        <el-col
          :xs="24"
          :sm="24"
          :md="2"
          :lg="24"
          :xl="24"
          v-for="(createinput, inputindex) in item.value"
          :key="inputindex"
        >
          <el-col
            class="selcol"
            :xs="24"
            :sm="24"
            :md="2"
            :lg="3"
            :xl="3"
            v-if="createinput.isinput"
            v-show="createinput.checked"
          >
            <div class="grid-content bg-purple">{{createinput.label}}：</div>
          </el-col>
          <el-col
            class="selcol"
            :xs="24"
            :sm="24"
            :md="2"
            :lg="21"
            :xl="21"
            v-if="createinput.isinput"
            v-show="createinput.checked"
          >
          <div class="grid-content checkboxdiv" v-show="createinput.label == '肿瘤'"> 
              <el-input v-model="watchvalue[index].inputvalue" :disabled="modify"></el-input>
            </div>
            <div class="grid-content checkboxdiv" v-show="createinput.label == '自身免疫性疾病'"> 
              <el-input v-model="watchvalue[index].inputvalue2"  :disabled="modify"></el-input>
            </div>
          </el-col>
        </el-col>
      </el-row>
    </div>
    <div class="modify" v-show="tofillin">
     
      <el-button type="primary" icon="el-icon-edit" circle @click="tomodify" v-show="modify"></el-button>
      <!-- <el-button type="warning" 
          >修改</el-button
        > -->
      <div v-show="!modify" >
        <el-row>
          <el-button type="success" icon="el-icon-check" circle @click="confirm"></el-button>
        </el-row>
        <el-row>
          <el-button type="danger" icon="el-icon-close" circle @click="cancel"></el-button>
        </el-row>
        <!-- <el-button type="success" @click="confirm">确定</el-button -->
        <!-- <el-button type="danger" @click="cancel">取消</el-button> -->
      </div>
    </div>

    <div v-show="!tofillin">
      <el-button type="primary" @click="showfillin"> 去填写 </el-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogwid:"60%",
      radioform: [
        {
          name: "父亲",
          value: [
            { label: "骨质疏松", value: "", isinput: false, checked: false },
            {
              label: "骨关节炎(膝)",
              value: "",
              isinput: false,
              checked: false,
            },
            {
              label: "骨关节炎(髋)",
              value: "",
              isinput: false,
              checked: false,
            },
            { label: "肿瘤", value: "", isinput: true, checked: false },
            {
              label: "自身免疫性疾病",
              value: "",
              isinput: true,
              checked: false,
            },
            { label: "不详", value: "", isinput: false, checked: false },
          ],
          radiovalue: [],
          inputvalue: "",
        },
        {
          name: "母亲",
          value: [
            { label: "骨质疏松", value: "", isinput: false, checked: false },
            {
              label: "骨关节炎(膝)",
              value: "",
              isinput: false,
              checked: false,
            },
            {
              label: "骨关节炎(髋)",
              value: "",
              isinput: false,
              checked: false,
            },
            { label: "肿瘤", value: "", isinput: true, checked: false },
            {
              label: "自身免疫性疾病",
              value: "",
              isinput: true,
              checked: false,
            },
            { label: "不详", value: "", isinput: false, checked: false },
          ],
          radiovalue: [],
          inputvalue: "",
        },
        {
          name: "爷爷",
          value: [
            { label: "骨质疏松", value: "", isinput: false, checked: false },
            {
              label: "骨关节炎(膝)",
              value: "",
              isinput: false,
              checked: false,
            },
            {
              label: "骨关节炎(髋)",
              value: "",
              isinput: false,
              checked: false,
            },
            { label: "肿瘤", value: "", isinput: true, checked: false },
            {
              label: "自身免疫性疾病",
              value: "",
              isinput: true,
              checked: false,
            },
            { label: "不详", value: "", isinput: false, checked: false },
          ],
          radiovalue: [],
          inputvalue: "",
        },
        {
          name: "奶奶",
          value: [
            { label: "骨质疏松", value: "", isinput: false, checked: false },
            {
              label: "骨关节炎(膝)",
              value: "",
              isinput: false,
              checked: false,
            },
            {
              label: "骨关节炎(髋)",
              value: "",
              isinput: false,
              checked: false,
            },
            { label: "肿瘤", value: "", isinput: true, checked: false },
            {
              label: "自身免疫性疾病",
              value: "",
              isinput: true,
              checked: false,
            },
            { label: "不详", value: "", isinput: false, checked: false },
          ],
          radiovalue: [],
          inputvalue: "",
        },
        {
          name: "兄弟姐妹",
          value: [
            { label: "骨质疏松", value: "", isinput: false, checked: false },
            {
              label: "骨关节炎(膝)",
              value: "",
              isinput: false,
              checked: false,
            },
            {
              label: "骨关节炎(髋)",
              value: "",
              isinput: false,
              checked: false,
            },
            { label: "肿瘤", value: "", isinput: true, checked: false },
            {
              label: "自身免疫性疾病",
              value: "",
              isinput: true,
              checked: false,
            },
            { label: "不详", value: "", isinput: false, checked: false },
          ],
          radiovalue: [],
          inputvalue: "",
        },
      ],

      customColors: [
        { color: "#1989fa", percentage: 80 },
        { color: "#6f7ad3", percentage: 100 },
      ],
      tofillin: false,
      dialogVisible: false,
      count: 1,
      modify: true,
      watchvalue: [
        { radiovalue: [], inputvalue: "",inputvalue2:"" },
        { radiovalue: [], inputvalue: "",inputvalue2:"" },
        { radiovalue: [], inputvalue: "",inputvalue2 :"" },
        { radiovalue: [], inputvalue: "",inputvalue2 :"" },
        { radiovalue: [], inputvalue: "" ,inputvalue2:"" },
      ],
      operatiom_id: "",
    };
  },
  // watch: {
  //   radioform: {
  //     handler(value, oldvalue) {
  //       this.watchvalue = JSON.parse(family(value));
  //       console.log(this.watchvalue, "www");
  //     },
  //     deep: true,
  //   },
  // },
  created(){
    this.operatiom_id = this.$route.query.room;
  },
  mounted() {
   this.getinfo()
   let wid = window.innerWidth
    // console.log(wid)
    this.dialogwid = wid <=480 ? "90%":wid>=1200 ? "60%":"80%"
  },
  methods: {
    async getinfo(){
      const { data: res } = await this.$http.get(
        "/Operation/getPreOperationInfo/" + this.operatiom_id,{
          params:{
            "pre_type":"家族史"
          }
        }
      );
      if(res.status =="1"){
        if(res.data.family_history){
          this.tofillin = true
          let value = res.data.family_history
          for(let i=0;i<value.length;i++){
            let obj = value[i]
            this.watchvalue[i].radiovalue = obj.radiovalue
            this.watchvalue[i].inputvalue = obj.inputvalue
            this.watchvalue[i].inputvalue2 = obj.inputvalue2
           let includevalue = obj.radiovalue
           includevalue.filter(item =>{
            if(item == "肿瘤"){
              this.radioform[i].value[2].checked = true
            }
            if(item=="自身免疫性疾病"){
              this.radioform[i].value[3].checked = true
            }
           } )
          }
        }
      }
      // console.log(res,'家族史')
    },
    changecheckbox(goods, option) {
      option.checked = !option.checked;
    },
    // 展示弹窗进行步骤填写
    showfillin() {
      // this.tofillin = true
      this.dialogVisible = true;
    },
    // 下一步
    nextstep() {
      this.count =
        this.count >= this.radioform.length
          ? this.radioform.length
          : this.count + 1;
    },
    // 上一步
    backstep() {
      this.count = this.count <= 1 ? 1 : this.count - 1;
    },
    // 退出步骤填写显示填写结果
    async exitfillin() {
      this.dialogVisible = false;
      this.tofillin = true;
      const { data: res } = await this.$http.post(
        "/Operation/addPreOperationInfo/" + this.operatiom_id,
        {
          family_history: this.watchvalue,
        }
      );
      // console.log(res)
      if(res.status=="1"){
          this.$message.success("提交成功")
      }else{
        this.$message.success("提交失败")
      }
      await this.getinfo()
    },
    // 修改按钮
    tomodify() {
      this.modify = false;
    },
    // 确定修改
   async confirm() {
      this.modify = true;
      const { data: res } = await this.$http.post(
        "/Operation/addPreOperationInfo/" + this.operatiom_id,
        {
          family_history: this.watchvalue,
        }
      );
      // console.log(res)
      if(res.status=="1"){
          this.$message.success("提交成功")
      }else{
        this.$message.success("提交失败")
      }
      await this.getinfo()
    },
    // 取消修改按钮
    cancel() {
      this.modify = true;
    },
  },
};
</script>

<style scoped>
.familyhistory {
  /* position: relative; */
  height: fit-content;
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
}

.familyhistory .grid-content-left{
  display: flex;
  align-items: baseline;
  margin: 10px 0;
}
.familyhistory .el-input-group__append, .el-input-group__prepend{
  background-color: unset;
}
.familyhistory .from-topic{
  min-width:140px;
}
.familyhistory .el-radio {
  margin: 0px 5px 10px 0px !important; 
  padding: 12px 7px 0 7px !important; ;
}

.allradioshow {
  width: 100%;
  height: fit-content;
}
.checkboxdiv {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  /* padding: 0px 10px;  */
}

.selcol {
  margin-top: 5px;
  padding-left: 0px !important;
  padding-right: 10px !important;
}
.modify {
  position: absolute;
  top: 60%;
  right: 15%;
 
}
.aa{
  display: flex;
  flex-direction: column;
}
.aa .el-button{
margin-bottom: 5px;
margin-left: 0;
}
@media screen and (max-width: 480px) {
  .modify {
    position: absolute;
    top: 75%;
    right: 5%;
  }
  .familyhistory .grid-content-left {
    display: grid;
    margin: 10px 0 !important;
    justify-content: start !important;
  }
  .familyhistory .from-topic{
    margin: 10px 0 !important;
    text-align: left !important;
  }
}
</style>