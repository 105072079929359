<!-- 机器人手术组件 -->
<template>
  <div class="robotic">
    <el-row :gutter="10">
      <!-- 活动度 -->
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"><div class="grid-content bg-purple">初始评估</div></el-col>

        <div class="hdld hdldbtn">
          <el-col  :xs="6" :sm="6" :md="6" :lg="6" :xl="6" class="hdldbtn"><div class="grid-content bg-purple-light">活动度</div></el-col>
          <el-col  :xs="18" :sm="18" :md="18" :lg="18" :xl="18" class="hdldbtn">
            <div class="grid-content">
              <el-input :disabled="othermodify" v-model="value[0].input1">
                <template slot="append">°</template>
              </el-input>
              <!-- <div class="w">°-</div> -->
              <el-input :disabled="othermodify"  v-model="value[0].input2">
                <template slot="append">°</template>
              </el-input> 
              <!-- <div class="w">°-</div> -->           
              <el-input :disabled="othermodify"  v-model="value[0].input3">
                <template slot="append">°</template>
              </el-input>
            </div>
          </el-col>
      <!-- 活动度 -->
      <!-- 重力下屈曲 -->
          <el-col  :xs="8" :sm="8" :md="6" :lg="6" :xl="6" class="hdldbtn"><div class="grid-content bg-purple-light">重力下屈曲</div></el-col>
          <el-col  :xs="16" :sm="16" :md="18" :lg="18" :xl="18" class="hdldbtn"><div class="grid-content ">
            <el-input :disabled="othermodify"  v-model="value[0].input4"><template slot="append">°</template></el-input>
          </div>
          </el-col>
        </div>
      <!-- <el-col  :xs="8" :sm="6" :md="24" :lg="24" :xl="24"><div class="grid-content bg-purple-light">重力下屈曲</div></el-col> -->

        <!-- 重力下屈曲 -->
        <!-- 力线 -->
      <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4"><div class="grid-content bg-purple-light">力线</div></el-col>
      <el-col :xs="24" :sm="24" :md="20" :lg="20" :xl="20">
        <el-col :xs="24" :sm="24" :md="10" :lg="10" :xl="10" class="grid-content" style="border: solid 1px rgba(134, 134, 134, 0.507)">
          <el-checkbox-group v-model="value[1].value1" :min="0" :max="1">
            <el-checkbox v-for="livalue in lixian" :label="livalue" :key="livalue"  :disabled="othermodify" >{{livalue}}</el-checkbox>
          </el-checkbox-group>
        </el-col>  
        <el-col :xs="24" :sm="24" :md="10" :lg="10" :xl="10" class="grid-content" style="border: solid 1px rgba(134, 134, 134, 0.507)">  
          <el-checkbox-group v-model="value[1].value2"  :min="0" :max="1">
            <el-checkbox v-for="livalue1 in lixian2" :label="livalue1" :key="livalue1" :disabled="othermodify" >{{livalue1}}</el-checkbox>
          </el-checkbox-group>
        </el-col>
    </el-col>
      <!-- 内翻 -->
      <el-col :xs="6" :sm="6" :md="4" :lg="4" :xl="4" v-if="value[1].isshow1"><div class="grid-content  bg-purple-light">内翻</div></el-col>
      <el-col :xs="18" :sm="18" :md="8" :lg="8" :xl="8"  v-if="value[1].isshow1"><div class="grid-content ">
          <el-input :disabled="othermodify"  v-model="value[1].input1" ><template slot="append">°</template></el-input>
        </div>
      </el-col>
      <!-- 外翻 -->
      <el-col :xs="6" :sm="6" :md="4" :lg="4" :xl="4" v-if="value[1].isshow2"><div class="grid-content  bg-purple-light">外翻</div></el-col>
      <el-col  :xs="18" :sm="18" :md="8" :lg="8" :xl="8"  v-if="value[1].isshow2"><div class="grid-content ">
          <el-input :disabled="othermodify"  v-model="value[1].input2" ><template slot="append">°</template></el-input>
        </div>
      </el-col>
      <!-- 屈伸 -->
      <el-col :xs="6" :sm="6" :md="4" :lg="4" :xl="4" v-if="value[1].isshow3"><div class="grid-content  bg-purple-light">屈伸</div></el-col>
      <el-col :xs="18" :sm="18" :md="8" :lg="8" :xl="8"  v-if="value[1].isshow3"><div class="grid-content ">
          <el-input :disabled="othermodify"  v-model="value[1].input3" ><template slot="append">°</template></el-input>
        </div>
      </el-col>
      <!-- 过伸 -->
      <el-col :xs="6" :sm="6" :md="4" :lg="4" :xl="4" v-if="value[1].isshow4"><div class="grid-content  bg-purple-light">过伸</div></el-col>
      <el-col  :xs="18" :sm="18" :md="8" :lg="8" :xl="8"  v-if="value[1].isshow4"><div class="grid-content ">
          <el-input :disabled="othermodify"  v-model="value[1].input4" ><template slot="append">°</template></el-input>
        </div>
      </el-col>
        <!-- 力线 -->
        <!-- TEA -->
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <el-col :xs="10" :sm="10" :md="8" :lg="4" :xl="4"><div class="grid-content bg-purple-light">TEA</div></el-col>
          <el-col  :xs="14" :sm="14" :md="16" :lg="4" :xl="4"><div class="grid-content">
            <el-input :disabled="othermodify"  v-model="value[2].input1" ><template slot="append">°</template></el-input>
          </div>
          </el-col>
            <!-- TEA -->
            <!-- 股骨假体屈曲 -->
            <el-col :xs="10" :sm="10" :md="8" :lg="4" :xl="4"><div class="grid-content  bg-purple-light">股骨假体屈曲</div></el-col>
          <el-col  :xs="14" :sm="14" :md="16" :lg="4" :xl="4"><div class="grid-content ">
            <el-input :disabled="othermodify"  v-model="value[2].input2" ><template slot="append">°</template></el-input>
          </div>
          </el-col>
          <!-- 股骨假体屈曲 -->
            <!-- 胫骨假体后倾 -->
            <el-col :xs="10" :sm="10" :md="8" :lg="4" :xl="4"><div class="grid-content  bg-purple-light">胫骨假体后倾</div></el-col>
          <el-col  :xs="14" :sm="14" :md="16" :lg="4" :xl="4"><div class="grid-content ">
            <el-input :disabled="othermodify"  v-model="value[2].input3" ><template slot="append">°</template></el-input>
          </div>
          </el-col>
        </el-col>  
         <!-- 胫骨假体后倾 -->
         <!-- 初始间隙 -->
         <!-- 伸（内） -->
         <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"><div class="grid-content bg-purple">初始间隙</div></el-col>
         <el-col :xs="6" :sm="4" :md="4" :lg="4" :xl="4"><div class="grid-content  bg-purple-light">伸(内)</div></el-col>
          <el-col  :xs="18" :sm="8" :md="8" :lg="8" :xl="8"><div class="grid-content ">
          <el-input :disabled="othermodify"  v-model="value[3].input1"  ><template slot="append">°</template></el-input>
        </div>
        </el-col>
        <!-- 伸（外） -->
        <el-col :xs="6" :sm="4" :md="4" :lg="4" :xl="4"><div class="grid-content  bg-purple-light">伸(外)</div></el-col>
          <el-col  :xs="18" :sm="8" :md="8" :lg="8" :xl="8"><div class="grid-content ">
          <el-input :disabled="othermodify"  v-model="value[3].input2"  ><template slot="append">°</template></el-input>
        </div>
        </el-col>
        <!-- 屈内 -->
        <el-col :xs="6" :sm="4" :md="4" :lg="4" :xl="4"><div class="grid-content  bg-purple-light">屈(内)</div></el-col>
          <el-col  :xs="18" :sm="8" :md="8" :lg="8" :xl="8"><div class="grid-content ">
          <el-input :disabled="othermodify"  v-model="value[3].input3"  ><template slot="append">°</template></el-input>
        </div>
        </el-col>
        <!-- 屈外 -->
        <el-col :xs="6" :sm="4" :md="4" :lg="4" :xl="4"><div class="grid-content  bg-purple-light">屈(外)</div></el-col>
          <el-col  :xs="18" :sm="8" :md="8" :lg="8" :xl="8"><div class="grid-content ">
          <el-input :disabled="othermodify"  v-model="value[3].input4" ><template slot="append">°</template></el-input>
        </div>
        </el-col>
       
         <!-- 初始间隙 -->
          <!-- 应力间隙 -->
         <!-- 伸（内） -->
         <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"><div class="grid-content bg-purple">应力间隙</div></el-col>
         <el-col :xs="6" :sm="4" :md="4" :lg="4" :xl="4"><div class="grid-content  bg-purple-light">伸(内)</div></el-col>
          <el-col  :xs="18" :sm="8" :md="8" :lg="8" :xl="8"><div class="grid-content ">
          <el-input :disabled="othermodify"  v-model="value[4].input1"><template slot="append">°</template></el-input>
        </div>
        </el-col>
        <!-- 伸（外） -->
        <el-col :xs="6" :sm="4" :md="4" :lg="4" :xl="4"><div class="grid-content  bg-purple-light">伸(外)</div></el-col>
          <el-col :xs="18" :sm="8" :md="8" :lg="8" :xl="8"><div class="grid-content ">
          <el-input :disabled="othermodify"  v-model="value[4].input2"><template slot="append">°</template></el-input>
        </div>
        </el-col>
        <!-- 屈内 -->
        <el-col :xs="6" :sm="4" :md="4" :lg="4" :xl="4"><div class="grid-content  bg-purple-light">屈(内)</div></el-col>
          <el-col  :xs="18" :sm="8" :md="8" :lg="8" :xl="8"><div class="grid-content ">
          <el-input :disabled="othermodify"  v-model="value[4].input3"><template slot="append">°</template></el-input>
        </div>
        </el-col>
        <!-- 屈外 -->
        <el-col :xs="6" :sm="4" :md="4" :lg="4" :xl="4"><div class="grid-content  bg-purple-light">屈(外)</div></el-col>
          <el-col :xs="18" :sm="8" :md="8" :lg="8" :xl="8"><div class="grid-content ">
          <el-input :disabled="othermodify"  v-model="value[4].input4"><template slot="append">°</template></el-input>
        </div>
        </el-col>
         <!-- 应力间隙 -->
         <!-- 股骨假体大小 -->
         <el-col :xs="10" :sm="6" :md="4" :lg="4" :xl="4"><div class="grid-content  bg-purple-light">股骨假体大小</div></el-col>
          <el-col  :xs="14" :sm="6" :md="8" :lg="8" :xl="8"><div class="grid-content ">
          <el-input :disabled="othermodify"  v-model="value[5].input1"><template slot="append">号</template></el-input>
        </div>
        </el-col>
        <!-- 股骨假体大小 -->

        <!-- 胫骨假体大小 -->
        <el-col :xs="10" :sm="6" :md="4" :lg="4" :xl="4"><div class="grid-content  bg-purple-light">胫骨假体大小</div></el-col>
          <el-col  :xs="14" :sm="6" :md="8" :lg="8" :xl="8"><div class="grid-content ">
          <el-input :disabled="othermodify"  v-model="value[5].input2"><template slot="append">号</template></el-input>
        </div>
        </el-col>
        <!-- 胫骨假体大小 -->
        <!-- 处理 -->
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"><div class="grid-content  bg-purple-light">处理</div></el-col>
          <el-col  :xs="24" :sm="24" :md="24" :lg="24" :xl="24"><div class="grid-content" style="border: solid 1px rgba(134, 134, 134, 0.507)">
            <el-checkbox-group v-model="value[6].value1" >
          <el-checkbox v-for="chulival in chuli" :label="chulival" :key="chulival"  :disabled="othermodify" >{{chulival}}</el-checkbox>
        </el-checkbox-group>
        </div>
        </el-col>
        <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4" v-show="value[6].isshow1"><div class="grid-content  bg-purple-light">其他</div></el-col>
          <el-col  :xs="20" :sm="20" :md="20" :lg="20" :xl="20" v-show="value[6].isshow1"><div class="grid-content ">
          <el-input :disabled="othermodify"  v-model="value[6].input"></el-input>
        </div>
        </el-col>
        <!-- 处理 -->
        <!-- 处理后评估 -->
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"><div class="grid-content  bg-purple">处理后评估</div></el-col>
        <!-- 关节活动度 -->
        <el-col  :xs="24" :sm="6" :md="6" :lg="6" :xl="6"><div class="grid-content bg-purple-light">关节活动度</div></el-col>
        <el-col  :xs="24" :sm="18" :md="18" :lg="18" :xl="18"><div class="grid-content">
          <el-input :disabled="othermodify"  v-model="value[7].input1"><template slot="append">°</template></el-input>
          <el-input :disabled="othermodify"  v-model="value[7].input2"><template slot="append">°</template></el-input>
          <el-input :disabled="othermodify"  v-model="value[7].input3"><template slot="append">°</template></el-input>
        </div>
      </el-col>
      <!-- 关节活动度 -->
      <!-- 下肢力线 -->
      <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4"><div class="grid-content bg-purple-light">下肢力线</div></el-col>
      <el-col :xs="24" :sm="24" :md="20" :lg="20" :xl="20">
        <el-col :xs="24" :sm="24" :md="10" :lg="10" :xl="10" class="grid-content" style="border: solid 1px rgba(134, 134, 134, 0.507)">
          <el-checkbox-group v-model="value[8].value1" :min="0" :max="1">
            <el-checkbox :disabled="othermodify"  v-for="livalue in lixian" :label="livalue" :key="livalue" >{{livalue}}</el-checkbox>
          </el-checkbox-group>
        </el-col>  
        <el-col :xs="24" :sm="24" :md="10" :lg="10" :xl="10" class="grid-content" style="border: solid 1px rgba(134, 134, 134, 0.507)">  
          <el-checkbox-group v-model="value[8].value2"  :min="0" :max="1">
            <el-checkbox :disabled="othermodify"  v-for="livalue1 in lixian2" :label="livalue1" :key="livalue1"  >{{livalue1}}</el-checkbox>
          </el-checkbox-group>
        </el-col>
    </el-col>
    <!-- 内翻 -->
    <el-col :xs="6" :sm="6" :md="4" :lg="4" :xl="4" v-if="value[8].isshow1"><div class="grid-content  bg-purple-light">内翻</div></el-col>
      <el-col :xs="18" :sm="18" :md="8" :lg="8" :xl="8"  v-if="value[8].isshow1"><div class="grid-content ">
          <el-input :disabled="othermodify"  v-model="value[8].input1" ><template slot="append">°</template></el-input>
        </div>
      </el-col>
      <!-- 外翻 -->
      <el-col :xs="6" :sm="6" :md="4" :lg="4" :xl="4" v-if="value[8].isshow2"><div class="grid-content  bg-purple-light">外翻</div></el-col>
      <el-col :xs="18" :sm="18" :md="8" :lg="8" :xl="8"  v-if="value[8].isshow2"><div class="grid-content ">
          <el-input :disabled="othermodify"  v-model="value[8].input2" ><template slot="append">°</template></el-input>
        </div>
      </el-col>
      <!-- 屈伸 -->
      <el-col :xs="6" :sm="6" :md="4" :lg="4" :xl="4" v-if="value[8].isshow3"><div class="grid-content  bg-purple-light">屈伸</div></el-col>
      <el-col :xs="18" :sm="18" :md="8" :lg="8" :xl="8"  v-if="value[8].isshow3"><div class="grid-content ">
          <el-input :disabled="othermodify"  v-model="value[8].input3" ><template slot="append">°</template></el-input>
        </div>
      </el-col>
      <!-- 过伸 -->
      <el-col :xs="6" :sm="6" :md="4" :lg="4" :xl="4" v-if="value[8].isshow4"><div class="grid-content  bg-purple-light">过伸</div></el-col>
      <el-col :xs="18" :sm="18" :md="8" :lg="8" :xl="8" v-if="value[8].isshow4"><div class="grid-content ">
          <el-input :disabled="othermodify"  v-model="value[8].input4" ><template slot="append">°</template></el-input>
        </div>
      </el-col>
    <!-- 下肢力线 -->
        <!-- 处理后评估 -->
          <!-- 应力间隙 -->
         <!-- 伸（内） -->
         <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"><div class="grid-content bg-purple">应力间隙</div></el-col>
         <el-col :xs="6" :sm="4" :md="4" :lg="4" :xl="4"><div class="grid-content  bg-purple-light">伸(内)</div></el-col>
          <el-col  :xs="18" :sm="8" :md="8" :lg="8" :xl="8"><div class="grid-content ">
          <el-input :disabled="othermodify"  v-model="value[9].input1"  ><template slot="append">°</template></el-input>
        </div>
        </el-col>
        <!-- 伸（外） -->
        <el-col :xs="6" :sm="4" :md="4" :lg="4" :xl="4"><div class="grid-content  bg-purple-light">伸(外)</div></el-col>
          <el-col  :xs="18" :sm="8" :md="8" :lg="8" :xl="8"><div class="grid-content ">
          <el-input :disabled="othermodify"  v-model="value[9].input2"  ><template slot="append">°</template></el-input>
        </div>
        </el-col>
        <!-- 屈内 -->
        <el-col :xs="6" :sm="4" :md="4" :lg="4" :xl="4"><div class="grid-content  bg-purple-light">屈(内)</div></el-col>
          <el-col  :xs="18" :sm="8" :md="8" :lg="8" :xl="8"><div class="grid-content ">
          <el-input :disabled="othermodify"  v-model="value[9].input3"  ><template slot="append">°</template></el-input>
        </div>
        </el-col>
        <!-- 屈外 -->
        <el-col :xs="6" :sm="4" :md="4" :lg="4" :xl="4"><div class="grid-content  bg-purple-light">屈(外)</div></el-col>
          <el-col  :xs="18" :sm="8" :md="8" :lg="8" :xl="8"><div class="grid-content ">
          <el-input :disabled="othermodify"  v-model="value[9].input4" ><template slot="append">°</template></el-input>
        </div>
        </el-col>
         <!-- 应力间隙 -->
         <!-- 术中调整 -->
         <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"><div class="grid-content  bg-purple">术中调整</div></el-col>
        <!-- 股骨 -->
    <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"> 
      <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"><div class="grid-content  bg-purple-light">股骨</div></el-col>
        <el-col  :xs="24" :sm="24" :md="24" :lg="24" :xl="24"  style="border: solid 1px rgba(134, 134, 134, 0.507)">
            <el-col  :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="grid-content" >
              <el-checkbox-group  v-model="value[10].value1" :min="0" :max="1">
                <el-checkbox :disabled="othermodify"  label="外翻">外翻</el-checkbox>
                <el-checkbox  :disabled="othermodify" label="内翻">内翻</el-checkbox>
              </el-checkbox-group>
             </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" v-show="value[10].isshow1" class="grid-content">
              <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8"><div class=" grid-content bg-purple-light">{{value[10].value1[0]}}</div></el-col>
              <el-col :xs="16" :sm="16" :md="16" :lg="16" :xl="16">
                <el-input :disabled="othermodify"  v-model="value[10].input1"><template slot="append">°</template></el-input>
              </el-col>
            </el-col>
        </el-col>

        <el-col  :xs="24" :sm="24" :md="24" :lg="24" :xl="24"  style="border: solid 1px rgba(134, 134, 134, 0.507)">
            <el-col  :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="grid-content">
              <el-checkbox-group  v-model="value[10].value2" :min="0" :max="1">
                <el-checkbox :disabled="othermodify"  label="外旋">外旋</el-checkbox>
                <el-checkbox :disabled="othermodify"  label="内旋">内旋</el-checkbox>
              </el-checkbox-group>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" v-show="value[10].isshow2" class="grid-content">
              <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8"><div class=" grid-content bg-purple-light">{{value[10].value2[0]}}</div></el-col>
              <el-col :xs="16" :sm="16" :md="16" :lg="16" :xl="16">
                <el-input :disabled="othermodify"  v-model="value[10].input2"><template slot="append">°</template></el-input>
              </el-col>
            </el-col>
        </el-col>

        <el-col  :xs="24" :sm="24" :md="24" :lg="24" :xl="24"  style="border: solid 1px rgba(134, 134, 134, 0.507)">
            <el-col  :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="grid-content">
              <el-checkbox-group  v-model="value[10].value3" :min="0" :max="1">
                <el-checkbox :disabled="othermodify"  label="屈曲口增">屈曲口增</el-checkbox>
                <el-checkbox  :disabled="othermodify" label="屈曲口减">屈曲口减</el-checkbox>
              </el-checkbox-group>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" v-show="value[10].isshow3" class="grid-content">
              <el-col :xs="10" :sm="10" :md="10" :lg="10" :xl="10"><div class=" grid-content bg-purple-light">{{value[10].value3[0]}}</div></el-col>
              <el-col :xs="14" :sm="14" :md="14" :lg="14" :xl="14">
                <el-input :disabled="othermodify"  v-model="value[10].input3"><template slot="append">°</template></el-input>
              </el-col>
            </el-col>
        </el-col>


        <el-col  :xs="24" :sm="24" :md="24" :lg="24" :xl="24"  style="border: solid 1px rgba(134, 134, 134, 0.507)">
            <el-col  :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="grid-content">
              <el-checkbox-group  v-model="value[10].value4" :min="0" :max="1">
                <el-checkbox :disabled="othermodify"  label="源端口移">源端口移</el-checkbox>
                <el-checkbox  :disabled="othermodify" label="近端口移">近端口移</el-checkbox>
              </el-checkbox-group>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" v-show="value[10].isshow4" class="grid-content">
              <el-col :xs="10" :sm="10" :md="10" :lg="10" :xl="10"><div class=" grid-content bg-purple-light">{{value[10].value4[0]}}</div></el-col>
              <el-col :xs="14" :sm="14" :md="14" :lg="14" :xl="14">
                <el-input :disabled="othermodify"  v-model="value[10].input4"><template slot="append">°</template></el-input>
              </el-col>
            </el-col>
        </el-col>

        <el-col  :xs="24" :sm="24" :md="24" :lg="24" :xl="24"  style="border: solid 1px rgba(134, 134, 134, 0.507)">
            <el-col  :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="grid-content">
              <el-checkbox-group  v-model="value[10].value5" :min="0" :max="1">
                <el-checkbox  :disabled="othermodify" label="大一号">大一号</el-checkbox>
                <el-checkbox  :disabled="othermodify" label="小一号">小一号</el-checkbox>
              </el-checkbox-group>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" v-show="value[10].isshow5" class="grid-content">
              <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8"><div class=" grid-content bg-purple-light">{{value[10].value5[0]}}</div></el-col>
              <el-col :xs="16" :sm="16" :md="16" :lg="16" :xl="16">
                <el-input :disabled="othermodify"  v-model="value[10].input5"><template slot="append">°</template></el-input>
              </el-col>
            </el-col>
        </el-col>
      </el-col> 
      
      <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"><div class="grid-content  bg-purple-light">胫骨</div></el-col>

        <el-col  :xs="24" :sm="24" :md="24" :lg="24" :xl="24"  style="border: solid 1px rgba(134, 134, 134, 0.507)">
            <el-col  :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="grid-content">
              <el-checkbox-group  v-model="value[11].value1" :min="0" :max="1">
                <el-checkbox :disabled="othermodify"  label="后颈增">后颈增</el-checkbox>
                <el-checkbox :disabled="othermodify"  label="后颈减">后颈减</el-checkbox>
              </el-checkbox-group>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" v-show="value[11].isshow1" class="grid-content">
              <el-col :xs="10" :sm="10" :md="10" :lg="10" :xl="10"><div class=" grid-content bg-purple-light">{{value[11].value1[0]}}</div></el-col>
              <el-col :xs="14" :sm="14" :md="14" :lg="14" :xl="14">
                <el-input :disabled="othermodify"  v-model="value[11].input1"><template slot="append">°</template></el-input>
              </el-col>
            </el-col>
        </el-col>

        <el-col  :xs="24" :sm="24" :md="24" :lg="24" :xl="24"  style="border: solid 1px rgba(134, 134, 134, 0.507)">
            <el-col  :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="grid-content">
              <el-checkbox-group  v-model="value[11].value2" :min="0" :max="1">
                <el-checkbox :disabled="othermodify"  label="外翻">外翻</el-checkbox>
                <el-checkbox :disabled="othermodify"  label="内翻">内翻</el-checkbox>
              </el-checkbox-group>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" v-show="value[11].isshow2" class="grid-content">
              <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8"><div class=" grid-content bg-purple-light">{{value[11].value2[0]}}</div></el-col>
              <el-col :xs="16" :sm="16" :md="16" :lg="16" :xl="16">
                <el-input :disabled="othermodify"  v-model="value[11].input2"><template slot="append">°</template></el-input>
              </el-col>
            </el-col>
        </el-col>

        <el-col  :xs="24" :sm="24" :md="24" :lg="24" :xl="24"  style="border: solid 1px rgba(134, 134, 134, 0.507)">
            <el-col  :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="grid-content">
              <el-checkbox-group  v-model="value[11].value3" :min="0" :max="1">
                <el-checkbox :disabled="othermodify"  label="源端口移">远端口移</el-checkbox>
                <el-checkbox :disabled="othermodify"  label="近端口移">近端口移</el-checkbox>
              </el-checkbox-group>    
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" v-show="value[11].isshow3" class="grid-content">
              <el-col :xs="10" :sm="10" :md="10" :lg="10" :xl="10"><div class=" grid-content bg-purple-light">{{value[11].value3[0]}}</div></el-col>
              <el-col :xs="14" :sm="14" :md="14" :lg="14" :xl="14">
                <el-input :disabled="othermodify"  v-model="value[11].input3"><template slot="append">°</template></el-input>
              </el-col>
            </el-col>
        </el-col>
      </el-col>  
    </el-col>

    <el-col  :xs="24" :sm="24" :md="24" :lg="24" :xl="24" >
      <el-col :xs="10" :sm="10" :md="10" :lg="10" :xl="10"><div class="grid-content  bg-purple-light">假体</div></el-col>
      <el-col :xs="14" :sm="14" :md="14" :lg="14" :xl="14"><div class="grid-content  bg-purple-light"><el-input :disabled="othermodify"  v-model="value[11].input4" /></div></el-col>
    </el-col>


        <!-- 术后调整间隙 -->
         <!-- 伸（内） -->
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"><div class="grid-content bg-purple">术后调整间隙</div></el-col>

        <el-col :xs="6" :sm="4" :md="4" :lg="2" :xl="2">
          <div class="grid-content  bg-purple-light">伸(内)</div></el-col>
          <el-col  :xs="18" :sm="8" :md="8" :lg="4" :xl="4"><div class="grid-content ">
            <el-input  :disabled="othermodify" v-model="value[12].input1"><template slot="append">°</template></el-input>
          </div></el-col>
        <!-- 伸（外） -->
        <el-col :xs="6" :sm="4" :md="4" :lg="2" :xl="2"><div class="grid-content  bg-purple-light">伸(外)</div></el-col>
          <el-col  :xs="18" :sm="8" :md="8" :lg="4" :xl="4"><div class="grid-content ">
          <el-input  :disabled="othermodify" v-model="value[12].input2"><template slot="append">°</template></el-input>
        </div>
        </el-col>
        <!-- 屈内 -->
        <el-col :xs="6" :sm="4" :md="4" :lg="2" :xl="2"><div class="grid-content  bg-purple-light">屈(内)</div></el-col>
          <el-col  :xs="18" :sm="8" :md="8" :lg="4" :xl="4"><div class="grid-content ">
          <el-input :disabled="othermodify"  v-model="value[12].input3"><template slot="append">°</template></el-input>
        </div>
        </el-col>
        <!-- 屈外 -->
        <el-col :xs="6" :sm="4" :md="4" :lg="2" :xl="2"><div class="grid-content  bg-purple-light">屈(外)</div></el-col>
          <el-col  :xs="18" :sm="8" :md="8" :lg="4" :xl="4"><div class="grid-content ">
          <el-input  :disabled="othermodify" v-model="value[12].input4"><template slot="append">°</template></el-input>
        </div>
        </el-col>
         <!-- 术后调整间隙 -->

      <!-- 调整后力线 -->
      <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4"><div class="grid-content bg-purple-light">力线</div></el-col>
      <el-col :xs="20" :sm="20" :md="20" :lg="20" :xl="820"><div class="grid-content" style="border: solid 1px rgba(134, 134, 134, 0.507)">
        <el-checkbox-group v-model="value[13].value1" :min="0" :max="1">
          <el-checkbox :disabled="othermodify"  v-for="livalue in lixian" :label="livalue" :key="livalue"  >{{livalue}}</el-checkbox>
        </el-checkbox-group>
      </div></el-col>
      <!-- 内翻、外翻 -->
      <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4" v-if="value[13].isshow1"><div class="grid-content  bg-purple-light">{{value[13].value1[0]}}</div></el-col>
      <el-col :xs="20" :sm="20" :md="20" :lg="20" :xl="20" v-if="value[13].isshow1"><div class="grid-content ">
          <el-input  :disabled="othermodify" v-model="value[13].input1" ><template slot="append">°</template></el-input>
        </div>
      </el-col>


      <!-- 平衡结果 -->
      <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4"><div class="grid-content bg-purple-light">平衡结果</div></el-col>
      <el-col :xs="20" :sm="20" :md="20" :lg="20" :xl="20"><div class="grid-content" style="border: solid 1px rgba(134, 134, 134, 0.507)">
        <el-checkbox-group v-model="value[14].value1" :min="0" :max="1">
          <el-checkbox v-for="pingheng in pinghengres" :label="pingheng" :key="pingheng"  :disabled="othermodify" >{{pingheng}}</el-checkbox>
        </el-checkbox-group>
      </div>
    </el-col>
    <!-- 内侧狭窄 -->
    <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4" v-show="value[14].isshow1"><div class="grid-content bg-purple-light">内侧狭窄</div></el-col>
      <el-col :xs="20" :sm="20" :md="20" :lg="20" :xl="20"  v-show="value[14].isshow1"><div class="grid-content" style="border: solid 1px rgba(134, 134, 134, 0.507)">
        <el-checkbox-group v-model="value[14].value2" >
          <el-checkbox v-for="neice in neicexiazhai" :label="neice" :key="neice"  :disabled="othermodify" >{{neice}}</el-checkbox>
        </el-checkbox-group>
      </div>
    </el-col>
        <!-- 外侧狭窄 -->
    <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4"  v-show="value[14].isshow2"><div class="grid-content bg-purple-light">外侧狭窄</div></el-col>
      <el-col :xs="20" :sm="20" :md="20" :lg="20" :xl="20"  v-show="value[14].isshow2"><div class="grid-content" style="border: solid 1px rgba(134, 134, 134, 0.507)">
        <el-checkbox-group v-model="value[14].value3" >
          <el-checkbox :disabled="othermodify"  v-for="waice in waicexiazhai" :label="waice" :key="waice"  >{{waice}}</el-checkbox>
        </el-checkbox-group>
      </div>
    </el-col>
    <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4"  v-show="value[14].isshow3"><div class="grid-content bg-purple-light">其他</div></el-col>
      <el-col :xs="20" :sm="20" :md="20" :lg="20" :xl="20"  v-show="value[14].isshow3"><div class="grid-content">
        <el-input :disabled="othermodify"  v-model="value[14].input1" />
      </div>
    </el-col>
    <!-- 平衡结果 -->
      <!-- 上假体后 -->
         <!-- 伸（内） -->
         <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"><div class="grid-content bg-purple">上假体后</div></el-col>
         <el-col :xs="6" :sm="4" :md="4" :lg="2" :xl="2"><div class="grid-content  bg-purple-light">伸(内)</div></el-col>
          <el-col :xs="18" :sm="8" :md="8" :lg="4" :xl="4"><div class="grid-content ">
          <el-input :disabled="othermodify"  v-model="value[15].input1"  ><template slot="append">°</template></el-input>
        </div>
        </el-col>
        <!-- 伸（外） -->
        <el-col :xs="6" :sm="4" :md="4" :lg="2" :xl="2"><div class="grid-content  bg-purple-light">伸(外)</div></el-col>
          <el-col :xs="18" :sm="8" :md="8" :lg="4" :xl="4"><div class="grid-content ">
          <el-input :disabled="othermodify"  v-model="value[15].input2"  ><template slot="append">°</template></el-input>
        </div>
        </el-col>
        <!-- 屈内 -->
        <el-col :xs="6" :sm="4" :md="4" :lg="2" :xl="2"><div class="grid-content  bg-purple-light">屈(内)</div></el-col>
          <el-col  :xs="18" :sm="8" :md="8" :lg="4" :xl="4"><div class="grid-content ">
          <el-input :disabled="othermodify"  v-model="value[15].input3"><template slot="append">°</template></el-input>
        </div>
        </el-col>
        <!-- 屈外 -->
        <el-col :xs="6" :sm="4" :md="4" :lg="2" :xl="2"><div class="grid-content  bg-purple-light">屈(外)</div></el-col>
          <el-col :xs="18" :sm="8" :md="8" :lg="4" :xl="4"><div class="grid-content ">
          <el-input :disabled="othermodify"  v-model="value[15].input4" ><template slot="append">°</template></el-input>
        </div>
        </el-col>
         <!-- 力线 -->
      <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4"><div class="grid-content bg-purple-light">力线</div></el-col>
      <el-col :xs="24" :sm="24" :md="20" :lg="20" :xl="20">
          <el-col :xs="24" :sm="24" :md="10" :lg="10" :xl="10" class="grid-content" style="border: solid 1px rgba(134, 134, 134, 0.507)">
            <el-checkbox-group v-model="value[16].value1" :min="0" :max="1">
              <el-checkbox v-for="livalue in lixian" :label="livalue" :key="livalue"  :disabled="othermodify" >{{livalue}}</el-checkbox>
            </el-checkbox-group>
          </el-col>  
          <el-col :xs="24" :sm="24" :md="10" :lg="10" :xl="10" class="grid-content" style="border: solid 1px rgba(134, 134, 134, 0.507)">
            <el-checkbox-group v-model="value[16].value2"  :min="0" :max="1">
              <el-checkbox v-for="livalue1 in lixian2" :label="livalue1" :key="livalue1"  :disabled="othermodify" >{{livalue1}}</el-checkbox>
            </el-checkbox-group>
        </el-col>
      </el-col>
    <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
      <!-- 内翻 -->  <!-- 外翻 -->
      <el-col :xs="6" :sm="6" :md="4" :lg="4" :xl="4" v-if="value[16].isshow1"><div class="grid-content  bg-purple-light">{{value[16].value1[0]}}</div></el-col>
      <el-col :xs="18" :sm="18" :md="8" :lg="8" :xl="8"   v-if="value[16].isshow1"><div class="grid-content ">
          <el-input :disabled="othermodify"  v-model="value[16].input1" ><template slot="append">°</template></el-input>
        </div>
      </el-col>
      <!-- 屈伸 --><!-- 过伸 -->
      <el-col :xs="6" :sm="6" :md="4" :lg="4" :xl="4" v-if="value[16].isshow2"><div class="grid-content  bg-purple-light">{{value[16].value2[0]}}</div></el-col>
      <el-col :xs="18" :sm="18" :md="8" :lg="8" :xl="8"   v-if="value[16].isshow2"><div class="grid-content ">
          <el-input :disabled="othermodify"  v-model="value[16].input3" ><template slot="append">°</template></el-input>
        </div>
      </el-col>
    </el-col>  
        <!-- 活动度 -->
        <el-col  :xs="24" :sm="6" :md="4" :lg="3" :xl="3"><div class="grid-content bg-purple-light">最大活动度</div></el-col>
        <el-col  :xs="24" :sm="18" :md="20" :lg="21" :xl="21"><div class="grid-content">
          <el-input :disabled="othermodify"  v-model="value[17].input1"><template slot="append">°</template></el-input>
          <el-input :disabled="othermodify"  v-model="value[17].input2"><template slot="append">°</template></el-input> 
          <el-input :disabled="othermodify"  v-model="value[17].input3"><template slot="append">°</template></el-input>
        </div>
      </el-col>
      <!-- 活动度 -->
      <!-- 重力下屈曲 -->
        <el-col  :xs="8" :sm="6" :md="4" :lg="3" :xl="3"><div class="grid-content bg-purple-light">重力下屈曲</div></el-col>
        <el-col  :xs="8" :sm="6" :md="8" :lg="8" :xl="8"><div class="grid-content ">
          <el-input :disabled="othermodify"  v-model="value[17].input4"><template slot="append">°</template></el-input>
        </div>
      </el-col>
        <!-- 重力下屈曲 -->
    </el-row>
  </div>
</template>
<script>
export default {
  props:['message',"othermodify"],
  data(){
    return{
      modify:false,
      // 力线选择遍历
      lixian:["中立","内翻","外翻"],
      lixian2:["屈伸","过伸"],
      // 处理
      chuli:["去骨赘","内侧袖套松懈","内侧副韧带松懈","平台缩容","髁胫束松懈","其他"],
      // 平衡结果
      pinghengres:["内外侧平衡良好","内侧狭窄","外侧狭窄"],
      // 内侧狭窄
      neicexiazhai:["继续内侧松弛","Pic-crusting","胫骨缩容","袖套松解"],
        // 外侧狭窄
        waicexiazhai:["针头松解髁胫束","切断髁胫束","其他"],
      // 股骨
      gugu:[
         {val:["外翻","内翻"]},
        {val:["外旋","内旋"]},
        {val:["屈曲口增","屈曲口减"]},
        {val:["源端口移","近端口移"]},
        {val:["大一号","小一号"]},
      ],
      // 存储填写的值
      value:[
        {input1:'',input2:'',input3:'',input4:'',},
        {value1:[],value2:[],input1:'',input2:'',input3:'',input4:'',isshow1:false,isshow2:false,isshow3:false,isshow4:false},
        {input1:'',input2:'',input3:''},
        {input1:'',input2:'',input3:'',input4:''},
        {input1:'',input2:'',input3:'',input4:''},
        {input1:'',input2:''},
        {value1:[],input:'',isshow1:false},
        {input1:'',input2:'',input3:''},
        {value1:[],value2:[],input1:'',input2:'',input3:'',input4:'',isshow1:false,isshow2:false,isshow3:false,isshow4:false},
        {input1:'',input2:'',input3:'',input4:''},
        {value1:[],value2:[],value3:[],value4:[],value5:[],
          input1:'',input2:'',input3:'',input4:'',input5:''
          ,isshow1:false,isshow2:false,isshow3:false,isshow4:false,isshow5:false
        },
        {value1:[],value2:[],value3:[],input1:'',input2:'',input3:'',input4:'',input5:'',isshow1:false,isshow2:false,isshow3:false},
        {input1:'',input2:'',input3:'',input4:''},
        {value1:[],input1:'',isshow1:false},
        {value1:[],value2:[],value3:[],input1:'',isshow1:false,isshow2:false,isshow3:false},
        {input1:'',input2:'',input3:'',input4:''},
        {value1:[],value2:[],input1:'',input2:'',isshow1:false,isshow2:false},
        {input1:'',input2:'',input3:'',input4:'',},

      ]
    }
  },
  watch:{
    message:{
      handler(newvalue,old){
        for(let i =0;i<newvalue.length;i++){
          let obj  = newvalue[i]
          // console.log(obj,'obj')
          for(let key in obj){
            // console.log(obj[key],'objkey')
            this.value[i][key]=obj[key]
          }
        }
      },deep:true
    },
    value:{
      handler(val,oldvalue){
        
        // console.table(val)
        let a21 = val[1].value1
        let a22 = val[1].value2
        let a61 = val[6].value1
        let a81 = val[8].value1
        let a82 = val[8].value2
        val[10].isshow1= val[10].value1[0] ==undefined ? false:true
        val[10].isshow2= val[10].value2[0] ==undefined ? false:true
        val[10].isshow3= val[10].value3[0] ==undefined ? false:true
        val[10].isshow4= val[10].value4[0] ==undefined ? false:true
        val[10].isshow5= val[10].value5[0] ==undefined ? false:true
        val[11].isshow1= val[11].value1[0] ==undefined ? false:true
        val[11].isshow2= val[11].value2[0] ==undefined ? false:true
        val[11].isshow3= val[11].value3[0] ==undefined ? false:true
        val[13].isshow1 =val[13].value1[0] ==undefined ? false:val[13].value1[0] =='中立' ? false:true
        val[14].isshow1= val[14].value1[0] =='内侧狭窄' ? true:false
        val[14].isshow2= val[14].value1[0] =='外侧狭窄' ? true:false
        val[14].isshow3= val[14].value3.includes("其他") ? true:false
        val[16].isshow1= val[16].value1[0] ==undefined ? false:val[16].value1[0] =='中立' ? false:true
        val[16].isshow2= val[16].value2[0] ==undefined ? false:true
        if(a61.includes("其他")){
            val[6].isshow1=true
        }else{
          val[6].input=''
          val[6].isshow1=false
        }
        // 初级评估
        if(a21.includes("内翻")){
            val[1].isshow1=true
        }else if(a21.includes("外翻")){
            val[1].isshow2=true
        }else{
          val[1].isshow1=false
          val[1].isshow2=false
        }
        if(a22.includes("屈伸")){
            val[1].isshow3=true
        }else if(a22.includes("过伸")){
            val[1].isshow4=true
        }else{
          val[1].isshow3=false
          val[1].isshow4=false
        }
        // 处理后评估
        if(a81.includes("内翻")){
            val[8].isshow1=true
        }else if(a81.includes("外翻")){
            val[8].isshow2=true
        }else{
          val[8].isshow1=false
          val[8].isshow2=false
        }
        if(a82.includes("屈伸")){
            val[8].isshow3=true
        }else if(a82.includes("过伸")){
            val[8].isshow4=true
        }else{
          val[8].isshow3=false
          val[8].isshow4=false
        }
        this.$emit('robotic',val)
      },deep:true
    }
  },
  mounted(){
    // console.log(this.message,'机器人手术，非表格')
  },
  methods:{
   
  }
}
</script>

<style scoped>
.robotic{
    height: fit-content;
    box-sizing: border-box;
    /* padding: 5px; */
    width: 100%;
}
.el-col {
    border-radius: 4px;
  }
  .bg-purple {
    background: #d3dce6;
  }
  .bg-purple-light {
    background: #e5e9f2;
  }
  .grid-content {
    border-radius: 4px;
    min-height: 36px;
  }
  .hdld{
    padding-left: 0px !important;
    /* margin: 0px !important; */
  }
  .hdldbtn{
    /* padding-left: 0px !important; */
    margin-bottom: 0px !important;
  }
  .w{
  width: 15px;
  white-space: nowrap;
}
</style>