<template>
  <div class="aainfomation" >
    <div class="newsurgery">
      <el-row :gutter="5"  >
      <el-col  :xs="item.xs" :sm="item.sm" :md="item.md" :lg="item.jg" :xl="item.xl" v-for="(item,index) in Surgical" :key="index" >
        <el-row>
          <el-col :xs="8" :sm="6" :md="4" :lg="8" :xl="8" v-if="item.label !='手术名称'"><div class="grid-content bg-purple">
            {{ item.label }}
          </div></el-col>
          <el-col :xs="8" :sm="6" :md="4" :lg="4" :xl="4" v-else><div class="grid-content bg-purple">
            {{ item.label }}
          </div></el-col>
          <el-col :xs="18" :sm="18" :md="18" :lg="16" :xl="16"  v-if="item.type=='input'">
            <el-input placeholder="请输入" v-model="value2[index].label" v-show="item.dw == ''" :disabled="item.label =='序号'">
            </el-input>
            <el-input placeholder="请输入" v-model="value2[index].label" v-show="item.dw != ''">
              <template slot="append" >{{item.dw}}</template>
            </el-input>
      </el-col>
      <el-col :xs="18" :sm="18" :md="18" :lg="16" :xl="16"  v-else-if="item.type=='select'">
          <el-select v-model="value2[index].label" placeholder="请选择" style="width: 100%;">
            <el-option
              v-for="item in item.dw"
              :key="item.label"
              :label="item.label"
              :value="item.value"
              >
            </el-option>
          </el-select>  
      </el-col>
      <el-col :key="20" :xs="18" :sm="18" :md="18" :lg="16" :xl="16"   v-else-if="item.type=='time'"><div class="grid-content">
          <el-date-picker
            v-model="value2[index].label"
            type="date"
            placeholder="选择日期"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            style="width: 100%;"
          >
          </el-date-picker>
      </div>
    </el-col>
    <el-col :xs="24" :sm="24" :md="24" :lg="16" :xl="16"  v-else-if="item.type=='checkbox'">
      <div class="leftcss" >
          <el-radio-group 
          v-model="value2[index].label"
            :min="0"
            :max="1"
            >
            <el-radio v-for="city in item.dw" :label="city" :key="city" style="padding-top: 10px; padding-right: 10px;">{{city}}</el-radio>
           </el-radio-group>
      </div>
        </el-col>
        </el-row>
      </el-col>
      </el-row>
    </div>
    <el-row :gutter="5" >
        <el-col :xs="item.xs" :sm="item.sm" :md="item.md" :lg="item.lg" :xl="item.xl" v-for="(item,index) in form" :key="index" >
           <el-row class="testrow" >
            <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8" v-if="item.name!='联系方式' && item.name!='婚姻状况'"><div class="grid-content bg-purple">{{ item.name }}</div></el-col>
            <el-col :xs="8" :sm="8" :md="4" :lg="4" :xl="4" v-if="item.name=='联系方式' || item.name=='婚姻状况'"><div class="grid-content bg-purple">{{ item.name }}</div></el-col>

            <el-col :xs="24" :sm="16" :md="16" :lg="16" :xl="16" v-if="item.type =='input'&&item.name !=='联系方式'">
                <el-input v-model="value[index].value"/>
            </el-col>
            <el-col :xs="24" :sm="16" :md="16" :lg="16" :xl="16" v-if="item.type =='range'">
              <div class="grid-content range">
                <el-input class="range1" type="range"  min="0" max="300" :value="value[index].value" v-model="value[index].value"/>
                <div class="itemdw">
                   {{ value[index].value }} {{ item.dw }}
                </div>
              </div>
            </el-col>
            <el-col :xs="17" :sm="17" :md="20" :lg="20" :xl="20" v-if="item.type =='input'&&item.name =='联系方式'">
                <div class="lxfs">
                  <div class="lxfscol"> <el-input v-model="value[index].value"/></div>
                  <div class="lxfscol"><el-input v-model="value[index].value1" /></div>
                  <div class="lxfscol"><el-input v-model="value[index].value2" /></div>
                </div>
            </el-col>
            <el-col :xs="16" :sm="16" :md="16" :lg="16" :xl="16" v-if="item.type =='checkbox' " style="text-align: left; padding-left: 10px; ">
                <el-radio-group 
                    v-model="value[index].value"
                    :min="0"
                    :max="1"
                    >
                    <el-radio v-for="checkvalue in item.value" :label="checkvalue" :key="checkvalue" style="padding-top: 10px; padding-right: 10px;" :disabled="item.name =='出生季节'">{{checkvalue}}</el-radio>
                </el-radio-group>
            </el-col>
            <el-col :xs="16" :sm="16" :md="16" :lg="16" :xl="16" v-if="item.type =='cascader'" style="text-align: left;">
                <el-cascader
                    class="cascader"
                        size="middle"
                        :options="place"
                        v-model="value[3].value"
                        @change="tonativapalce"
                        style="width: 100%;"
                    >
                </el-cascader>
            </el-col>
            <el-col :xs="16" :sm="16" :md="16" :lg="16" :xl="16" v-if="item.type =='time'">
                <el-date-picker
                disabled
                  v-model="value[index].value"
                  type="date"
                  placeholder="选择日期"
                  format="yyyy 年 MM 月 dd 日"
                  value-format="yyyy-MM-dd"
                  style="width: 100%;"
                >
                </el-date-picker>
            </el-col>
            <el-col :xs="16" :sm="16" :md="16" :lg="16" :xl="16" v-if="item.type =='select'">
                <el-select v-model="value[index].value"  style="width: 100%;">
                  <el-option v-for="(select,indexselect) in item.value" :value="select.name" :key="indexselect+'p'">
                    {{ select.name }}
                  </el-option>
                </el-select>
            </el-col>
           </el-row>
        </el-col>
        <el-col  :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <div class="grid-content bg-purple">家庭地址</div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                    <el-cascader
                    class="cascader"
                        size="middle"
                        :options="options"
                        v-model="value[14].value"
                        @change="address"
                        style="width: 100%;"
                    >
                </el-cascader>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">  
                <el-input v-model="value[14].add" placeholder="请输入地址"></el-input>
            </el-col>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-button :disabled="toommit"  @click="tosubmit" type="primary" style="width: 100%; margin-top: 20px;">提交</el-button>
        </el-col>
    </el-row>
  </div>
</template>
<script>
import { regionData, CodeToText ,provinceAndCityData } from "element-china-area-data";
import {getDate} from '../../util/Process'
import {nation} from '../../util/jsData'
import {toinfomation} from '../../util/Process'
export default {
  data() {
    return {
      numbering:'',
        options: regionData,
        place:provinceAndCityData,
        nativapalce:[],
        addres:[],
      form: [
        { type: "input", name: "患者姓名",xs:24,sm:24,md:12,lg:12,xl:12 },
        { type: "input", name: "身份证号",xs:24,sm:24,md:12,lg:12,xl:12 },
        { type: "input", name: "联系方式",xs:24,sm:24,md:24,lg:24,xl:24},
        { type: "cascader", name: "籍贯",xs:24,sm:24,md:12,lg:12,xl:12 },
        { type: "checkbox", name: "性别", value: ["男", "女"],xs:24,sm:24,md:12,lg:12,xl:12 },
        { type: "checkbox", name: "婚姻状况", value: ["未婚", "已婚", "离婚","丧偶","再婚"],xs:24,sm:24,md:24,lg:24,xl:24 },
        { type: "input", name: "工作单位",xs:24,sm:24,md:12,lg:12,xl:12 },
        { type: "input", name: "职业",xs:24,sm:24,md:12,lg:12,xl:12 },
        { type: "select", name: "民族",value:nation,xs:24,sm:24,md:12,lg:12,xl:12 },
        { type: "time", name: "出生年月日",xs:24,sm:24,md:12,lg:12,xl:12 },
        { type: "range", name: "身高",xs:24,sm:24,md:12,lg:12,xl:12,dw:'CM' },
        { type: "range", name: "体重",xs:24,sm:24,md:12,lg:12,xl:12,dw:'KG' },
        { type: "input", name: "出生时天气",xs:24,sm:24,md:12,lg:12,xl:12 },
        {type: "checkbox",name: "出生季节",value: ["春", "夏","秋","冬"],xs:24,sm:24,md:12,lg:12,xl:12},
      ],
      Surgical:[
        {type:"input",label:"医院ID",dw:'',xs:24,sm:24,md:12,lg:12,xl:12},
        {type:"select",label:"费用类型",
        dw:[
        {label:"广州职工医保",value:'广州职工医保'},
          {label:"广州居民医保",value:'广州居民医保'},
          {label:"省内异地医保",value:'省内异地医保'},
          {label:"跨省异地医保",value:'跨省异地医保'},
          {label:"公医",value:'公医'},
          {label:"工伤保险",value:'工伤保险'},
          {label:"商业医保",value:'商业医保'},
          {label:"自费",value:'自费'},
          {label:"其他",value:'其他'},
        ],xs:24,sm:24,md:12,lg:12,xl:12
      },
        // {type:"select",label:"手术关节",dw:[{label:'左髋',value:'左髋'},{label:'右髋',value:'右髋'},{label:'左膝',value:'左膝'},{label:'右膝',value:'右膝'},{label:'单髁',value:'单髁'}],xs:24,sm:24,md:12,lg:12,xl:12},
        {type:"checkbox",label:"手术名称",dw:["左髋","右髋","左膝","右膝","单髁"],xs:24,sm:24,md:24,lg:24,xl:24},
        // {type:"checkbox",label:"手术名称",dw:["膝关节置换","髋关节置换","膝关节内侧单髁置换"],xs:24,sm:24,md:24,lg:24,xl:24},
      ],
      toommit:true,
      value: [
        { value: "" },
        { value: "" },
        { value: "",value1:'',value2:'' },
        { value: [] },
        { value: [] },
        { value: [] },
        { value: "" },
        { value: "" },
        { value: "汉族" },
        { value: "" },
        { value: "160" },
        { value: "60" },
        { value: "" },
        { value: [] },

       {value:[],add:''}
      ],
      value2:[
        {label:''},
        {label:''},
        {label:''},
        // {label:[]},
      ],
      valuename:["patients_name","id_card","phone","native","gender","marital_status","work_unit", "occupation", "nation","birth_date","stature", "weight","birth_weather", "birth_seasons","home_address"],
    };
  },
  watch:{
    value:{
      handler(array,old){
        let allValuesNotEmpty = true;
        for (let i = 0; i < array.length; i++) {
            let item = array[i];
            if (Array.isArray(item.value) && !item.value.length) {
              allValuesNotEmpty = false;
              break;
            } else if (typeof item.value === 'string' && !item.value) {
              allValuesNotEmpty = false;
              break;
            }
            if ('add' in item && typeof item.add === 'string' && !item.add) {
              allValuesNotEmpty = false;
              break;
            }
          }
          this.toommit = !allValuesNotEmpty
          let regex = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
          let shenfenid = array[1].value
          if(regex.test(shenfenid)){
            if(shenfenid.length==18){
              let year = shenfenid.substr(6, 4);
              let month = shenfenid.substr(10, 2);
              let day = shenfenid.substr(12, 2);
              let aa = year + '-' + month-1 + '-' + day
              // console.log(aa,'789',year)
              array[9].value = `${year}-${month}-${day}`;
              switch (true) {
                  case (parseInt(month) >= 3 && parseInt(month) <= 5):
                  array[13].value = "春";
                    break;
                  case (parseInt(month) >= 6 && parseInt(month) <= 8):
                  array[13].value = "夏";
                    break;
                  case (parseInt(month) >= 9 && parseInt(month) <= 11):
                  array[13].value = "秋";
                    break;
                  default:
                  array[13].value = "冬";
                }
            }
            
          }else{
            if(shenfenid.length>=18){
              this.$message.error('身份证号错误')
            }
          }
      },deep:true
    },
    firstLabel(newvalue,oldvalue){
      // console.log(newvalue,'\]')
      this.getinfo(newvalue)
    },
    value2:{
      handler(newvalue,oldvalue){
        let num = newvalue[2].label.includes('髋') ? '21':'11'
        this.numbering =newvalue[2].label=='' ? '': getDate()+num

      },deep:true
    },
  },
  created() {
  },
  computed:{
    firstLabel() {
    return this.value2[0].label;
  }
  },
  methods:{
    address(value) {
    },
    tonativapalce(value){
    },
   checkLabelsNotEmpty(value2) {
  return value2.every(obj => obj.label && obj.label.length > 0);
},
    async tosubmit(){
      let checkvalue2 = this.checkLabelsNotEmpty(this.value2)
      if(checkvalue2){
        const {data:res} =await this.$http.post('/operation/addOperationInfo',{
            "hospital_id":this.value2[0].label,
            "serial_number":this.numbering,
            "pay_type":this.value2[1].label,
            "operation_site":this.value2[2].label,
            // "operation_name":this.value2[3].label[0],
            "patients_name":this.value[0].value,
            "id_card":this.value[1].value,
            "phone":this.value[2],
            "native":this.value[3].value,
            "gender":this.value[4].value[0],
            "marital_status":this.value[5].value,
            "work_unit":this.value[6].value,
            "occupation":this.value[7].value,
            "nation":this.value[8].value,
            "birth_date":this.value[9].value,
            "stature":this.value[10].value,
            "weight":this.value[11].value,
            "birth_weather":this.value[12].value,
            "birth_seasons":this.value[13].value[0],
            "home_address":this.value[14],
        })
        if(res.status=="1"){
          this.$store.commit("changeshowbtn", "true");
      this.$store.commit("changemedicalname", "病例信息列表");
      this.$store.commit("showinputmethod",'true')
            this.$router.push("/MedicalList")
        }else{
          this.$message.error('提交出错，请联系管理员')
          console.log(res)
        }
      }else{
       this.$message.error('请填写手术信息部分')
      }
        // console.log(res)
    },
    async getinfo(value){
      // console.log(resvalue,'7898798')
      try{
      const { data: res } = await this.$http.get("/patient/verifyHospitalId",{
        params:{
          'hospital_id':value
        }
      }
      );
      // console.log(res.data,'\\')
      if (res.status == "1") {
       let arr = toinfomation(res.data)
      //  console.log(arr)
        for(let i=0;i<arr.length;i++){
          if(i==2 || i==3 || i==14){
            if(i==2){
              for(let key in arr[2]){
                this.value[2][key] = arr[2][key]
              }
            }else if(i==3){
              this.value[3].value = arr[i] == null ? '':Object.values(arr[i])
            }else{
              this.value[14].value=arr[14].value
              this.value[14].add = arr[14].add
            }
          }else{
              this.value[i].value = arr[i]
          }
        }

      }else{
        // console.log('error')
      }
    }catch(e){

    }
    },
  }
};
</script>
<style >
.aainfomation{
    height: fit-content;
    width: 100%;
    box-sizing: border-box;
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    /* background-color: red; */
}
.newsurgery{
  width: 100%;
  height: fit-content;
}
/* .newsurgery .el-col{
  margin-top: 5px;
} */
.bg-purple-light {
    background: #e5e9f2;
  }
  .testrow{
    /* margin-bottom: 5px; */
  }
  .addres{
    width: 30%;
  }
  .cascader{
    width:70%;
  }
  .lxfs{
    display: flex;
    align-items: center;
    justify-content: space-around;
    /* width: 100%; */
    /* background-color: black; */
  }
  .lxfscol{
    /* border: 1px solid red; */
    height: 100%;
    flex: 1;
    margin-left: 10px;
  }
  .itemdw{
    width: 100px;
    color: black;
    /* background-color: blue; */
  }

.leftcss{
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding-left: 15px;

}

.range .el-input__inner{
  height: 10px !important;
  padding: 0px !important;
  cursor: pointer;
}

.aainfomation .bg-purple {
    background: #66b1ff;
}

.aainfomation .grid-content {
    color: white;
    font-size: 14px;
    min-height: 40px;
    margin-right: 5px;
    margin-top: 2px;
}

.aainfomation .el-radio {
  margin-top: 5px;
}
</style>