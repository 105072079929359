<template>
  <div class="insurgery2other">
    <el-row :gutter="10">
        <!-- 勺子厚度 -->
        <el-col :xs="24" :sm="24" :md="3" :lg="3" :xl="3"><div class="grid-content bg-purple">
            勺子厚度
        </div></el-col>
        <el-col :xs="24" :sm="24" :md="7" :lg="7" :xl="7"><div class="grid-content">
            <el-checkbox-group 
                v-model="value[0].checkbox"
                :min="0"
                :max="1"
                :disabled="othermodify"
                >
                <el-checkbox v-for="city in houdu" :label="city" :key="city">{{city}}</el-checkbox>
            </el-checkbox-group>
        </div></el-col>
        <!-- 勺子厚度 -->
        <!-- 测量股骨大小 -->
        <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4"><div class="grid-content bg-purple">
            测量股骨大小
        </div></el-col>
        <el-col :xs="24" :sm="24" :md="10" :lg="10" :xl="10"><div class="grid-content">
            <el-checkbox-group 
                v-model="value[0].checkbox2"
                :min="0"
                :max="1"
                :disabled="othermodify"
                >
                <el-checkbox v-for="city in size" :label="city" :key="city">{{city}}</el-checkbox>
            </el-checkbox-group>
        </div></el-col>
        <!-- 测量股骨大小 -->
         <!-- G型夹厚度 -->
         <el-col :xs="24" :sm="24" :md="3" :lg="3" :xl="3"><div class="grid-content bg-purple">
            G型夹厚度
        </div></el-col>
        <el-col :xs="24" :sm="24" :md="5" :lg="5" :xl="5"><div class="grid-content">
            <el-checkbox-group 
                v-model="value[0].checkbox3"
                :min="0"
                :max="1"
                :disabled="othermodify"
                >
                <el-checkbox v-for="city in Ghoudu" :label="city" :key="city">{{city}}</el-checkbox>
            </el-checkbox-group>
        </div></el-col>
        <!-- G型夹厚度 -->
          <!-- 测伸直间隙 -->
          <el-col :xs="24" :sm="24" :md="3" :lg="3" :xl="3"><div class="grid-content bg-purple">
            测伸直间隙
        </div></el-col>
        <el-col :xs="24" :sm="24" :md="13" :lg="13" :xl="13"><div class="grid-content">
            <el-input placeholder="请输入内容" v-model="value[1].inputvalue1"  :disabled="othermodify">
               
                <template slot="append">mm</template>
            </el-input>
        </div></el-col>
        <!-- 测伸直间隙 -->
        <!-- 测量胫骨平台厚度 -->
        <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4"><div class="grid-content bg-purple">
                测量胫骨平台厚度
        </div>
    </el-col>
        <el-col :xs="24" :sm="24" :md="20" :lg="20" :xl="20"><div class="grid-content">
            <el-row>
                <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8"><div class="grid-content">
                <el-input placeholder="请输入内容" v-model="value[1].inputvalue2"  :disabled="othermodify">
                                <template slot="prepend">最薄处</template>
                                <template slot="append">mm</template>
                            </el-input>
                </div></el-col>
                <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8"><div class="grid-content">
                    <el-input placeholder="请输入内容" v-model="value[1].inputvalue3"  :disabled="othermodify">
                        <template slot="prepend">后方</template>
                        <template slot="append">mm</template>
                    </el-input>
                </div></el-col>
                <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8"><div class="grid-content">
                    <el-input placeholder="请输入内容" v-model="value[1].inputvalue4"  :disabled="othermodify">
                <template slot="prepend">脊</template>
                <template slot="append">mm</template>
            </el-input>
                </div></el-col>
            </el-row>
        </div></el-col>
        <!-- 测量胫骨平台厚度 -->
        <!-- 测量胫骨平台大小 -->
        <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4"><div class="grid-content bg-purple">
            测量胫骨平台大小
        </div></el-col>
        <el-col :xs="24" :sm="24" :md="20" :lg="20" :xl="20"><div class="grid-content">
            <el-checkbox-group 
                v-model="value[1].checkbox"
                :min="0"
                :max="1"
                :disabled="othermodify"
                >
                <el-checkbox v-for="city in csize" :label="city" :key="city">{{city}}</el-checkbox>
            </el-checkbox-group>
            &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;
            <el-checkbox-group 
                v-model="value[1].checkbox2"
                :min="0"
                :max="1"
                :disabled="othermodify"
                >
                <el-checkbox v-for="city in bsize" :label="city" :key="city">{{city}}</el-checkbox>
            </el-checkbox-group>
        </div></el-col>
             <!-- 测屈曲间隙 -->
             <el-col :xs="24" :sm="24" :md="3" :lg="3" :xl="3"><div class="grid-content bg-purple">
                测屈曲间隙
        </div></el-col>
        <el-col :xs="24" :sm="24" :md="9" :lg="9" :xl="9"><div class="grid-content">
            <el-input placeholder="请输入内容" v-model="value[2].inputvalue1"  :disabled="othermodify">
                <template slot="prepend">最薄处</template>
                <template slot="append">mm</template>
            </el-input>
        </div></el-col>
        <!-- 测屈曲间隙 -->
         <!-- 选择 -->
         <el-col :xs="24" :sm="24" :md="3" :lg="3" :xl="3"><div class="grid-content bg-purple">
            选择
        </div></el-col>
        <el-col :xs="24" :sm="24" :md="9" :lg="9" :xl="9"><div class="grid-content">
            <el-input placeholder="请输入内容" v-model="value[2].inputvalue2"  :disabled="othermodify">
                <!-- <template slot="prepend">最薄处</template> -->
                <template slot="append">mm 栓子</template>
            </el-input>
        </div></el-col>
        <!-- 选择 -->
         <!-- 再测屈曲间隙 -->
         <el-col :xs="24" :sm="24" :md="3" :lg="3" :xl="3"><div class="grid-content bg-purple">
            再测屈曲间隙
        </div></el-col>
        <el-col :xs="24" :sm="24" :md="9" :lg="9" :xl="9"><div class="grid-content">
            <el-input placeholder="请输入内容" v-model="value[2].inputvalue3"  :disabled="othermodify">
                <template slot="prepend">最薄处</template>
                <template slot="append">mm</template>
            </el-input>
        </div></el-col>
        <!-- 再测屈曲间隙 -->
         <!-- 选择半月板厚度 -->
         <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4"><div class="grid-content bg-purple">
            选择半月板厚度
        </div></el-col>
        <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8"><div class="grid-content">
            <el-input placeholder="请输入内容" v-model="value[2].inputvalue4"  :disabled="othermodify">
                <!-- <template slot="prepend">最薄处</template> -->
                <template slot="append">mm 栓子</template>
            </el-input>
        </div></el-col>
        <!-- 选择半月板厚度 -->
        <!-- 骨水泥 -->
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"><div class="grid-content bg-purple">
            骨水泥
        </div></el-col>
        <!-- 品牌 -->
        <el-col :xs="24" :sm="24" :md="2" :lg="2" :xl="2"><div class="grid-content bg-purple">
            品牌
        </div></el-col>
        <el-col :xs="24" :sm="24" :md="22" :lg="22" :xl="22"><div class="grid-content">
            <el-checkbox-group 
                v-model="value[3].checkbox"
                :min="0"
                :max="1"
                :disabled="othermodify"
                >
                <el-checkbox v-for="city in paivalue" :label="city" :key="city">{{city}}</el-checkbox>
            </el-checkbox-group>
        </div></el-col>
        <el-col :xs="24" :sm="24" :md="2" :lg="2" :xl="2" v-show="value[3].checkbox.includes('其他')"><div class="grid-content bg-purple" >
            其他
        </div></el-col>
        <el-col :xs="24" :sm="24" :md="22" :lg="22" :xl="22" v-show="value[3].checkbox.includes('其他')"><div class="grid-content">
            <el-input placeholder="请输入内容" v-model="value[3].inputvalue1"  :disabled="othermodify">
            </el-input>
        </div></el-col>
        <!-- 品牌 -->
        <!-- 抗生素 -->
        <el-col :xs="24" :sm="24" :md="2" :lg="2" :xl="2"><div class="grid-content bg-purple">
            抗生素
        </div></el-col>
        <el-col :xs="24" :sm="24" :md="22" :lg="22" :xl="22"><div class="grid-content">
            <el-checkbox-group 
                v-model="value[3].checkbox2"
                :min="0"
                :max="1"
                :disabled="othermodify"
                >
                <el-checkbox v-for="city in leivalue" :label="city" :key="city">{{city}}</el-checkbox>
            </el-checkbox-group>
        </div></el-col>
        <el-col :xs="24" :sm="24" :md="2" :lg="2" :xl="2" v-show="value[3].checkbox2.includes('抗生素')"><div class="grid-content bg-purple">
            抗生素
        </div></el-col>
        <el-col :xs="24" :sm="24" :md="22" :lg="22" :xl="22" v-show="value[3].checkbox2.includes('抗生素')"><div class="grid-content">
            <el-checkbox-group 
                v-model="value[3].checkbox4"
                :min="0"
                :max="1"
                :disabled="othermodify"
                >
                <el-checkbox v-for="city in ksvalue" :label="city" :key="city">{{city}}</el-checkbox>
            </el-checkbox-group>
        </div></el-col>
        <!-- 抗生素 -->
        <!-- 粘度 -->
        <el-col :xs="24" :sm="24" :md="2" :lg="2" :xl="2"><div class="grid-content bg-purple">
            粘度
        </div></el-col>
        <el-col :xs="24" :sm="24" :md="22" :lg="22" :xl="22"><div class="grid-content">
            <el-checkbox-group 
                v-model="value[3].checkbox3"
                :min="0"
                :max="1"
                :disabled="othermodify"
                >
                <el-checkbox v-for="city in nianvalue" :label="city" :key="city">{{city}}</el-checkbox>
            </el-checkbox-group>
        </div></el-col>
        <!-- 粘度 -->
          
        <!-- 骨水泥 -->
        <!-- 引流管 -->
        <el-col :xs="24" :sm="24" :md="3" :lg="3" :xl="3" ><div class="grid-content bg-purple">
            引流管
        </div></el-col>
        <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4" ><div class="grid-content">
            <el-checkbox-group 
                v-model="value[4].checkbox"
                :min="0"
                :max="1"
                :disabled="othermodify"
                >
                <el-checkbox label="无">无</el-checkbox>
                <el-checkbox label="有">有</el-checkbox>
            </el-checkbox-group>
        </div></el-col>
        <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4" ><div class="grid-content bg-purple">
            氨甲环酸
        </div></el-col>
        <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6" ><div class="grid-content">
            <el-checkbox-group 
                v-model="value[4].checkbox2"
                :min="0"
                :max="1"
                :disabled="othermodify"
                >
                <el-checkbox label="关节内注射">关节内注射</el-checkbox>
            </el-checkbox-group>
        </div></el-col>
        <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6" ><div class="grid-content">
            <el-checkbox-group 
                v-model="value[4].checkbox3"
                :min="0"
                :max="1"
                :disabled="othermodify"
                >
                <el-checkbox label="静脉滴注">静脉滴注</el-checkbox>
            </el-checkbox-group>
        </div></el-col>
        <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4" v-show="value[4].checkbox2.includes('关节内注射')" ><div class="grid-content bg-purple">
            关节内注射
        </div></el-col>
        <el-col :xs="24" :sm="24" :md="20" :lg="20" :xl="20"  v-show="value[4].checkbox2.includes('关节内注射')"><div class="grid-content">
            <el-input placeholder="请输入内容" v-model="value[4].inputvalue1"  :disabled="othermodify">
                <!-- <template slot="prepend">最薄处</template> -->
                <template slot="append">g/</template>
            </el-input>
            <el-input placeholder="请输入内容" v-model="value[4].inputvalue2"  :disabled="othermodify">
                <!-- <template slot="prepend">最薄处</template> -->
                <template slot="append">ml</template>
            </el-input>
        </div></el-col>
        <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4"  v-show="value[4].checkbox3.includes('静脉滴注')"><div class="grid-content bg-purple">
            静脉滴注
        </div></el-col>
        <el-col :xs="24" :sm="24" :md="20" :lg="20" :xl="20"  v-show="value[4].checkbox3.includes('静脉滴注')"><div class="grid-content">
            <el-input placeholder="请输入内容" v-model="value[4].inputvalue3"  :disabled="othermodify">
                <!-- <template slot="prepend">最薄处</template> -->
                <template slot="append">g/</template>
            </el-input>
            <el-input placeholder="请输入内容" v-model="value[4].inputvalue4"  :disabled="othermodify">
                <!-- <template slot="prepend">最薄处</template> -->
                <template slot="append">ml</template>
            </el-input>
        </div></el-col>
        <!-- 引流管 -->
        <!-- 鸡尾酒局部注射 -->
        <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4" ><div class="grid-content bg-purple">
            鸡尾酒局部注射
        </div></el-col>
        <el-col :xs="24" :sm="24" :md="20" :lg="20" :xl="20" ><div class="grid-content">
            <el-checkbox-group 
                v-model="value[5].checkbox"
                :min="0"
                :max="1"
                :disabled="othermodify"
                >
                <el-checkbox label="无">无</el-checkbox>
                <el-checkbox label="有">有</el-checkbox>
            </el-checkbox-group>
        </div></el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="partone" v-show="value[5].checkbox.includes('有')"><div class="grid-content">
                      <el-row>
                <el-col :xs="24" :sm="24" :md="2" :lg="2" :xl="2" ><div class="grid-content bg-purple">
            配方
                </div>
            </el-col>
                <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6" ><div class="grid-content">
                    <el-input placeholder="请输入内容" v-model="value[5].inputvalue1"  :disabled="othermodify">
                        <template slot="prepend">吗啡</template>
                        <template slot="append">支/</template>
                    </el-input>
                </div></el-col>
                <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6" ><div class="grid-content">
                    <el-input placeholder="请输入内容" v-model="value[5].inputvalue2"  :disabled="othermodify">
                        <template slot="prepend">得宝松</template>
                        <template slot="append">支/</template>
                    </el-input>
                </div></el-col>
                <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6" ><div class="grid-content">
                    <el-input placeholder="请输入内容" v-model="value[5].inputvalue3"  :disabled="othermodify">
                        <template slot="prepend">罗哌卡因</template>
                        <template slot="append">支/</template>
                    </el-input>
                </div></el-col>
                <el-col :xs="24" :sm="24" :md="2" :lg="2" :xl="2" ><div class="grid-content">
                    <el-input placeholder="请输入内容" v-model="value[5].inputvalue4"  :disabled="othermodify">
               
                    </el-input>
                </div></el-col>
            </el-row>
        </div></el-col>
        <!-- 鸡尾酒局部注射 -->
        <!-- 皮肤缝合方式 -->
        <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4" ><div class="grid-content bg-purple">
            皮肤缝合方式
        </div></el-col>
        <el-col :xs="24" :sm="24" :md="20" :lg="20" :xl="20" ><div class="grid-content">
            <el-checkbox-group 
                v-model="value[6].checkbox"
                :min="0"
                :max="1"
                :disabled="othermodify"
                >
                <el-checkbox v-for="city in pifu" :label="city" :key="city">{{city}}</el-checkbox>
            </el-checkbox-group>
        </div></el-col>
        <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4" v-show="value[6].isshow"><div class="grid-content bg-purple">
            {{value[6].checkbox[0]}}
        </div></el-col>
        <el-col :xs="24" :sm="24" :md="20" :lg="20" :xl="20" v-show="value[6].isshow"><div class="grid-content">
            <el-checkbox-group 
                v-model="value[6].checkbox2"
                :min="0"
                :max="1"
                :disabled="othermodify"
                >
                <el-checkbox label="4-0">4-0</el-checkbox>
                <el-checkbox label="3-0">3-0</el-checkbox>
            </el-checkbox-group>
        </div></el-col>
        <!-- 皮肤缝合方式 -->
        <!-- 病理 -->
        <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4" ><div class="grid-content bg-purple">
            病理
        </div></el-col>
        <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8" ><div class="grid-content">
            <el-checkbox-group 
                v-model="value[7].checkbox"
                :min="0"
                :max="1"
                :disabled="othermodify"
                >
                <el-checkbox label="无">无</el-checkbox>
                <el-checkbox label="有">有</el-checkbox>

            </el-checkbox-group>
        </div></el-col>
        <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4" ><div class="grid-content bg-purple">
            标本库
        </div></el-col>
        <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8" ><div class="grid-content">
            <el-checkbox-group 
                v-model="value[7].checkbox2"
                :min="0"
                :max="1"
                :disabled="othermodify"
                >
                <el-checkbox label="无">无</el-checkbox>
                <el-checkbox label="有">有</el-checkbox>

            </el-checkbox-group>
        </div></el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="partone" v-show="value[7].checkbox2.includes('有')"><div class="grid-content">
            <el-row>
                <el-col :xs="24" :sm="24" :md="16" :lg="16" :xl="16"><div class="grid-content">
            <el-checkbox-group 
                v-model="value[7].checkbox3"
                :min="0"
                :max="1"
                :disabled="othermodify"
                >
                <el-checkbox v-for="city in biaoben" :label="city" :key="city">{{city}}</el-checkbox>
                </el-checkbox-group>
            </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8" ><div class="grid-content ">
            <el-input placeholder="请输入内容" v-model="value[7].inputvalue1" v-show="value[7].checkbox3.includes('其他')"  :disabled="othermodify">
               
            </el-input>
        </div>
    </el-col>
            </el-row>
            
        </div></el-col>

        <!-- 病理 -->
        <!-- 术中并发症 -->
        <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4" ><div class="grid-content bg-purple">
            术中并发症
        </div></el-col>
        <el-col :xs="24" :sm="24" :md="20" :lg="20" :xl="20"><div class="grid-content">
            <el-checkbox-group 
                v-model="value[8].checkbox"
                :min="0"
                :max="1"
                :disabled="othermodify"
                >
                <el-checkbox v-for="city in bfz" :label="city" :key="city">{{city}}</el-checkbox>
            </el-checkbox-group>
        </div></el-col>
        <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4" v-show="value[8].checkbox.includes('骨折')"><div class="grid-content bg-purple">
            骨折
        </div></el-col>
        <el-col :xs="24" :sm="24" :md="20" :lg="20" :xl="20"  v-show="value[8].checkbox.includes('骨折')"><div class="grid-content">
            <el-checkbox-group 
                v-model="value[8].checkbox2"
                :min="0"
                :max="1"
                :disabled="othermodify"
                >
                <el-checkbox label="股骨" >股骨</el-checkbox>
                <el-checkbox label="胫骨" >胫骨</el-checkbox>
            </el-checkbox-group>
        </div></el-col>
        <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4" v-show="value[8].checkbox.includes('肌腱韧带损伤')"><div class="grid-content bg-purple">
            肌腱韧带损伤
        </div></el-col>
        <el-col :xs="24" :sm="24" :md="20" :lg="20" :xl="20"  v-show="value[8].checkbox.includes('肌腱韧带损伤')"><div class="grid-content">
            <el-checkbox-group 
                v-model="value[8].checkbox3"
                :min="0"
                :max="1"
                :disabled="othermodify"
                >
                <el-checkbox label="股骨" >髌韧带</el-checkbox>
                <el-checkbox label="胫骨" >MCL</el-checkbox>
            </el-checkbox-group>
        </div></el-col>
        <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4" v-show="value[8].checkbox.includes('其他')"><div class="grid-content bg-purple">
            其他
        </div></el-col>
        <el-col :xs="24" :sm="24" :md="20" :lg="20" :xl="20"  v-show="value[8].checkbox.includes('其他')"><div class="grid-content">
            <el-checkbox-group 
                v-model="value[8].checkbox4"
                :disabled="othermodify"
                >
                <el-checkbox v-for="city in bfzqt" :label="city" :key="city">{{city}}</el-checkbox>
            </el-checkbox-group>
        </div></el-col>
        <!-- 术中并发症 -->
<!-- 假体类型 -->
    <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4"><div class="grid-content bg-purple">
            假体类型
        </div></el-col>
        <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6" ><div class="grid-content">
            <el-checkbox-group 
                v-model="value[9].checkbox"
                :min="0"
                :max="1"
                :disabled="othermodify"
                >
                <el-checkbox label="活动平台">活动平台</el-checkbox>
                <el-checkbox label="固定平台">固定平台</el-checkbox>

            </el-checkbox-group>
        </div></el-col>
        <el-col :xs="24" :sm="24" :md="2" :lg="2" :xl="2"><div class="grid-content bg-purple">
            厂家
        </div></el-col>
        <el-col :xs="24" :sm="24" :md="5" :lg="5" :xl="5" ><div class="grid-content">
            <el-checkbox-group 
                v-model="value[9].checkbox2"
                :min="0"
                :max="1"
                :disabled="othermodify"
                >
                <el-checkbox label="Biomet">Biomet</el-checkbox>
                <el-checkbox label="其他">其他</el-checkbox>

            </el-checkbox-group>
        </div></el-col>
        <el-col :xs="24" :sm="24" :md="2" :lg="2" :xl="2"><div class="grid-content bg-purple">
            类型
        </div></el-col>
        <el-col :xs="24" :sm="24" :md="5" :lg="5" :xl="5" ><div class="grid-content">
            <el-checkbox-group 
                v-model="value[9].checkbox3"
                :min="0"
                :max="1"
                :disabled="othermodify"
                >
                <el-checkbox label="Oxford">Oxford</el-checkbox>
                <el-checkbox label="其他">其他</el-checkbox>
            </el-checkbox-group>
        </div></el-col>
        <el-col :xs="24" :sm="24" :md="22" :lg="2" :xl="2" v-show="value[9].checkbox2.includes('其他')"><div class="grid-content bg-purple">
            厂家
        </div></el-col>
        <el-col :xs="24" :sm="24" :md="22" :lg="22" :xl="22" v-show="value[9].checkbox2.includes('其他')"><div class="grid-content bg-purple">
          <el-input   :disabled="othermodify" v-model="value[9].inputvalue1"></el-input>
        </div></el-col>
        <el-col :xs="24" :sm="24" :md="22" :lg="2" :xl="2" v-show="value[9].checkbox3.includes('其他')"><div class="grid-content bg-purple">
            类型
        </div></el-col>
        <el-col :xs="24" :sm="24" :md="22" :lg="22" :xl="22" v-show="value[9].checkbox3.includes('其他')"><div class="grid-content bg-purple">
          <el-input   :disabled="othermodify" v-model="value[9].inputvalue2"></el-input>
        </div></el-col>
        <el-col :xs="24" :sm="24" :md="2" :lg="2" :xl="2" ><div class="grid-content bg-purple">
            假体大小
        </div></el-col>
        <el-col :xs="24" :sm="24" :md="2" :lg="22" :xl="22" ><div class="grid-content ">
            <el-row>
                <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8" >
                <div class="grid-content ">
                    <el-input placeholder="请输入内容" v-model="value[10].inputvalue1"  :disabled="othermodify">
                        <template slot="prepend">股骨</template>
                        <template slot="append">号</template>
                    </el-input>
                </div>
                </el-col>
                <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8" >
                <div class="grid-content ">
                    <el-input placeholder="请输入内容" v-model="value[10].inputvalue2"  :disabled="othermodify">
                        <template slot="prepend">胫骨</template>
                        <template slot="append">号</template>
                    </el-input>
                </div>
                </el-col>
                <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8" >
                <div class="grid-content ">
                    <el-input placeholder="请输入内容" v-model="value[10].inputvalue3"  :disabled="othermodify">
                        <template slot="prepend">垫片</template>
                        <template slot="append">mm</template>
                    </el-input>
                </div>
                </el-col>
            </el-row>
        </div></el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"><div class="grid-content bg-purple">
            补充其他信息
        </div></el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="224" :xl="24"><div class="grid-content bg-purple">
          <el-input  type="textarea"  :disabled="othermodify" v-model="value[10].inputvalue4"></el-input>
        </div></el-col>
<!-- 假体类型 -->


    </el-row>
  </div>
</template>

<script>
export default {
    props:[
    "message1","othermodify"
    ],
    data(){
        return{
            // 勺子厚度
            houdu:["1mm","2mm","3mm"],
            // 测量股骨大小
            size:["XS","S","M","L","XL"],
            // G型夹厚度
            Ghoudu:["3mm","4mm"],
            // 测量胫骨平台大小
            csize:["AA","A","B","C","D","E","F",],
            bsize:["略大","略小"],
            // 骨水泥
            paivalue:["Depuy","Biomet","其他"],
            leivalue:["抗生素","非抗生素"],
            ksvalue:["庆大","万古"],
            nianvalue:["高","中","低"],
            // 皮肤缝合方式
            pifu:["金属钉","微桥美容缝合","Prolinc美容缝合"],
            // 标本库
            biaoben:["滑液","软骨","软骨下骨","滑膜","半夜板","其他"],
            // 术中并发症
            bfz:["无","骨折","肌腱韧带损伤","血管损伤","其他"],
            bfzqt:["低血压","肺栓塞","心侓不齐","脑血管意外","心肌缺血","死亡"],
            // 需要提交的值
            value:[
                {checkbox:[],checkbox2:[],checkbox3:[]},
                {inputvalue1:"",inputvalue2:"",inputvalue3:"",inputvalue4:'',checkbox:[],checkbox2:[]},
                {inputvalue1:'',inputvalue2:'',inputvalue3:'',inputvalue4:''},
                {checkbox:[],checkbox2:[],checkbox3:[],checkbox4:[],inputvalue1:''},
                {inputvalue1:"",inputvalue2:"",inputvalue3:"",inputvalue4:'',checkbox:[],checkbox2:[],checkbox3:[]},
                {inputvalue1:'',inputvalue2:'',inputvalue3:'',checkbox:[]},
                {checkbox:[],checkbox2:[],isshow:false},
                {checkbox:[],checkbox2:[],checkbox3:[],inputvalue1:''},
                {checkbox:[],checkbox2:[],checkbox3:[],checkbox4:[]},
                {checkbox:[],checkbox2:[],checkbox3:[],inputvalue1:'',inputvalue2:''},
                {inputvalue1:'',inputvalue2:'',inputvalue3:'',inputvalue4:''},

            ]
        }
    },
    watch:{
        value:{
            handler(newval,old){
                newval[6].isshow = newval[6].checkbox[0]==undefined ? false:newval[6].checkbox == '金属钉' ? false:true
                this.$emit('other',newval)
            },deep:true
        },
        message1:{
            handler(newvalue,old){
                for(let i=0;i<newvalue.length;i++){
                    let obj=newvalue[i]
                    // console.log(obj)
                    for(let key in obj){
                        this.value[i][key] = obj[key]
                    }
                }
            },deep:true
        }
    }
}
</script>

<style scoped>
.insurgery2other{
    box-sizing: border-box;
    padding:5px;
}
.insurgery2other .el-col{
margin-bottom: 5px;
}
.partone{
    padding-left: 0px !important;
}
</style>