<template>
  <el-row>
    <el-dialog title="评分" :visible.sync="dialogVisible" width="800px" :center="true"  @close="handleCloseDialog" :fullscreen="isMobileScreen" >
      <el-progress :percentage="currentStep / totalSteps * 100" />
      <!-- <p>评分ID: {{ scoringIdInternal  }}</p>
      <p>Total: {{ total }}</p> -->


      <!-- <div v-if="currentStep === 1">
        <div class="block" style="height: 110px;">
          <h3>VAS评分（视觉模拟评分法）</h3>
          <el-slider
            v-model="sliderValue"
            :max="10"
            :step="1"
            show-stops
            :marks="marks"
            style="width: inherit;"
          ></el-slider>
        </div>
      </div> -->


      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" v-if="currentStep === 1">
        <h2 style="font-size: 24px; margin-bottom: 10px;">VAS评分（视觉模拟评分法）</h2>
        <div class="grid-content bg-purple">VAS评分（视觉模拟评分法）</div>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <div class="knee-options">
            <el-radio-group v-model="selectedKnee">
              <el-radio :label="0">左侧膝关节</el-radio>
              <el-radio :label="1">右侧膝关节</el-radio>
            </el-radio-group>
          </div>
          <el-slider
            v-model="sliderValue"
            :max="10"
            :step="1"
            show-stops
            :marks="marks"
            class="custom-slider" 
          ></el-slider>
          <div class="icon-container">
      <!-- 第一个图标 -->
      <div class="icon-item first-item">
        <img :src="require(`@/assets/icons/1.jpg`)" alt="Icon" class="custom-icon" />
      </div>
      <!-- 其他图标 -->
      <div class="icon-item" v-for="index in 5" :key="index">
        <img :src="require(`@/assets/icons/${index + 1}.jpg`)" alt="Icon" class="custom-icon" />
      </div>
    </div>
        </el-col>
      </el-col>


      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" v-if="currentStep === 2">
        <h2 style="font-size: 24px; margin-bottom: 10px;">AKS:{{ totalAKSScore }}</h2>
        <div class="grid-content bg-purple">疼痛(50分)</div>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" v-for="(option, index) in painOptions" :key="index" style="margin: 10px 0px 10px 0px">
          <el-radio v-model="AKS[0]" :label="option.value" >
            {{ option.text }}
          </el-radio>
        </el-col>
      </el-col>


      <!-- <div v-if="currentStep === 2">
    <h3>疼痛(50分)</h3>
    <el-col
      v-for="(option, index) in painOptions"
      :key="index"
      
    >
      <el-radio v-model="AKS[0]" :label="option.value">
        {{ option.text }}
      </el-radio>
    </el-col>
  </div> -->

      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" v-if="currentStep === 3">
        <h2 style="font-size: 24px; margin-bottom: 10px;">AKS:{{ totalAKSScore }}</h2>
        <div class="grid-content bg-purple">活动度(25分)</div>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <el-input v-model="AKS[1]" placeholder="请填写分数">
            <template slot="prepend">由屈曲到伸膝（每5°=1 分）</template>
            <!-- <template slot="append">°</template> -->
          </el-input>
        </el-col>
      </el-col>

      <!-- <div v-if="currentStep === 3">
        <h3>活动度(25分)</h3>
        <el-input v-model="AKS[1]" placeholder="请输入屈曲到伸膝的角度(°)" />
      </div> -->


      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" v-if="currentStep === 4">
        <h2 style="font-size: 24px; margin-bottom: 10px;">AKS:{{ totalAKSScore }}</h2>
        <div class="grid-content bg-purple">稳定性(25分)</div>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="margin-top: 10px; " > 
          <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4" class="grid-content bg-purple" >前后方向(10分)</el-col>
          <el-col :xs="24" :sm="24" :md="20" :lg="20" :xl="19"  class="grid-content border">
            <el-radio
              v-model="AKS[2]"
              @change="handleStabilityFrontBackChange(option)"
              :label="option.value"
              v-for="(option, index) in stabilityFrontBackOptions" :key="index"
            >
              {{ option.text }}
            </el-radio>
          </el-col>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"  style="margin-top: 10px;">
          <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4" class="grid-content bg-purple">内外方向(15分)</el-col>
          <el-col :xs="24" :sm="24" :md="20" :lg="20" :xl="20" class="grid-content border">
              <el-radio
                v-model="AKS[3]"
                @change="handleStabilityInOutChange(option)"
                :label="option.value"
                v-for="(option, index) in stabilityInOutOptions" :key="index"
              >
                {{ option.text }}
              </el-radio>
          </el-col>
        </el-col>  
      </el-col>

      <!-- <div v-if="currentStep === 4">
      <div>
        <h3>稳定性(25分)</h3>
        <h4>前后方向(10分)</h4>
        <el-col v-for="(option, index) in stabilityFrontBackOptions" :key="index">
          <el-radio
            v-model="AKS[2]"
            @change="handleStabilityFrontBackChange(option)"
            :label="option.value"
          >
            {{ option.text }}
          </el-radio>
        </el-col>
      </div>
      <div>
        <h4>内外方向(15分)</h4>
        <el-col v-for="(option, index) in stabilityInOutOptions" :key="index">
          <el-radio
            v-model="AKS[3]"
            @change="handleStabilityInOutChange(option)"
            :label="option.value"
          >
            {{ option.text }}
          </el-radio>
        </el-col>
      </div>
    </div> -->

<!-- <div v-if="currentStep === 5">
      <h3>减分项(-50分)</h3>
      <div>
        <h4>屈曲挛缩(-15分)</h4>
        <el-radio-group v-model="AKS[4]">
          <el-radio
            v-for="option in quFlexionOptions"
            :key="option.value"
            :label="option.value"
            @change="handleQuFlexionChange(option)"
          >
            {{ option.text }}
          </el-radio>
        </el-radio-group>
      </div>
      <div>
        <h4>伸膝迟滞(-15分)</h4>
        <el-radio-group v-model="AKS[5]">
          <el-radio
            v-for="option in shenZhiOptions"
            :key="option.value"
            :label="option.value"
            @change="handleShenZhiChange(option)"
          >
            {{ option.text }}
          </el-radio>
        </el-radio-group>
      </div>
      <div>
        <h4>解剖对线(-20分)</h4>
        <el-radio-group v-model="AKS[6]">
          <el-radio
            v-for="option in jieduilineOptions"
            :key="option.value"
            :label="option.value"
            @change="handleJieduilineChange(option)"
          >
            {{ option.text }}
          </el-radio>
        </el-radio-group>
      </div>
    </div> -->


    <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" v-if="currentStep === 5">
      <h2 style="font-size: 24px; margin-bottom: 10px;">AKS:{{ totalAKSScore }}</h2>
        <div class="grid-content bg-purple">减分项(-50分)</div>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="margin-top: 10px; padding:0;"> 
          <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4" class="grid-content bg-purple" >屈曲挛缩(-15分)</el-col>
          <el-col :xs="24" :sm="24" :md="20" :lg="20" :xl="19"  class="grid-content border">
            <el-radio-group v-model="AKS[4]">
              <el-radio
                v-for="option in quFlexionOptions"
                :key="option.value"
                :label="option.value"
                @change="handleQuFlexionChange(option)"
              >
                {{ option.text }}
              </el-radio>
            </el-radio-group>
          </el-col>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"  style="margin-top: 10px; padding:0;">
          <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4" class="grid-content bg-purple">伸膝迟滞(-15分)</el-col>
          <el-col :xs="24" :sm="24" :md="20" :lg="20" :xl="20" class="grid-content border">
            <el-radio-group v-model="AKS[5]">
              <el-radio
                v-for="option in shenZhiOptions"
                :key="option.value"
                :label="option.value"
                @change="handleShenZhiChange(option)"
              >
                {{ option.text }}
              </el-radio>
            </el-radio-group>
          </el-col>
        </el-col>  
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"  style="margin-top: 10px; padding:0;">
          <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4" class="grid-content bg-purple">解剖对线(-20分)</el-col>
          <el-col :xs="24" :sm="24" :md="20" :lg="20" :xl="20" class="grid-content border">
            <el-radio-group v-model="AKS[6]">
              <el-radio
                v-for="option in jieduilineOptions"
                :key="option.value"
                :label="option.value"
                @change="handleJieduilineChange(option)"
              >
                {{ option.text }}
              </el-radio>
            </el-radio-group>
          </el-col>
        </el-col> 
      </el-col>






    <!-- <div v-if="currentStep === 6">
    <div>
      <h3>1</h3>
      <h4>行走能力(50分)</h4>
      <el-radio-group v-model="formData2[0]">
        <el-radio
          v-for="option in walkAbilityOptions"
          :key="option.value"
          :label="option.value"
          @change="handleWalkAbilityChange(option)"
        >
          {{ option.text }}
        </el-radio>
      </el-radio-group>
    </div>
    <div>
      <h4>上下楼(50分)</h4>
      <el-radio-group v-model="formData2[1]">
        <el-radio
          v-for="option in stairsAbilityOptions"
          :key="option.value"
          :label="option.value"
          @change="handleStairsAbilityChange(option)"
        >
          {{ option.text }}
        </el-radio>
      </el-radio-group>
    </div>
    <div>
      <h4>减分项(-50分)</h4>
      <el-radio-group v-model="formData2[2]">
        <el-radio
          v-for="option in deductionOptions"
          :key="option.value"
          :label="option.value"
          @change="handleDeductionChange(option)"
        >
          {{ option.text }}
        </el-radio>
      </el-radio-group>
    </div>
  </div> -->


    <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" v-if="currentStep === 6">
      <h2 style="font-size: 24px; margin-bottom: 10px;">患者整体功能评分: {{ totalFormData2Score }} </h2>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="margin-top: 10px; padding:0;"> 
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="grid-content bg-purple" >行走能力(50分)</el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"  class="grid-content border">
            <el-radio-group v-model="formData2[0]">
              <el-radio
                v-for="option in walkAbilityOptions"
                :key="option.value"
                :label="option.value"
                @change="handleWalkAbilityChange(option)"
              >
                {{ option.text }}
              </el-radio>
            </el-radio-group>
          </el-col>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"  style="margin-top: 10px; padding:0;">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="grid-content bg-purple">上下楼(50分)</el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="grid-content border">
            <el-radio-group v-model="formData2[1]">
              <el-radio
                v-for="option in stairsAbilityOptions"
                :key="option.value"
                :label="option.value"
                @change="handleStairsAbilityChange(option)"
              >
                {{ option.text }}
              </el-radio>
            </el-radio-group>
          </el-col>
        </el-col>  
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"  style="margin-top: 10px; padding:0;">
          <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4" class="grid-content bg-purple">减分项(-50分)</el-col>
          <el-col :xs="24" :sm="24" :md="20" :lg="20" :xl="20" class="grid-content border">
            <el-radio-group v-model="formData2[2]">
              <el-radio
                v-for="option in deductionOptions"
                :key="option.value"
                :label="option.value"
                @change="handleDeductionChange(option)"
              >
                {{ option.text }}
              </el-radio>
            </el-radio-group>
          </el-col>
        </el-col> 
      </el-col>



    <!-- <div v-if="currentStep === 7">
            <h3>减分项(-50分)</h3>
            <div>
              <span class="input-group-text text-center bg-secondary text-white" style="display: block;">最近两天膝关节的疼痛程度</span>
<div class="input-group" v-for="(question, index) in questions" :key="index" v-if="index < 5">
  <span class="input-group-text" style="width: 350px;">{{ index + 1 }}、{{ question }}</span>
  <el-radio-group v-model="womac.womacscores[index]">
    <el-radio :label="0">0分</el-radio>
    <el-radio :label="1">1分</el-radio>
    <el-radio :label="2">2分</el-radio>
    <el-radio :label="3">3分</el-radio>
    <el-radio :label="4">4分</el-radio>
  </el-radio-group>
</div>
<span class="input-group-text text-center bg-secondary text-white" style="display: block;">最近两天膝关节的僵硬程度</span>

<div class="input-group" v-for="(question, index) in questions" :key="index" v-if="index >= 5 && index < 7">
  <span class="input-group-text" style="width: 350px;">{{ index + 1 }}、{{ question }}</span>
  <el-radio-group v-model="womac.womacscores[index]">
    <el-radio :label="0">0分</el-radio>
    <el-radio :label="1">1分</el-radio>
    <el-radio :label="2">2分</el-radio>
    <el-radio :label="3">3分</el-radio>
    <el-radio :label="4">4分</el-radio>
  </el-radio-group>
</div>
<span class="input-group-text text-center bg-secondary text-white" style="display: block;">近两天因膝关节问题进行下列日常活动困难程度</span>
<div class="input-group" v-for="(question, index) in questions" :key="index" v-if="index >= 7 && index < 24">
  <span class="input-group-text" style="width: 350px;">{{ index + 1 }}、{{ question }}</span>
  <el-radio-group v-model="womac.womacscores[index]">
    <el-radio :label="0">0分</el-radio>
    <el-radio :label="1">1分</el-radio>
    <el-radio :label="2">2分</el-radio>
    <el-radio :label="3">3分</el-radio>
    <el-radio :label="4">4分</el-radio>
  </el-radio-group>
</div>
            </div>
     </div> -->
 
     <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" v-if="currentStep === 7">
      <h2 style="font-size: 24px; margin-bottom: 10px;">WOMAC评分: {{ totalWomacScore  }} </h2>
        <div class="grid-content bg-purple">WOMAC 评分（0 代表无，4 代表最严重）</div>

        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="grid-content bg-secondary text-white"  style="margin-top: 10px;">最近两天膝关节的疼痛程度</el-col> 
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="margin-top: 10px; padding:0;" v-for="(question, index) in questions" :key="index" v-if="index < 5">
          <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6" class="grid-content bg-purple" >{{ index + 1 }}、{{ question }}</el-col>
          <el-col :xs="18" :sm="18" :md="18" :lg="18" :xl="18"  class="grid-content border">
            <el-radio-group v-model="womac.womacscores[index]">
              <el-radio :label="0">0分</el-radio>
              <el-radio :label="1">1分</el-radio>
              <el-radio :label="2">2分</el-radio>
              <el-radio :label="3">3分</el-radio>
              <el-radio :label="4">4分</el-radio>
            </el-radio-group>
          </el-col>
        </el-col>

        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="grid-content bg-secondary text-white"  style="margin-top: 10px;">最近两天膝关节的僵硬程度</el-col> 
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="margin-top: 10px; padding:0;" v-for="(question, index) in questions":key="index" v-if="index >= 5 && index < 7">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="grid-content bg-purple" >{{ index + 1 }}、{{ question }}</el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"  class="grid-content border">
            <el-radio-group v-model="womac.womacscores[index]">
              <el-radio :label="0">0分</el-radio>
              <el-radio :label="1">1分</el-radio>
              <el-radio :label="2">2分</el-radio>
              <el-radio :label="3">3分</el-radio>
              <el-radio :label="4">4分</el-radio>
            </el-radio-group>
          </el-col>
        </el-col>

        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="grid-content bg-secondary text-white"  style="margin-top: 10px;">近两天因膝关节问题进行下列日常活动困难程度</el-col> 
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="margin-top: 10px; padding:0;" v-for="(question, index) in questions" :key="index" v-if="index >= 7 && index < 24">
          <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6" class="grid-content bg-purple" >{{ index + 1 }}、{{ question }}</el-col>
          <el-col :xs="18" :sm="18" :md="18" :lg="18" :xl="18"  class="grid-content border">
            <el-radio-group v-model="womac.womacscores[index]">
              <el-radio :label="0">0分</el-radio>
              <el-radio :label="1">1分</el-radio>
              <el-radio :label="2">2分</el-radio>
              <el-radio :label="3">3分</el-radio>
              <el-radio :label="4">4分</el-radio>
            </el-radio-group>
          </el-col>
        </el-col>

      </el-col>




     <!-- <div v-if="currentStep === 8">
  <div>
    <h3>减分项(-50分)</h3> -->

    <!-- 膝关节僵硬程度的问题和选项 -->
    <!-- <span class="input-group-text text-center bg-secondary text-white" style="display: block;">膝关节僵硬程度</span>
    <p class="pt-2">以下问题是关于最近一个星期内您对膝关节僵硬程度的感受。膝关节僵硬是指在活动膝关节的时候，您感受到活动受到限制或者是活动缓慢。</p>
    <div class="input-group">
      <span class="input-group-text" style="width:180px;">{{ activitiesquestions[0].question }}</span>
      <el-radio-group v-model="KOOSactivitiesanswers[0]">
        <el-radio
          v-for="(option, optionIndex) in activitiesquestions[0].options"
          :key="'q1_option_' + optionIndex"
          :label="option.value"
        >
          {{ option.label }}
        </el-radio>
      </el-radio-group>
    </div> -->

    <!-- 膝关节疼痛的问题和选项 -->
    <!-- <span class="input-group-text text-center bg-secondary text-white" style="display: block;">膝关节疼痛</span>
    <p class="pt-2">最近一个星期，在进行以下活动时，您膝关节的疼痛达到何种程度?</p>
    <div class="input-group" v-for="(activity, index) in activitiesquestions.slice(1, 5)" :key="'q2_option_' + index">
      <span class="input-group-text" style="width:180px;">{{ activity.question }}</span>
      <el-radio-group v-model="KOOSactivitiesanswers[index + 1]">
        <el-radio
          v-for="(option, optionIndex) in activity.options"
          :key="'q2_option_' + index + '_option_' + optionIndex"
          :label="option.value"
        >
          {{ option.label }}
        </el-radio>
      </el-radio-group>
    </div> -->

    <!-- 关节功能与日常生活的问题和选项 -->
    <!-- <span class="input-group-text text-center bg-secondary text-white" style="display: block;">关节功能与日常生活</span>
    <p class="pt-2">以下的问题将有助于了解您的身体情况。旨在评估您自身行动及自我照顾的能力。请指出在最近一星期内，由于膝关节的原因在进行以下活动时困难程度。</p>
    <div class="input-group" v-for="(activity, index) in activitiesquestions.slice(5, 7)" :key="'q3_option_' + index">
      <span class="input-group-text" style="width:180px;">{{ activity.question }}</span>
      <el-radio-group v-model="KOOSactivitiesanswers[index + 5]">
        <el-radio
          v-for="(option, optionIndex) in activity.options"
          :key="'q3_option_' + index + '_option_' + optionIndex"
          :label="option.value"
        >
          {{ option.label }}
        </el-radio>
      </el-radio-group>
    </div>
  </div>
</div> -->


      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" v-if="currentStep === 8">
        <h2 style="font-size: 24px; margin-bottom: 10px;">KOOS, JR.评分: {{ totalKOOSActivitiesScore   }} </h2>
        <div class="grid-content bg-purple" style="padding: 20px; font-size: 16px; line-height: 1.5; text-align: left; color: #333;">
  <p>说明：本表格会询问一些关于您膝关节的问题，这些信息有助于我们评估您的膝关节功能。请在每个问题答案合适您觉得符合自身情况的选项处打勾，每题只能选一个答案。如果您对某个问题不确定，请尽量选择一个您认为最符合的选项。</p>
</div>

        <!-- 膝关节僵硬程度的问题和选项 -->
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="grid-content bg-secondary text-white"  style="margin-top: 10px;">膝关节僵硬程度</el-col> 
        <p>以下问题是关于最近一个星期内您对膝关节僵硬程度的感受。膝关节僵硬是指在活动膝关节的时候，您感受到活动受到限制或者是活动缓慢。</p>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="padding:0;">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="grid-content bg-purple" >{{ activitiesquestions[0].question }}</el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"  class="grid-content border">
            <el-radio-group v-model="KOOSactivitiesanswers[0]">
              <el-radio
                v-for="(option, optionIndex) in activitiesquestions[0].options"
                :key="'q1_option_' + optionIndex"
                :label="option.value"
                :disabled="isRadioDisabled2"
              >
                {{ option.label }}
              </el-radio>
            </el-radio-group>
          </el-col>
        </el-col>

        <!-- 膝关节疼痛的问题和选项 -->
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="grid-content bg-secondary text-white"  style="margin-top: 10px;">膝关节疼痛</el-col> 
        <p>最近一个星期，在进行以下活动时，您膝关节的疼痛达到何种程度?</p>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="padding:0;"  v-for="(activity, index) in activitiesquestions.slice(1, 5)" :key="'q2_option_' + index">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="grid-content bg-purple" >{{ activity.question }}</el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"  class="grid-content border">
            <el-radio-group v-model="KOOSactivitiesanswers[index + 1]">
              <el-radio
                v-for="(option, optionIndex) in activity.options"
                :key="'q2_option_' + index + '_option_' + optionIndex"
                :label="option.value"
                :disabled="isRadioDisabled(index)"
              >
                {{ option.label }}
              </el-radio>
            </el-radio-group>
          </el-col>
        </el-col>     

        <!-- 关节功能与日常生活的问题和选项 -->
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="grid-content bg-secondary text-white"  style="margin-top: 10px;">关节功能与日常生活</el-col> 
        <p>以下的问题将有助于了解您的身体情况。旨在评估您自身行动及自我照顾的能力。请指出在最近一星期内，由于膝关节的原因在进行以下活动时困难程度。</p>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="padding:0;"  v-for="(activity, index) in activitiesquestions.slice(5, 7)" :key="'q3_option_' + index">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="grid-content bg-purple" >{{ activity.question }}</el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"  class="grid-content border">
            <el-radio-group v-model="KOOSactivitiesanswers[index + 5]">
              <el-radio
                v-for="(option, optionIndex) in activity.options"
                :key="'q3_option_' + index + '_option_' + optionIndex"
                :label="option.value"
                :disabled="isRadioDisabled1(index)"
              >
                {{ option.label }}
              </el-radio>
            </el-radio-group>
          </el-col>
        </el-col>     
      </el-col>


  <!-- <div v-if="currentStep === 9">
    <div>
      <div v-for="(question, index) in sf12Questions" :key="question.id">
  <p>{{ question.question }}</p>
  <el-radio-group v-model="sf12Scores[question.id-1]" >
    <el-radio
      v-for="option in question.options"
      :key="option.value"
      :label="option.value"
    >{{ option.label }}</el-radio>
  </el-radio-group>
</div>
    <div>
      <pre>{{ sf12Scores }}</pre>
    </div>
   
  </div>
  </div>
   -->



      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" v-if="currentStep === 9">
        <h2 style="font-size: 24px; margin-bottom: 10px;">SF-12评分: {{ totalSF12Score }} </h2>
        <h2 style="font-size: 24px; margin-bottom: 10px;">SF-12 健康评分</h2>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="padding:0; margin-top: 10px;"  v-for="(question, index) in sf12Questions" :key="question.id">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="grid-content bg-purple" >{{ question.question }}</el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"  class="grid-content border">
            <el-radio-group v-model="sf12Scores[question.id-1]" >
              <el-radio
                v-for="option in question.options"
                :key="option.value"
                :label="option.value"
              >{{ option.label }}</el-radio>
            </el-radio-group>
          </el-col>
        </el-col>

      </el-col>


      <!-- <div v-if="currentStep === 10">
  <div v-for="(question, index) in promisQuestions" :key="question.id">
    <p>{{ question.question }}</p>
    <el-radio-group v-model="promisScores[index]" >
      <el-radio
        v-for="option in question.options"
        :key="option.value"
        :label="option.value"
      >{{ option.label }}</el-radio>
    </el-radio-group>
  </div>
  <div>
    <pre>{{ promisScores }}</pre>
  </div>
</div> -->


      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" v-if="currentStep === 10">
        <h2 style="font-size: 24px; margin-bottom: 10px;">PROMIS Global-10评分: {{ totalPromisScore  }} </h2>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="padding:0; margin-top: 10px;"  v-for="(question, index) in promisQuestions" :key="question.id">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="grid-content bg-purple" >{{ question.question }}</el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"  class="grid-content border">
            <el-radio-group v-model="promisScores[index]" >
              <el-radio
                v-for="option in question.options"
                :key="option.value"
                :label="option.value"
              >{{ option.label }}</el-radio>
            </el-radio-group>
          </el-col>
        </el-col>


      </el-col>


      <div style="text-align: center;">
      <div style="padding-top: 20px; display: inline-block;">
        <el-button v-if="currentStep > 1" class="prev-button" @click="prevStep">上一步</el-button>
        <el-button v-if="currentStep < totalSteps" type="primary" class="next-button" @click="nextStep">下一步</el-button>
        <el-button v-if="currentStep === totalSteps" class="submit-button" @click="calculateTotalScore">提交</el-button>
      </div>
      </div>
   

    </el-dialog>
  </el-row>
</template>




<script>
import axios from 'axios';
export default {
  props: {
   
    scoreDialogVisible: Boolean,
    visible: Boolean,
    scoringId: {
      type: [Number, null],// 如果评分ID是一个数字，请设置合适的类型
     // 可根据需要更改是否需要该属性
    },
  },
  data() {
    return {
      disabledQuestions: [0], // 存储要禁用选项的问题索引
      disabledQuestions1: [2, 3], // 存储要禁用选项的问题索引
      disabledQuestions2: [0], // 存储要禁用选项的问题索引
      isMobileScreen: false, // 根据屏幕宽度判断是否为移动设备
      totalScores: 0, // 初始化 totalScores
      scoringIdInternal: null,// 添加一个用于存储评分ID的属性
      dialogVisible: this.scoreDialogVisible,
      currentStep: 1,
      totalSteps:10 ,
      sliderValue: 0,
      selectedKnee: 0,
      //   AKS: { // 将 formData 更名为 AKS
      //   painScore: '',
      //   activityScore: '',
      //   selectedStabilityFrontBacOption: '',//单选按钮的值
      //   selectedStabilityInOutOption: '',//单选按钮的值
      //   quFlexionScore: '',   // 保存屈曲挛缩选项的值
      //   shenZhiScore: '',     // 保存伸膝迟滞选项的值
      //   jieduilineScore: '',  // 保存解剖对线选项的值
      // },
      //AKS:[0, 0, 0,0,null,0,null],
      AKS: new Array(7).fill(null),
      formData2: new Array(3).fill(null),
      //formData2:[0, 0, 0],
     womac: {
      // womacscores: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], // 存储每个问题的活动能力分数
       womacscores: new Array(24).fill(null),

      },
      KOOSactivitiesanswers: new Array(7).fill(null),
     // sf12Scores: new Array(12).fill(null),
      promisScores: new Array(10).fill(null),
      // KOOSactivitiesanswers: [0, 0, 0, 0, 0, 0,0],
       sf12Scores: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      // promisScores:[0, 0, 0, 0, 0, 0, 0, 0, 0, 0],

      marks: {
          0: '0',
          1: '1',
          2: '2',
          3: '3',
          4: '4',
          5: '5',
          6: '6',
          7: '7',
          8: '8',
          9: '9',
          10: '10',
      },
      painOptions: [
        { value: '50', text: '不疼 (50分)' },
        { value: '45', text: '轻度（偶尔） (45分)' },
        { value: '40', text: '轻度（上下楼时轻微疼痛） (40分)' },
        { value: '30', text: '轻度（平地行走时也轻微疼痛） (30分)' },
        { value: '20', text: '中度（偶尔） (20分)' },
        { value: '10', text: '中度（经常） (10分)' },
        { value: '0', text: '重度 (0分)' },
        // 其他选项类似
      ],
      stabilityFrontBackOptions: [
        { value: '10', text: '<5mm (10分)' },
        { value: '5', text: '<5～10mm (5分)' },
        { value: '0', text: '>10mm (0分)' },
        // 其他选项类似
      ],
      stabilityInOutOptions: [
        { value: '15', text: '<5° (15分)' },
        { value: '6', text: '6°～9° (6分)' },
        // 其他选项类似
      ],
      quFlexionOptions: [
        { value: -2, text: '5~10°' },
        { value: -5, text: '10~15°' },
        { value: -10, text: '16~20°' },
        { value: -15, text: '大于20°' },
      ],
      shenZhiOptions: [
        { value: -5, text: '小于10°' },
        { value: -10, text: '10~20°' },
        { value: -15, text: '大于20°' },
      ],
      jieduilineOptions: [
        { value: 0, text: '正常外翻5~10°' },
        { value: 1, text: '内翻0~4°' },
        { value: 2, text: '外翻0~4°' },
        { value: -20, text: '更严重内外翻' },
      ],
      walkAbilityOptions: [
        { value: 50, text: '无任何限制' },
        { value: 40, text: '约1千米以上' },
        { value: 30, text: '500-1000米' },
        { value: 20, text: '不到500米' },
        { value: 10, text: '仅能在室内活动' },
        { value: 0, text: '不能步行' },
      ],
      stairsAbilityOptions: [
        { value: 50, text: '正常上下楼梯' },
        { value: 40, text: '正常上楼，下楼梯需扶栏杆' },
        { value: 30, text: '上下楼梯均需扶栏杆' },
        { value: 15, text: '借助扶手能上楼，但不能下楼' },
        { value: 0, text: '完全不能上下楼梯' },
      ],
      deductionOptions: [
        { value: -5, text: '单手杖' },
        { value: -10, text: '双手杖' },
        { value: -20, text: '扶拐或助行器' },
        // 其他减分项的选项数据
      ],
      questions: [
        "在平地行走时",
        "上下楼梯时",
        "晚上在床上睡觉时",
        "坐着或者躺着时",
        "站立时",
        "在早晨刚醒时，膝关节僵硬程度如何",
        "白天，在坐、躺或休息后，关节僵硬程度如何",
        "下楼梯",
        "上楼梯",
        "从椅子上站起来时",
        "站立",
        "弯腰",
        "在平地行走",
        "上、下汽车",
        "逛街、购物",
        "穿鞋、袜",
        "起床",
        "脱鞋、袜",
        "上床躺下的时候",
        "进、出浴缸的时候",
        "坐着",
        "坐马桶或站起的时候",
        "干比较重的家务活",
        "干比较轻的家务活",
      ],
      selectedOptions: [],

      activitiesquestions: [
        {
          question: '1. 早晨当醒来时，您的膝关节僵硬有多严重?',
          selectedOption: null,
          options: [
            { label: '没有僵硬', value: 0 },
            { label: '轻微', value: 1 },
            { label: '中等', value: 2 },
            { label: '严重', value: 3 },
            { label: '非常严重', value: 4 },
          ],
        },
        {
          question: '2. 以膝关节为中心做旋转',
          selectedOption: null,
          options: [
            { label: '没有', value: 0 },
            { label: '轻微', value: 1 },
            { label: '中等', value: 2 },
            { label: '严重', value: 3 },
            { label: '非常严重', value: 4 },
          ],
        },
        {
          question: '3. 完全伸直膝关节',
          selectedOption: null,
          options: [
            { label: '没有', value: 0 },
            { label: '轻微', value: 1 },
            { label: '中等', value: 2 },
            { label: '严重', value: 3 },
            { label: '非常严重', value: 4 },
          ],
        },
        {
          question: '4. 上/下楼梯',
          selectedOption: null,
          options: [
            { label: '没有', value: 0 },
            { label: '轻微', value: 1 },
            { label: '中等', value: 2 },
            { label: '严重', value: 3 },
            { label: '非常严重', value: 4 },
          ],
        },
        {
          question: '5. 站立时',
          selectedOption: null,
          options: [
            { label: '没有困难', value: 0 },
            { label: '轻微困难', value: 1 },
            { label: '中等困难', value: 2 },
            { label: '非常困难', value: 3 },
            { label: '极其严重', value: 4 },
          ],
        },
        {
          question: '6. 坐椅子上起身',
          selectedOption: null,
          options: [
            { label: '没有困难', value: 0 },
            { label: '轻微困难', value: 1 },
            { label: '中等困难', value: 2 },
            { label: '非常困难', value: 3 },
            { label: '极其严重', value: 4 },
          ],
        },
        {
          question: '7. 下蹲从地上捡起东西',
          selectedOption: null,
          options: [
            { label: '没有困难', value: 0 },
            { label: '轻微困难', value: 1 },
            { label: '中等困难', value: 2 },
            { label: '非常困难', value: 3 },
            { label: '极其严重', value: 4 },
          ],
        },
      ],
    };
  },
  beforeMount() {
    // 在组件挂载前检查屏幕宽度
    this.checkMobileScreen();
    // 监听窗口大小变化，以便在屏幕尺寸改变时更新 isMobileScreen
    window.addEventListener("resize", this.checkMobileScreen);
  },
  beforeDestroy() {
    // 在组件销毁前移除窗口大小变化的监听器
    window.removeEventListener("resize", this.checkMobileScreen);
  },
  methods: { 
    // 根据屏幕宽度设置 isMobileScreen
    checkMobileScreen() {
      const screenWidth = window.innerWidth;
      this.isMobileScreen = screenWidth < 768; // 768px 是一个示例断点，可以根据需要调整
    },
    calculateTotalScore() {
  
      // 步骤1：从 data 中提取各个字段的值
      let vas = this.sliderValue;
      //let total = this.totalScores;
      let selectedKnee = this.selectedKnee;
      const aks = {
        0: this.AKS.reduce((acc, val, index) => {
              if (index === 1) {
                return acc + (Number(val) / 5 || 0);//数组中的第二个元素除以5
              }
              return acc + (Number(val) || 0);
            }, 0),
            1: this.AKS, // 提取所有的 value 值
          };

      const {
        walkAbilityScore,
        stairsAbilityScore,
        deductionScore
      }=this.formData2

      const global = {
        0: this.formData2.reduce((a, b) => a + b, 0),
        1: this.formData2
        // 其他字段...
      };
      const { womacscores } = this.womac;
      const pain = {
    0: womacscores.slice(0, 5).reduce((a, b) => a + b, 0), // 前五个元素总和
    1: womacscores.slice(0, 5),
    // 其他字段...
  };

  const stiffness = {
    0: womacscores[5] + womacscores[6], // 第六和第七个元素总和
    1: womacscores.slice(5, 7), // 第六和第七个元素
    // 其他字段...
  };

  const difficulty = {
    0: womacscores.slice(7).reduce((a, b) => a + b, 0), // 第八个及之后元素总和
    1: womacscores.slice(7), // 第八个及之后元素
    // 其他字段...
  };

      const koos_jr = {
        0: this.KOOSactivitiesanswers.reduce((a, b) => a + b, 0), // 所有元素总和
        1: this.KOOSactivitiesanswers,
        // 其他字段...
      };

      const sf = {
        0: this.sf12Scores.reduce((a, b) => a + b, 0), // 计算总和
        1: this.sf12Scores, // 提取所有的 value 值
        // 其他字段...
      };

      const promis = {
        0: this.promisScores.reduce((a, b) => a + b, 0), // 所有元素总和
        1: this.promisScores,
        // 其他字段...
      };
      // console.log(this.AKS[0])

      

      // 步骤2：构建请求参数
      const requestData = {
        vas2: [
              {
                sliderValue: this.sliderValue.toString()
              },
              {
                radio: this.selectedKnee === 0 ? '左侧膝关节' : '右侧膝关节'
              }
            ],
        aks,
        global,
        pain,
        stiffness,
        difficulty,
        koos_jr,
        sf,
        promis,
       
      };
  

      // 步骤3：发送POST请求
      axios.post(`/Scoring/addScoringInfo/${this.scoringIdInternal}`, requestData)
    .then((response) => {
      // 处理响应
      const responseData = response.data;
      console.log('成功发送POST请求', response.data);
      this.$parent.fetchScoringData();
      this.dialogVisible = false;
      //window.location.reload();
      // 处理responseData中的其他数据
    })
    .catch((error) => {
      // console.error('发送POST请求失败', error);
      // 处理错误
    });
    },
    handleCloseDialog() {
      this.$emit('close'); // 触发自定义的 close 事件
      this.currentStep = 1;
      //window.location.reload();
    },
    nextStep() {
      this.currentStep++;
    },
    prevStep() {
      this.currentStep--;
    },

    
    handleStabilityFrontBackChange(option) {
      this.AKS.selectedStabilityFrontBackOption = option.value;
      // console.log(this.AKS);

    },
    handleStabilityInOutChange(option) {
      this.AKS.selectedStabilityInOutOption = option.value;
    },
 // 处理屈曲挛缩选项的变化
  handleQuFlexionChange(option) {
    this.AKS.quFlexionScore = option.value;
  },
  handleShenZhiChange(option) {
    this.AKS.shenZhiScore = option.value;
  },
  handleJieduilineChange(option) {
    this.AKS.jieduilineScore = option.value;
  },
    // 处理行走能力选项的变化
handleWalkAbilityChange(option) {
  // 创建一个包含 value 和 text 的对象
  const selectedOption = {
    value: option.value,
    text: option.text,
  };
  this.formData2.walkAbilityScoreoption = [selectedOption];
  // 其他处理逻辑...
},

// 处理上下楼选项的变化
handleStairsAbilityChange(option) {
  // 创建一个包含 value 和 text 的对象
  const selectedOption = {
    value: option.value,
    text: option.text,
  };
  this.formData2.stairsAbilityScoreoption = [selectedOption];
  // 其他处理逻辑...
  // console.log(this.formData2.stairsAbilityScoreoption); 
},

// 处理减分项选项的变化
handleDeductionChange(option) {
  // 创建一个包含 value 和 text 的对象
  const selectedOption = {
    value: option.value,
    text: option.text,
  };
  this.formData2.deductionScoreoption = [selectedOption];
  // console.log(this.formData2.deductionScoreoption); 
  // 其他处理逻辑...
},

updateAnswer(questionIndex, value, label) {
      // 确保为该问题初始化数组
      if (!this.KOOSactivitiesanswers[questionIndex]) {
        this.$set(this.KOOSactivitiesanswers, questionIndex, {
          value: null,
          label: null,
        });
      }
      this.KOOSactivitiesanswers[questionIndex].value = value;
      this.KOOSactivitiesanswers[questionIndex].label = label;
      // console.log(this.KOOSactivitiesanswers)
    },

  saveResponse(question, selectedValue) {
  const index = question.id - 1; // 计算正确的索引
  if (index >= 0 && index < this.sf12Scores.length) {
    // 检查索引是否有效
    this.$set(this.sf12Scores, index, selectedValue);
  }
  
},

  promisScoresaveResponse(question, selectedValue) {
    this.$set(this.promisScores, question.scoreKey, selectedValue);
  },

  },
  computed: {
    dialogStyle() {
      // 检测屏幕宽度，小于某个值时全屏，大于某个值时水平居中
      const screenWidth = window.innerWidth;
      const breakpoint = 768; // 设置断点宽度，根据您的需要调整
      const style = {};

      if (screenWidth <= breakpoint) {
        style.maxWidth = '100%'; // 在小设备上全屏
        style.width = '100%';
      } else {
        style.maxWidth = '80%'; // 在大设备上水平居中并设置宽度
        style.width = '80%';
        style.margin = '0 auto';
      }
      return style;

    },
    sf12Questions() {
    // SF-12 问题列表
    return [
      {
        id:1,
        question: '1. 总体而言，您认为您的健康状况如何',
        scoreKey: 'overallHealth',
        options: [
          { label: '极好', value: 1 },
          { label: '非常好', value: 2 },
          { label: '好', value: 3 },
          { label: '还行', value: 4 },
          { label: '差', value: 5 },
        ],
      },
      {
        id:2,
        question: '2. 您觉得您的健康状况限制您进行适度活动诸如搬桌子，搞卫生，打保龄球或高尔夫球吗',
        scoreKey: 'limitationInModerateActivities',
        options: [
          { label: '很大限制', value: 1 },
          { label: '稍有限制', value: 2 },
          { label: '没有限制', value: 3 },
        ],
      },
      {
        id:3,
        question: '3. 您觉得您的健康状况限制您爬数层楼梯吗',
        scoreKey: 'limitationInClimbingStairs',
        options: [
          { label: '很大限制', value: 1 },
          { label: '稍有限制', value: 2 },
          { label: '没有限制', value: 3 },
        ],
      },
      {
        id:4,
        question: '4. 过去一周，您是否由于健康原因，想做的事不能完成',
        scoreKey: 'unableToDoActivities',
        options: [
          { label: '是', value: 1 },
          { label: '否', value: 2 },
        ],
      },
      {
        id:5,
        question: '5. 过去一周，您是否由于健康原因，某些工作或日常活动受到限制',
        scoreKey: 'limitationInWorkDueToEmotions',
        options: [
          { label: '是', value: 1 },
          { label: '否', value: 2 },
        ],
      },
      {
        id:6,
        question: '6. 过去一周，您是否由于情绪原因（郁闷或焦虑），想做的事不能完成',
        scoreKey: 'unableToDoWorkDueToEmotions',
        options: [
          { label: '是', value: 1 },
          { label: '否', value: 2 },
        ],
      },
      {
        id:7,
        question: '7. 过去一周，您是否由于情绪原因（郁闷或焦虑），不能像往常一样工作或做其他活动',
        scoreKey: 'painInterferesWithWork',
        options: [
          { label: '是', value: 1 },
          { label: '否', value: 2 },
        ],
      },
      {
        id:8,
        question: '8. 过去一周，疼痛在多大程度上妨碍你的正常工作（包括家外和家务劳动）',
        scoreKey: 'unableToDoSocialActivities',
        options: [
          { label: '一点也不', value: 1 },
          { label: '轻微', value: 2 },
          { label: '中度', value: 3 },
          { label: '很大程度', value: 4 },
          { label: '严重影响', value: 5 },
        ],
      },
      {
        id:9,
        question: '9. 过去一周，您有多少时间是心情平静',
        scoreKey: 'peacefulMoodDuration',
        options: [
          { label: '一直都是', value: 1 },
          { label: '大部分时间', value: 2 },
          { label: '较长时间', value: 3 },
          { label: '有些时间', value: 4 },
          { label: '偶尔', value: 5 },
          { label: '完全没有', value: 6 },
        ],
      },
      {id:10,
        question: '10. 过去一周，您有多少时间是精力充沛的',
        scoreKey: 'energeticDuration',
        options: [
          { label: '一直都是', value: 1 },
          { label: '大部分时间', value: 2 },
          { label: '较长时间', value: 3 },
          { label: '有些时间', value: 4 },
          { label: '偶尔', value: 5 },
          { label: '完全没有', value: 6 },
        ],
      },
      {
        id:11,
        question: '11. 过去一周，您有多少时间感到心情郁闷',
        scoreKey: 'depressedDuration',
        options: [
          { label: '一直都是', value: 1 },
          { label: '大部分时间', value: 2 },
          { label: '较长时间', value: 3 },
          { label: '有些时间', value: 4 },
          { label: '偶尔', value: 5 },
          { label: '完全没有', value: 6 },
        ],
      },
      {
        id:12,
        question: '12. 过去一周，由于健康状况或情绪问题，您有多长时间因此而导致日常社交活动（像探访亲友等）受到限制',
        scoreKey: 'socialActivitiesLimited',
        options: [
          { label: '一直都是', value: 1 },
          { label: '大部分时间', value: 2 },
          { label: '有些时间', value: 3 }, 
          { label: '偶尔', value: 4 },
          { label: '完全没有', value: 5 },
        ],
      },
    ];
  },
    promisQuestions() {
        return [
          {
            id: 1,
            question: "1. 总体来说，您觉得您的健康状况如何",
            scoreKey: "overallHealth",
            options: [
              { value: 5, label: "极好" },
              { value: 4, label: "非常好" },
              { value: 3, label: "好" },
              { value: 2, label: "还行" },
              { value: 1, label: "差" },
            ],
          },
          {
            id: 2,
            question: "2. 总体来说，您觉得您的生活质量如何",
            scoreKey: "overallQualityOfLife",
            options: [
              { value: 5, label: "极好" },
              { value: 4, label: "非常好" },
              { value: 3, label: "好" },
              { value: 2, label: "还行" },
              { value: 1, label: "差" },
            ],
          },
          {
            id:3 ,
            question: "3. 总体来说，您觉得您的身体健康状况如何",
            scoreKey: "physicalHealth",
            options: [
              { value: 5, label: "极好" },
              { value: 4, label: "非常好" },
              { value: 3, label: "好" },
              { value: 2, label: "还行" },
              { value: 1, label: "差" },
            ],
          },
          {
            id: 4,
            question: "4. 总体来说，您觉得您的心理健康状况如何？包括情绪、思考能力等。",
            scoreKey: "mentalHealth",
            options: [
              { value: 5, label: "极好" },
              { value: 4, label: "非常好" },
              { value: 3, label: "好" },
              { value: 2, label: "还行" },
              { value: 1, label: "差" },
            ],
          },
          {
            id:5,
            question: "5. 总体来说，您对您的社交活动以及亲友关系满意程度怎么样",
            scoreKey: "socialSatisfaction",
            options: [
              { value: 5, label: "极好" },
              { value: 4, label: "非常好" },
              { value: 3, label: "好" },
              { value: 2, label: "还行" },
              { value: 1, label: "差" },
            ],
          },
          {
            id:6,
            question: "9r. 总体来说，您对您开展日常社交活动以及履行社会关系角色如何评价（包括在家中或工作中作为父母、儿女、配偶、员工或是朋友等角色所进行的相关活动",
            scoreKey: "socialSatisfaction",
            options: [
              { value: 5, label: "极好" },
              { value: 4, label: "非常好" },
              { value: 3, label: "好" },
              { value: 2, label: "还行" },
              { value: 1, label: "差" },
            ],
          },
          {
            id:7,
            question: "6. 您的日常的体力活动如行走、爬楼梯、买菜或搬椅子等活动能否正常开展？",
            scoreKey: "physicalFunctioning",
            options: [
              { value: 5, label: "完全可以" },
              { value: 4, label: "大部分可以" },
              { value: 3, label: "部分可以" },
              { value: 2, label: "少部分可以" },
              { value: 1, label: "完全不能" },
            ],
          },
          {
            id:8,
          question: "10r. 您有多经常受自己的情绪（如焦虑、抑郁或易怒等）所影响？",
          scoreKey: "emotionalImpact",
          options: [
            { value: 5, label: "从不" },
            { value: 4, label: "极少" },
            { value: 3, label: "偶尔" },
            { value: 2, label: "经常" },
            { value: 1, label: "总是" },
          ],
        },
        {
          id:9,
          question: "8r. 您是否感觉疲惫？",
          scoreKey: "fatigue",
          options: [
            { value: 5, label: "不觉得" },
            { value: 4, label: "轻度" },
            { value: 3, label: "中度" },
            { value: 2, label: "重度" },
            { value: 1, label: "极重度" },
          ],
        },
        {
          id:10,
          question: "7rc. 您的疼痛程度如何？",
          scoreKey: "pain",
          options: [
            { value: 0, label: "0" },
            { value: 1, label: "1" },
            { value: 2, label: "2" },
            { value: 3, label: "3" },
            { value: 4, label: "4" },
            { value: 5, label: "5" },
            { value: 6, label: "6" },
            { value: 7, label: "7" },
            { value: 8, label: "8" },
            { value: 9, label: "9" },
            { value: 10, label: "10" },
          ],
        },

                  
          // 添加其他问题
        ];
    },
 // 计算属性 total，根据依赖的数据自动计算
    total() {
        // 计算各个数组的总和，然后相加
        const totalScores =
          this.AKS.reduce((acc, val) => acc + (Number(val) || 0), 0) +
          this.sliderValue +
          this.formData2.reduce((acc, val) => acc + (Number(val) || 0), 0) +
          this.womac.womacscores.reduce((acc, val) => acc + val, 0) +
          this.KOOSactivitiesanswers.reduce((acc, val) => acc + (Number(val) || 0), 0) +
          this.promisScores.reduce((acc, val) => acc + (Number(val) || 0), 0) +
          this.sf12Scores.reduce((acc, val) => acc + val, 0);
        
        // 将计算结果保存到 totalScores
        this.totalScores = totalScores;

        return totalScores;
    },
    totalAKSScore() {
      // 使用防御性编程确保 AKS 数组有效且只包含数字
    if (!Array.isArray(this.AKS)) {
      return 0; // 如果不是有效数组，返回 0 或其他默认值
    }

    // 使用 Number() 函数将数组元素转为数字，并过滤掉非数字元素
    const numericValues = this.AKS.map((value) => {
      const numericValue = Number(value);
      // console.log('Numeric Value:', numericValue);
      return numericValue;
    }).filter((value) => !isNaN(value));

    // console.log('Numeric Values:', numericValues);

    // 计算总和
    return numericValues.reduce((total, value) => total + value, 0);
    },
    totalFormData2Score() {
      // 计算 formData2 数组的总分
      return this.formData2.reduce((total, score) => total + (score || 0), 0);
    },
    totalWomacScore() {
      // 计算 womac.womacscores 数组的总分
      return this.womac.womacscores.reduce((total, score) => total + (score || 0), 0);
    },
    totalKOOSActivitiesScore() {
      // 计算 KOOSactivitiesanswers 数组的总分
      return this.KOOSactivitiesanswers.reduce((total, score) => total + (score || 0), 0);
    },
    totalPromisScore() {
      // 计算 promisScores 数组的总分
      return this.promisScores.reduce((total, score) => total + (score || 0), 0);
    },
    totalSF12Score() {
      // 计算 sf12Scores 数组的总分
      return this.sf12Scores.reduce((total, score) => total + (score || 0), 0);
    },
    isRadioDisabled() {
    return (index) => {
      return this.disabledQuestions1.includes(index);
      };
    
    },
    isRadioDisabled1() {
    return (index) => {
      return this.disabledQuestions.includes(index);
    };
    },
    isRadioDisabled2() {
    return true
  },
  },
  created() {
  if (this.womac && this.womac.womacscores) {
    // 在确保 womac 和 womacscores 存在时再访问
    //const stiffnessLevel = this.womac.womacscores[5];
    //const stiffnessLevel1 = this.womac.womacscores[1];
    // 其他逻辑...
  }
    this.scoringIdInternal = this.scoringId;

  
},
watch: {
  'womac.womacscores': {
    handler(newVal, oldVal) {
      const indexToWatchStiffness = 5; // 要监听的数组元素的索引位置，这里是第 6 个元素
      this.KOOSactivitiesanswers[0] = newVal[indexToWatchStiffness]; // 将 womac.womacscores 数组的第 6 个元素的值赋给 koos 对象的 stiffnessLevel 属性
  

      const indexToWatchActivity1 = 1; // 要监听的数组元素的索引位置，这里是第 2 个元素
      this.KOOSactivitiesanswers[3] = newVal[indexToWatchActivity1]; // 将 womac.womacscores 数组的第 2 个元素的值赋给 koos.selectedActivities 数组的第 3 个元素
   
      const indexToWatchActivity2 = 4; // 要监听的数组元素的索引位置，这里是第 4 个元素
      this.KOOSactivitiesanswers[4] = newVal[indexToWatchActivity2]; // 将 womac.womacscores 数组的第 4 个元素的值赋给 koos.selectedActivities 数组的第 4 个元素
      

      const indexToWatchActivity3 = 9; // 要监听的数组元素的索引位置，这里是第 4 个元素
      this.KOOSactivitiesanswers[5] = newVal[indexToWatchActivity3]; // 将 womac.womacscores 数组的第 10 个元素的值赋给 koos.selectedActivities 数组的第 6 个元素

     
        
    },
    deep: true, // 告诉 Vue 深度监听 womac.womacscores 数组的变化
  },

  visible(newVal) {
      this.isVisible = newVal;
    },
    visible(newVal) {
      // 当父组件传递的 visible prop 发生变化时，更新内部的 dialogVisible
      // console.log('visible prop changed to:', newVal);
      this.dialogVisible = newVal;
    },
    KOOSactivitiesanswers: {
    handler(newVal) {
      // console.log(newVal);
    },
    deep: true
  },

  scoringId: function (newScoringId) {
      // 当评分ID变化时执行的操作
      this.scoringIdInternal = newScoringId;
    },
  
},
mounted() {
  this.calculateTotalScore = this.calculateTotalScore.bind(this);
  

},


};
</script>
<style>
/* 使用媒体查询来调整断点宽度 */
@media screen and (max-width: 768px) {
  /* 在小设备上，不需要设置样式，因为已经全屏 */
}
.icon-container {
  display: flex;
  flex-wrap: nowrap; 
}

.icon-item {
  margin-right: 125px; 
  margin-top: 30px; 
  text-align: left; 
}

.first-item {
  margin-left: -10px; 
}

.custom-icon {
  width: 35px; 
  height: 35px; 
}
.custom-slider {
  white-space: nowrap; 
}


/* 自定义上一步按钮样式 */
.prev-button {
  margin-right: 10px; /* 右边距 10px */
  display: inline-block;
}

/* 自定义下一步按钮样式 */
.next-button {
  margin-left: 10px; /* 左边距 10px */
  display: inline-block;
  padding-top: 50px;
}

/* 自定义提交按钮样式 */
.submit-button {
  background-color: #42b983; /* 自定义按钮背景颜色 */
  color: white; /* 字体颜色为白色 */
  border: none; /* 移除边框 */
  display: inline-block;
  padding-top: 50px;
}

.submit-button:hover {
  background-color: #339966; /* 鼠标悬停时的背景颜色 */
}

</style>