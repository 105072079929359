<!-- PROMIS Global-10 -->
<template>
  <div class="promise">
    <el-row>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"><div class="grid-content bg-purple">PROMIS Global-10</div></el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" v-for="(item,index) in option" :key="index"><div class="grid-content">
            <el-row>
                <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"><div class="grid-content bg-purple-light">{{ item.name }}</div></el-col>
                <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"><div class="grid-content">
                    <el-radio-group v-model="radiovalue[index].value">
                        <el-radio v-for="(lavalue,index2) in item.value" :key="index2" :label="index2+1">{{lavalue}}</el-radio>
                    </el-radio-group>
                </div></el-col>

            </el-row>
        </div></el-col>
    </el-row>
  </div>
</template>

<script>
export default {
data(){
    return{
        radiovalue:[{value:''},{value:''},{value:''},{value:''},{value:''},{value:''},{value:''},{value:''},{value:''},{value:''}],
        option:[
            {
                name:'1. 总体来说，您觉得您的健康状况如何',
                value:["极好 1 分","非常好 2 分","好 3 分","还行 4 分","差 5 分"]
            },
            {
                name:'2. 总体来说，您觉得您的生活质量如何',
                value:["极好 1 分","非常好 2 分","好 3 分","还行 4 分","差 5 分"]

            },
            {
                name:'3. 总体来说，您觉得您的身体健康状况如何',
                value:["极好 1 分","非常好 2 分","好 3 分","还行 4 分","差 5 分"]
            },
            {
                name:'4. 总体来说，您觉得您的心理健康状况如何？包 括情绪、思考能力等。',
                value:["极好 1 分","非常好 2 分","好 3 分","还行 4 分","差 5 分"]
            },
            {
                name:'5. 总体来说，您对您的社交活动以及亲友关系满 意程度怎么样',
                value:["极好 1 分","非常好 2 分","好 3 分","还行 4 分","差 5 分"]
            },
            {
                name:'9r. 总体来说，您对您开展日常社交活动以及履行 社会关系角色如何评价（包括在家中或工作中作为 父母、儿女、配偶、员工或是朋友等角色所进行的 相关活动）',
                value:["极好 1 分","非常好 2 分","好 3 分","还行 4 分","差 5 分"]
            },
            {
                name:'6. 您的日常的体力活动如行走、爬楼梯、买菜或 搬椅子等活动能否正常开展？',
                value:["完全不能 1 分","少部分可以 2 分","部分可以 3 分","大部分可以 4 分","完全可以5 分"]
            },
            {
                name:'10r. 您有多经常受自己的情绪（如焦虑、抑郁或易怒等）所影响？',
                value:["总是 1 分","经常 2 分","偶尔 3 分","极少 4 分","从不 5 分"]
            },
            {
                name:'8r. 您是否感觉疲惫？',
                value:["极重度 1 分","重度 2 分","中度 3 分","轻度 4 分","不觉得 5 分"]
            },
            {
                name:'7rc. 您的疼痛程度如何？',
                value:["0","1","2","3","4","5","6","7","8","9","10"]
            },
            
        ]
    }
},
props:["message"],
watch:{
    radiovalue:{
        handler(newvalue,oldvalue){
            let sum=0
                let radioarr=[]
                let sumarr=[]
                for(let i=0;i<newvalue.length;i++){
                    let obj = newvalue[i]
                    if(obj.value!=''){
                         sum = sum + obj.value
                        radioarr.push(obj.value)
                    }else{
                        sum = sum + 0
                        radioarr.push(obj.value)
                    }
                }
                sumarr.push(sum),
                sumarr.push(radioarr)
            this.$emit("promiseemit",sumarr)
        },deep:true
    }
    ,
    message(newvalue){
        for(let i=0;i<newvalue.length;i++){
            this.radiovalue[i].value = parseInt(newvalue[i])
        }
    }
}
}
</script>

<style>

</style>