<template>
  <div class="tableContent">
    <!-- 时间 -->
    <div class="postoperative-date">
      <el-row :gutter="0">
        <el-col :xs="4" :sm="3" :md="3" :lg="3" :xl="3">
          <div class="grid-content bg-purple-dark">
            <span>时间</span>
          </div>
        </el-col>
        <el-col :xs="20" :sm="21" :md="21" :lg="21" :xl="21">
          <div class="grid-content">
            <el-date-picker
              type="date"
              value-format="yyyy-MM-dd"
              v-model="operateForm.Postop_date"
            >
            </el-date-picker>
          </div>
        </el-col>
      </el-row>
    </div>

    <!-- 血常规 -->
    <div class="blood_routine">
      <div class="grid-content bg-purple-dark">血常规</div>
      <el-row :gutter="0" v-for="(item, index) in formData.blood" :key="index">
        <el-col :xs="4" :sm="3" :md="3" :lg="3" :xl="3"
          ><div class="grid-content">{{ item.label }}</div></el-col
        >
        <el-col
          :xs="20"
          :sm="21"
          :md="21"
          :lg="21"
          :xl="21"
          v-if="item.type == 'radio'"
          ><div class="grid-content bg-purple-light">
            <el-radio-group
              v-model="operateForm.blood_routine[item.model]"
            >
              <el-radio
                v-for="(item, index) in item.list"
                :key="index"
                :label="item"
                >{{ item }}</el-radio
              >
            </el-radio-group>
          </div></el-col
        >
        <el-col
          :xs="20"
          :sm="21"
          :md="21"
          :lg="21"
          :xl="21"
          v-if="item.type == 'input'"
          ><div class="grid-content bg-purple-light">
            <el-input
              placeholder="请输入内容"
              v-model="operateForm.blood_routine[item.model]"
            >
              <template slot="append">{{ item.unit }}</template>
            </el-input>
          </div></el-col
        >
      </el-row>
    </div>

    <!-- 生化 炎症指标 凝血功能 -->
    <div class="bmp" v-for="(item, index) in formData.inputParams" :key="index">
      <div class="grid-content bg-purple-dark">{{ item.span }}</div>
      <el-row :gutter="5">
        <el-col
          :xs="24"
          :sm="12"
          :md="12"
          :lg="8"
          :xl="6"
          v-for="(_item, _index) in item.opt"
          :key="_index"
        >
          <el-input
            placeholder="请输入内容"
            v-model="operateForm[item.obj][_item.model]"
          >
            <template slot="prepend">{{ _item.label }}</template>
            <template slot="append" v-if="item.showUnit">{{
              _item.unit
            }}</template>
          </el-input>
        </el-col>
      </el-row>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
    name:'commonPostoperative',
    props:{
        formData:Object,
        operateForm:Object
    },
  data() {
    return {};
  },
  watch:{
    operateForm:{
      deep:true,
      handler(newvalue,oldvalue){
        console.log(newvalue,'[[p[]]]')
      }
    }
  },
  methods: {},
};
</script>

<style scoped>
.el-row {
  border-bottom: 1px solid #d6dde2;
  padding: 10px 0 5px 0;
}
.el-col {
  border-radius: 4px;
  margin-bottom: 5px;
}
.bg-purple-dark {
  background: #99a9bf;
  color: #fff;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  /* background: #e5e9f2; */
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
}
.el-radio {
  margin-bottom: 0;
}

.shape_signal_text {
  font-size: 14px;
}
.el-date-editor.el-date-editor {
  width: 100%;
}
</style>