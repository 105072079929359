<template>
  <!-- 围手术期术后信息 -->
  <div class="postoperative">
    <el-button @click="addColor" v-if="showAddColor" type="primary">
        添加彩超信息
      </el-button>
    <el-button type="primary" @click="addItem"> 添加术后检验学信息 </el-button>
    
    <!-- 彩超 -->
    <div class="colord" v-if="showColort">
      
      

      <el-row :gutter="0">
        <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
          <div class="grid-content bg-purple-dark">彩超时间</div>
        </el-col>

        <el-col :xs="24" :sm="24" :md="20" :lg="20" :xl="20">
          <div class="grid-content">
            <el-date-picker
              type="date"
              value-format="yyyy-MM-dd"
              v-model="dvtData.Postop_date"
              :disabled=true
            >
            </el-date-picker>
          </div>
        </el-col>
      </el-row>

      <el-row :gutter="0"
        ><el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
          <div class="grid-content bg-purple-dark">
            <span>下肢静脉血栓</span>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="20" :lg="20" :xl="20">
          <div class="grid-content bg-purple-light">
            <el-radio-group v-model="dvtData.dvt" :disabled=true>
              <el-radio :label="'1'">FV</el-radio>
              <el-radio :label="'2'">PFV</el-radio>
              <el-radio :label="'3'">PV</el-radio>
              <el-radio :label="'4'">ATV</el-radio>
              <el-radio :label="'5'">PTV</el-radio>
              <el-radio :label="'6'">PeV</el-radio>
            </el-radio-group>
          </div>
        </el-col>
      </el-row>
    </div>

    <!-- 添加弹窗 -->
    <el-dialog
      title="添加术后检验学信息"
      :visible.sync="isShow"
      :width="diawid"
      >
      <commonPostoperative 
        :operateForm="operateForm"
        :formData="formData"
        ref="dialogform"
      />
      <div slot="footer" class="dialog-footer">
        <el-button @click="isShow = false">取 消</el-button>
        <el-button type="primary" @click="confirm">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 彩超弹窗 -->
    <el-dialog
    title="添加彩超信息"
    :width="diawid"
    :visible.sync="showColor">
    <div class="colord">
      <div class="grid-content bg-purple-dark mgb10">彩超</div>

      <el-row :gutter="0">
        <el-col :xs="4" :sm="3" :md="3" :lg="3" :xl="3">
          <div class="grid-content bg-purple-dark">
            <span>时间</span>
          </div>
        </el-col>
        <el-col :xs="20" :sm="21" :md="21" :lg="21" :xl="21">
          <div class="grid-content">
            <el-date-picker
              type="date"
              value-format="yyyy-MM-dd"
              v-model="dvtData.Postop_date"
            >
            </el-date-picker>
          </div>
        </el-col>
      </el-row>

      <el-row :gutter="0"
        ><el-col :xs="24" :sm="24" :md="3" :lg="3" :xl="3">
          <div class="grid-content bg-purple-dark">
            <span>下肢静脉血栓</span>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="21" :lg="21" :xl="21">
          <div class="grid-content bg-purple-light">
            <el-radio-group v-model="dvtData.dvt">
              <el-radio :label="'1'">FV</el-radio>
              <el-radio :label="'2'">PFV</el-radio>
              <el-radio :label="'3'">PV</el-radio>
              <el-radio :label="'4'">ATV</el-radio>
              <el-radio :label="'5'">PTV</el-radio>
              <el-radio :label="'6'">PeV</el-radio>
            </el-radio-group>
          </div>
        </el-col>
      </el-row>
        <el-button @click="showColor = false">取 消</el-button>
        <el-button type="primary" @click="colorConfirm">确 定</el-button>
    </div>
    </el-dialog>

    <!-- 表格 -->
    <div class="postoperative-table">
      <el-table
        :data="tableData"
        :header-cell-style="{ 'text-align': 'center' }"
      >
        <el-table-column type="expand">
          <template slot-scope="props">
            <commonPostoperative 
            :operateForm="props.row"
            :formData="formData"
            
            />
          </template>
        </el-table-column>
        <el-table-column label="术后检验学信息" prop="Postop_date"> </el-table-column>
      </el-table>
    </div>


    

  </div>
</template>

<script>
import commonPostoperative from './common-postoperative.vue';
export default {
  name: "postoperative",
  components:{
    commonPostoperative,
  },
  data() {
    return {
      operation_id:"",
      isShow:false,
      operateType:"add",
      showAddColor:false,
      showColor:false,
      showColort:false,
      diawid:"90%",
      dvtData: {
        Postop_date:"",
        dvt:""
      },
      tableData:[{
            PPostop_id: "",
            Postop_date: "",
            blood_routine: {
              abo: "1",
              rh: "2",
              wbc: "3",
              n: "4",
              hb: "5",
              plt: "6",
            },
            bmp: {
              alb: "",
              k: "",
              na: "",
              cl: "",
              alt: "",
              ast: "",
              cr: "",
              urea: "",
            },
            inflammation: {
              il: "",
              pct: "",
              esr: "",
              crp: "",
            },
            coagulation: {
              tt: "",
              pt: "",
              aptt: "",
              fib: "",
              fdp: "",
              dd: "",
            },
          },],
      operateForm: 
          {
            PPostop_id: "",
            Postop_date: "",
            blood_routine: {
              abo: "",
              rh: "",
              wbc: "",
              n: "",
              hb: "",
              plt: "",
            },
            bmp: {
              alb: "",
              k: "",
              na: "",
              cl: "",
              alt: "",
              ast: "",
              cr: "",
              urea: "",
            },
            inflammation: {
              il: "",
              pct: "",
              esr: "",
              crp: "",
            },
            coagulation: {
              tt: "",
              pt: "",
              aptt: "",
              fib: "",
              fdp: "",
              dd: "",
            },
          },

      formData: {
        blood: [
          {
            label: "ABO血型",
            model: "abo",
            type: "radio",
            list: ["A", "B", "O", "AB"],
          },
          {
            label: "RH血型",
            model: "rh",
            type: "radio",
            list: ["RH+", "RH-"],
          },
          {
            model: "wbc",
            type: "input",
            label: "WBC",
            unit: "10⁹/L",
          },
          {
            model: "n",
            type: "input",
            label: "N",
            unit: "10⁹/L",
          },
          {
            model: "hb",
            type: "input",
            label: "HB",
            unit: "g/L",
          },
          {
            model: "plt",
            type: "input",
            label: "PLT",
            unit: "10⁹/L",
          },
        ],
        inputParams: [
          {
            span: "生化",
            next: 4,
            obj: "bmp",
            showUnit: true,
            opt: [
              {
                model: "alb",
                label: "ALB",
                unit: "g/L",
              },
              {
                model: "k",
                label: "K+",
                unit: "mmol/L",
              },
              {
                model: "na",
                label: "Na+",
                unit: "mmol/L",
              },
              {
                model: "cl",
                label: "Cl-",
                unit: "mmol/L",
              },
              {
                model: "alt",
                label: "ALT",
                unit: "U/L",
              },
              {
                model: "ast",
                label: "AST",
                unit: "U/L",
              },
              {
                model: "cr",
                label: "CR",
                unit: "μmol/L",
              },
              {
                model: "urea",
                label: "Urea",
                unit: "mmol/L",
              },
            ],
          },
          {
            span: "炎症指标",
            next: 5,
            obj: "inflammation",
            showUnit: true,
            opt: [
              {
                model: "il",
                label: "IL-6",
                unit: "pg/ml",
              },
              {
                model: "pct",
                label: "PCT",
                unit: "ug/L",
              },
              {
                model: "esr",
                label: "ESR",
                unit: "mm/h",
              },
              {
                model: "crp",
                label: "CRP",
                unit: "mg/L",
              },
            ],
          },
          {
            span: "凝血功能",
            next: 6,
            obj: "coagulation",
            showUnit: true,
            opt: [
              {
                model: "tt",
                label: "TT",
                unit: "s",
              },
              {
                model: "pt",
                label: "PT",
                unit: "s",
              },
              {
                model: "aptt",
                label: "APTT",
                unit: "s",
              },
              {
                model: "fib",
                label: "FIB",
                unit: "g/L",
              },
              {
                model: "fdp",
                label: "FDP",
                unit: "μg/mL",
              },
              {
                model: "dd",
                label: "D-D",
                unit: "μg/L",
              },
            ],
          },
        ],
      },
    };
  },

  created() {
    this.operation_id = this.$route.query.room;
    this.GetPostoperativeInfo()
    this.GetDvtInfo()

  },
  mounted() {
  },

  methods: {
    // 获取表单信息请求
    async GetPostoperativeInfo() {
      try {
        const { data: res } = await this.$http.get(
          "/Periop/getPeriopPostop/" + this.operation_id,
          {params:{"pre_type": "检验学信息"}}
        );
        if (res.status == 0) {
        } else if (res.status == 1) {
          this.tableData = res.data
        // console.log(res, "围手术期术后检验学信息请求数据");
        }
      } catch (error) {
        console.log(error);
      }

    },
    // 查询dvt,目前只显示第一条数据
    async GetDvtInfo() {
      try {
        const { data: res } = await this.$http.get(
          "/Periop/getPeriopPostop/" + this.operation_id,
          {params:{"pre_type": "彩超"}}
        );
        if(res.status == '0' ){
          this.showAddColor = true
        }else if(res.status == '1'){
            if(res.data[0]==undefined){
              this.showAddColor = true
            }else{
            this.dvtData = res.data[0]
            this.showColort=true
            }
        }
      } catch (error) {
        // console.log(error);
      }
    },

    // 添加dvt
    async PostDvtInfo() {
      try {
        const { data: res } = await this.$http.post(
          "/Periop/addPeriopDvt/" + this.operation_id,
          this.dvtData
        );
        if (res.status == 0) {
          // console.log(res,"00000");
          // console.log(this.dvtData);
        } else if (res.status == 1) {
          // console.log(res, "围手术期术后dvt写入成功");
          this.dvtData = res.data
          this.showAddColor = false
        }
      } catch (error) {
        // console.log(error);
      }
    },

    // 添加术后信息
     PostPostoperativeInfo() {
      return new Promise((resolve,reject) =>{
        const {PPostop_id,...objRest} = this.operateForm
        const { data: res } =  this.$http.post(
          "/Periop/addPeriopPostop/" + this.operation_id,
          objRest
        ).then(res =>{
          resolve(res)
        })
      })
    },

    // 查看
    handleRead() {},
    // 编辑
    handleEdit(row) {
      this.operateType = "edit"
      this.isShow = true
      // console.log(row,'编辑的row');
      this.operateForm = {...row}
    },

    handleChange(val) {
      // console.log(val);
    },
    // 新增
    addItem(){
      this.isShow = true
      this.operateType = "add"
      this.operateForm ={
            PPostop_id: "",
            blood_routine: {
              abo: "",
              rh: "",
              wbc: "",
              n: "",
              hb: "",
              plt: "",
            },
            bmp: {
              alb: "",
              k: "",
              na: "",
              cl: "",
              alt: "",
              ast: "",
              cr: "",
              urea: "",
            },
            inflammation: {
              il: "",
              pct: "",
              esr: "",
              crp: "",
            },
            coagulation: {
              tt: "",
              pt: "",
              aptt: "",
              fib: "",
              fdp: "",
              dd: "",
            },
      }
    },

    // 确认
    async confirm(){

         await this.PostPostoperativeInfo()

          this.isShow = false

          this.GetPostoperativeInfo()

    },
    addColor(){
      this.showColor = true
    },
    colorConfirm(){
      this.showColor = !this.showColor 
      this.PostDvtInfo()
      this.showColort = true
    }

  },
};
</script>

<style scoped>
.el-row {
  /* border-bottom: 1px solid #d6dde2; */
  /* padding: 10px 0 5px 0; */
}
.el-col {
  border-radius: 4px;
  margin-bottom: 5px;
}
.bg-purple-dark {
  background: #99a9bf;
  color: #fff;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  /* background: #e5e9f2; */
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
}
.el-radio {
  margin-bottom: 0;
}

.shape_signal_text {
  font-size: 14px;
}
.el-date-editor.el-date-editor {
  width: 100%;
}
.postoperative .el-button{
  margin-bottom: 10px;
}
.mgb10{
  margin-bottom: 10px;
}
</style>