import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    showbtn:sessionStorage.getItem('ashowbtn')==null ? 'true':sessionStorage.getItem('ashowbtn'),
    medicalname2:sessionStorage.getItem('amedicalname2')==null ? '病例信息列表':sessionStorage.getItem('amedicalname2'),
    selectview:sessionStorage.getItem('stateselect')==null ? '/homepag':sessionStorage.getItem('stateselect'),
    level1:sessionStorage.getItem('issel')==null ? '0':sessionStorage.getItem('issel'),
    level2:sessionStorage.getItem('secissel')==null ? '0':sessionStorage.getItem('secissel'),
    showinput:sessionStorage.getItem('showinputmu')==null ? 'true':sessionStorage.getItem('showinputmu'),
    isshowbreadcrumb:sessionStorage.getItem('sessionbreadcrumb')==null ? false:sessionStorage.getItem('sessionbreadcrumb'),
  },
  getters: {
    gettersshowbtn(state){
      return state.showbtn
    },
    gettermedicalname2(state){
      return state.medicalname2
    },
    getselectview(state){
      return state.selectview
    },
    getlevel1(state){
      return state.level1
    }
    ,
    getlevel2(state){
      return state.level2
    },
    getshowinput(state){
      return state.showinput
    },
    getisshowbreadcrumb(state){
      return state.isshowbreadcrumb
    }

  },
  mutations: {
    changeshowbtn(state,value){
      state.showbtn = value
      sessionStorage.setItem("ashowbtn",value)
    },
    changemedicalname(state,value){
      state.medicalname2 = value
      sessionStorage.setItem("amedicalname2",value)
    },
    changeselectview(state,value){
      state.selectview = value
      sessionStorage.setItem("stateselect",value)
    },
    changelevel1(state,value){
      state.level1=value
      sessionStorage.setItem("issel",value)
    },
    changelevel2(state,value){
      state.level2=value
      sessionStorage.setItem("secissel",value)
    },
    showinputmethod(state,value){
      state.showinput = value
      sessionStorage.setItem("showinputmu",value)
    },
    changebreadcrumb(state,value){
      state.isshowbreadcrumb = value
      sessionStorage.setItem("sessionbreadcrumb",value)

    }
  },
  actions: {

  },
  modules: {

  }
})
