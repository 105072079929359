<!-- HOOS, JR.评分 -->
<template>
  <div class="hoos">
    <el-row :gutter="10">
    <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"><div class="grid-content bg-purple">HOOS, JR.评分</div></el-col>
    <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"><div class="grid-content bg-purple-light">
        <strong>说明：</strong>
    </div></el-col>
    <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"><div class="grid-content bg-purple-light">本表格会询问一些关于您髋关节的问题，帮助我们评估您的关节功能。请在合适问题答案
        选项处打勾，每题只能选一个答案。如果您对某个问题不确定，尽量选择您认为最符合的选项。</div></el-col>
    <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" v-for="(item,index) in hoor" :key="index"><div class="grid-content">
        <el-row>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"><div class="grid-content bg-purple"><strong>{{ item.title }}</strong></div></el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"><div class="grid-content bg-purple-light" >{{  item.illustrate}}</div></el-col>
            <el-col  :xs="24" :sm="24" :md="24" :lg="24" :xl="24" v-for="(option,index2) in item.oparr" :key="index2"><div class="grid-content">
                <el-row>
                    <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6"><div class="grid-content bg-purple-light">{{ option.name }}</div></el-col>
                    <el-col :xs="18" :sm="18" :md="18" :lg="18" :xl="18"><div class="grid-content">
                        <el-radio-group v-model="radiovalue[option.opvalue].value">
                            <el-radio v-for="(label,index3) in option.value" :label="index3" :key="index3">{{ label }}</el-radio>
                        </el-radio-group>
                    </div></el-col>
                </el-row>
            </div>
        </el-col>
        </el-row>
    </div></el-col>

    </el-row>
  </div>
</template>

<script>
export default {
    data(){
        return{
            hoor:[
                {
                    title:'髋关节疼痛:',
                    illustrate:'最近一个星期，在进行以下活动时，您膝关节的疼痛达到何种程度?',
                    oparr:[
                        {   opvalue:'0',
                            name:'1. 在椅子上站起或坐下',
                            value:["没有疼痛","轻微","中等","严重","非常严重"]
                        },
                        {
                            opvalue:'1',
                            name:'2. 在不平整的路面行走',
                            value:["没有疼痛","轻微","中等","严重","非常严重"]
                        }
                         ]   
                },
                {
                    title:'髋关节功能与日常生活： ',
                    illustrate:'以下问题有关您髋关节功能，也就是指您能够四处走动并照顾自己的能力。您过去一周因为髋关节问题，在进行以下活动时的困难程度',
                    oparr:[
                        {
                            opvalue:'2',
                            name:'3. 坐着站起来',
                            value:["没有困难","轻微","中等","严重","非常严重"]
                        },
                        {
                            opvalue:'3',
                            name:'4. 弯腰碰地面或检东西',
                            value:["没有疼痛","轻微","中等","严重","非常严重"]
                        },
                        {
                            opvalue:'4',
                            name:'5. 躺床上（翻身，保持髋关节的位置）',
                            value:["没有疼痛","轻微","中等","严重","非常严重"]
                        }
                        ,
                        {
                            opvalue:'5',
                            name:'6. 坐着',
                            value:["没有疼痛","轻微","中等","严重","非常严重"]
                        }
                         ]   
                },
            ],
            radiovalue:[{value:''},{value:''},{value:''},{value:''},{value:''},{value:''}]
        }
    },
    props:["message"],

    watch:{
        radiovalue:{
            handler(newvalue,oldvalue){
                let sum=0
                let radioarr=[]
                let sumarr=[]
                for(let i=0;i<newvalue.length;i++){
                    let obj = newvalue[i]
                    if(obj.value !=''){
                        sum = sum + obj.value
                        radioarr.push(obj.value)
                    }else{
                        sum = sum + 0
                        radioarr.push(obj.value)
                    }
                }
                sumarr.push(sum),
                sumarr.push(radioarr)
                this.$emit("hoosemit",sumarr)
            },deep:true
        },
        message(newvalue){
            for(let i=0;i<newvalue.length;i++){
                let numbvalue = parseInt(newvalue[i])
                this.radiovalue[i].value=numbvalue
            }
        }
    }
}
</script>

<style scoped>
.hoos .el-col{
    margin-top: 5px;
}
 
</style>