<!-- Harris 髋关节评分 -->
<template>
  <div class="harris">
    <el-row>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"><div class="grid-content bg-purple">Harris 髋关节评分</div></el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" v-for="(item,index) in option" :key="index"><div class="grid-content">
            <el-row>
                <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"><div class="grid-content bg-purple-light">{{ item.name }}</div></el-col>
                <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"><div class="grid-content">
                    <el-radio-group v-model="radiovalue[index].value[0]" v-if="item.checkbox">
                    <el-radio v-for="(label,index2) in item.label" :key="index2" :label="item.value[index2]">{{label}}</el-radio>
                    </el-radio-group>
                    <el-input v-model="radiovalue[index].value" v-else-if="item.input">`    `
                    <template slot="prepend">{{ item.input }}</template>
                    <template slot="append">°(度)</template>
                    </el-input>
                    <el-radio-group v-model="radiovalue[index].value" v-else>
                    <el-radio v-for="(label,index2) in item.label" :key="index2" :label="item.value[index2]">{{label}}</el-radio>
                    </el-radio-group>
                    &nbsp;
                    &nbsp;
                    <el-checkbox-group v-model="radiovalue[index].value[1]" v-if="item.checkbox" :disabled="radiovalue[index].value[0] =='4'">
                        <el-checkbox v-for="(checkbox,index4) in item.checkbox" :key="index4" :label="checkbox" >{{ checkbox }}</el-checkbox>
                    </el-checkbox-group>
                </div></el-col>

            </el-row>
        </div></el-col>
    </el-row>
  </div>
</template>

<script>
export default {
    data(){
        return{
            radiovalue:[{value:''},{value:''},{value:''},{value:''},{value:''},{value:''},{value:''},{value:''},{value:[0,[]]},{value:''},{value:''},{value:''},{value:''}],
            option:[
                {
                    name:'1.疼痛',
                    label:["没有疼痛或可忽略","轻微痛，偶发生，不影响活动","轻度疼痛，日常活动无影响，偶因活动出现中度疼痛，可能需要口服 NSAIDs","中度疼痛，可忍受，但常因此放弃某些活动，日常活动和工作稍受限，常需口服 NSAIDs","剧痛，活动严重受限","残疾，卧床仍感疼痛，需长期卧床"],
                    value:[44,40,30,20,10,0]
                },
                {
                    name:'2. 助行装置',
                    label:["不需要","久走需要手杖","大部分时间需要手杖","需单拐","需双手杖","需双拐或无法行走"],
                    value:[11,7,5,3,2,0]
                },
                {
                    name:'3. 最远行走距离',
                    label:["不受限","1200 米","400-600 米","仅能室内活动","卧床或坐椅"],
                    value:[11,8,5,2,0]
                }
                ,
                {
                    name:'4. 跛行',
                    label:["无","轻度","中度","重度"],
                    value:[11,8,5,0]
                },
                {
                    name:'5. 穿鞋袜',
                    label:["穿袜、系鞋带无困难","穿袜、系鞋带困难","不能穿袜、系鞋带"],
                    value:[4,2,0]
                },
                {
                    name:'6. 爬楼梯',
                    label:["一步一阶，不用扶手","一步一阶，需要扶手","用某种方式能上楼","不能上楼"],
                    value:[4,2,1,0]
                }, {
                    name:'7. 乘坐公共交通工具（公交车或地铁等）',
                    label:["能","不能"],
                    value:[1,0]
                },
                {
                    name:'8. 坐',
                    label:["在普通椅子上坐 1 小时而无不适","在高椅上坐半小时而无不适" ,"坐任何椅子均不适"],
                    value:[5,3,0]
                },
                {
                    name:"9. 关节是否存在以下畸形：固定屈曲挛缩畸形<30°；固定内收畸形<10°；伸直位固定内旋畸形<10°；双下肢长度相差 3cm 内",
                    label:["是","否"],
                    value:[-1,4],
                    checkbox:["固定屈曲挛缩畸形<30°","固定内收畸形<10°","伸直位固定内旋畸形<10°","双下肢长度相差 3cm 内"]
                },
                {
                    name:'10.髋关节屈曲角度',
                    input:"髋关节屈曲角度"
                },
                {
                    name:'11. 髋关节外展角度',
                    input:"髋关节外展角度"
                },
                {
                    name:'12. 髋关节外旋角度',
                    input:"髋关节外旋角度"
                },
                {
                    name:'13. 髋关节内收角度',
                    input:"髋关节外旋角度"
                },
            ]
        }
    },
    props:["message"],
    watch:{
        radiovalue:{
            handler(newvalue,oldvalue){
                let sum = 0 ,sumarr=[],resarr=[]
                for(let i=0;i<newvalue.length;i++){
                    let obj = newvalue[i].value
                    if(Array.isArray(obj)){
                        let arr=[]
                        if(obj[0]==4){
                            sum = sum +4
                        }else{
                            sum = sum + (-1*obj[1].length)
                        }
                        sumarr.push(obj)
                    }else{
                        if(obj !=''){
                            let reg = /^\d+$/;
                        if(reg.test(obj)){
                        sum = sum + parseInt(obj)
                        sumarr.push(obj)
                        }else{
                            this.$message.error('请输入数字')
                            newvalue[i].value=''
                            // return
                        }
                        }else{
                            sum = sum + 0
                        sumarr.push(obj)
                        }
                    }
                    
                }
                resarr.push(sum)
                resarr.push(sumarr)
               this.$emit('harrisemit',resarr)
            },deep:true
        },
        message(newvalue){
            for(let i=0;i<newvalue.length;i++){
                let obj = newvalue[i]
                if(Array.isArray(obj)){
                    let va1 = parseInt(obj[0])
                    let va2 = obj[1]
                    this.radiovalue[i].value[0]=va1
                    this.radiovalue[i].value[1]=va2
                }else{
                    let objvalue = parseInt(obj)
                    this.radiovalue[i].value = objvalue
                }
            }
        }
    }
}
</script>

<style scoped>
.harris .el-col{
margin-top: 5px;
}
</style>