<!-- 检验数据组件 -->
<template>
  <div class="commonpreinspdata" :disabled="modify">
    <!-- 血常规 -->
    <div class="blood_routine" v-show="next == 1 || showAll">
      <div class="grid-content bg-purple-dark">血常规</div>
      <el-row  v-for="(item, index) in formData.blood" :key="index">
        <el-col :xs="4" :sm="3" :md="3" :lg="3" :xl="3"
          ><div class="grid-content">{{ item.label }}</div></el-col
        >
        <el-col
          :xs="20"
          :sm="21"
          :md="21"
          :lg="21"
          :xl="20"
          v-if="item.type == 'radio'"
          ><div class="grid-content bg-purple-light">
            <el-radio-group v-model="operateForm.blood_routine[item.model]">
              <el-radio
                v-for="(item, index) in item.list"
                :key="index"
                :label="item"
                :disabled="modify"
                >{{ item }}</el-radio
              >
            </el-radio-group>
          </div></el-col
        >
        
        <el-col
          :xs="20"
          :sm="21"
          :md="21"
          :lg="21"
          :xl="21"
          v-if="item.type == 'input'"
          ><div class="grid-content bg-purple-light">
            <el-input
              v-model="operateForm.blood_routine[item.model]"
              :disabled="modify"
            >
              <template slot="append">{{ item.unit }}</template>
            </el-input>
          </div></el-col
        >
      </el-row>
    </div>
    <!-- 尿常规 -->
    <div class="urine_routine" v-show="next == 2 || showAll">
      <div class="grid-content bg-purple-dark">尿常规</div>
      <el-row>
        <el-col
          :xs="24"
          :sm="12"
          :md="12"
          :lg="8"
          :xl="8"
          v-for="(item, index) in formData.urine"
          :key="index"
        >
          <div class="niaochanggui">
            <span v-show="item.label == '尿白细胞'" class="demonstration"
              >尿白细胞</span
            >
            <el-input
              v-show="item.label == '镜检白细胞' || item.label == '镜检细菌'"
              v-model="operateForm.urine_routine[item.model]"
              :disabled="modify"
            >
              <template slot="prepend">{{ item.label }}</template>
              <template slot="append" v-if="item.hasUnit">{{item.unit}}</template>
            </el-input>

            <el-slider
              v-show="item.label == '尿白细胞'"
              style="width: 250px"
              v-model="operateForm.urine_routine.wbc"
              :marks="marks"
              :disabled="modify"
              :step="1"
              :min="0"
              :max="3"
            >
            </el-slider>
            <!-- {{ operateForm.urine_routine[item.model] }} -->
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- 中段尿细菌涂片 -->
    <div class="smear" v-show="next == 3 || showAll">
      <div class="grid-content bg-purple-dark">中段尿细菌涂片</div>

      <el-row :gutter="0" v-for="(item, index) in formData.smear" :key="index">
        <el-col :xs="4" :sm="3" :md="3" :lg="3" :xl="3"
          ><div class="grid-content">{{ item.label }}</div></el-col
        >
        <el-col :xs="20" :sm="21" :md="21" :lg="21" :xl="21"
          ><div class="grid-content bg-purple-light">
            <el-radio-group
              v-model="operateForm.smear[item.model]"
              :disabled="modify"
            >
              <el-radio
                v-for="(item, index) in item.list"
                :key="index"
                :label="item"
                >{{ item }}</el-radio
              >
            </el-radio-group>
          </div></el-col
        >
      </el-row>
    </div>
    <!-- 生化到25OH-VD的输入框组 next步数4到9-->
    <div
      class="bmp"
      v-for="(item, index) in formData.inputParams"
      :key="index"
      v-show="next == item.next || showAll"
    >
      <div class="grid-content bg-purple-dark">{{ item.span }}</div>
      <el-row :gutter="5">
        <el-col
          :xs="24"
          :sm="12"
          :md="12"
          :lg="8"
          :xl="6"
          v-for="(_item, _index) in item.opt"
          :key="_index"
        >
          <el-input
            v-model="operateForm[item.obj][_item.model]"
            :disabled="modify"
          >
            <template slot="prepend">{{ _item.label }}</template>
            <template slot="append" v-if="item.showUnit">{{
              _item.unit
            }}</template>
          </el-input>

          <!-- <el-input
            v-else
            placeholder="请输入内容" 
            v-model="operateForm[_item.model]"
          >
            <template slot="prepend">{{ _item.label }}</template>
            <template slot="append">{{ _item.unit }}</template>
          </el-input> -->
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "commonpreinspdata",
  props:{
        next:Number,
        formData:Object,
        operateForm:Object,
        showAll:Boolean,
        modify:Boolean,
        operation_site:String,
    },
  data() {
    return {
        marks: {
        0: "-",
        1: "+",
        2: "++",
        3: "+++",
      },
    };
  },
  watch:{
    operateForm:{
      handler(newvalue,oldvalue){
        let w1 = newvalue.urine_routine.wbcoscopy
        let w2 = newvalue.urine_routine.germoscopy
        newvalue.urine_routine.wbc=parseInt(newvalue.urine_routine.wbc)
        let regPos = /^[0-9]+(\\.[0-9]*)?$/g;
        let regPos1 = /^[0-9]+(\\.[0-9]*)?$/g;
        newvalue.urine_routine.wbcoscopy = regPos.test(w1) ? newvalue.urine_routine.wbcoscopy:''
        newvalue.urine_routine.germoscopy = regPos1.test(w2) ? newvalue.urine_routine.germoscopy:''
        // console.log(newvalue)
      },deep:true
    }
  },
};
</script>

<style scoped>
.commonpreinspdata {
  /* overflow: hidden; */
  /* border: 1px solid black; */
  /* box-sizing: border-box; */
}
.blood_routine{
  box-sizing: border-box;
}
.smear{
  box-sizing: border-box;
}
.bmp{
  box-sizing: border-box;
}
.Preoperative{
  box-sizing: border-box;
  padding: 3px;
}
.el-row {
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid #d6dde2;
  padding: 10px 0 5px 0;
}
.el-col {
  border-radius: 4px;
  margin-bottom: 5px;
}
.bg-purple-dark {
  background: #99a9bf;
  color: #fff;
}
.bg-purple {
  background: #d3dce6;
}

.grid-content {
  box-sizing: border-box;
  border-radius: 4px;
  min-height: 36px;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
}
.el-radio {
  margin-bottom: 0;
}

.shape_signal_text{
  font-size: 14px;
}
.niaochanggui{
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.range{
  display: flex;
}
.range .el-input__inner{
        height: 10px !important;
        padding: 0px !important;
        cursor: pointer;
      }
</style>