<template>
  <div class="commonprecheckdata" :disabled="modify">
    <!-- 骨密度：正位脊柱到股骨 -->
    <div class="bmp" 
        v-for="(item, index) in formData.inputParams" :key="index"
        v-show="next == item.next || showAll">
      <div class="grid-content bg-purple-dark">{{ item.span }}</div>
      <el-row :gutter="5">
        <el-col
          :xs="24"
          :sm="12"
          :md="12"
          :lg="8"
          :xl="6"
          v-for="(_item, _index) in item.opt"
          :key="_index"
        >
          <el-input
            v-model="operateForm[item.obj][_item.model]"
            :disabled="modify"
          >
            <template slot="prepend">{{ _item.label }}</template>
            <template slot="append" v-if="item.showUnit">{{
              _item.unit
            }}</template>
          </el-input>

          <!-- <el-input
            v-else
            placeholder="请输入内容" 
            v-model="operateForm[_item.model]"
          >
            <template slot="prepend">{{ _item.label }}</template>
            <template slot="append">{{ _item.unit }}</template>
          </el-input> -->
        </el-col>
      </el-row>
    </div>

    <!-- X线检查 -->
    <div class="xray-table" v-show="next == 3 || showAll">
      <div class="grid-content bg-purple-dark">X线检查(术前/术后)</div>
      <el-table
        :data="operateForm.xray"
        :header-cell-style="{ 'text-align': 'center' }"
      >
        <el-table-column width="140" prop="name"> </el-table-column>
        <el-table-column label="术前" prop="pre" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.pre"
              :disabled="modify"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column label="术后" prop="after" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.after"
              :disabled="modify"
            ></el-input>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- CT测量 -->
    <div class="ct-table" v-show="next == 4 || showAll">
      <div class="grid-content bg-purple-dark">CT测量</div>
      <el-table
        :data="operateForm.ct"
        :header-cell-style="{ 'text-align': 'center' }"
      >
        <el-table-column width="60" prop="name"> </el-table-column>
        <el-table-column label="左侧" prop="name" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.left"
              :disabled="modify"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column label="右侧" prop="address" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.right"
              :disabled="modify"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column label="手术侧术后" prop="after" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.after"
              :disabled="modify"
            ></el-input>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 规划 -->
    <div class="plan-table" v-show="next == 5 || showAll">
      <div class="grid-content bg-purple-dark">规划</div>
      <el-table
        :data="operateForm.plan"
        :header-cell-style="{ 'text-align': 'center' }"
      >
        <el-table-column label="截骨量mm" width="90" prop="name"> </el-table-column>
        <el-table-column label="规划" prop="plan" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.plan"
              :disabled="modify"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column label="术中" prop="mid" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.mid"
              :disabled="modify"
            ></el-input>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- MRI-骨髓损伤 -->
    <div class="plan-table" v-show="next == 6 || showAll">
      <div class="grid-content bg-purple-dark">MRI-骨髓损伤</div>
      <el-table
        :data="operateForm.mri"
        :border="true"
        :header-cell-style="{ 'text-align': 'center' }"
        :span-method="objectSpanMethod"
      >
        <el-table-column width="50" prop="name" fixed > </el-table-column>
        <el-table-column width="95" prop="region" fixed> </el-table-column>
        <el-table-column label="亚区域占比" prop="subregion">
          <template slot-scope="scope" >
            <el-radio-group v-model="scope.row.subregion" :disabled="modify"  >
              <el-radio :label="'0'">0</el-radio>
              <el-radio :label="'1'">1</el-radio>
              <el-radio :label="'2'">2</el-radio> 
              <el-radio :label="'3'">3</el-radio>
            </el-radio-group>
          </template>
        </el-table-column>
        <el-table-column label="损伤面积占比" prop="damage">
          <template slot-scope="scope" >
            <el-radio-group v-model="scope.row.damage" :disabled="modify">
              <el-radio :label="'0'">0</el-radio>
              <el-radio :label="'1'">1</el-radio>
              <el-radio :label="'2'">2</el-radio>
              <el-radio :label="'3'">3</el-radio>
            </el-radio-group>
          </template>
        </el-table-column>
        <el-table-column label="骨髓损伤的数量" prop="count" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.count"
              :disabled="modify"
            ></el-input>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 关节软骨 -->
    <div class="cartilage" v-show="next == 7 || showAll">
      <div class="grid-content bg-purple-dark">关节软骨</div>
      <el-table
        :data="operateForm.cartilage"
        :border="true"
        :header-cell-style="{ 'text-align': 'center' }"
        :span-method="objectSpanMethod"
      >
        <el-table-column width="50" prop="name"> </el-table-column>
        <el-table-column width="100" prop="region"> </el-table-column>
        <el-table-column label="全层" prop="all">
          <template slot-scope="scope">
            <el-radio-group v-model="scope.row.all" :disabled="modify">
              <el-radio :label="'0'">0</el-radio>
              <el-radio :label="'1'">1</el-radio>
              <el-radio :label="'2'">2</el-radio>
              <el-radio :label="'3'">3</el-radio>
            </el-radio-group>
          </template>
        </el-table-column>
        <el-table-column label="部分或全层" prop="part">
          <template slot-scope="scope">
            <el-radio-group v-model="scope.row.part" :disabled="modify">
              <el-radio :label="'0'">0</el-radio>
              <el-radio :label="'1'">1</el-radio>
              <el-radio :label="'2'">2</el-radio>
              <el-radio :label="'3'">3</el-radio>
            </el-radio-group>
          </template>
        </el-table-column>
      </el-table>
      <div class="grid-content bg-purple-dark">滑膜炎</div>
      <el-row :gutter="0" v-for="(item, index) in formData.hoffa_arthrohydrops" :key="index">
        <el-col :xs="24" :sm="10" :md="10" :lg="10" :xl="10"
          ><div class="grid-content bg-purple-light">{{ item.label }}</div></el-col
        >
        <el-col :xs="24" :sm="14" :md="14" :lg="14" :xl="14"
          ><div class="grid-content" style="border: solid 1px rgba(134, 134, 134, 0.507)">
            <el-radio-group v-model="operateForm[item.model]" :disabled="modify">
              <el-radio
                v-for="(item, index) in item.list"
                :key="index"
                :label="item"
                >{{ item }}</el-radio
              >
            </el-radio-group>
          </div></el-col
        >
      </el-row>
    </div>

    <!-- 关节周征象 -->
    <div class="joint_signs" v-show="next == 8 || showAll">
      <div class="grid-content bg-purple-dark">关节周征象</div>
      <el-row :gutter="0" v-for="(item, index) in formData.joint_signs" :key="index">
        <el-col :xs="10" :sm="10" :md="10" :lg="10" :xl="10"
          ><div class="grid-content bg-purple-light">{{ item.label }}</div></el-col
        >
        <el-col :xs="14" :sm="14" :md="14" :lg="14" :xl="14"
          ><div class="grid-content " style="border: solid 1px rgba(134, 134, 134, 0.507)">
            <el-radio-group v-model="operateForm.joint_signs[item.model]" :disabled="modify">
              <el-radio
                v-for="(item, index) in item.list"
                :key="index"
                :label="item"
                >{{ item }}</el-radio
              >
            </el-radio-group>
          </div></el-col
        >
      </el-row>
    </div>

    <!-- 韧带/肌腱 -->
    <div class="ligament_tendon" v-show="next == 9 || showAll">
      <div class="grid-content bg-purple-dark">韧带/肌腱</div>
      <el-row :gutter="0" v-for="(item, index) in formData.ligament_tendon" :key="index">
        <el-col :xs="10" :sm="10" :md="10" :lg="10" :xl="10"
          ><div class="grid-content bg-purple-light">{{ item.label }}</div></el-col
        >
        <el-col :xs="14" :sm="14" :md="14" :lg="14" :xl="14"
          ><div class="grid-content " style="border: solid 1px rgba(134, 134, 134, 0.507)">
            <el-radio-group v-model="operateForm.ligament_tendon[item.model]" :disabled="modify">
              <el-radio
                v-for="(item, index) in item.list"
                :key="index"
                :label="item"
                >{{ item }}</el-radio
              >
            </el-radio-group>
          </div></el-col
        >
      </el-row>
    </div>

    <!-- 骨赘 -->
    <div class="osteophyte" v-show="next == 10 || showAll">
      <div class="grid-content bg-purple-dark">骨赘</div>
      <el-row :gutter="0" v-for="(item, index) in formData.osteophyte" :key="index">
        <el-col :xs="6" :sm="4" :md="4" :lg="4" :xl="4"
          ><div class="grid-content bg-purple-light">{{ item.label }}</div></el-col
        >
        <el-col :xs="18" :sm="20" :md="20" :lg="20" :xl="20"
          ><div class="grid-content" style="border: solid 1px rgba(134, 134, 134, 0.507)">
            <el-radio-group v-model="operateForm.osteophyte[item.model]" :disabled="modify">
              <el-radio
                v-for="(item, index) in item.list"
                :key="index"
                :label="item"
                >{{ item }}</el-radio
              >
            </el-radio-group>
          </div></el-col
        >
      </el-row>
    </div>

    <!-- 半月板位置 -->
    <div class="meniscus_position" v-show="next == 11 || showAll">
      <div class="grid-content bg-purple-dark">半月板位置</div>
      <el-row :gutter="0" v-for="(item, index) in formData.meniscus_position" :key="index">
        <el-col :xs="6" :sm="6" :md="4" :lg="4" :xl="4"
          ><div class="grid-content bg-purple-light">{{ item.label }}</div></el-col
        >
        <el-col :xs="18" :sm="18" :md="20" :lg="20" :xl="20"
          ><div class="grid-content" style="border: solid 1px rgba(134, 134, 134, 0.507)">
            <el-radio-group v-model="operateForm.meniscus_position[item.model]" :disabled="modify">
              <el-radio
                v-for="(item, index) in item.list"
                :key="index"
                :label="item"
                >{{ item }}</el-radio
              >
            </el-radio-group>
          </div></el-col
        >
      </el-row>
    </div> 
    
    <!-- 半月板形态和信号异常 -->
    <div class="shape_signal" v-show="next == 12 || showAll">
      <div class="grid-content bg-purple-dark">半月板形态和信号异常</div>
      <span class="shape_signal_text">(0信号异常;1垂直撕裂;2水平及辐状撕裂;3复合撕裂;4跟断裂;5部分缺失;6进展部分缺失;7完全缺失;8半月板囊肿;9半月板肥大)</span>
      <el-table
        :data="operateForm.shape_signal"
        :border="true"
        :header-cell-style="{ 'text-align': 'center' }"
        :span-method="objectSpanMethod"
        :show-header="false"
      >
        <el-table-column width="50" fixed prop="name"> </el-table-column>
        <el-table-column width="50" fixed prop="region"> </el-table-column>
        <el-table-column prop="signs">
          <template slot-scope="scope">
            <el-radio-group v-model="scope.row.signs" :disabled="modify">
              <el-radio :label="'0'">0</el-radio>
              <el-radio :label="'1'">1</el-radio>
              <el-radio :label="'2'">2</el-radio>
              <el-radio :label="'3'">3</el-radio>
              <el-radio :label="'4'">4</el-radio>
              <el-radio :label="'5'">5</el-radio>
              <el-radio :label="'6'">6</el-radio>
              <el-radio :label="'7'">7</el-radio>
              <el-radio :label="'8'">8</el-radio>
              <el-radio :label="'9'">9</el-radio>
            </el-radio-group>
          </template>
        </el-table-column>
      </el-table>
    </div>
    
    <!-- 彩超 -->
    <div class="colord" v-show="next == 13 || showAll">
      <div class="grid-content bg-purple-dark">彩超</div>
      <el-row>
        <el-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="24"
          :xl="24"
          v-for="(item, index) in formData.cardiac"
          :key="index"
        >
        <div class="range">
          <span style="width: 150px;">{{ item.label }}</span>
          <!-- <el-slider  v-model="operateForm[item.model]"></el-slider> -->
             <el-input
            type="range"  min="0" max="100" 
            v-model="operateForm[item.model]"
            :disabled="modify">
             <!-- <template slot="prepend"></template>
              <template slot="append"></template>  -->
            </el-input>
            <span style="width: 100px;"> {{operateForm[item.model]}} {{ item.unit }}</span>
           
        </div>
         
        </el-col>
      </el-row>
      <el-row 
        :gutter="0"
        ><el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
          <div class="grid-content bg-purple-dark">
            <span>下肢静脉血栓</span>
          </div>
        </el-col>
        <el-col :xs="24" :sm="18" :md="18" :lg="18" :xl="18">
          <div class="grid-content bg-purple-light" style="text-align: left; padding: 8px;">
            <el-checkbox-group
            v-model="operateForm.dvt"
            :disabled="modify"
            >
              <el-checkbox :label="'FV'">FV</el-checkbox>
              <el-checkbox :label="'PFV'">PFV</el-checkbox>
              <el-checkbox :label="'PV'">PV</el-checkbox>
              <el-checkbox :label="'ATV'">ATV</el-checkbox>
              <el-checkbox :label="'PTV'">PTV</el-checkbox>
              <el-checkbox :label="'PeV'">PeV</el-checkbox>
            </el-checkbox-group>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
    name:'commonprecheckdata',
    props:{
        next:Number,
        formData:Object,
        operateForm:Object,
        showAll:Boolean,
        modify:Boolean,
        operation_site:String,
    },
  data () {
    return {

    }
  },
    
  mounted() {
    const config = ['mri', 'cartilage','shape_signal'];
    for (const key of Object.keys(this.operateForm)) {
      if (config.indexOf(key) > -1) {
        this.operateForm[key].map(i => {
          i.config = key
        })
      }
    }
  },

  methods: {
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      let _rowIndex = 0; //每合并的table长度之和
      let config = []
      if (row.config) {
        if (row.config == 'mri') {
          config = [
          { id: 1, _length: 6 },
          { id: 2, _length: 7 },
          { id: 3, _length: 2 }]
        }

        if (row.config == 'cartilage') {
          config = [
          { id: 1, _length: 6, },
          { id: 2, _length: 6, },
          { id: 3, _length: 2, },
        ]
        }

        if (row.config == 'shape_signal') {
          config = [
          { id: 1, _length: 3, },
          { id: 2, _length: 3, },]
        }


        if (config.some((i) => i.id == row.id) && row.id != 1) {
            config.map((item, index) => {
              if (index < config.findIndex((i) => i.id == row.id)) {
                _rowIndex += item._length;
              }
            });
          }
      }

      if (columnIndex === 0) {
        if (rowIndex == _rowIndex && row.config) {
          return {
            rowspan: config.filter((i) => i.id == row.id)[0]._length,
            colspan: 1,
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0,
          };
        }
      }
    },
  }


}
</script>

<style scoped>
.commonprecheckdata{
  padding: 3px;
}
.el-row {
  border-bottom: 1px solid #d6dde2;
  padding: 10px 0 5px 0;
}
.el-col {
  border-radius: 4px;
  margin-bottom: 5px;
}
.bg-purple-dark {
  background: #99a9bf;
  color: #fff;
}
.bg-purple {
  background: #d3dce6;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
}
.el-radio {
  margin-bottom: 0;
}

.shape_signal_text{
  font-size: 14px;
}
.niaochanggui{
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.range{
  display: flex;
}
.range .el-input__inner{
        height: 10px !important;
        padding: 0px !important;
        cursor: pointer;
      }
</style>