<template>
  <div class="insurgery">
    <!-- 弹窗填写 -->
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      :width="diawid">
        <el-row :gutter="10" v-show="next ==1">
          <el-col :xs="24" :sm="24" :md="item.md" :lg="item.lg" :xl="item.xl" v-for="(item,index) in doctorinput" :key="index"><div class="grid-content ">
           
              <el-col :xs="6" :sm="24" :md="8" :lg="8" :xl="8"><div class="grid-content bg-purple">{{ item.name }}</div></el-col>
              <el-col :xs="18" :sm="24" :md="8" :lg="16" :xl="16" v-if="item.type=='isinput'"><div class="grid-content bg-purple" ><el-input  v-model="doctorinputvalue[index].value" /></div></el-col>
              <el-col :xs="18" :sm="16" :md="16" :lg="16" :xl="16" v-if="item.type=='notinput'">
              <div class="grid-content bg-purple">
                <el-date-picker
                v-model="doctorinputvalue[index].value"
                 class="insurgery1picker"
                :placeholder="item.name"
                type="datetime"
                default-time="12:00:00"
                >
                </el-date-picker>
              </div>
            </el-col>

            <el-col :xs="18" :sm="16" :md="16" :lg="16" :xl="16" v-if="item.type=='timelong'">
              <div class="grid-content" ><el-input disabled v-model="doctorinputvalue[index].value"><template slot="append">分</template></el-input></div>
            </el-col>
            <el-col :xs="18" :sm="16" :md="16" :lg="16" :xl="16" v-if="item.type=='isradio'">
              <div class="grid-content" >
                <el-radio-group 
                  v-model="doctorinputvalue[index].value"
                    :min="0"
                    :max="1"
                    >
                    <el-radio v-for="city in operaname" :label="city" :key="city" style="padding-top: 10px; padding-right: 10px;">{{city}}</el-radio>
                  </el-radio-group>
              </div>
            </el-col>
           </div>
            </el-col>
        </el-row>
          <el-row :gutter="10" v-show="next ==2">
          <el-col :xs="24" :sm="24" :md="3" :lg="3" :xl="3"><div class="grid-content bg-purple">畸形</div></el-col>
          <el-col :xs="24" :sm="24" :md="21" :lg="21" :xl="21"><div class="grid-content">
            <el-checkbox-group v-model="jixingvalue[0].value" @change="jixingbtn" :min="0" :max="1">
          <el-checkbox  v-for="jix in jixing[0].value" :label="jix" :key="jix">{{jix}}</el-checkbox>
        </el-checkbox-group>
          </div></el-col>
          <el-col :xs="24" :sm="24" :md="3" :lg="3" :xl="3" v-if="jixingvalue[0].leftishow"><div class="grid-content bg-purple">内翻</div></el-col>
          <el-col :xs="24" :sm="24" :md="21" :lg="21" :xl="21" v-if="jixingvalue[0].leftishow"><div class="grid-content ">
          <el-input  v-model="jixingvalue[0].leftvalue" />
          </div></el-col>
          <el-col :xs="24" :sm="24" :md="3" :lg="3" :xl="3" v-if="jixingvalue[0].rightisshow"><div class="grid-content bg-purple">外翻</div></el-col>
          <el-col :xs="24" :sm="24" :md="21" :lg="21" :xl="21" v-if="jixingvalue[0].rightisshow"><div class="grid-content">
          <el-input  v-model="jixingvalue[0].rightvalue" />
          </div></el-col>
          <el-col :xs="24" :sm="24" :md="5" :lg="5" :xl="5"><div class="grid-content bg-purple">麻醉手术后可否纠正</div></el-col>
          <el-col :xs="24" :sm="24" :md="21" :lg="19" :xl="19"><div class="grid-content ">
            <el-checkbox-group v-model="jixingvalue[1].value" @change="jixingbtn"  :min="0" :max="1">
          <el-checkbox  v-for="jix in jixing[1].value" :label="jix" :key="jix">{{jix}}</el-checkbox>
        </el-checkbox-group>
          </div></el-col>
          <el-col :xs="24" :sm="24" :md="5" :lg="5" :xl="5"><div class="grid-content bg-purple">麻醉后关节活动度</div></el-col>
          <el-col :xs="24" :sm="24" :md="19" :lg="19" :xl="19"><div class="grid-content">
            <el-input  v-model="jixingvalue[2].value1" /> 。<el-input  v-model="jixingvalue[2].value2" />  。<el-input  v-model="jixingvalue[2].value3" /> 。
          </div></el-col>
          <el-col :xs="24" :sm="24" :md="5" :lg="5" :xl="5"><div class="grid-content bg-purple">双下肢全长X线片</div></el-col>
          <el-col :xs="24" :sm="24" :md="19" :lg="19" :xl="19">
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
              <el-input v-model="jixingvalue[3].value1">
                <template slot="prepend">MPTA</template>
                <template slot="append">°</template>
              </el-input>
            </el-col>
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8"> 
              <el-input  v-model="jixingvalue[3].value2">
                <template slot="prepend">LDFA</template>
                <template slot="append">°</template>
              </el-input>
            </el-col>  
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">  
              <el-input  v-model="jixingvalue[3].value3">
                <template slot="prepend">CPAK</template>
                <template slot="append">°</template>
              </el-input>
            </el-col>  
          </el-col>
          <el-col :xs="24" :sm="24" :md="5" :lg="5" :xl="5"><div class="grid-content bg-purple">术前诊断</div></el-col>
          <el-col :xs="24" :sm="24" :md="21" :lg="19" :xl="19"><div class="grid-content">
            <el-checkbox-group v-model="jixingvalue[4].value" @change="jixingbtn" >
          <el-checkbox  v-for="jix in jixing[2].value" :label="jix" :key="jix">{{jix}}</el-checkbox>
        </el-checkbox-group>
          </div></el-col>
          <el-col :xs="24" :sm="24" :md="3" :lg="3" :xl="3" v-if="jixingvalue[4].other"><div class="grid-content bg-purple">其他</div></el-col>
          <el-col :xs="24" :sm="24" :md="21" :lg="21" :xl="21" v-if="jixingvalue[4].other"><div class="grid-content">
          <el-input  v-model="jixingvalue[4].othervalue" />
          </div></el-col>
          <el-col :xs="24" :sm="24" :md="5" :lg="5" :xl="5"><div class="grid-content bg-purple">术后诊断</div></el-col>
          <el-col :xs="24" :sm="24" :md="21" :lg="19" :xl="19"><div class="grid-content ">
            <el-checkbox-group v-model="jixingvalue[5].value" @change="jixingbtn">
          <el-checkbox  v-for="jix in jixing[2].value" :label="jix" :key="jix">{{jix}}</el-checkbox>
        </el-checkbox-group>
          </div></el-col>
          <el-col :xs="24" :sm="24" :md="3" :lg="3" :xl="3" v-if="jixingvalue[5].other"><div class="grid-content bg-purple">其他</div></el-col>
          <el-col :xs="24" :sm="24" :md="21" :lg="21" :xl="21" v-if="jixingvalue[5].other"><div class="grid-content ">
          <el-input  v-model="jixingvalue[5].othervalue" />
          </div></el-col>
          </el-row> 
          <!-- 止血带 -->
          <el-row :gutter="10">
<!-- 止血带模块始 -->
       <!-- 止血带选择 -->
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"  v-show="next==3"><div class="grid-content">
            <el-col :xs="24" :sm="3" :md="3" :lg="3" :xl="3" class="zhixuedaiinputclass zhixuedai"><div class="grid-content  bg-purple">止血带</div></el-col>
            <el-col :xs="24" :sm="3" :md="3" :lg="21" :xl="21" ><div class="grid-content ">
              <el-radio-group v-model="tourniquetvalue[0].label">
                <el-radio  label="无"></el-radio>
                <el-radio  label="有"></el-radio>
              </el-radio-group>
            </div></el-col>
        </div>
        </el-col>
     <!-- 止血带选择 -->
        <!-- 是否选择有止血带始 -->
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"  v-show="next==3"><div class="grid-content" v-show="tourniquetvalue[0].isshow">
          <el-row>
            <el-col :xs="24" :sm="24" :md="3" :lg="3" :xl="3" class="zhixuedaiinputclass"><div class="grid-content  bg-purple">止血带时间</div></el-col>
            <el-col :xs="24" :sm="3" :md="6" :lg="6" :xl="6"><div class="grid-content ">
              <el-radio-group  v-model="tourniquetvalue[0].label2"  :min="0" :max="1">
                <el-radio label="全程"></el-radio>
                <el-radio  label="仅上假体时"></el-radio>
              </el-radio-group>
            </div></el-col>
              <el-col :xs="24" :sm="24" :md="5" :lg="5" :xl="5" class="grid-content ">
                  <el-date-picker
                  v-model="tourniquetvalue[0].inp1"
                    type="datetime"
                    placeholder="选择开始时间"
                    default-time="12:00:00">
                  </el-date-picker>
          </el-col>

          <el-col :xs="24" :sm="24" :md="5" :lg="5" :xl="5" class="grid-content ">  
                  <el-date-picker
                     v-model="tourniquetvalue[0].inp2"
                    type="datetime"
                    @change="zhixuedaipick"
                    placeholder="选择结束时间"
                    default-time="12:00:00">
                  </el-date-picker>
          </el-col>
            <el-col :xs="24" :sm="24" :md="5" :lg="5" :xl="5"><div class="grid-content  ">
              <el-input disabled v-model="tourniquetvalue[0].inp5"></el-input>分
            </div></el-col>

          </el-row>
            
        </div>
        </el-col>
  <!-- 是否选择有止血带始 -->
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"  v-show="next==3">
          <el-row class="zhixuedai" :gutter="10" v-for="(item,index) in tourniquet" :key="index">
            <el-col :xs="24" :sm="24" :md="3" :lg="3" :xl="3" >
            <div class="grid-content  bg-purple">
            {{ item.name }}
            </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="21" :lg="21" :xl="21" >
            <div class="grid-content ">
            <el-radio-group 
              v-model="tourniquetvalue[index+1].label"
              :min="0"
              :max="1">
              <el-radio  v-for="city in item.value" :label="city.label" :key="city.label">{{city.label}}</el-radio>
            </el-radio-group>
            </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" v-for="inputvalue in item.value" :label="inputvalue.label" :key="inputvalue.label">
            <el-col :xs="3" :sm="3" :md="3" :lg="3" :xl="3" class="zhixuedaiinputclass" v-if="inputvalue.isinput" v-show="tourniquetvalue[index+1].isshow">
          <div class="grid-content bg-purple">
            {{inputvalue.name}}
          </div></el-col>
            <el-col :xs="21" :sm="21" :md="21" :lg="21" :xl="21" v-if="inputvalue.isinput" v-show="tourniquetvalue[index+1].isshow">
          <div class="grid-content ">
              <el-input  v-model="tourniquetvalue[index+1].inputvalue" /><label>{{ inputvalue.dw }}</label>
          </div></el-col>
          </el-col>
        </el-row>
        </el-col>
        <!-- 止血带模块终 -->
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"  v-show="next==4">
          <!-- 表格组件 -->
        <unsurgerytable @insurgerytable="getinsurgerytable" @insurgery="getinsurgery" :message1="insurgery" :message2="insurgerytable"></unsurgerytable>
        </el-col>
        <!-- 选择手术方式 -->
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"  v-show="next==5">
          <div class="grid-content bg-purple">
            选择手术方式
        </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"  v-show="next==5"> 
          <el-radio v-model="Surgicalselection" label="1">传统手术</el-radio>
          <el-radio v-model="Surgicalselection" label="2">Mako 机器人手术</el-radio>
        </el-col>
          </el-row>
          <el-row v-if="Surgicalselection==1">
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"  v-show="next==6">
              <traditionalsurgery @trandition="gettrandition" :message="traditionvalue"></traditionalsurgery>
            </el-col>
          </el-row>
          <el-row v-if="Surgicalselection==2">
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" v-show="next==6">
                  <tobotsurgery @robotic="getrobotic" :message="robotvalue"></tobotsurgery>
                  </el-col>
                    <!-- 机器人手术表格界面 -->
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" v-show="next==7">
                  <robottable @robotictable="getrobotictable" :message="robottablevalue"></robottable>
                  </el-col>
          </el-row>
          <el-row>
    <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" v-show="next==showpart4">
        <part4insurgery @part4="getpart4" :message="part4resvalue"></part4insurgery>
        </el-col>
  </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="tolast" type="warning" :disabled="next==1">上一步</el-button>
        <el-button @click="tonext" type="success" :disabled="next==9">下一步</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
       <!-- 弹窗填写 -->
    <div class="insurgery1" v-show="show">
    <!-- 手术人员填写始 -->
    <el-row :gutter="10">
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" v-for="(item,index) in doctorinput" :key="index">
          <div class="grid-content " v-show="index !=10">
            <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8" v-show="item.type !=='isradio'"><div class="grid-content bg-purple">{{ item.name }}</div></el-col>

            <el-col :xs="16" :sm="16" :md="16" :lg="16" :xl="16" v-if="item.type=='isinput'">
              <div class="grid-content bg-purple" ><el-input :disabled="modify" v-model="doctorinputvalue[index].value" /></div>
            </el-col>

            <el-col :xs="16" :sm="16" :md="16" :lg="16" :xl="16" v-if="item.type=='notinput'">
              <div class="grid-content bg-purple">
                <el-date-picker
                v-model="doctorinputvalue[index].value"
                 class="insurgery1picker"
                    :placeholder="item.name"
                    type="datetime"
                    :disabled="modify"
                    default-time="12:00:00"
                    @change="handleTimeChange($event,index)"
                  >
                </el-date-picker>
              </div>
            </el-col>

            <el-col :xs="16" :sm="16" :md="16" :lg="16" :xl="16" v-if="item.type=='timelong'">
              <div class="grid-content" ><el-input disabled v-model="doctorinputvalue[index].value"><template slot="append">分</template></el-input></div>
            </el-col>
            <!-- <el-col :xs="18" :sm="16" :md="16" :lg="16" :xl="16" v-if="item.type=='isradio'">
              <div class="grid-content" >
                <el-radio-group 
                  v-model="doctorinputvalue[index].value"
                    :min="0"
                    :max="1"
                    >
                    <el-radio v-for="city in operaname" :label="city" :key="city" style="padding-top: 10px; padding-right: 10px;">{{city}}</el-radio>
                  </el-radio-group>
              </div>
            </el-col> -->
        </div>
    </el-col>
    <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"> 
      <div class="grid-content">
        <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4" ><div class="grid-content bg-purple">手术名称</div></el-col>
         <el-col :xs="20" :sm="20" :md="20" :lg="20" :xl="20" >
              <div class="grid-content" >
                <el-radio-group 
                  v-model="doctorinputvalue[10].value"
                    :min="0"
                    :max="1"
                    :disabled="modify"
                    >
                    <el-radio v-for="city in operaname" :label="city" :key="city" style="padding-top: 10px; padding-right: 10px;">{{city}}</el-radio>
                  </el-radio-group>
              </div>
            </el-col>
      </div>
    </el-col>
</el-row>

<el-row :gutter="10">
    <el-col :xs="4" :sm="3" :md="3" :lg="3" :xl="3"><div class="grid-content bg-purple">畸形</div></el-col>
    <el-col :xs="20" :sm="10" :md="21" :lg="21" :xl="21" >
      <div class="grid-content" style="border: solid 1px rgba(134, 134, 134, 0.507);margin-bottom: 5px;">
        <el-checkbox-group v-model="jixingvalue[0].value" @change="jixingbtn" :min="0" :max="1">
          <el-checkbox :disabled="modify" v-for="jix in jixing[0].value" :label="jix" :key="jix" style="margin-right: 15px;">{{jix}}</el-checkbox>
        </el-checkbox-group>
      </div>
    </el-col>
    <el-col :xs="4" :sm="3" :md="3" :lg="3" :xl="3" v-if="jixingvalue[0].leftishow"><div class="grid-content bg-purple">内翻</div></el-col>
    <el-col :xs="20" :sm="8" :md="21" :lg="21" :xl="21" v-if="jixingvalue[0].leftishow">
      <div class="grid-content range">
        <el-input type="range" min="0" max="80"  :disabled="modify" v-model="jixingvalue[0].leftvalue" />
        <span style="padding: 0 10px;"> {{ jixingvalue[0].leftvalue== '' ? 0:jixingvalue[0].leftvalue  }}°</span>
       
      </div>
    </el-col>
    <el-col :xs="4" :sm="3" :md="3" :lg="3" :xl="3" v-if="jixingvalue[0].rightisshow"><div class="grid-content bg-purple">外翻</div></el-col>
    <el-col :xs="20" :sm="8" :md="21" :lg="21" :xl="21" v-if="jixingvalue[0].rightisshow">
      <div class="grid-content range">
        <el-input type="range" min="0" max="80" :disabled="modify" v-model="jixingvalue[0].rightvalue" />
        <span style="padding: 0 10px;"> {{ jixingvalue[0].rightvalue == '' ? 0:jixingvalue[0].rightvalue }}°</span>
      </div>
    </el-col>


    <el-col :xs="24" :sm="24" :md="5" :lg="5" :xl="5"><div class="grid-content bg-purple">麻醉手术后可否纠正</div></el-col>
    <el-col :xs="24" :sm="24" :md="19" :lg="19" :xl="19"><div class="grid-content box-shadow" style="border: solid 1px rgba(134, 134, 134, 0.507)">
      <el-checkbox-group v-model="jixingvalue[1].value" @change="jixingbtn"  :min="0" :max="1">
        <el-checkbox :disabled="modify" v-for="jix in jixing[1].value" :label="jix" :key="jix">{{jix}}</el-checkbox>
      </el-checkbox-group>
    </div></el-col>

    <el-col :xs="24" :sm="24" :md="24" :lg="5" :xl="5"><div class="grid-content bg-purple">麻醉后关节活动度</div></el-col>
    <el-col :xs="24" :sm="24" :md="24" :lg="19" :xl="19"><div class="grid-content">
      <el-input :disabled="modify" v-model="jixingvalue[2].value1"><template slot="append">°</template></el-input>
      <el-input :disabled="modify" v-model="jixingvalue[2].value2"><template slot="append">°</template></el-input>
      <el-input :disabled="modify" v-model="jixingvalue[2].value3"><template slot="append">°</template></el-input>
    </div></el-col>

    
    <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6"><div class="grid-content bg-purple">双下肢全长X线片</div></el-col>
    <el-col :xs="24" :sm="24" :md="24" :lg="18" :xl="18">
      <!-- <div class="grid-content bg-purple"> -->
        <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
          <el-input :disabled="modify" v-model="jixingvalue[3].value1">
            <template slot="prepend">MPTA</template>
            <template slot="append">°</template>
          </el-input>
        </el-col>
        <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8"> 
          <el-input :disabled="modify" v-model="jixingvalue[3].value2">
            <template slot="prepend">LDFA</template>
            <template slot="append">°</template>
          </el-input>
        </el-col>
        <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">    
          <el-input :disabled="modify" v-model="jixingvalue[3].value3">
            <template slot="prepend">CPAK</template>
            <template slot="append">°</template>
          </el-input>
        </el-col>  
      <!-- </div> -->
    </el-col>


    <el-col :xs="24" :sm="24" :md="24" :lg="5" :xl="5"><div class="grid-content bg-purple">术前诊断</div></el-col>
    <el-col :xs="24" :sm="24" :md="24" :lg="19" :xl="19"><div class="grid-content"  style="border: solid 1px rgba(134, 134, 134, 0.507)">
      <el-checkbox-group v-model="jixingvalue[4].value" @change="jixingbtn">
        <el-checkbox :disabled="modify" v-for="jix in jixing[2].value" :label="jix" :key="jix">{{jix}}</el-checkbox>
      </el-checkbox-group>
    </div></el-col>
    <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4" v-if="jixingvalue[4].other"><div class="grid-content bg-purple">其他</div></el-col>
    <el-col :xs="20" :sm="20" :md="20" :lg="20" :xl="20" v-if="jixingvalue[4].other"><div class="grid-content bg-purple">
     <el-input :disabled="modify" v-model="jixingvalue[5].othervalue" />
    </div></el-col>


    <el-col :xs="24" :sm="24" :md="24" :lg="5" :xl="5"><div class="grid-content bg-purple">术后诊断</div></el-col>
    <el-col :xs="24" :sm="24" :md="24" :lg="19" :xl="19"><div class="grid-content"  style="border: solid 1px rgba(134, 134, 134, 0.507)">
      <el-checkbox-group v-model="jixingvalue[5].value" @change="jixingbtn">
        <el-checkbox :disabled="modify" v-for="jix in jixing[2].value" :label="jix" :key="jix">{{jix}}</el-checkbox>
      </el-checkbox-group>
    </div></el-col>
    <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4" v-if="jixingvalue[5].other"><div class="grid-content bg-purple">其他</div></el-col>
    <el-col :xs="20" :sm="20" :md="20" :lg="20" :xl="20" v-if="jixingvalue[5].other"><div class="grid-content bg-purple">
     <el-input :disabled="modify" v-model="jixingvalue[5].othervalue" />
    </div></el-col>

</el-row>
  <!-- 手术人员填写始 -->
  <el-row :gutter="10">
<!-- 止血带模块始 -->
       <!-- 止血带选择 -->
    <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"><div class="grid-content">
      <el-col :xs="24" :sm="4" :md="4" :lg="4" :xl="4" class="zhixuedaiinputclass zhixuedai"><div class="grid-content  bg-purple">止血带</div></el-col>
      <el-col :xs="24" :sm="20" :md="20" :lg="20" :xl="20" ><div class="grid-content"  style="border: solid 1px rgba(134, 134, 134, 0.507)">
        <el-radio-group v-model="tourniquetvalue[0].label">
          <el-radio :disabled="modify" label="无"></el-radio>
          <el-radio :disabled="modify" label="有"></el-radio>
        </el-radio-group>
      </div></el-col>
   </div>
   </el-col>
     <!-- 止血带选择 -->
        <!-- 是否选择有止血带始 -->
      <div v-show="tourniquetvalue[0].isshow">
        <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6" class="zhixuedaiinputclass"><div class="grid-content  bg-purple">止血带时间</div></el-col>
        <el-col :xs="24" :sm="18" :md="18" :lg="18" :xl="18"><div class="grid-content "  style="border: solid 1px rgba(134, 134, 134, 0.507)">
          <el-radio-group  v-model="tourniquetvalue[0].label2"  :min="0" :max="1">
            <el-radio :disabled="modify" label="全程"></el-radio>
            <el-radio :disabled="modify" label="仅上假体时"></el-radio>
          </el-radio-group>
        </div></el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">   
          <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8" class="grid-content ">
                  <el-date-picker
                  v-model="tourniquetvalue[0].inp1"
                    type="datetime"
                    :disabled="modify"
                    placeholder="选择日期时间"
                    default-time="12:00:00">
                  </el-date-picker>
          </el-col>

          <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8" class="grid-content ">  
                  <el-date-picker
                     v-model="tourniquetvalue[0].inp2"
                    type="datetime"
                    :disabled="modify"
                    @change="zhixuedaipick"
                    placeholder="选择日期时间"
                    default-time="12:00:00">
                  </el-date-picker>
          </el-col>
          
          <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8" class="grid-content ">   
            <el-input disabled v-model="tourniquetvalue[0].inp5">
              <template slot="prepend">时长</template>
              <template slot="append">分</template>
            </el-input>
          </el-col>
        </el-col>    
      </div>

  <!-- 是否选择有止血带始 -->
    <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" >
        <el-row class="zhixuedai" :gutter="10" v-for="(item,index) in tourniquet" :key="index">
          <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8" >
          <div class="grid-content  bg-purple">
          {{ item.name }}
          </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="16" :lg="16" :xl="16" >
            <div class="grid-content " style="border: solid 1px rgba(134, 134, 134, 0.507)">
              <el-radio-group 
                v-model="tourniquetvalue[index+1].label"
                :min="0"
                :max="1">
                <el-radio :disabled="modify" v-for="city in item.value" :label="city.label" :key="city.label">{{city.label}}</el-radio>
              </el-radio-group>
            </div>
          </el-col>

          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" v-for="inputvalue in item.value" :label="inputvalue.label" :key="inputvalue.label">
            <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8" class="zhixuedaiinputclass" v-if="inputvalue.isinput" v-show="tourniquetvalue[index+1].isshow">
              <div class="grid-content bg-purple">
                {{inputvalue.name}}
              </div>
            </el-col>
            <el-col :xs="16" :sm="16" :md="16" :lg="16" :xl="16" v-if="inputvalue.isinput" v-show="tourniquetvalue[index+1].isshow">
              <div class="grid-content" > 
                <el-input :disabled="modify" v-model="tourniquetvalue[index+1].inputvalue"><template slot="append">{{ inputvalue.dw }}</template></el-input>
              </div>
            </el-col>
          </el-col>
        </el-row>
    </el-col>


        <!-- 止血带模块终 -->
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <!-- 表格组件 -->
          <unsurgerytable @insurgerytable="getinsurgerytable" @insurgery="getinsurgery" :message1="insurgery" :message2="insurgerytable" :othermodify="othermodify"></unsurgerytable>
        </el-col>
        <!-- 选择手术方式 -->
        <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
          <div class="grid-content bg-purple">
            选择手术方式
        </div>
        </el-col>
        
          <el-col :xs="24" :sm="24" :md="24" :lg="16" :xl="16" >
            <div class="grid-content" style="border: solid 1px rgba(134, 134, 134, 0.507)">
              <el-radio :disabled="modify" v-model="Surgicalselection" label="1">传统手术</el-radio>
              <el-radio :disabled="modify" v-model="Surgicalselection" label="2">Mako 机器人手术</el-radio>
            </div>
          </el-col>
        
    </el-row>

   <!-- 传统手术界面 -->
  <el-row>
    <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" v-show="assemblyshow" >
      <traditionalsurgery @trandition="gettrandition" :message="traditionvalue" :othermodify="othermodify"></traditionalsurgery>
    </el-col>
  </el-row>
     <!-- 机器人手术界面 -->
  <el-row>
    <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" v-show="!assemblyshow" >
      <tobotsurgery @robotic="getrobotic" :message="robotvalue" :othermodify="othermodify"></tobotsurgery>
    </el-col>
          <!-- 机器人手术表格界面 -->
    <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" v-show="!assemblyshow">
      <robottable @robotictable="getrobotictable" :message="robottablevalue" :othermodify="othermodify"></robottable>
    </el-col>
  </el-row>
 
  <!-- <el-row>
    <el-col :xs="21" :sm="21" :md="24" :lg="24" :xl="24" v-show="!assemblyshow">
        <robottable @robotictable="getrobotictable" :message="robottablevalue"></robottable>
        </el-col>
  </el-row>   -->
    <!-- 机器人手术最后部分，垫片选择开始那部分 -->
    <el-row>
    <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" >
        <part4insurgery @part4="getpart4" :message="part4resvalue" :othermodify="othermodify"></part4insurgery>
        </el-col>
  </el-row>
   
</div>
  <div class="button" v-show="show">
    <el-button type="primary" icon="el-icon-edit" circle @click="tomodify" v-show="modify"></el-button>
    <!-- <el-button @click="tomodify" type="warning" v-show="modify">修改</el-button> -->
    <div v-show="!modify" >
      <el-row>
        <el-button type="success" icon="el-icon-check" circle @click="submit"></el-button>
      </el-row>
      <el-row>
        <el-button type="danger" icon="el-icon-close" circle @click="cancel"></el-button>
      </el-row>
    </div>
    <!-- <el-button @click="submit" type="success" v-show="!modify">提交</el-button>
    <el-button @click="cancel"  type="danger"  v-show="!modify">取消</el-button> -->

  </div>
  <el-button v-show="!show" type="primary" @click="tofillin">去填写</el-button>
  </div>
</template>
<script>
import unsurgerytable from "@/components/insurgery/insurgerytable.vue"
import traditionalsurgery from "./traditionalsurgery.vue";
import tobotsurgery from "../insurgery/roboticsurgery.vue"
import robottable from "./robottable.vue"
import part4insurgery from "./part4insurgery.vue"
import {phy} from "../../util/Process"
export default {
    data() {
        return {
          diawid:"62%",
          operationid:'',
          // 韧带损伤程度评估返回值
          insurgery:[],
          // 获取韧带损伤程度评估  
          getinsurgeryvalue:[],
          // 软骨损伤程度评估返回值
          insurgerytable:[],
          // 获取软骨损伤程度评估 
          getinsurgerytablevalue:[],
          // 传统手术的返回值
          traditionvalue:[],
          // 获取传统手术传递过来的值
          gettranditionvalue:[],
          // 机器人手术返回值（不包含表格）
          robotvalue:[],
           // 获取机器人手术传递过来的值（不包含表格）
           getrobotvalue:[],
          // 机器人手术返回值（表格）
          robottablevalue:[],
          // 获取机器人手术传递过来的值（表格）
          getrobotictablevalue:[],
          // 第四部分传递过来的值
          getpart4value:[],
          // 第四部分的返回值
          part4resvalue:[],

          // 决定展示哪个组件，传统or机器人
          assemblyshow:true,
            modify: true,
            othermodify:true,
            doctorinput: [
                { name: "麻醉医生", type: "isinput",md:8,lg:8,xl:8 },
                { name: "手术护士", type: "isinput" ,md:8,lg:8,xl:8},
                { name: "巡回护士", type: "isinput" ,md:8,lg:8,xl:8},
                { name: "主刀医生", type: "isinput" ,md:8,lg:8,xl:8},
                { name: "第一助手", type: "isinput" ,md:8,lg:8,xl:8},
                { name: "第二助手", type: "isinput" ,md:8,lg:8,xl:8},
                { name: "第三助手", type: "isinput" ,md:8,lg:8,xl:8},
                { name: "开始时间", type: "notinput" ,md:8,lg:8,xl:8},
                { name: "结束时间", type: "notinput" ,md:8,lg:8,xl:8},
                { name: "手术时长", type: "timelong" ,md:8,lg:8,xl:8},
                { name: "手术名称", type: "isradio" ,md:16,lg:16,xl:16},
            ],
            doctorinputvalue: [
                { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' },{ value: '' }
            ],
            // 畸形
            jixing: [
                { value: ["无", "外翻", "内翻"] },
                { value: ["否", "小部分可", "大部分可", "完全"] },
                { value: ["骨关节炎", "类风湿关节炎", "床上后关节炎", "痛风性关节炎", "其他"] },
            ],
            // 畸形选择的值
            jixingvalue: [
                { value: [], leftvalue: '', leftishow: false, rightvalue: '', rightisshow: false },
                { value: [] },
                { value1: '', value2: '', value3: '' },
                { value1: '', value2: '', value3: '' },
                { value: [], other: false, othervalue: '' },
                { value: [], other: false, othervalue: '' },
            ],
            // 止血带
            tourniquet:[
              {
                name:'切口选择',
                oninput:false,
                value:[
                  {label:"膝前正中",isinput:false,checked:false},
                  {label:"膝前内侧",isinput:false,checked:false},
                  {label:"膝前外侧",isinput:false,checked:false},
                ],
                checkvalue:[]
              },
              {
                name:'使用既往切口',
                oninput:true,
                value:[
                {label:"否",isinput:false,checked:false},
                {label:"是",isinput:true,checked:false,name:"长度",dw:"cm(屈 90°)"},
               
                ],
                checkvalue:[]
              },
              {
                name:'入路选择',
                oninput:true,
                value:[
                {label:"髋旁内侧",isinput:false,checked:false},
                {label:"髋旁外侧",isinput:false,checked:false},
                {label:"股内侧肌",isinput:false,checked:false},
                {label:"股内侧肌下",isinput:false,checked:false},
                {label:"其他",isinput:true,checked:false,name:"其他",},
                ],
                checkvalue:[]
              }, 
              {
                name:'关节内积液',
                oninput:false,
                value:[
                {label:"无",isinput:false,checked:false},
                {label:"少量",isinput:false,checked:false},
                {label:"大量",isinput:false,checked:false},
                ] ,
                checkvalue:[]
                // value:["无","少量","大量"]
              }, {
                name:'关节积液性状',
                oninput:false, 
                value:[
                {label:"淡黄",isinput:false,checked:false},
                {label:"清亮",isinput:false,checked:false},
                {label:"浑浊",isinput:false,checked:false},
                {label:"血性",isinput:false,checked:false},
                ] ,
                checkvalue:[]
              }, 
              {
                name:'滑膜增生',
                oninput:false,
                value:[
                {label:"无",isinput:false,checked:false},
                {label:"轻度充血",isinput:false,checked:false},
                {label:"明显充血",isinput:false,checked:false},
                {label:"血性",isinput:false,checked:false},
                ] ,
                checkvalue:[]
              }, 
              {
                name:'髁间窝骨质增生',
                oninput:false,
                value:[
                {label:"无",isinput:false,checked:false},
                {label:"轻度",isinput:false,checked:false},
                {label:"明显",isinput:false,checked:false},
                ] ,
                checkvalue:[]
              }, 
            ],
            // 止血带选择的值
            tourniquetvalue:[
              {label:[],label2:[],inp1:'',inp2:'',inp3:'',inp4:'',inp5:'',isshow:false},
              {label:[]},
              {label:[],inputvalue:'',isshow:false},
              {label:[],inputvalue:'',isshow:false},
              {label:[]},
              {label:[]},
              {label:[]},
              {label:[]},
            ],
            // 选择传统手术亦或者机器人手术
            Surgicalselection:0,
            show:false,
            dialogVisible:false,
            next:1,
            showpart4:6,
            operaname:["膝关节置换","髋关节置换","膝关节内侧单髁置换"]
        };
    },
    watch: {
        jixingvalue: {
            handler(newvalue, oldvalue) {
                let a1 = newvalue[0].value;
                let a4 = newvalue[4].value, a5 = newvalue[5].value;
                if (a4.includes("其他")) {
                    newvalue[4].other = true;
                }
                else {
                    newvalue[4].other = false;
                }
                if (a5.includes("其他")) {
                    newvalue[5].other = true;
                }
                else {
                    newvalue[5].other = false;
                }
                if (a1.includes("内翻")) {
                    newvalue[0].leftishow = true;
                }
                else if (a1.includes("外翻")) {
                    newvalue[0].rightisshow = true;
                }
                else {
                    newvalue[0].leftishow = false;
                    newvalue[0].rightisshow = false;
                }
            },
            deep: true
        },
        tourniquetvalue:{
          handler(newvalue,oldvalue){
            let t1 = newvalue[0].inp1
          let t2 =  newvalue[0].inp2
          if(t2 !=''){
              let alltime = Date.parse(t2) - Date.parse(t1);
              newvalue[0].inp5 =Math.floor(alltime/ 1000 / 60)
          }
            let arr = newvalue[0].label
            let arr2 = newvalue[2].label 
            let arr4 = newvalue[3].label
            // console.log(arr)
            if(arr.includes("有")){
              newvalue[0].isshow = true
            }else{
              newvalue[0].isshow = false
            }
            if(arr2.includes("是")){
              newvalue[2].isshow = true
            }else{
              newvalue[2].isshow = false
            }
            if(arr4.includes("其他")){
              newvalue[3].isshow = true
            }else{
              newvalue[3].isshow = false
            }
          },deep:true
        },
        Surgicalselection(val,oldval){
         this.assemblyshow = val == 1 ? true:false
         this.showpart4 = val == 1 ? 7:8
        },
        doctorinputvalue:{
          handler(val,old){
            let t1 =val[7].value
            let t2 = val[8].value
            if(t2 != ''){
              let alltime = Date.parse(t2) - Date.parse(t1);
              this.doctorinputvalue[9].value =Math.floor(alltime/ 1000 / 60)
            }
          },deep:true
        },
        
    },
    components:{
      robottable,
      unsurgerytable,
      traditionalsurgery,
      tobotsurgery,
      part4insurgery,
    },
    created(){
      this.operationid = this.$route.query.room
      // console.log(this.operationid,'8789')
    },
    mounted(){
     this.getresvalue()
     let wid = window.innerWidth
    this.diawid = wid<=480 ? "90%":wid>=1200 ? "62%":"80%"
    },
    methods: {
     async getresvalue(){
      this.dialogVisible = false
        try{
          const {data:res} =await this.$http.get('/Intraop/getIntraopInfo/'+this.operationid)
          // console.log(res)
          if(res.status==0){
            this.$message.error("暂无信息")
            this.show=false
          }else{
            this.show=true
        let robotsel = res.data[0].choice.scalar
          // 处理手术人员+手术时间
         this.basicmethod(res.data[0].basic)
          // 处理关节情况
         this.joint_conditionmethod(res.data[0].joint_condition)
         this.insurgerytable = phy(res.data[0].crm)
         this.insurgery = phy(res.data[0].lige)
         this.tourniquetmethods(res.data[0].conduct)
         this.traditionvalue = phy(res.data[0].traditional)
         this.Surgicalselection = robotsel.toString() 
         this.robotvalue = phy(res.data[0].robot_data)
         this.robottablevalue = phy(res.data[0].robot_osteotomy)
         this.part4resvalue = phy(res.data[0].other)
          }
        }catch(e){
        }
      },
      zhixuedaipick(){
        let t1 = this.tourniquetvalue[0].inp1
        let t2 =  this.tourniquetvalue[0].inp2
        let alltime = Date.parse(t2) - Date.parse(t1);
        this.tourniquetvalue[0].inp5 =Math.floor(alltime/ 1000 / 60)
      },
      // handleTimeChange(value,index){
      //   let t1 = this.doctorinputvalue[7].value
      //   let t2 = this.doctorinputvalue[8].value
      //   if(index == '8'){
      //     let alltime = Date.parse(t2) - Date.parse(t1);
      //     this.doctorinputvalue[9].value =Math.floor(alltime/ 1000 / 60)
      //     console.log(alltime,'778899')
      //   }
      // },
      // 下一步
      tonext(){
        if(this.Surgicalselection == 1){
          this.next = this.next >=7 ? 7:this.next+1
        }else if(this.Surgicalselection == 2){
          this.next = this.next >=8 ? 8:this.next+1
        }else{
          this.next =this.next+1
        }
       
      },
       // 上一步
      tolast(){
        this.next = this.next <=1 ? 1:this.next-1
      },
     
        jixingbtn() {

        },
        tomodify(){
          this.modify = false
          this.othermodify=false
        },
        cancel(){
          this.modify = true
          this.othermodify=true
        },
        tofillin(){
          this.dialogVisible = true
        },
       async submit(){
          try{
            const {data:res} = await this.$http.post('/Intraop/addIntraopTKAInfo/'+this.operationid,{
              "basic":this.doctorinputvalue,
              "joint_condition":this.jixingvalue,
              "crm":this.getinsurgerytablevalue,
              "lige":this.getinsurgeryvalue,
              "conduct":this.tourniquetvalue,
              "traditional":this.gettranditionvalue,
              "choice":this.Surgicalselection,
              "robot_data":this.getrobotvalue,
              "robot_osteotomy":this.getrobotictablevalue,
              "other":this.getpart4value
            })
            console.log(res,'pp')
            if(res.status=='1'){
              this.$message.success("修改成功")
            this.getresvalue()
            this.modify = true
            this.othermodify=true
            }else{
              this.$message.error(res.message)
            }
            
            // console.log(res,'我是测试')
          }catch(e){

          }
        },
        // 处理止血带的数据回显
        tourniquetmethods(value){
          let basic = phy(value)
          for(let i=0;i<basic.length;i++){
            let arr = basic[i]
            for(let key in arr){
              this.tourniquetvalue[i][key] = arr[key]
            }
          }
        },
      // 机器人非表格部分
        getrobotic(value){
          this.getrobotvalue=[]
          this.getrobotvalue = value
          // console.table(this.getrobottablevalue)
        },
        // 机器人表格部分
        getrobotictable(value){
          this.getrobotictablevalue=[]
          this.getrobotictablevalue = value
        },
        // 传统手术部分
        gettrandition(value){
          this.gettranditionvalue = []
          this.gettranditionvalue = value
        },
         // 处理手术人员+手术时间
        basicmethod(value){
          let basic = phy(value)
          for(let i=0;i<basic.length;i++){
            let arr = basic[i]
            for(let key in arr){
              this.doctorinputvalue[i][key] = arr[key]
            }
          }
        },
        // 关节情况  
        joint_conditionmethod(value){
          let basic = phy(value)
          for(let i=0;i<basic.length;i++){
            let arr = basic[i]
            for(let key in arr){
              this.jixingvalue[i][key] = arr[key]
            }
          }
        },
        getpart4(value){
          this.getpart4value=[]
          this.getpart4value = value
        },
        getinsurgerytable(value){
          this.getinsurgerytablevalue = []
          this.getinsurgerytablevalue=value
          // console.log(value,'jjj')
          
        },
        getinsurgery(value){
          this.getinsurgeryvalue = []
          this.getinsurgeryvalue = value
        }
    },
  
}
</script>

<style scoped>
.insurgery{
    height: fit-content;
    box-sizing: border-box;
    padding: 5px;
    width: 100%;
}
.insurgery .el-date-editor{
  width:100% !important;
}
.indw{
  display: flex;

  flex-wrap: nowrap;
}
.inputwid{
  width: 60px;
}
.zhixuedai .el-col{
  margin-bottom: 0px !important;
}
.zhixuedaiinputclass{
  padding-left: 0px !important;
  padding-right: 10px !important;
  margin-top: 5px;
}
.button{
  position: absolute;
  top: 60%;
  right:15%;
  display: flex;
  flex-direction:column
}
.button .el-button{
  margin-bottom:5px
}
.nowrapdiv{
  width: 40px;
  white-space: nowrap;
}
.inputdiv{
  width: 25%;
}
.w{
  width: 15px;
  white-space: nowrap;
}
.range {
  width: 95%;
}
.range .el-input__inner{
        height: 10px !important;
        padding: 0px !important;
        cursor: pointer;
      }
.insurgery1picker .el-input__inner{
  width:100% !important;
}
@media screen and (max-width: 480px) {
  .button{
    right:5%;
    top: 75%;
  }
}
</style>