<template>
  <div class="common-personal">
    <!-- 吸烟史 -->
    <div style=" padding: 10px 20px; width: 100%; overflow: auto;" v-show="next == 1 || showAll">
        <div class="grid-content-left">
          <div class="from-topic">是否吸烟：</div>
          <el-radio-group
            v-model="operateForm.smoking_history.whether"
            :disabled="modify"
          >
            <el-radio :label="'1'" border>无</el-radio>
            <el-radio :label="'2'" border>已戒烟</el-radio>
            <el-radio :label="'3'" border>偶尔</el-radio>
            <el-radio :label="'4'" border>经常</el-radio>
          </el-radio-group>
        </div>
        <!-- 已戒烟 -->
        <div class="grid-content-left" v-show="operateForm.smoking_history.whether == '2'">
          <div class="from-topic">已戒烟：</div>
          <el-input
            placeholder="戒烟年限"
            v-model.number="operateForm.smoking_history.cessation"
            :disabled="modify"
          >
            <template slot="append">年</template>
          </el-input>
        </div>
        <!-- 抽烟 -->
        <div class="grid-content-left" v-show="['3', '4'].indexOf(operateForm.smoking_history.whether) > -1">
          <div class="from-topic">主要烟种：</div>
          <el-input
            placeholder="如：黄鹤楼/中华/利群/红塔山/芙蓉王/玉溪/南京牌/双喜烟/黄金叶 等"
            v-model.number="operateForm.smoking_history.ingredient"
            :disabled="modify"
          >
          </el-input>
          <div class="from-topic">已抽烟：</div>
          <el-input
            placeholder="抽烟年限"
            v-model.number="operateForm.smoking_history.datal"
            :disabled="modify"
          >
            <template slot="append">年</template>
          </el-input>
        
        <div class="from-topic">抽烟量：</div>
          <el-input
            placeholder="烟量"
            v-model.number="operateForm.smoking_history.amount"
            :disabled="modify"
          >
            <template slot="append">包/天</template>
          </el-input>
      </div>      
    </div>
    <!-- 嗜酒史 -->
    <div style=" padding: 10px 20px; width: 100%; overflow: auto;"  v-show="next == 2 || showAll">
      <div class="grid-content-left">
        <div class="from-topic">是否嗜酒：</div>
        <el-radio-group
          v-model="operateForm.alcohol_history.whether"
          :disabled="modify"
        >
          <el-radio :label="'1'" border>否</el-radio>
          <el-radio :label="'2'" border>偶尔喝酒</el-radio>
          <el-radio :label="'3'" border>经常喝酒</el-radio>
        </el-radio-group>
      </div>
      <!-- 喝酒 -->
      <div  v-show="['2', '3'].indexOf(operateForm.alcohol_history.whether) > -1">
        <div class="grid-content-left">
          <div class="from-topic">主要酒种：</div>
          <el-radio-group
          v-model="operateForm.alcohol_history.ingredient.opt"
          :disabled="modify"
          >
            <el-radio :label="'1'" border>红酒</el-radio>
            <el-radio :label="'2'" border>低度白酒</el-radio>
            <el-radio :label="'3'" border>高度白酒</el-radio>
            <el-radio :label="'4'" border>啤酒</el-radio>
            <el-radio :label="'5'" border>米酒</el-radio>
            <el-radio :label="'6'" border>多种组合</el-radio>
            <el-radio :label="'7'" border>其他酒种</el-radio>
          </el-radio-group>
        </div> 
        <!-- 其他酒  -->
        <div class="grid-content-left"v-show="operateForm.alcohol_history.ingredient.opt == '7'">
          <div class="from-topic">其他酒种：</div>
          <el-input
              v-model="operateForm.alcohol_history.ingredient.otherWine"
              :disabled="modify"
          ></el-input>
        </div>
        <!-- 米酒 -->
        <div class="grid-content-left"v-show="operateForm.alcohol_history.ingredient.opt == '5'">
          <div class="from-topic">米酒类型：</div>
          <el-radio-group
            v-model="operateForm.alcohol_history.ingredient.riceWine"
            :disabled="modify"
          >
            <el-radio :label="'1'" border>自酿米酒</el-radio>
            <el-radio :label="'2'" border>品牌米酒</el-radio>
          </el-radio-group>
          <div class="from-topic" v-show="operateForm.alcohol_history.ingredient.riceWine == '2'">品牌为：</div>
          <el-input
            v-model="operateForm.alcohol_history.ingredient.brandRiceWine"
            v-show="operateForm.alcohol_history.ingredient.riceWine == '2'"
            :disabled="modify"
            style="width: 40%;"
          ></el-input>
        </div>
        <!-- 酒龄 -->
        <div class="grid-content-left">
          <div class="from-topic">酒龄：</div>
          <el-input
            placeholder="喝酒年限"
            v-model.number="operateForm.alcohol_history.datal"
            :disabled="modify">
            <template slot="append">年</template>
          </el-input>
          <div class="from-topic">酒量：</div>
          <el-input
            placeholder="酒量"
            v-model.number="operateForm.alcohol_history.amount"
            :disabled="modify"
          ></el-input>

          <el-select 
          v-model="operateForm.alcohol_history.unit"
          placeholder="请选择"
          :disabled="modify"
          >
            <el-option  label="两/天" value="两/天"></el-option>
            <el-option  label="斤/天" value="斤/天"></el-option>
            <el-option  label="杯/天" value="杯/天"></el-option>
            <el-option  label="瓶/天" value="瓶/天"></el-option>
          </el-select>
        </div>
      </div>  
    </div>  
    <!-- 输血史 -->
    <div style=" padding: 10px 20px; width: 100%; overflow: auto;" v-show="next == 3 || showAll">
      <div class="grid-content-left">
        <div class="from-topic">输血经历：</div>
        <el-radio-group
        v-model="operateForm.transfusion_history.whether"
          :disabled="modify"
        >
          <el-radio :label="'1'" border>无</el-radio>
          <el-radio :label="'2'" border>有</el-radio>
          <el-radio :label="'3'" border>有,但不详</el-radio>
        </el-radio-group>
        
      </div>
      <!-- 有输血经历 -->
      <div v-show="operateForm.transfusion_history.whether == '2'">
        <div class="grid-content-left" >
          <div class="from-topic" >输血时间：</div>
          <el-date-picker
            type="date"
            value-format="yyyy-MM-dd"
            v-model="operateForm.transfusion_history.datal"
            :disabled="modify"
            style="margin-right:10px;"
          >
          </el-date-picker>
          <div class="from-topic">输血原因：</div>
          <el-input
              placeholder="请输入内容"
              v-model="operateForm.transfusion_history.reason"
              :disabled="modify"
            >
            </el-input>
        </div> 
        <div class="grid-content-left" >
          <div class="from-topic" >输血成分：</div>
          <el-radio-group
            v-model="operateForm.transfusion_history.ingredient.opt"
            :disabled="modify"
          >
            <el-radio :label="'1'" border>悬浮红细胞</el-radio>
            <el-radio :label="'2'" border>新鲜冰冻血浆</el-radio>
            <el-radio :label="'3'" border>普通冰冻血浆</el-radio>
            <el-radio :label="'4'" border>其他</el-radio>
          </el-radio-group>
        </div> 
        <div class="grid-content-left" >
          <div class="from-topic" >输血量：</div>
          <el-input
            v-model="operateForm.transfusion_history.ingredient.capacity"
            :disabled="modify"
            style="margin-right:10px;"
          >
            <template slot="append">ml</template>
          </el-input>

          <div class="from-topic" v-show="operateForm.transfusion_history.ingredient.opt == '4'">其他成分：</div>
          <el-input
              v-model="operateForm.transfusion_history.ingredient.other"
              v-show="operateForm.transfusion_history.ingredient.opt == '4'"
              :disabled="modify"
            >
          </el-input>
        </div> 
      </div>
    </div>
    <!-- 食物药物过敏史 -->
    <div style=" padding: 10px 20px; width: 100%; overflow: auto;" v-show="next == 4 || showAll">
      <div class="grid-content-left" >
        <div class="from-topic" >过敏史：</div>
        <el-radio-group
          v-model="operateForm.allergic_history.whether"
          :disabled="modify"
        >
          <el-radio :label="'1'" border>无</el-radio>
          <el-radio :label="'2'" border>有</el-radio>
        </el-radio-group>
      </div>
      <div class="grid-content-left" v-show="operateForm.allergic_history.whether == 2">  
        <div class="from-topic" >过敏源为：</div>
        <el-input
          v-model="operateForm.allergic_history.allergy_source"
          :disabled="modify"
        >
        </el-input>
      </div>    
    </div>
    <!-- 月经史 -->
    <div style=" padding: 10px 20px; width: 100%; overflow: auto;" v-show="next == 5 || showAll" v-if="gender=='女'">
      <div class="grid-content-left" >
        <div class="from-topic" >是否闭经：</div>
        <el-radio-group
          v-model="operateForm.menstrual_history.whether"
          :disabled="modify"
        >
          <el-radio :label="'1'" border>未闭经</el-radio>
          <el-radio :label="'2'" border>已闭经</el-radio>
        </el-radio-group>
      </div>
      <div class="grid-content-left" >
        <div class="from-topic" >初潮年龄：</div>
        <el-input
          v-model="operateForm.menstrual_history.age"
          :disabled="modify"
          style="width: auto; margin-right:10px;"
        >
          <template slot="append">岁</template>
        </el-input>

        <div class="from-topic" v-show="operateForm.menstrual_history.whether == '1'">月经量：</div>
        <el-radio-group
          v-model="operateForm.menstrual_history.quantity"
          v-show="operateForm.menstrual_history.whether == '1'"
          :disabled="modify"
        >
          <el-radio :label="'1'" border>正常</el-radio>
          <el-radio :label="'2'" border>不规律</el-radio>
        </el-radio-group>

        <div class="from-topic" v-show="operateForm.menstrual_history.whether == '2'">末次月经：</div>
        <el-input
          v-model="operateForm.menstrual_history.lmp"
          v-show="operateForm.menstrual_history.whether == '2'"
          :disabled="modify"
          style="width: auto;"
        >
          <template slot="append">岁</template>
        </el-input>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "CommonPerson",
  props: {
    next: Number,
    showAll: Boolean,
    modify: Boolean,
    operateForm: Object,
    gender:String
  },
  data() {
    return {};
  },

  components: {},

  methods: {},
};
</script>

<style >

.common-personal .grid-content {
  min-height: 40px;
  display: flex !important;
  align-items: center !important;
}
.common-personal .grid-content-left{
  display: flex !important;
  align-items: baseline !important;
  margin: 10px 0;
}
.common-personal .el-input-group__append, .el-input-group__prepend{
  background-color: unset;
}
.common-personal .from-topic{
  min-width:80px;
}
.common-personal .el-radio {
  margin: 0px 5px 10px 0px !important; 
  padding: 12px 7px 0 7px !important; ;
}


@media screen and (max-width: 480px) {
  .common-personal .grid-content-left {
    display: grid !important;
    margin: 10px 0 !important;
    justify-content: start !important;
  }
  .common-personal .from-topic{
    margin: 10px 0 !important;
    text-align: left !important;
  }
}
</style>