<template>
  <div class="background-div">
    <el-container>
      <el-header>
        <div class="header">
          <div class="name">人工关节置换登记</div>
          <div class="loginname">测试账号 &nbsp;<div class="exitbtn" @click="exit">退出</div></div>
        </div>
      </el-header>
      <el-main>
        <div class="main">
          <div class="tabdiv">
            <div class="tab">
              <div
                :class="[isselect == '/homepag' ? 'bt' : '', 'tab1']"
                @click="jump('/homepag')"
              >
                首页
              </div>
              <div
                :class="[isselect == '/MedicalList' ? 'bt' : '', 'tab2']"
                @click="jump('/MedicalList')"
              >
                病例信息列表
              </div>
            </div>
          </div>
          <div class="switch">
            <router-view />
          </div>
        </div>
      </el-main>
      <el-footer class="grid-content">
        人工关置换登记 <br />© 2022-2023 XXXX 版权所有 · 内测版
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      isselect: "/homepag",
    };
  },
  computed: {
    ...mapState(["selectview"]),
  },
  watch: {
    selectview() {
      this.isselect = this.$store.getters.getselectview;
    },
  },

  mounted() {
    this.isselect = this.$store.state.selectview;
    // this.gettoken()
  },
  methods: {
    jump(value) {
      this.isselect = value;
      this.$store.commit("changeselectview", value);
      // console.log(sessionStorage.getItem("stateselect"));
      this.$router.push(value).catch((error) => {
        // this.$message.error("已在当前界面");
      });
    },
  async  exit(){
    const confirm = await this.$confirm(
      '是否退出?',
      '提示',{
        confirmButtonText:"确定",
        cancelButtonText:"取消",
        type:"warning"
      }).catch(e=>{

})
    if(confirm == 'confirm'){
      this.$store.commit("changeshowbtn", "true");
      this.$store.commit("changemedicalname", "病例信息列表");
      window.localStorage.clear()
      sessionStorage.clear()
      this.$router.push("/")
      }
     
    }
    // async gettoken() {
    //   const { data: res } = await this.$http.post("/user/login", {
    //       phone: "13113962510",
    //       password: "123456",
    //   });
    //   if(res.status=='1'){
    //     this.$message.success('获取token成功')
    //     sessionStorage.setItem('token',res.data.token)
    //   }
    //   console.log(res.data,"我是获取token的返回")
    // },
  },
};
</script>

<style scoped>
.bt {
  margin-bottom: -2px;
  border-bottom: 2px solid #409eff;
}
.el-container {
  height: 100%;
}
.el-header {
  height: 100%;
  height: 48px;
  background-color: #1d273b;
  display: flex;
  align-items: center;
  justify-content: center;
}

.el-main {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
}
.header {
  width: 70%;
  height: 100%;
  color: #ffffff;
  display: flex;
}
.name {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.loginname {
  flex: 1;
  height: 100%;
  /* background-color: red; */
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.main {
  width: 100%;
  height: 100%;
  background-color: #f3f4f6;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.tab {
  width: 70%;
  height: 48px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.tabdiv {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 48px;
  background-color: #ffffff;
  box-sizing: border-box;
  padding: 0px 10px;
}
.switch {
  height: calc(100% - 48px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 15px 0px;
}
.tab1,
.tab2 {
  width: fit-content;
  padding: 5px;
  cursor: pointer;
}
.exitbtn{
  height:25px;
  width:50px;
  font-size: 14px;
  border-radius: 15px;
  /* padding: 10px 20px; */
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background: #f56c6c;
}
@media screen and (max-width: 480px) {
  .header{
    width: 90%;
  }
  .tab{
    width: 90%;
  }
}
</style>