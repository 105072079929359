<template>
  <div>
    <el-dialog title="这是查看结果" :visible.sync="dialogVisible" width="800px" :center="true" @close="handleCloseDialog"  :fullscreen="isMobileScreen">
      <el-progress :percentage="currentStep / totalSteps * 100" />
      <!-- <p>评分ID: {{ scoringIdInternal  }}</p>
      <el-button @click="closeDialog">关闭</el-button> -->

      <el-row>
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" v-if="currentStep === 1">
    <div class="grid-content bg-purple" >VAS评分（视觉模拟评分法）</div>
    <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
      <div class="slider-container">
        <div class="knee-options">
            <el-radio-group v-model="selectedKnee"  :disabled="isradioDisabled">
              <el-radio :label="0">左侧膝关节</el-radio>
              <el-radio :label="1">右侧膝关节</el-radio>
            </el-radio-group>
          </div>
      <el-slider
        v-model="sliderValue"
        :max="10"
        :step="1"
        show-stops
        :marks="marks"
        :disabled="isradioDisabled"
        class="custom-slider" 
      ></el-slider>
      <div class="container-wrapper">
    <div class="icon-container">
      <!-- 第一个图标 -->
      <div class="icon-item first-item">
        <img :src="require(`@/assets/icons/1.jpg`)" alt="Icon" class="custom-icon" />
      </div>
      <!-- 其他图标 -->
      <div class="icon-item" v-for="index in 5" :key="index">
        <img :src="require(`@/assets/icons/${index + 1}.jpg`)" alt="Icon" class="custom-icon" />
      </div>
    </div>
  </div>
    </div>
    </el-col>
  
  </el-col>
</el-row>



      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" v-if="currentStep === 2">
        <p> AKS 评分: {{ computedAKS }}</p>
        <div class="grid-content bg-purple">疼痛(50分)</div>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" v-for="(option, index) in painOptions" :key="index" style="margin: 10px 0px 10px 0px">
          <el-radio v-model="AKS[0]" :label="option.value"   :disabled="isradioDisabled">
            {{ option.text }}
          </el-radio>
        </el-col>
      </el-col>



      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" v-if="currentStep === 3">
        <p> AKS 评分: {{ computedAKS }}</p>
        <div class="grid-content bg-purple">活动度(25分)</div>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <el-input v-model="AKS[1]" placeholder="请填写分数"  :disabled="isradioDisabled">
            <template slot="prepend">由屈曲到伸膝（每5°=1 分）</template>
            <!-- <template slot="append">°</template> -->
          </el-input>
        </el-col>
      </el-col>


   
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" v-if="currentStep === 4">
        <p> AKS 评分: {{ computedAKS }}</p>
        <div class="grid-content bg-purple">稳定性(25分)</div>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="margin-top: 10px; " > 
          <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4" class="grid-content bg-purple" >前后方向(10分)</el-col>
          <el-col :xs="24" :sm="24" :md="20" :lg="20" :xl="19"  class="grid-content border">
            <el-radio
              v-model="AKS[2]"
              :label="option.value"
              v-for="(option, index) in stabilityFrontBackOptions" :key="index"
              :disabled="isradioDisabled"
            >
              {{ option.text }}
            </el-radio>
          </el-col>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"  style="margin-top: 10px;">
          <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4" class="grid-content bg-purple">内外方向(15分)</el-col>
          <el-col :xs="24" :sm="24" :md="20" :lg="20" :xl="20" class="grid-content border">
              <el-radio
                v-model="AKS[3]"
                :label="option.value"
                v-for="(option, index) in stabilityInOutOptions" :key="index"
                :disabled="isradioDisabled"
              >
                {{ option.text }}
              </el-radio>
          </el-col>
        </el-col>  
      </el-col>

      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" v-if="currentStep === 5">
        <p> AKS 评分: {{ computedAKS }}</p>
        <div class="grid-content bg-purple">减分项(-50分)</div>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="margin-top: 10px; padding:0;"> 
          <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4" class="grid-content bg-purple" >屈曲挛缩(-15分)</el-col>
          <el-col :xs="24" :sm="24" :md="20" :lg="20" :xl="19"  class="grid-content border">
            <el-radio-group v-model="AKS[4]">
              <el-radio
                v-for="option in quFlexionOptions"
                :key="option.value"
                :label="option.value"
                :disabled="isradioDisabled"
              >
                {{ option.text }}
              </el-radio>
            </el-radio-group>
          </el-col>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"  style="margin-top: 10px; padding:0;">
          <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4" class="grid-content bg-purple">伸膝迟滞(-15分)</el-col>
          <el-col :xs="24" :sm="24" :md="20" :lg="20" :xl="20" class="grid-content border">
            <el-radio-group v-model="AKS[5]">
              <el-radio
                v-for="option in shenZhiOptions"
                :key="option.value"
                :label="option.value"
                :disabled="isradioDisabled"
              >
                {{ option.text }}
              </el-radio>
            </el-radio-group>
          </el-col>
        </el-col>  
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"  style="margin-top: 10px; padding:0;">
          <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4" class="grid-content bg-purple">解剖对线(-20分)</el-col>
          <el-col :xs="24" :sm="24" :md="20" :lg="20" :xl="20" class="grid-content border">
            <el-radio-group v-model="AKS[6]">
              <el-radio
                v-for="option in jieduilineOptions"
                :key="option.value"
                :label="option.value"
                :disabled="isradioDisabled"
              >
                {{ option.text }}
              </el-radio>
            </el-radio-group>
          </el-col>
        </el-col> 
      </el-col>
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" v-if="currentStep === 6">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="margin-top: 10px; padding:0;"> 
          <p>患者整体功能评分: {{ formData2total }}</p>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="grid-content bg-purple" >行走能力(50分)</el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"  class="grid-content border">
            <el-radio-group v-model="formData2[0]">
              <el-radio
                v-for="option in walkAbilityOptions"
                :key="option.value"
                :label="option.value"
                :disabled="isradioDisabled"
              >
                {{ option.text }}
              </el-radio>
            </el-radio-group>
          </el-col>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"  style="margin-top: 10px; padding:0;">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="grid-content bg-purple">上下楼(50分)</el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="grid-content border">
            <el-radio-group v-model="formData2[1]">
              <el-radio
                v-for="option in stairsAbilityOptions"
                :key="option.value"
                :label="option.value"
                :disabled="isradioDisabled"
              >
                {{ option.text }}
              </el-radio>
            </el-radio-group>
          </el-col>
        </el-col>  
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"  style="margin-top: 10px; padding:0;">
          <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4" class="grid-content bg-purple">减分项(-50分)</el-col>
          <el-col :xs="24" :sm="24" :md="20" :lg="20" :xl="20" class="grid-content border">
            <el-radio-group v-model="formData2[2]">
              <el-radio
                v-for="option in deductionOptions"
                :key="option.value"
                :label="option.value"
                :disabled="isradioDisabled"
              >
                {{ option.text }}
              </el-radio>
            </el-radio-group>
          </el-col>
        </el-col> 
      </el-col>

      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" v-if="currentStep === 7">
        <p>WOMAC评分: {{ computedWomacTotal }}</p>
        <div class="grid-content bg-purple">WOMAC 评分（0 代表无，4 代表最严重）</div>

        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="grid-content bg-secondary text-white"  style="margin-top: 10px;">最近两天膝关节的疼痛程度</el-col> 
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="margin-top: 10px; padding:0;" v-for="(question, index) in questions" :key="index" v-if="index < 5">
          <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6" class="grid-content bg-purple" >{{ index + 1 }}、{{ question }}</el-col>
          <el-col :xs="18" :sm="18" :md="18" :lg="18" :xl="18"  class="grid-content border">
            <el-radio-group v-model="womac.womacscores[index]"  :disabled="isradioDisabled">
              <el-radio :label="0">0分</el-radio>
              <el-radio :label="1">1分</el-radio>
              <el-radio :label="2">2分</el-radio>
              <el-radio :label="3">3分</el-radio>
              <el-radio :label="4">4分</el-radio>
            </el-radio-group>
          </el-col>
        </el-col>

        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="grid-content bg-secondary text-white"  style="margin-top: 10px;">最近两天膝关节的僵硬程度</el-col> 
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="margin-top: 10px; padding:0;" v-for="(question, index) in questions":key="index" v-if="index >= 5 && index < 7">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="grid-content bg-purple" >{{ index + 1 }}、{{ question }}</el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"  class="grid-content border">
            <el-radio-group v-model="womac.womacscores[index]"  :disabled="isradioDisabled">
              <el-radio :label="0">0分</el-radio>
              <el-radio :label="1">1分</el-radio>
              <el-radio :label="2">2分</el-radio>
              <el-radio :label="3">3分</el-radio>
              <el-radio :label="4">4分</el-radio>
            </el-radio-group>
          </el-col>
        </el-col>

        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="grid-content bg-secondary text-white"  style="margin-top: 10px;">近两天因膝关节问题进行下列日常活动困难程度</el-col> 
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="margin-top: 10px; padding:0;" v-for="(question, index) in questions" :key="index" v-if="index >= 7 && index < 24">
          <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6" class="grid-content bg-purple" >{{ index + 1 }}、{{ question }}</el-col>
          <el-col :xs="18" :sm="18" :md="18" :lg="18" :xl="18"  class="grid-content border">
            <el-radio-group v-model="womac.womacscores[index]"  :disabled="isradioDisabled"> 
              <el-radio :label="0">0分</el-radio>
              <el-radio :label="1">1分</el-radio>
              <el-radio :label="2">2分</el-radio>
              <el-radio :label="3">3分</el-radio>
              <el-radio :label="4">4分</el-radio>
            </el-radio-group>
          </el-col>
        </el-col>

      </el-col>
 
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" v-if="currentStep === 8">
        <p>KOOS, JR.评分: {{ KOOStotal }}</p>
        <div class="grid-content bg-purple">本表格会询问一些关于您膝关节的问题，这些信息有助于我们评估您的膝关节功能。请在每个问
            题答案合适您觉得符合自身情况的选项处打勾，每题只能选一个答案。如果您对某个问题不确定，
            请尽量选择一个您认为最符合的选</div>
        <!-- 膝关节僵硬程度的问题和选项 -->
     
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="grid-content bg-secondary text-white"  style="margin-top: 10px;">膝关节僵硬程度</el-col> 
        <p>以下问题是关于最近一个星期内您对膝关节僵硬程度的感受。膝关节僵硬是指在活动膝关节的时候，您感受到活动受到限制或者是活动缓慢。</p>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="padding:0;">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="grid-content bg-purple" >{{ activitiesquestions[0].question }}</el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"  class="grid-content border">
            <el-radio-group v-model="KOOSactivitiesanswers[0]">
              <el-radio
                v-for="(option, optionIndex) in activitiesquestions[0].options"
                :key="'q1_option_' + optionIndex"
                :label="option.value"
                :disabled="isradioDisabled"
              >
                {{ option.label }}
              </el-radio>
            </el-radio-group>
          </el-col>
        </el-col>

        <!-- 膝关节疼痛的问题和选项 -->
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="grid-content bg-secondary text-white"  style="margin-top: 10px;">膝关节疼痛</el-col> 
        <p>最近一个星期，在进行以下活动时，您膝关节的疼痛达到何种程度?</p>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="padding:0;"  v-for="(activity, index) in activitiesquestions.slice(1, 5)" :key="'q2_option_' + index">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="grid-content bg-purple" >{{ activity.question }}</el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"  class="grid-content border">
            <el-radio-group v-model="KOOSactivitiesanswers[index + 1]">
              <el-radio
                v-for="(option, optionIndex) in activity.options"
                :key="'q2_option_' + index + '_option_' + optionIndex"
                :label="option.value"
                :disabled="isradioDisabled"
              >
                {{ option.label }}
              </el-radio>
            </el-radio-group>
          </el-col>
        </el-col>     

        <!-- 关节功能与日常生活的问题和选项 -->
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="grid-content bg-secondary text-white"  style="margin-top: 10px;">关节功能与日常生活</el-col> 
        <p>以下的问题将有助于了解您的身体情况。旨在评估您自身行动及自我照顾的能力。请指出在最近一星期内，由于膝关节的原因在进行以下活动时困难程度。</p>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="padding:0;"  v-for="(activity, index) in activitiesquestions.slice(5, 7)" :key="'q3_option_' + index">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="grid-content bg-purple" >{{ activity.question }}</el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"  class="grid-content border">
            <el-radio-group v-model="KOOSactivitiesanswers[index + 5]">
              <el-radio
                v-for="(option, optionIndex) in activity.options"
                :key="'q3_option_' + index + '_option_' + optionIndex"
                :label="option.value"
                :disabled="isradioDisabled"
              >
                {{ option.label }}
              </el-radio>
            </el-radio-group>
          </el-col>
        </el-col>     
      </el-col>
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" v-if="currentStep === 9">
        <p>SF-12 评分: {{ sf12total }}</p>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="padding:0; margin-top: 10px;"  v-for="(question, index) in sf12Questions" :key="question.id">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="grid-content bg-purple" >{{ question.question }}</el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"  class="grid-content border">
            <el-radio-group v-model="sf12Scores[question.id-1]" >
              <el-radio
                v-for="option in question.options"
                :key="option.value"
                :label="option.value"
                :disabled="isradioDisabled"
              >{{ option.label }}</el-radio>
            </el-radio-group>
          </el-col>
        </el-col>

        <!-- <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"><pre>{{ sf12Scores }}</pre></el-col> -->

      </el-col>

      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" v-if="currentStep === 10">
        <p>PROMIS Global-10评分: {{ promisScorestotal }}</p>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="padding:0; margin-top: 10px;"  v-for="(question, index) in promisQuestions" :key="question.id">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="grid-content bg-purple" >{{ question.question }}</el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"  class="grid-content border">
            <el-radio-group v-model="promisScores[index]" >
              <el-radio
                v-for="option in question.options"
                :key="option.value"
                :label="option.value"
                :disabled="isradioDisabled"
              >{{ option.label }}</el-radio>
            </el-radio-group>
          </el-col>
        </el-col>

     <!-- <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"><pre>{{ promisScores }}</pre></el-col> -->

      </el-col>
      <div style="text-align: center;">
      <div style="padding-top: 20px; display: inline-block;">
        <el-button v-if="currentStep > 1" class="prev-button" @click="prevStep">上一步</el-button>
        <el-button v-if="currentStep < totalSteps" type="primary" class="next-button" @click="nextStep">下一步</el-button>
        <el-button v-if="currentStep === totalSteps" class="submit-button" @click="dialogVisible = false">关闭</el-button>
      </div>
      </div>
    </el-dialog>
  </div>
</template>




<script>
import axios from 'axios';
export default {
  props: {
    scoreDialogVisible: Boolean,
    visible: Boolean,
    scoringId: {
      type: [Number, null],
     // 可根据需要更改是否需要该属性
    },
  },
  data() {
    return {
      iconSpacing: 10, // 初始图标间距
      isradioDisabled: true,
      isMobileScreen: false, // 根据屏幕宽度判断是否为移动设备
      scoringIdInternal: null,// 添加一个用于存储评分ID的属性
      dialogVisible: this.scoreDialogVisible,
      currentStep: 1, // 初始化当前步骤
      totalSteps:10 ,
      sliderValue: 0,
      selectedKnee: 0,
      AKS: [],
      AKStotal: 0,
      formData2: [],
      formData2total: 0,
     womac: {
      womacscores: [], // 存储每个问题的活动能力分数

      },
      KOOStotal: 0,
      sf12total: 0,
      promisScorestotal: 0,
      KOOSactivitiesanswers: [],

      marks: {
          0: '0',
          1: '1',
          2: '2',
          3: '3',
          4: '4',
          5: '5',
          6: '6',
          7: '7',
          8: '8',
          9: '9',
          10: '10',
      },
      painOptions: [
        { value: 50, text: '不疼 (50分)' },
        { value: 45, text: '轻度（偶尔） (45分)' },
        { value: 40, text: '轻度（上下楼时轻微疼痛） (40分)' },
        { value: 30, text: '轻度（平地行走时也轻微疼痛） (30分)' },
        { value: '20', text: '中度（偶尔） (20分)' },
        { value: '10', text: '中度（经常） (10分)' },
        { value: '0', text: '重度 (0分)' },
        // 其他选项类似
      ],
      stabilityFrontBackOptions: [
        { value: 10, text: '<5mm (10分)' },
        { value: 5, text: '<5～10mm (5分)' },
        { value: 0, text: '>10mm (0分)' },
        // 其他选项类似
      ],
      stabilityInOutOptions: [
        { value: 15, text: '<5° (15分)' },
        { value: 6, text: '6°～9° (6分)' },
        // 其他选项类似
      ],
      quFlexionOptions: [
        { value: -2, text: '5~10°' },
        { value: -5, text: '10~15°' },
        { value: -10, text: '16~20°' },
        { value: -15, text: '大于20°' },
      ],
      shenZhiOptions: [
        { value: -5, text: '小于10°' },
        { value: -10, text: '10~20°' },
        { value: -15, text: '大于20°' },
      ],
      jieduilineOptions: [
        { value: 0, text: '正常外翻5~10°' },
        { value: 1, text: '内翻0~4°' },
        { value: 2, text: '外翻0~4°' },
        { value: -20, text: '更严重内外翻' },
      ],
      walkAbilityOptions: [
        { value: 50, text: '无任何限制' },
        { value: 40, text: '约1千米以上' },
        { value: 30, text: '500-1000米' },
        { value: 20, text: '不到500米' },
        { value: 10, text: '仅能在室内活动' },
        { value: 0, text: '不能步行' },
      ],
      stairsAbilityOptions: [
        { value: 50, text: '正常上下楼梯' },
        { value: 40, text: '正常上楼，下楼梯需扶栏杆' },
        { value: 30, text: '上下楼梯均需扶栏杆' },
        { value: 15, text: '借助扶手能上楼，但不能下楼' },
        { value: 0, text: '完全不能上下楼梯' },
      ],
      deductionOptions: [
        { value: -5, text: '单手杖' },
        { value: -10, text: '双手杖' },
        { value: -20, text: '扶拐或助行器' },
        // 其他减分项的选项数据
      ],
      questions: [
        "在平地行走时",
        "上下楼梯时",
        "晚上在床上睡觉时",
        "坐着或者躺着时",
        "站立时",
        "在早晨刚醒时，膝关节僵硬程度如何",
        "白天，在坐、躺或休息后，关节僵硬程度如何",
        "下楼梯",
        "上楼梯",
        "从椅子上站起来时",
        "站立",
        "弯腰",
        "在平地行走",
        "上、下汽车",
        "逛街、购物",
        "穿鞋、袜",
        "起床",
        "脱鞋、袜",
        "上床躺下的时候",
        "进、出浴缸的时候",
        "坐着",
        "坐马桶或站起的时候",
        "干比较重的家务活",
        "干比较轻的家务活",
      ],
      selectedOptions: [],
      sf12Scores: [],
      promisScores:[],
      activitiesquestions: [
        {
          question: '1. 早晨当醒来时，您的膝关节僵硬有多严重?',
          selectedOption: null,
          options: [
            { label: '没有僵硬', value: 0 },
            { label: '轻微', value: 1 },
            { label: '中等', value: 2 },
            { label: '严重', value: 3 },
            { label: '非常严重', value: 4 },
          ],
        },
        {
          question: '2. 以膝关节为中心做旋转',
          selectedOption: null,
          options: [
            { label: '没有', value: 0 },
            { label: '轻微', value: 1 },
            { label: '中等', value: 2 },
            { label: '严重', value: 3 },
            { label: '非常严重', value: 4 },
          ],
        },
        {
          question: '3. 完全伸直膝关节',
          selectedOption: null,
          options: [
            { label: '没有', value: 0 },
            { label: '轻微', value: 1 },
            { label: '中等', value: 2 },
            { label: '严重', value: 3 },
            { label: '非常严重', value: 4 },
          ],
        },
        {
          question: '4. 上/下楼梯',
          selectedOption: null,
          options: [
            { label: '没有', value: 0 },
            { label: '轻微', value: 1 },
            { label: '中等', value: 2 },
            { label: '严重', value: 3 },
            { label: '非常严重', value: 4 },
          ],
        },
        {
          question: '5. 站立时',
          selectedOption: null,
          options: [
            { label: '没有困难', value: 0 },
            { label: '轻微困难', value: 1 },
            { label: '中等困难', value: 2 },
            { label: '非常困难', value: 3 },
            { label: '极其严重', value: 4 },
          ],
        },
        {
          question: '6. 坐椅子上起身',
          selectedOption: null,
          options: [
            { label: '没有困难', value: 0 },
            { label: '轻微困难', value: 1 },
            { label: '中等困难', value: 2 },
            { label: '非常困难', value: 3 },
            { label: '极其严重', value: 4 },
          ],
        },
        {
          question: '7. 下蹲从地上捡起东西',
          selectedOption: null,
          options: [
            { label: '没有困难', value: 0 },
            { label: '轻微困难', value: 1 },
            { label: '中等困难', value: 2 },
            { label: '非常困难', value: 3 },
            { label: '极其严重', value: 4 },
          ],
        },
      ],
    };
  },
  beforeMount() {
    // 在组件挂载前检查屏幕宽度
    this.checkMobileScreen();
    // 监听窗口大小变化，以便在屏幕尺寸改变时更新 isMobileScreen
    window.addEventListener("resize", this.checkMobileScreen);
  },
  beforeDestroy() {
    // 在组件销毁前移除窗口大小变化的监听器
    window.removeEventListener("resize", this.checkMobileScreen);
  },
  methods: {
    // 根据屏幕宽度设置 isMobileScreen
    checkMobileScreen() {
      const screenWidth = window.innerWidth;
      this.isMobileScreen = screenWidth < 768; // 768px 是一个示例断点，可以根据需要调整
    },
    handleCloseDialog() {
      this.$emit('close'); // 触发自定义的 close 事件
      //window.location.reload();
      this.$parent.fetchScoringData();
      this.currentStep = 1;
    },

    nextStep() {
      // 实现切换到下一步的逻辑
      this.currentStep++;
    },
    prevStep() {
       // 实现切换到上一步的逻辑
      this.currentStep--;
    },
    fetchScoringData() {
      // 根据 operatiom_id 构建动态请求 URL
      const requestUrl = `Scoring/getScoringInfo/${this.scoringIdInternal}`;
      
      // 发送 GET 请求到服务器
      axios
        .get(requestUrl)
        .then((response) => {
          // 根据需要处理服务器响应
          if (response.data.status === 1) {
             // 处理 vas2 字段
             if (response.data.data && typeof response.data.data.vas2 === 'object') {
                    const vas2Data = Object.values(response.data.data.vas2);
                    // 处理 sliderValue
                    const sliderValueData = vas2Data.find(item => item.hasOwnProperty('sliderValue'));
                    if (sliderValueData) {
                      this.sliderValue = parseInt(sliderValueData.sliderValue, 10);
                    }
                    // 处理 selectedKnee
                    const selectedKneeData = vas2Data.find(item => item.hasOwnProperty('radio'));
                    if (selectedKneeData) {
                      this.selectedKnee = selectedKneeData.radio === '左侧膝关节' ? 0 : 1;
                    }
                  }
            // 处理 AKS 字段
            if (response.data.data.aks && Array.isArray(response.data.data.aks[1])) {
                this.AKS = response.data.data.aks[1].map(value => {
                  const numericValue = parseInt(value, 10);
                  return isNaN(numericValue) ? 0 : numericValue; // 将 NaN 替换为 0，或者替换为其他默认值

                });
                console.log('AKS', this.AKS);
              } else {
                this.AKS = [];
              }

            // 处理 global 字段
            if (response.data.data.global && Array.isArray(response.data.data.global[1])) {
              this.formData2 = response.data.data.global[1].map(value => parseInt(value, 10));
              this.formData2total = response.data.data.global[0];
              console.log('global', response.data.data.global);
            } else {
              this.formData2 = [];
            }

            // 处理 womac 字段
            if (response.data.data.pain) {
              this.womac.womacscores = [
                ...response.data.data.pain[1].slice(0, 5).map(value => parseInt(value, 10)),
                ...response.data.data.stiffness[1].slice(0, 2).map(value => parseInt(value, 10)),
                ...response.data.data.difficulty[1].slice(0, 17).map(value => parseInt(value, 10))
              ];
             
            } else {
              this.womac.womacscores = [];
            }

            // 处理 KOOSactivitiesanswers 字段
            if (response.data.data.koos_jr) {
              this.KOOSactivitiesanswers = response.data.data.koos_jr[1].map(value => parseInt(value, 10));
              this.KOOStotal = response.data.data.koos_jr[0];
            } else {
              this.KOOSactivitiesanswers = [];
            }

            // 处理 sf12Scores 字段
            if (response.data.data.sf) {
              this.sf12Scores = response.data.data.sf[1].map(value => parseInt(value, 10));
              this.sf12total = response.data.data.sf[0];
            } else {
              this.sf12Scores = [];
            }

            // 处理 promisScores 字段
            if (response.data.data.promis) {
              this.promisScores = response.data.data.promis[1].map(value => parseInt(value, 10));
              this.promisScorestotal = response.data.data.promis[0];
            } else {
              this.promisScores = [];
            }
          } else {
            // 服务器返回错误消息，根据需要进行处理
            console.error('服务器返回错误：', response.data.message);
          }
        })
        .catch((error) => {
          // 处理请求错误，例如网络错误
          console.error('请求错误：', error);
        });
    },
    calculateSpacing() {
      const containerWidth = this.$el.offsetWidth; // 获取容器宽度
      const maxIconWidth = 35; // 图标最大宽度
      const minSpacing = 10; // 图标最小间距
      const maxSpacing = 80; // 图标最大间距

      // 计算可以容纳的最大图标数量
      const maxIcons = Math.floor((containerWidth + minSpacing) / (maxIconWidth + minSpacing));

      // 计算实际图标间距
      this.iconSpacing = (containerWidth - maxIconWidth * maxIcons) / (maxIcons - 1);

      // 限制间距不超过最大值
      if (this.iconSpacing > maxSpacing) {
        this.iconSpacing = maxSpacing;
      }
    },
  },
  computed: {
    dialogStyle() {
      // 检测屏幕宽度，小于某个值时全屏，大于某个值时水平居中
      const screenWidth = window.innerWidth;
      const breakpoint = 768; // 设置断点宽度，根据您的需要调整
      const style = {};

      if (screenWidth <= breakpoint) {
        style.maxWidth = '100%'; // 在小设备上全屏
        style.width = '100%';
      } else {
        style.maxWidth = '80%'; // 在大设备上水平居中并设置宽度
        style.width = '80%';
        style.margin = '0 auto';
      }
      return style;

    },
    sf12Questions() {
    // SF-12 问题列表
    return [
      {
        id:1,
        question: '1. 总体而言，您认为您的健康状况如何',
        scoreKey: 'overallHealth',
        options: [
          { label: '极好', value: 1 },
          { label: '非常好', value: 2 },
          { label: '好', value: 3 },
          { label: '还行', value: 4 },
          { label: '差', value: 5 },
        ],
      },
      {
        id:2,
        question: '2. 您觉得您的健康状况限制您进行适度活动诸如搬桌子，搞卫生，打保龄球或高尔夫球吗',
        scoreKey: 'limitationInModerateActivities',
        options: [
          { label: '很大限制', value: 1 },
          { label: '稍有限制', value: 2 },
          { label: '没有限制', value: 3 },
        ],
      },
      {
        id:3,
        question: '3. 您觉得您的健康状况限制您爬数层楼梯吗',
        scoreKey: 'limitationInClimbingStairs',
        options: [
          { label: '很大限制', value: 1 },
          { label: '稍有限制', value: 2 },
          { label: '没有限制', value: 3 },
        ],
      },
      {
        id:4,
        question: '4. 过去一周，您是否由于健康原因，想做的事不能完成',
        scoreKey: 'unableToDoActivities',
        options: [
          { label: '是', value: 1 },
          { label: '否', value: 2 },
        ],
      },
      {
        id:5,
        question: '5. 过去一周，您是否由于健康原因，某些工作或日常活动受到限制',
        scoreKey: 'limitationInWorkDueToEmotions',
        options: [
          { label: '是', value: 1 },
          { label: '否', value: 2 },
        ],
      },
      {
        id:6,
        question: '6. 过去一周，您是否由于情绪原因（郁闷或焦虑），想做的事不能完成',
        scoreKey: 'unableToDoWorkDueToEmotions',
        options: [
          { label: '是', value: 1 },
          { label: '否', value: 2 },
        ],
      },
      {
        id:7,
        question: '7. 过去一周，您是否由于情绪原因（郁闷或焦虑），不能像往常一样工作或做其他活动',
        scoreKey: 'painInterferesWithWork',
        options: [
          { label: '是', value: 1 },
          { label: '否', value: 2 },
        ],
      },
      {
        id:8,
        question: '8. 过去一周，疼痛在多大程度上妨碍你的正常工作（包括家外和家务劳动）',
        scoreKey: 'unableToDoSocialActivities',
        options: [
          { label: '一点也不', value: 1 },
          { label: '轻微', value: 2 },
          { label: '中度', value: 3 },
          { label: '很大程度', value: 4 },
          { label: '严重影响', value: 5 },
        ],
      },
      {
        id:9,
        question: '9. 过去一周，您有多少时间是心情平静',
        scoreKey: 'peacefulMoodDuration',
        options: [
          { label: '一直都是', value: 1 },
          { label: '大部分时间', value: 2 },
          { label: '较长时间', value: 3 },
          { label: '有些时间', value: 4 },
          { label: '偶尔', value: 5 },
          { label: '完全没有', value: 6 },
        ],
      },
      {id:10,
        question: '10. 过去一周，您有多少时间是精力充沛的',
        scoreKey: 'energeticDuration',
        options: [
          { label: '一直都是', value: 1 },
          { label: '大部分时间', value: 2 },
          { label: '较长时间', value: 3 },
          { label: '有些时间', value: 4 },
          { label: '偶尔', value: 5 },
          { label: '完全没有', value: 6 },
        ],
      },
      {
        id:11,
        question: '11. 过去一周，您有多少时间感到心情郁闷',
        scoreKey: 'depressedDuration',
        options: [
          { label: '一直都是', value: 1 },
          { label: '大部分时间', value: 2 },
          { label: '较长时间', value: 3 },
          { label: '有些时间', value: 4 },
          { label: '偶尔', value: 5 },
          { label: '完全没有', value: 6 },
        ],
      },
      {
        id:12,
        question: '12. 过去一周，由于健康状况或情绪问题，您有多长时间因此而导致日常社交活动（像探访亲友等）受到限制',
        scoreKey: 'socialActivitiesLimited',
        options: [
          { label: '一直都是', value: 1 },
          { label: '大部分时间', value: 2 },
          { label: '有些时间', value: 3 }, 
          { label: '偶尔', value: 4 },
          { label: '完全没有', value: 5 },
        ],
      },
    ];
  },
  promisQuestions() {
        return [
          {
            id: 1,
            question: "1. 总体来说，您觉得您的健康状况如何",
            scoreKey: "overallHealth",
            options: [
              { value: 5, label: "极好" },
              { value: 4, label: "非常好" },
              { value: 3, label: "好" },
              { value: 2, label: "还行" },
              { value: 1, label: "差" },
            ],
          },
          {
            id: 2,
            question: "2. 总体来说，您觉得您的生活质量如何",
            scoreKey: "overallQualityOfLife",
            options: [
              { value: 5, label: "极好" },
              { value: 4, label: "非常好" },
              { value: 3, label: "好" },
              { value: 2, label: "还行" },
              { value: 1, label: "差" },
            ],
          },
          {
            id:3 ,
            question: "3. 总体来说，您觉得您的身体健康状况如何",
            scoreKey: "physicalHealth",
            options: [
              { value: 5, label: "极好" },
              { value: 4, label: "非常好" },
              { value: 3, label: "好" },
              { value: 2, label: "还行" },
              { value: 1, label: "差" },
            ],
          },
          {
            id: 4,
            question: "4. 总体来说，您觉得您的心理健康状况如何？包括情绪、思考能力等。",
            scoreKey: "mentalHealth",
            options: [
              { value: 5, label: "极好" },
              { value: 4, label: "非常好" },
              { value: 3, label: "好" },
              { value: 2, label: "还行" },
              { value: 1, label: "差" },
            ],
          },
          {
            id:5,
            question: "5. 总体来说，您对您的社交活动以及亲友关系满意程度怎么样",
            scoreKey: "socialSatisfaction",
            options: [
              { value: 5, label: "极好" },
              { value: 4, label: "非常好" },
              { value: 3, label: "好" },
              { value: 2, label: "还行" },
              { value: 1, label: "差" },
            ],
          },
          {
            id:6,
            question: "9r. 总体来说，您对您开展日常社交活动以及履行社会关系角色如何评价（包括在家中或工作中作为父母、儿女、配偶、员工或是朋友等角色所进行的相关活动",
            scoreKey: "socialSatisfaction",
            options: [
              { value: 5, label: "极好" },
              { value: 4, label: "非常好" },
              { value: 3, label: "好" },
              { value: 2, label: "还行" },
              { value: 1, label: "差" },
            ],
          },
          {
            id:7,
            question: "6. 您的日常的体力活动如行走、爬楼梯、买菜或搬椅子等活动能否正常开展？",
            scoreKey: "physicalFunctioning",
            options: [
              { value: 5, label: "完全可以" },
              { value: 4, label: "大部分可以" },
              { value: 3, label: "部分可以" },
              { value: 2, label: "少部分可以" },
              { value: 1, label: "完全不能" },
            ],
          },
          {
            id:8,
          question: "10r. 您有多经常受自己的情绪（如焦虑、抑郁或易怒等）所影响？",
          scoreKey: "emotionalImpact",
          options: [
            { value: 5, label: "从不" },
            { value: 4, label: "极少" },
            { value: 3, label: "偶尔" },
            { value: 2, label: "经常" },
            { value: 1, label: "总是" },
          ],
        },
        {
          id:9,
          question: "8r.您是否感觉疲惫？",
          scoreKey: "fatigue",
          options: [
            { value: 5, label: "不觉得" },
            { value: 4, label: "轻度" },
            { value: 3, label: "中度" },
            { value: 2, label: "重度" },
            { value: 1, label: "极重度" },
          ],
        },
        {
          id:10,
          question: "7rc. 您的疼痛程度如何？",
          scoreKey: "pain",
          options: [
            { value: 0, label: "0" },
            { value: 1, label: "1" },
            { value: 2, label: "2" },
            { value: 3, label: "3" },
            { value: 4, label: "4" },
            { value: 5, label: "5" },
            { value: 6, label: "6" },
            { value: 7, label: "7" },
            { value: 8, label: "8" },
            { value: 9, label: "9" },
            { value: 10, label: "10" },
          ],
        },

                  
          // 添加其他问题
        ];
    },
    computedAKS() {
    // 使用防御性编程确保 AKS 数组有效且只包含数字
    if (!Array.isArray(this.AKS)) {
      return 0; // 如果不是有效数组，返回 0 或其他默认值
    }

    // 使用 Number() 函数将数组元素转为数字，并过滤掉非数字元素
    const numericValues = this.AKS.map((value) => {
      const numericValue = Number(value);
      console.log('Numeric Value:', numericValue);
      return numericValue;
    }).filter((value) => !isNaN(value));

    console.log('Numeric Values:', numericValues);

    // 计算总和
    return numericValues.reduce((total, value) => total + value, 0);
    },
    computedWomacTotal() {
  // 使用防御性编程确保 womac.womacscores 数组有效且只包含数字
  if (!Array.isArray(this.womac.womacscores)) {
    return 0; // 如果不是有效数组，返回 0 或其他默认值
  }

  // 使用 Number() 函数将数组元素转为数字，并过滤掉非数字元素
  const numericValues = this.womac.womacscores.map((value) => {
    const numericValue = Number(value);
    console.log('Numeric Value:', numericValue);
    return numericValue;
  }).filter((value) => !isNaN(value));

  console.log('Numeric Values:', numericValues);

  // 计算总和
  return numericValues.reduce((total, value) => total + value, 0);
},
    
  },
  created() {
  if (this.womac && this.womac.womacscores) {
    // 在确保 womac 和 womacscores 存在时再访问
    //const stiffnessLevel = this.womac.womacscores[5];
    //const stiffnessLevel1 = this.womac.womacscores[1];
    // 其他逻辑...
  }
  console.log( this.scoringIdInternal);
  this.scoringIdInternal = this.scoringId;
 
},
watch: {

  visible(newVal) {
      this.isVisible = newVal;
    },
    visible(newVal) {
      // 当父组件传递的 visible prop 发生变化时，更新内部的 dialogVisible
      this.dialogVisible = newVal;
    },
    KOOSactivitiesanswers: {
    handler(newVal) {
      console.log(newVal);
    },
    deep: true
  },
  scoringId: function (newScoringId) {
      // 当评分ID变化时执行的操作
      this.scoringIdInternal = newScoringId;
      this.fetchScoringData();
    },

  
},
mounted() {
// 在页面加载时调用fetchScoringData方法
if (this.visible && this.scoringId) {
      // 当组件可见并且有评分ID时执行数据获取操作
      this.fetchScoringData();
    }
    this.calculateSpacing(); // 计算图标间距
     window.addEventListener('resize', this.calculateSpacing); // 监听窗口大小变化

},


};
</script>
<style>
.container-wrapper {
  max-width: 100%;
  overflow-x: hidden;
  white-space: nowrap;
}

.icon-container {
  display: flex;
  align-items:flex-start ; 
  width: 100%;
}

.icon-item {
  flex: 0 0 calc(16.666% - 20px);
  max-width: calc(16.666% - 25px);
  margin-right: 30px;
  white-space: nowrap;
  text-align: center;
}

.first-item {
  margin-left: 0px; /* 第一个图标的右间距设为0，使其靠最边边 */
}

.slider-container {
  padding: 0px;
}
.dialog-footer {
  text-align: center; /* 按钮居中对齐 */
}

</style>