<template>
  <div class="login">
    <div class="container">
      <div class="name">关节置换登记</div>
      <el-form label-width="80px" label-position="right" ref="loginRef" :model="loginform" :rules="loginRules"  @keydown.enter.native="handleEnter">
        <el-form-item label="手机号" prop="username">
            <el-input
              v-model="loginform.username"
              autocomplete="on"
            />
        </el-form-item>
        <el-form-item label="密码" prop="password">
            <el-input
              type="password" v-model="loginform.password"/>
       
        </el-form-item>
        <el-form-item>
          <el-button type="primary" style="width: 100%" @click="login"
          >登录</el-button>
        </el-form-item>
      </el-form>
      <!-- <div class="logbtn">
        <el-button type="primary" style="width: 100%" @click="login"
          >登录</el-button>
      </div> -->
    </div>
  </div>
</template>
<script>
export default {
    name:"login",
  data() {
    return {
      loginform: {
        username: "",
        password: "",
      },
      loginRules: {
        username: [{ required: true, message: "请输入账号", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
    };
  },
  mounted(){
    this.crashtoken()
  },
  methods: {
    async crashtoken(){
      const {data:res} = await this.$http.get('/user/checkToken')
      if(res.status=='1'){
       this.$message.success("登录成功")
        this.$router.push('/mainview')
      }else{
        this.$message.error("请重新登录")
      }
    },
    handleEnter(event){
      event.preventDefault();  
      // 调用登录方法  
      this.login();  
    },
    login() {
      this.$refs.loginRef.validate(async (v) => {
        if (!v) return this.$message.error("账号信息不能为空");
        const { data: res } = await this.$http.post("/user/login", {
            "phone":this.loginform.username,
            "password":this.loginform.password
        });
        if(res.status == "1"){
            localStorage.setItem('token',res.data.token)
      this.$store.commit("changeselectview", '/homepag');

            this.$message.success("登录成功")
            this.$router.push('/mainview')
        }else{
            this.$message.error(res.message)
        }
      });
    },
  },
};
</script>

<style scoped>
.login {
  /* display: grid; */
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.name{
    margin-bottom: 15px;
}
/* .loginput{
    width:fit-content;
} */
.colinp {
  width:85%;
}
.logbtn {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.container {
  border-radius: 10px;
  box-sizing: border-box;
  padding: 10px 20px;
  border: 1px solid #e7e8e9;
  width: 450px;
  height: fit-content;
}
@media screen and (max-width: 480px) {
  .container {
    box-sizing: border-box;
    padding: 10px;
    border: 1px solid #e7e8e9;
    width: 90%;
    height: fit-content;
  }
  .colinp {
  width:75%;
}
}
</style>