import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import axios from 'axios';
//设置 关闭点击弹窗遮罩层关闭弹窗的操作（点击遮罩层不关闭弹窗）
ElementUI.Dialog.props.closeOnClickModal.default = false;
Vue.use(BootstrapVue)
Vue.use(ElementUI);
Vue.config.productionTip = false
//全局统一接口请求路径，若需要更改，进需要修改=号后面双引号内的内容
axios.defaults.baseURL = "https://api.jrr.kunbocs.cn"
axios.interceptors.request.use(req=>{
  req.headers.Authorization = localStorage.getItem('token')
  return req
})
axios.interceptors.response.use(function (response) {
  if(response.data.data=='422'){
    try{
      localStorage.clear()
        router.push('/').catch(err => {});
    }catch(err){
    }
  
  }
  return response;
  
});
Vue.prototype.$http = axios

new Vue({
  
  router,
  store,
  render: h => h(App)
}).$mount('#app')
