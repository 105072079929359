<!-- 步行能力测定 -->
<template>
  <div class="warlk">
    <el-dialog title="提示" :visible.sync="dialogVisible" :width="diawid">
      <!-- <el-progress percentage="100"></el-progress> -->
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4"
          ><div class="grid-content bg-purple">危险度评分</div></el-col
        >
        <el-col :xs="24" :sm="24" :md="20" :lg="20" :xl="20"
          ><div class="grid-content bg-purple-light">
            <el-checkbox-group
              v-model="checkvalue.checkboxvalue"
              :min="0"
              :max="1"
            >
              <el-checkbox
                v-for="(walk, index) in walking"
                :key="index"
                :label="walk.label"
                style="margin: 0 3px 0 3px !important ;"
              >
                {{ walk.label }}{{ walk.value }}
              </el-checkbox>
            </el-checkbox-group>
          </div></el-col
        >
        <el-col :xs="10" :sm="10" :md="4" :lg="4" :xl="4"
          ><div class="grid-content bg-purple">时间</div></el-col
        >
        <el-col :xs="14" :sm="1146" :md="20" :lg="20" :xl="20"
          ><div class="grid-content">
            <!-- <el-input type="number" v-model="checkvalue.tme" /> &nbsp;秒 -->
            <el-input type="number" v-model="checkvalue.tme">
              <template slot="append">秒</template>
            </el-input>
          </div></el-col
        >
        <el-col :xs="10" :sm="10" :md="6" :lg="6" :xl="6"
          ><div class="grid-content bg-purple">2-min walk test</div></el-col
        >
        <el-col :xs="14" :sm="14" :md="18" :lg="18" :xl="18"
          ><div class="grid-content">
            <!-- <el-input type="number" v-model="checkvalue.walk" /> &nbsp;米 -->
            <el-input type="number" v-model="checkvalue.walk">
              <template slot="append">米</template>
            </el-input>

          </div></el-col
        >

      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirm">确 定</el-button>
      </span>
    </el-dialog>
    <div class="dialog" v-show="fillin">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4"
          ><div class="grid-content bg-purple">危险度评分</div></el-col
        >
        <el-col :xs="24" :sm="24" :md="20" :lg="20" :xl="20"
          ><div class="grid-content bg-purple-light" style="justify-content: flex-start !important;">
            <el-checkbox-group
              v-model="checkvalue.checkboxvalue"
              :min="0"
              :max="1"
            >
              <el-checkbox
                v-for="(walk, index) in walking"
                :key="index"
                :label="walk.label"
                :disabled="modify"
                style="margin: 0 3px 0 3px !important;"
              >
                {{ walk.label }}{{ walk.value }}
              </el-checkbox>
            </el-checkbox-group>
          </div></el-col
        >
        <el-col :xs="6" :sm="6" :md="4" :lg="4" :xl="4"
          ><div class="grid-content bg-purple">时间</div></el-col
        >
        <el-col :xs="18" :sm="18" :md="7" :lg="20" :xl="20"
          ><div class="grid-content">
            <!-- <el-input
              type="number"
              v-model="checkvalue.tme"
              :disabled="modify"
            />
            &nbsp;秒 -->

            <el-input  :disabled="modify" type="number" v-model="checkvalue.tme">
              <template slot="append">秒</template>
            </el-input>

          </div></el-col
        >
        <el-col :xs="10" :sm="10" :md="6" :lg="4" :xl="4"
          ><div class="grid-content bg-purple">2-min walk test</div></el-col
        >
        <el-col :xs="14" :sm="14" :md="7" :lg="20" :xl="20"
          ><div class="grid-content">
            <!-- <el-input
              type="number"
              v-model="checkvalue.walk"
              :disabled="modify"
            />
            &nbsp;米 -->

            <el-input type="number"  :disabled="modify" v-model="checkvalue.walk">
              <template  slot="append">米</template>
            </el-input>

          </div></el-col
        >
      </el-row>
    </div>
    <div class="modify" v-show="fillin">
      <el-button type="primary" icon="el-icon-edit" circle @click="tomodify" v-show="modify"></el-button>
      <!-- <el-button type="warning" @click="tomodify" v-show="modify"
        >修改</el-button> -->

      <div v-show="!modify" >
        <el-row>
          <el-button type="success" icon="el-icon-check" circle @click="confirm"></el-button>
        </el-row>
        <el-row>
          <el-button type="danger" icon="el-icon-close" circle @click="cancel"></el-button>
        </el-row>

      </div>

      <!-- <div class="aamodify" v-show="!modify">
        <el-button type="success" @click="confirm">确定</el-button
        ><el-button type="danger" @click="cancel">取消</el-button>
      </div> -->

    </div>
    <div class="tofillin" v-show="!fillin">
      <el-button type="primary" @click="tofillin">去填写</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogwid:"",
      walking: [
        { label: "1", value: "分" },
        { label: "2", value: "分" },
        { label: "3", value: "分" },
        { label: "4", value: "分" },
        { label: "5", value: "分" },
      ],
      checkvalue: {
        checkboxvalue: [],
        tme: "",
        walk: "",
      },
      modify: true,
      fillin: false,
      diawid: "60%",
      dialogVisible: false,
    };
  },
  created() {
    this.operatiom_id = this.$route.query.room;
  },
  mounted() {
    this.getinfo();
    let wid = window.innerWidth
    // console.log(wid)
      this.diawid = wid <=480 ? "90%":wid>=1200 ? "40%":"80%"
  },
  methods: {
    tofillin() {
      this.dialogVisible = true;
    },
    async getinfo() {
      try {
        const { data: res } = await this.$http.get(
          "/Operation/getPreOperationInfo/" + this.operatiom_id,
          {
            params: {
              pre_type: "步行能力评定",
            },
          }
        );
        if (res.status == "1") {
          if (res.data.flm != null) {
            this.fillin = true;
            this.checkvalue.checkboxvalue = res.data.flm.checkboxvalue;
            this.checkvalue.tme = res.data.flm.tme;
            this.checkvalue.walk = res.data.flm.walk;
          }
          // console.log(res);
        }
      } catch (err) {}
      // console.log(res);
    },
    async confirm() {
      this.dialogVisible = false;
      const { data: res } = await this.$http.post(
        "/Operation/addPreOperationInfo/" + this.operatiom_id,
        {
          flm: this.checkvalue,
        }
      );
      if (res.status == "1") {
        this.$message.success("提交成功");
        this.getinfo();
      }
    },
    // 确定提交
    async confirm() {
      this.modify = true;
      this.dialogVisible = false;
      const { data: res } = await this.$http.post(
        "/Operation/addPreOperationInfo/" + this.operatiom_id,
        {
          flm: this.checkvalue,
        }
      );
      if (res.status == "1") {
        this.$message.success("提交成功");
        this.getinfo();
      }
    },
    // 取消
    cancel() {
      this.modify = true;
    },
    // 去修改
    tomodify() {
      this.modify = false;
    },
  },
};
</script>

<style  scoped>
.warlk {
  /* position: relative ; */
  width: 100%;
  height: fit-content;
  box-sizing: border-box;
  padding: 10px 5px;
}
.bg-purple-light {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  padding: 0px 10px;
}
.el-col {
  margin-bottom: 10px;
}
.modify {
        position: absolute;
        top: 60%;
        right: 15%;
       
      }
      .aamodify{
         display: flex;
        flex-direction: column;
      }
      .aamodify .el-button{
        margin-left:0px;
        margin-bottom: 5px;

      }
@media screen and (max-width: 600px) {
  .modify {
    top: 75%;
    right: 5%;
 
}
}
</style>