<template>
  <div class="PersonalHistory">
    <el-dialog :title="'请填写'+dialogTitle[next-1]" :visible.sync="dialogVisible" v-if="dialogVisible" :width="diawid">
      <el-progress :percentage="percentage"></el-progress>
      <common-person :operateForm="operateForm" :next="next" :gender="gender"> </common-person>
      <span slot="footer" class="dialog-footer">
        <el-button @click="backlist" type="warning">上一步</el-button>
        <el-button @click="nextlist" type="primary" v-show="nextBtn"
          >下一步</el-button
        >
        <el-button type="danger" @click="closedialog" v-show="this.next == 5"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <common-person
      :operateForm="operateForm"
      :showAll="showAll"
      :modify="modify"
      :gender="gender"
      v-else
    >
    </common-person>

    <el-button type="primary" @click="goFillin" v-show="showBtn">去填写</el-button>
    <div class="modifyBtn" v-show="!showBtn">
      <el-button type="primary" icon="el-icon-edit" circle @click="toModify" v-show="modify"></el-button>
      <!-- <el-button type="warning" @click="toModify" v-show="modify"
        >修改</el-button
      > -->
      <div v-show="!modify" >
        <el-row>
          <el-button type="success" icon="el-icon-check" circle @click="sure"></el-button>
        </el-row>
        <el-row>
          <el-button type="danger" icon="el-icon-close" circle @click="cancel"></el-button>
        </el-row>
      </div>

      <!-- <el-button type="success" @click="sure" v-show="!modify">确定</el-button>
      <el-button type="danger" @click="cancel" v-show="!modify">取消</el-button> -->
    </div>
  </div>
</template>
  
  <script>
import CommonPerson from "./CommonPerson.vue";
export default {
  name: "personal-history",
  components: {
    CommonPerson,
  },
  data() {
    return {
      patient_gender:"",
      dialogTitle:['吸烟史','嗜酒史','输血史','食物、药物过敏史','月经史'],
      operation_id: "",
      next: 1,
      modify: true,
      dialogVisible: false,
      percentage: 20,
      showBtn: true,
      nextBtn: true,
      showAll: false,
      diawid: "90%",
      formData: [
        {
          label: "吸烟史",
          type: "radio",
          model: "smoking_history",
          radioList: [
            { label: 1, value: "无" },
            { label: 2, value: "已戒烟" },
            { label: 3, value: "偶尔" },
            { label: 4, value: "经常" },
          ],
        },
        {
          label: "嗜酒史",
          type: "radio",
          model: "",
          radioList: [
            { label: 1, value: "无" },
            { label: 2, value: "偶尔喝酒" },
            { label: 3, value: "经常喝酒" },
          ],
        },
      ],
      operateForm: {
        smoking_history: {
          //吸烟史
          whether: '',
          cessation: "",
          ingredient: "",
          datal: "",
          amount: "",
        },
        alcohol_history: {
          //嗜酒史
          whether: '',
          cessation: "",
          ingredient: {
            opt: "",
            riceWine: "",
            brandRiceWine: "",
            otherWine: "",
          },
          datal: "",
          amount: "",
          unit: "",
        },
        transfusion_history: {
          //输血史
          whether: '',
          reason: "",
          ingredient: {
            opt: "",
            other: "",
          },
          datal: "",
          capacity: "",
        },
        allergic_history: {
          //过敏史
          whether: '',
          allergy_source: "",
        },
        menstrual_history: {
          //月经史
          age: "",
          whether: '',
          quantity: "",
          lmp: "",
        },
      },
    };
  },
  created() {
    this.operation_id = this.$route.query.room;
    this.gender = this.$route.query.gender
    this.GetPersonalHistoryInfo();
  },
  mounted(){
    let wid = window.innerWidth
    this.diawid = wid <=480 ? '90%':wid >=1200 ? "60%" : '80%'
  },
  methods: {
    // 获取表单请求
    async GetPersonalHistoryInfo() {
      try {
        const { data: res } = await this.$http.get(
          "/Operation/getPreOperationInfo/" + this.operation_id,
          {
            params: { pre_type: "个人史" },
          }
        );
        if (res.status == 0) {
          this.showBtn = true
        }else
        if (res.status == 1) {
          let value = res.data;
            for(let key in value){
              this.operateForm[key] = value[key] ? value[key] : this.operateForm[key]
          }
          // this.operateForm = res.data
          this.showAll = true;
          this.showBtn = !this.showBtn;
          this.loading = false
        }
        // console.log(res, "个人史请求数据");
      } catch (error) {
        // console.log(error);
      }
    },

    // 更改表单请求
    async PostPersonalHistoryInfo() {
      try {
        const { data: res } = await this.$http.post(
          "/Operation/addPreOperationInfo/" + this.operation_id,
          this.operateForm
        );
        if (res.status == 1) {
          this.$message.success("修改成功")

          // console.log(res, "写入成功");
        }
      } catch (error) {
        alert(error.message)
      }
    },
    goFillin() {
      this.dialogVisible = true;
    },
    toModify() {
      this.modify = !this.modify;
    },
    // 点击确定修改
    sure() {
      this.modify = !this.modify;
      this.PostPersonalHistoryInfo();
    },
    // 点击取消修改
    cancel() {
      this.modify = !this.modify;
    },
    // 点击填写下一步
    nextlist() {
      this.next = this.next < 5 ? this.next + 1 : 5;
      if (this.next < 5) {
        this.percentage += 20;
      } else {
        this.percentage = 100;
        this.nextBtn = false;
      }
    },
    // 上一步
    backlist() {
      this.next = this.next - 1 <= 1 ? 1 : this.next - 1;
      this.percentage = this.percentage > 20 ? this.percentage - 20 : 20;
      this.nextBtn = this.next > 4 ? false : true;
    },
    // dialog确定
    closedialog() {
      this.PostPersonalHistoryInfo()
      this.dialogVisible = false;
      this.showBtn = false;
      this.showAll = true;
    },
  },
};
</script>
  
  <style scoped>
  .PersonalHistory .modifyBtn{
    position: absolute;
    right: 15%;
    top: 60%;
    display: flex;
    flex-direction: column;
  }
  @media screen and (max-width: 480px) {
  .PersonalHistory .modifyBtn{
    right:5%;
    top: 75%;
  }
}
</style>