<!-- 机器人手术表格 -->
<template>
    <div class="robottable">
      <el-table
        :data="tableData"
        :header-cell-style="{ textAlign: 'center' }"
        :cell-style="{ 'text-align': 'center' }"
        style="width: 100%"
      >
        <el-table-column prop="title" label="软骨/韧带损伤程度评估" width="200">
        </el-table-column>
        <el-table-column label="股骨远端">
          <el-table-column prop="input1" label="内髁">
            <template slot-scope="scope">
              <el-input :disabled="othermodify"  v-model="scope.row.input1" />
            </template>
          </el-table-column>
          <el-table-column prop="input2" label="外髁">
            <template slot-scope="scope">
              <el-input :disabled="othermodify"  v-model="scope.row.input2"></el-input>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column label="股骨后髁">
          <el-table-column prop="input5" label="内侧">
            <template slot-scope="scope">
              <el-input :disabled="othermodify"  v-model="scope.row.input3"></el-input> </template
          ></el-table-column>
          <el-table-column prop="input6" label="外侧"
            ><template slot-scope="scope">
              <el-input :disabled="othermodify"  v-model="scope.row.input4"></el-input>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column label="股骨滑车">
          <el-table-column prop="input2" label="内侧">
            <template slot-scope="scope">
              <el-input :disabled="othermodify"  v-model="scope.row.input5"></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="input4" label="外侧">
            <template slot-scope="scope">
              <el-input :disabled="othermodify"  v-model="scope.row.input6"></el-input>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column label="胫骨平台">
       
          <el-table-column prop="input7" label="内侧"
            ><template slot-scope="scope">
              <el-input :disabled="othermodify"  v-model="scope.row.input7"></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="input8" label="外侧">
            <template slot-scope="scope">
              <el-input :disabled="othermodify"  v-model="scope.row.input8"></el-input> </template
          ></el-table-column>
          <el-table-column prop="input9" label="内侧脊">
            <template slot-scope="scope">
              <el-input :disabled="othermodify"  v-model="scope.row.input9"></el-input> </template
          ></el-table-column>
          <el-table-column prop="input10" label="外侧脊">
            <template slot-scope="scope">
              <el-input :disabled="othermodify"  v-model="scope.row.input10"></el-input> </template
          ></el-table-column>
        </el-table-column>
      </el-table>
    </div>
  </template>
    
    <script>
  export default {
    props:["message","othermodify" ],
    data() {
      return {
        tableData: [
          {
            title: "初始规划",
            input1: '',
            input2: '',
            input2: '',
            input4: '',
            input5: '',
            input6: '',
            input7: '',
            input8: '',
            input9: '',
            input10: '',
          },
          {
            title: "术中调整后",
            input1: '',
            input2: '',
            input2: '',
            input4: '',
            input5: '',
            input6: '',
            input7: '',
            input8: '',
            input9: '',
            input10: '',
          },
          {
            title: "实际截骨",
            input1: '',
            input2: '',
            input2: '',
            input4: '',
            input5: '',
            input6: '',
            input7: '',
            input8: '',
            input9: '',
            input10: '',
          },
          {
            title: "残留截骨",
            input1: '',
            input2: '',
            input2: '',
            input4: '',
            input5: '',
            input6: '',
            input7: '',
            input8: '',
            input9: '',
            input10: '',
          },
        ],
      };
    },
    watch: {
      message:{
        handler(newvalue,oldvcalue){
          for(let i =0;i<newvalue.length;i++){
          let obj  = newvalue[i]
          // console.log(obj,'obj')
          for(let key in obj){
            // console.log(obj[key],'objkey')
            this.tableData[i][key]=obj[key]
          }
        }
        },
        deep:true
      },
      tableData: {
        handler(newvalue, oldvalue) {
          this.$emit('robotictable',newvalue)
          // console.log(newvalue);
        },
        deep: true,
      },
    },
  };
  </script>