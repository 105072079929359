<!-- SF-12 评分 -->
<template>
  <div class="SF">
    <el-row>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"><div class="grid-content bg-purple">SF-12 评分</div></el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" v-for="(item,index) in option" :key="index"><div class="grid-content">
            <el-row>
                 <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"><div class="grid-content bg-purple-light">{{ item.name }}</div></el-col>
                 <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"><div class="grid-content">
                    <el-radio-group v-model="radiovalue[index].value">
                        <el-radio v-for="(label,index2) in item.value" :key="index2" :label="index2+1">{{label}}</el-radio>
                    </el-radio-group>
                    </div>
                </el-col>
            </el-row>
        </div></el-col>

    </el-row>
  </div>
</template>

<script>
export default {
data(){
    return{
        radiovalue:[{value:''},{value:''},{value:''},{value:''},{value:''},{value:''},{value:''},{value:''},{value:''},{value:''},{value:''},{value:''}],
        option:[
            {
                name:'1. 总体而言，您认为您的健康状况如何',
                value:["极好 1 分","非常好 2 分","好 3 分","还行 4 分","差 5 分"]
            },
            {
                name:'2. 您觉得您的健康状况限制您进行适度活动诸如搬桌子，搞卫生，打保龄球或高尔夫球吗',
                value:["很大限制 1 分","稍有限制 2 分","没有限制 3 分"]
            },
            {
                name:'3. 您觉得您的健康状况限制您爬数层楼梯吗',
                value:["很大限制 1 分","稍有限制 2 分","没有限制 3 分"]
            },
            {
                name:'4. 过去一周，您是否由于健康原因，想做的事不能完成',
                value:["是","否"]
            },
            {
                name:'5. 过去一周，您是否由于健康原因，某些工作或日常活 动受到限制',
                value:["是","否"]
            },
            {
                name:'6. 过去一周，您是否由于情绪原因（郁闷或焦虑），想 做的事不能完成',
                value:["是","否"]
            },
            {
                name:'7. 过去一周，您是否由于情绪原因（郁闷或焦虑），不 能像往常一样工作或做其他活动',
                value:["是","否"]
            },
            {
                name:'8. 过去一周，疼痛在多大程度上妨碍你的正常工作（包 括家外和家务劳动）',
                value:["一点也不 1 分","轻微 2 分","中度 3 分","很大程度 4 分","严重影响 5 分"]
            },
            {
                name:'9. 过去一周，您有多少时间是心情平静',
                value:["一直都是 1 分","大部分时间 2 分","较长时间 3 分","有些时间 4 分","偶尔 5 分","完全没有 6分"]
            },
            {
                name:'10. 过去一周，您有多少时间是精力充沛的',
                value:["一直都是 1 分","大部分时间 2 分","较长时间 3 分","有些时间 4 分","偶尔 5 分","完全没有 6分"]
            },
            {
                name:'11. 过去一周，您有多少时间感到心情郁闷',                
                value:["一直都是 1 分","大部分时间 2 分","较长时间 3 分","有些时间 4 分","偶尔 5 分","完全没有 6分"]

            },
            {
                name:'12. 过去一周，由于健康状况或情绪问题，您有多长时间 因此而导致日常社交活动（像探访亲友等）受到限制',                
                value:["一直都是 1 分","大部分时间 2 分","较长时间 3 分","有些时间 4 分","偶尔 5 分","完全没有 6分"]

            },
        ]
    }
},
props:["message"],
watch:{
    radiovalue:{
        handler(newvalue,oldvalue){
            let sum=0
                let radioarr=[]
                let sumarr=[]
                for(let i=0;i<newvalue.length;i++){
                    let obj = newvalue[i]
                    if(obj.value!=''){
                         sum = sum + obj.value
                        radioarr.push(obj.value)
                    }else{
                        sum = sum + 0
                        radioarr.push(obj.value)
                    }
                }
                sumarr.push(sum),
                sumarr.push(radioarr)
               
            this.$emit("sf12emit",sumarr)
        },deep:true
    },
    message(newvalue){
        for(let i=0;i<newvalue.length;i++){
            this.radiovalue[i].value = parseInt(newvalue[i])
        }
    }
}
}
</script>

<style scoped>
.SF .el-col{
margin-top: 5px;
}
</style>