  // 家族史数据处理
  export function family(value){
    let arr=[]
    for(let i=0;i<value.length;i++){
      let obj={},fam1=value[i]
      obj[i]=fam1.radiovalue,obj[i+1]=fam1.inputvalue
      arr.push(obj)
    }
    return JSON.stringify(arr);
  }
// 专科体查
  export function phy(value){
    let arr=[]
    for(let key in value){
      arr.push(value[key])
    }
   return arr
  }
  export function getDate(){
    let myDate = new Date()
    let y = myDate.getFullYear()
    let month = myDate.getMonth()+1
    let day = myDate.getDate()
    let m = month < 10 ? '0'+month:month
    let d = day < 10 ? '0'+day:day
    let str = y.toString()
    let res = str.slice(-2) + m + d
    return res
  }

  export function toinfomation(values){
    let value = values
    let valuename=["patients_name","id_card","phone","native","gender","marital_status","work_unit", "occupation", "nation","birth_date","stature", "weight","birth_weather", "birth_seasons","home_address"]
    let arr=[]
    for(let i=0;i<valuename.length;i++){
    for(let key in value){
      if(key == valuename[i]){
        arr.push(value[key])
      }
    }
  }
   return arr
  }
  export function debounce(fn, wait) {
    let timeout = null;
    wait = wait || 600;
    return function () {
      let that = this;
      if(timeout !== null)   clearTimeout(timeout);  
      timeout = setTimeout(() => {
        fn.apply(that);
      }, wait);
    }    
}
  