<template>
  <div class="pdasm">
    <el-dialog
      title="术前诊断及手术方式"
      :visible.sync="dialogVisible"
      width="60%"
     >
     <div>
      <el-progress :percentage="curpercent"></el-progress>
      <el-row>
        <!-- 术前诊断 -->
        <el-col :xs="24" :sm="24" :md="2" :lg="2" :xl="2" v-show="next==1"><div class="grid-content bg-purple-light">术前诊断</div></el-col>
        <el-col :xs="24" :sm="24" :md="22" :lg="22" :xl="22" v-show="next==1"><div class="grid-content">
          <el-checkbox-group 
            v-model="value[0].checkbox"
           >
            <el-checkbox v-for="city in preoperative" :label="city" :key="city">{{city}}</el-checkbox>
          </el-checkbox-group>
          </div>
        </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"  v-show="value[0].checkbox.includes('假体断裂') && next==1"><div class="grid-content">
            <el-checkbox-group 
            v-model="value[0].checkbox2"
            :disabled="edit"
           >
            <el-checkbox  label="髋臼">髋臼</el-checkbox>
            <el-checkbox  label="股骨">股骨</el-checkbox>
          </el-checkbox-group>
           
            </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" v-show="value[0].checkbox.includes('其他')  && next==1"><div class="grid-content bg-purple">
            <el-input v-model="value[0].inputval">
            <template slot="prepend">其他</template>
            </el-input>
          </div></el-col>
        <el-col :xs="24" :sm="24" :md="2" :lg="2" :xl="2" v-show="next==2"><div class="grid-content bg-purple-light">手术方式</div></el-col>
        <el-col :xs="24" :sm="24" :md="22" :lg="22" :xl="22" v-show="next==2"><div class="grid-content">
          <el-checkbox-group 
            v-model="value[1].checkbox"
           >
            <el-checkbox v-for="city in operation" :label="city" :key="city">{{city}}</el-checkbox>
          </el-checkbox-group>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"  v-show="value[1].checkbox.includes('部分翻修') && next==2"><div class="grid-content">
          <el-checkbox-group 
            v-model="value[1].checkbox2"
            :disabled="edit"
           >
            <el-checkbox  label="髋臼">髋臼</el-checkbox>
            <el-checkbox  label="股骨头">股骨头</el-checkbox>
            <el-checkbox  label="内衬">内衬</el-checkbox>
            <el-checkbox  label="股骨柄">股骨柄</el-checkbox>
          </el-checkbox-group>
            </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" v-show="value[1].checkbox.includes('其他') && next==2"><div class="grid-content bg-purple">
            <el-input v-model="value[1].inputval">
            <template slot="prepend">其他</template>
            </el-input>
          </div></el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"  v-show="next==3"><div class="grid-content bg-purple">FNF分型</div></el-col>
        <el-col :xs="24" :sm="24" :md="2" :lg="2" :xl="2"  v-show="next==3"><div class="grid-content bg-purple-light">Garden</div></el-col>
        <el-col :xs="24" :sm="24" :md="22" :lg="22" :xl="22"  v-show="next==3"><div class="grid-content">
          <el-radio-group v-model="value[2].radio">
            <el-radio  v-for="city in garden" :label="city" :key="city">{{city}}</el-radio>
            </el-radio-group>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="2" :lg="2" :xl="2"  v-show="next==4"><div class="grid-content bg-purple-light">解剖部位</div></el-col>
        <el-col :xs="24" :sm="24" :md="10" :lg="10" :xl="10"  v-show="next==4"><div class="grid-content">
          <el-radio-group v-model="value[3].radio">
            <el-radio  v-for="city in Anatomical" :label="city" :key="city">{{city}}</el-radio>
            </el-radio-group>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="2" :lg="2" :xl="2"  v-show="next==4"><div class="grid-content bg-purple-light">Puawels</div></el-col>
        <el-col :xs="24" :sm="24" :md="10" :lg="10" :xl="10"  v-show="next==4"><div class="grid-content">
          <el-radio-group v-model="value[4].radio">
            <el-radio  v-for="city in Puawels" :label="city" :key="city">{{city}}</el-radio>
            </el-radio-group>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8"  v-show="next==5"><div class="grid-content bg-purple-light">假体周围骨折 VaVanancouver 分型</div></el-col>
        <el-col :xs="24" :sm="24" :md="16" :lg="16" :xl="16"  v-show="next==5"><div class="grid-content">
          <el-radio-group v-model="value[5].radio">
            <el-radio  v-for="city in VaVanancouver" :label="city" :key="city">{{city}}</el-radio>
            </el-radio-group>
        </div></el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"  v-show="next==6"><div class="grid-content bg-purple">骨溶解 Paprosky ky 分型</div></el-col>
        <el-col :xs="24" :sm="24" :md="2" :lg="2" :xl="2"  v-show="next==6"><div class="grid-content bg-purple-light">股骨侧</div></el-col>
        <el-col :xs="24" :sm="24" :md="22" :lg="22" :xl="22"  v-show="next==6"><div class="grid-content">
          <el-radio-group v-model="value[6].radio">
            <el-radio  v-for="city in femur" :label="city" :key="city">{{city}}</el-radio>
            </el-radio-group>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="2" :lg="2" :xl="2"  v-show="next==7"><div class="grid-content bg-purple-light">髋臼侧</div></el-col>
        <el-col :xs="24" :sm="24" :md="22" :lg="22" :xl="22"  v-show="next==7"><div class="grid-content">
          <el-radio-group v-model="value[7].radio">
            <el-radio  v-for="city in acetabulum" :label="city" :key="city">{{city}}</el-radio>
            </el-radio-group>
          </div>
        </el-col>
      </el-row>
     </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="warning" @click="tolast" :disabled="next==1">上一步</el-button>
        <el-button type="primary" @click="tonext" :disabled="next==7">下一步</el-button>
        <el-button type="danger" @click="dialogVisible=false">取 消</el-button>
        <el-button type="success" @click="tosubmit">确 定</el-button>
      </span>
    </el-dialog>
    <div class="buttondiv" v-show="show">
      <el-button type="primary"  @click="tofillin">去填写</el-button>
    </div>
    <div class="container" v-show="!show">
      <el-row>
        <!-- 术前诊断 -->
        <el-col :xs="24" :sm="24" :md="2" :lg="2" :xl="2"><div class="grid-content bg-purple-light">术前诊断</div></el-col>
        <el-col :xs="24" :sm="24" :md="22" :lg="22" :xl="22"><div class="grid-content">
          <el-checkbox-group 
            v-model="value[0].checkbox" 
            :disabled="edit"
           >
            <el-checkbox v-for="city in preoperative" :label="city" :key="city">{{city}}</el-checkbox>
          </el-checkbox-group>
          </div>
        </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"  v-show="value[0].checkbox.includes('假体断裂')"><div class="grid-content">
            <el-checkbox-group 
            v-model="value[0].checkbox2"
            :disabled="edit"
           >
            <el-checkbox  label="髋臼">髋臼</el-checkbox>
            <el-checkbox  label="股骨">股骨</el-checkbox>
          </el-checkbox-group>
            </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" v-show="value[0].checkbox.includes('其他')"><div class="grid-content bg-purple">
            <el-input   :disabled="edit" v-model="value[0].inputval">
            <template slot="prepend">其他</template>
            </el-input>
          </div></el-col>
        <el-col :xs="24" :sm="24" :md="2" :lg="2" :xl="2"><div class="grid-content bg-purple-light">手术方式</div></el-col>
        <el-col :xs="24" :sm="24" :md="22" :lg="22" :xl="22"><div class="grid-content">
          <el-checkbox-group 
            v-model="value[1].checkbox"
            :disabled="edit"
           >
            <el-checkbox v-for="city in operation" :label="city" :key="city">{{city}}</el-checkbox>
          </el-checkbox-group>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"  v-show="value[1].checkbox.includes('部分翻修')"><div class="grid-content">
          <el-checkbox-group 
            v-model="value[1].checkbox2"
            :disabled="edit"
           >
            <el-checkbox  label="髋臼">髋臼</el-checkbox>
            <el-checkbox  label="股骨头">股骨头</el-checkbox>
            <el-checkbox  label="内衬">内衬</el-checkbox>
            <el-checkbox  label="股骨柄">股骨柄</el-checkbox>
          </el-checkbox-group>
            </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" v-show="value[1].checkbox.includes('其他')"><div class="grid-content bg-purple">
            <el-input  :disabled="edit" v-model="value[1].inputval">
            <template slot="prepend">其他</template>
            </el-input>
          </div></el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"><div class="grid-content bg-purple">FNF分型</div></el-col>
        <el-col :xs="24" :sm="24" :md="2" :lg="2" :xl="2"><div class="grid-content bg-purple-light">Garden</div></el-col>
        <el-col :xs="24" :sm="24" :md="22" :lg="22" :xl="22"><div class="grid-content">
          <el-radio-group v-model="value[2].radio"  :disabled="edit">
            <el-radio  v-for="city in garden" :label="city" :key="city">{{city}}</el-radio>
            </el-radio-group>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="2" :lg="2" :xl="2"><div class="grid-content bg-purple-light">解剖部位</div></el-col>
        <el-col :xs="24" :sm="24" :md="10" :lg="10" :xl="10"><div class="grid-content">
          <el-radio-group v-model="value[3].radio"  :disabled="edit">
            <el-radio  v-for="city in Anatomical" :label="city" :key="city">{{city}}</el-radio>
            </el-radio-group>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="2" :lg="2" :xl="2"><div class="grid-content bg-purple-light">Puawels</div></el-col>
        <el-col :xs="24" :sm="24" :md="10" :lg="10" :xl="10"><div class="grid-content">
          <el-radio-group v-model="value[4].radio"  :disabled="edit">
            <el-radio  v-for="city in Puawels" :label="city" :key="city">{{city}}</el-radio>
            </el-radio-group>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8"><div class="grid-content bg-purple-light">假体周围骨折 VaVanancouver 分型</div></el-col>
        <el-col :xs="24" :sm="24" :md="16" :lg="16" :xl="16"><div class="grid-content">
          <el-radio-group v-model="value[5].radio"  :disabled="edit">
            <el-radio  v-for="city in VaVanancouver" :label="city" :key="city">{{city}}</el-radio>
            </el-radio-group>
        </div></el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"><div class="grid-content bg-purple">骨溶解 Paprosky ky 分型</div></el-col>
        <el-col :xs="24" :sm="24" :md="2" :lg="2" :xl="2"><div class="grid-content bg-purple-light">股骨侧</div></el-col>
        <el-col :xs="24" :sm="24" :md="22" :lg="22" :xl="22"><div class="grid-content">
          <el-radio-group v-model="value[6].radio"  :disabled="edit">
            <el-radio  v-for="city in femur" :label="city" :key="city">{{city}}</el-radio>
            </el-radio-group>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="2" :lg="2" :xl="2"><div class="grid-content bg-purple-light">髋臼侧</div></el-col>
        <el-col :xs="24" :sm="24" :md="22" :lg="22" :xl="22"><div class="grid-content">
          <el-radio-group v-model="value[7].radio"  :disabled="edit">
            <el-radio  v-for="city in acetabulum" :label="city" :key="city">{{city}}</el-radio>
            </el-radio-group>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="button"   v-show="!show">
    <el-button type="primary" icon="el-icon-edit" circle @click="tomodify" v-show="edit"></el-button>
    <!-- <el-button @click="tomodify" type="warning" v-show="modify">修改</el-button> -->
    <div v-show="!edit" >
      <el-row>
        <el-button type="success" icon="el-icon-check" circle @click="submit"></el-button>
      </el-row>
      <el-row>
        <el-button type="danger" icon="el-icon-close" circle @click="cancel"></el-button>
      </el-row>
    </div>
    <!-- <el-button @click="submit" type="success" v-show="!modify">提交</el-button>
    <el-button @click="cancel"  type="danger"  v-show="!modify">取消</el-button> -->

  </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      operationid:'',
      show:true,
      preoperative:["AVN","FNF","DDH","OA","RA","PJI","假体周围骨折","骨溶解","假体松动","假体脱落","假体断裂","其他"],
      operation:["THA","BHA","THA翻修","部分翻修","ORIF","CR","其他"],
      garden:["Ⅰ","Ⅱ","Ⅲ","Ⅳ","Ⅴ"],
      Puawels:["Ⅰ","Ⅱ","Ⅲ"],
      Anatomical:["头下型","胫颈行","基底型"],//解剖部位
      VaVanancouver:["AG","AL","B1","B2","B3","C"],
      femur:["Ⅰ","Ⅱ","ⅢA","ⅢB","Ⅳ"],//股骨测
      acetabulum:["Ⅰ","ⅡA","ⅡB","ⅡC","ⅢB","ⅢA"],//髋臼
      value:[
        {checkbox:[],checkbox2:[],inputval:''},
        {checkbox:[],checkbox2:[],inputval:''},
        {radio:''},
        {radio:''},
        {radio:''},
        {radio:''},
        {radio:''},
        {radio:''},
      ],
      edit:true,
      dialogVisible:false,
      next:1,
      curpercent:15
    }
  },
  created(){
    this.operationid = this.$route.query.room
  },
  mounted(){
    this.getinfo()
  },
  methods:{
    tofillin(){
      this.dialogVisible=true
    },
    tonext(){
      this.next = this.next >=7 ? 7:this.next+1
      this.curpercent = this.next >= 7 ? 100 : this.curpercent + 14
    },
    tolast(){
      this.next = this.next<=1 ? 1:this.next-1
      this.curpercent = this.next <= 1 ? 15 : this.curpercent - 14
    },
    tomodify(){
      this.edit = false
    },
   async submit(){
      try {
        const {data:res} = await this.$http.post("/Operation/addPreOperationInfo/"+this.operationid,{
          "preop_diagnosis":this.value
        })
        if(res.status=='1'){
          this.dialogVisible = false
          this.edit = true
          this.getinfo()
        }
      } catch (error) {
        
      }
    } ,
   async tosubmit(){
      try {
        const {data:res} = await this.$http.post("/Operation/addPreOperationInfo/"+this.operationid,{
          "preop_diagnosis":this.value
        })
        if(res.status=='1'){
          this.dialogVisible = false
          this.getinfo()
        }
      } catch (error) {
        
      }
    },  
    cancel(){
      this.edit = true
    },
   async getinfo(){
      try {
        const {data:res} =await this.$http.get('/Operation/getPreOperationInfo/'+this.operationid,{
         params:{
          'pre_type':'术前诊断及手术方式'
         }
        })
       if(res.status=='1'){
        let resdata = res.data.preop_diagnosis
        for(let i=0;i<resdata.length;i++){
          let obj = resdata[i]
          for(let key in obj){
            this.value[i][key] = obj[key]
          }
        }
        this.show = false
       }else{

       }
      } catch (error) {
        
      }
     
    }
  }
}
</script>

<style lang="scss" scoped>
.pdasm{
    height: fit-content;
    box-sizing: border-box;
    padding: 5px;
    width: 100%;
}
.colnopadding{

}
.pdasm .el-col{
  margin-top: 5px;
}
.bg-purple-light {
    background: #e5e9f2;
  }
  .button{
  position: absolute;
  top: 60%;
  right:15%;
  display: flex;
  flex-direction:column
}
.button .el-button{
  margin-bottom:5px
}
</style>

