import { render, staticRenderFns } from "./common-precheckdata.vue?vue&type=template&id=23345584&scoped=true&"
import script from "./common-precheckdata.vue?vue&type=script&lang=js&"
export * from "./common-precheckdata.vue?vue&type=script&lang=js&"
import style0 from "./common-precheckdata.vue?vue&type=style&index=0&id=23345584&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23345584",
  null
  
)

export default component.exports