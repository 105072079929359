<template>
  <div class="addcenter medical" :style="cssvar">
    <el-dialog
      title="添加手术信息"
      :visible.sync="dialogVisible"
      :width="dialogwid"
      >
       <el-row :gutter="10" v-for="(item,index) in Surgical" :key="index">
      <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6"><div class="grid-content bg-purple-light">{{ item.label }}</div></el-col>
      <el-col :xs="18" :sm="18" :md="18" :lg="18" :xl="18"  v-if="item.type=='input'">
        <el-col  :xs="24" :sm="24" :md="24" :lg="24" :xl="24" >
            <el-input placeholder="请输入" v-model="value[index].label" v-show="item.dw == ''">
            </el-input>
            <el-input placeholder="请输入" v-model="value[index].label" v-show="item.dw != ''">
              <template slot="append" >{{item.dw}}</template>
            </el-input>
        </el-col>
      </el-col>
      <el-col :xs="18" :sm="18" :md="18" :lg="18" :xl="18"  v-else-if="item.type=='select'">
        <el-col  :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <el-select v-model="value[index].label" placeholder="请选择" style="width: 100%;">
            <el-option
              v-for="item in item.dw"
              :key="item.label"
              :label="item.label"
              :value="item.value"
              >
            </el-option>
          </el-select>  
        </el-col>
      </el-col>
      <el-col :key="20" :xs="18" :sm="18" :md="18" :lg="18" :xl="18"   v-else-if="item.type=='time'"><div class="grid-content">
        <el-col  :xs="24" :sm="24" :md="24" :lg="24" :xl="24" >
          <el-date-picker
            v-model="value[index].label"
            type="date"
            placeholder="选择日期"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            style="width: 100%;"
          >
          </el-date-picker>
        </el-col>
        <el-col  :xs="0" :sm="0" :md="0" :lg="0" :xl="0"></el-col>
      </div></el-col>
      <el-col :xs="18" :sm="18" :md="18" :lg="18" :xl="18"  v-else-if="item.type=='checkbox'">
        <el-col  :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left;">
          <el-checkbox-group v-model="value[index].label" :min="0" :max="1">
            <el-checkbox v-for="city in item.dw" :label="city" :key="city" style="padding-top: 10px;">{{city}}</el-checkbox>
          </el-checkbox-group>
        </el-col>
        <el-col  :xs="0" :sm="0" :md="0" :lg="0" :xl="0"></el-col>
    </el-col>
  </el-row>
  <span slot="footer" class="dialog-footer">
    <el-button @click="cancel">取 消</el-button>
    <el-button :disabled="tocommit" type="primary" @click="confirm">确 定</el-button>
  </span>
    </el-dialog>
    <div class="medicalcontainer">

      <el-card class="box-card">
        <div  style="padding: 9px 20px; overflow: hidden;">
          <span style="float: left;margin-top: 3px; font-size: 1.2rem;">{{ medicalname }}</span>
          <div style="float: right;">
            <el-button type="primary" icon="el-icon-circle-plus-outline" v-show="isshowbtn=='true' " @click="tocase">增加手术信息</el-button>

            <el-button type="primary" icon="el-icon-d-arrow-left" v-if="isshowbtn=='false'" @click="backcase" >返回手术列表</el-button>
          </div>
        </div>
        <div v-show="showinput == 'true'" style="padding: 9px 20px; overflow: hidden;">
          <el-input 
            placeholder="姓名/医院ID"  
            class="input-with-select"
            
            v-model="queryvalue" 
            clearable 
            @input="(val) => { handleInput(500,query,val)}"
            >
            <el-button slot="append" icon="el-icon-search">搜索</el-button>
          </el-input>

        </div>
      </el-card>

      <!-- <div class="toptab">
        <label>{{ medicalname }}</label>
        <el-input v-show="showinput == 'true'" style="width:60%;" v-model="queryvalue" clearable @input="(val) => { handleInput(500,query,val)}" prefix-icon="el-icon-search" placeholder="姓名/医院ID"></el-input>
        <el-button type="primary" v-show="isshowbtn=='true' " @click="tocase">增加手术信息</el-button>
        <el-button type="warning" v-if="isshowbtn=='false'" @click="backcase" >返回手术信息列表</el-button>
      </div> 
      <div class="toptabmin">
        <label>{{ medicalname }}</label>
        <el-button type="primary" v-show="isshowbtn=='true' " @click="tocase" 
          >增加手术信息</el-button
        >
        <el-button type="warning" v-if="isshowbtn=='false'" @click="backcase"
          >返回手术信息列表</el-button
        >
        <el-input v-show="showinput == 'true'" v-model="queryvalue" clearable @input="(val) => { handleInput(500,query,val)}"></el-input>
      </div> -->
      <div class="medicalrouter">
        <router-view />
      </div>
    </div>

    



  </div>
</template>
<script>
import { mapState } from "vuex";
import EventBus from "@/util/EventBus";
export default {
  data() {
    return {
      queryvalue:'',
      tocommit:true,
      dialogwid:"40%",
      isshowbtn:true,
      medicalname: "手术信息列表",
      dialogVisible:false,
      Surgical:[
        {type:"input",label:"医院ID",dw:''},
        {type:"time",label:"手术日期",dw:''},
        {type:"select",label:"费用类型",
        dw:[
          {label:"省内异地医保",value:'省内异地医保'},
          {label:"跨省异地医保",value:'跨省异地医保'},
          {label:"广州医保",value:'广州医保'},
          {label:"居民医保",value:'居民医保'},
          {label:"市直医保",value:'市直医保'},
          {label:"外地医保",value:'外地医保'},
          {label:"自费",value:'自费'},
          {label:"商业医保",value:'商业医保'},
          {label:"职工医保",value:'职工医保'},
          {label:"公医",value:'公医'},
          {label:"其他",value:'其他'},
        ]
      },
        {type:"checkbox",label:"手术名称",dw:["膝关节置换","髋关节置换","膝关节内侧单髁置换"]},
        {type:"checkbox",label:"手术关节",dw:['髋',"左膝","右膝"]},
        {type:"input",label:"体重",dw:'KG'},
        {type:"input",label:"身高",dw:'cm'},
      ],
      value:[
        {label:''},
        {label:''},
        {label:''},
        {label:[]},
        {label:[]},
        {label:''},
        {label:''},
      ],
      Timer: null,
      padheight:'66px'
    };
  },
  computed: {
    ...mapState(["showbtn", "medicalname2",'showinput']),
    cssvar(){
      return {
        '--height':`calc(90% - ${this.padheight})`
      }
    }
  },
  created(){
    this.isshowbtn = this.$store.state.showbtn
    
  },
  watch: {
    '$route'(to,from){
      let aa=''
        // console.log(to,from,'asd')
        if(to.path=='/list'){
            this.padheight=66+'px'
           
        }else{
          this.padheight=15+'px'

        }
        aa=this.padheight
        sessionStorage.setItem('padheight',aa)
    },
    medicalname2() {
      this.isshowbtn = this.$store.getters.gettersshowbtn;
      this.medicalname = this.$store.getters.gettermedicalname2;
    },
    value:{
      handler(value,old){
        let allValuesNotEmpty = true
        for(let i=0;i<value.length;i++){
          let item = value[i]
          if(Array.isArray(item.label) && !item.label.length){
          allValuesNotEmpty = false;
          break;
        }else if(typeof item.label === 'string' && !item.label){
          allValuesNotEmpty = false;
          break; 
        }
        }
         this.tocommit = !allValuesNotEmpty
      },deep:true
    },
   
  },
  mounted(){
    this.medicalname =  this.$store.state.medicalname2
    let wid = window.innerWidth
    this.dialogwid = wid <=480 ? "90%":wid>=1200 ? "40%":"80%"
    this.padheight = sessionStorage.getItem('padheight')
  },
  methods: {
    handleInput(delay, cb, val) {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        cb(val)
      }, delay)
    },
      query(value){
       EventBus.$emit('querymethod',value)
      },
    cancel(){
      this.dialogVisible = false
      this.value.forEach(item => {
                if(Array.isArray(item.label )){
                  item.label =[];
                }else{
                  item.label = '';
                }
              // item.label = '';
            });
    },
    async confirm(){
      let overlength = this.value[0].label.length
      if(overlength<=11){
        const {data:res} = await this.$http.post("/operation/addOperationInfo",{
          "hospital_id":this.value[0].label,
          "hospital_date":this.value[1].label,
          "pay_type":this.value[2].label,
          "operation_name":this.value[3].label[0],
          "operation_site":this.value[4].label[0],
          "weight":this.value[5].label,
          "stature":this.value[6].label,
        })
          
        if(res.status =="1"){
          if(res.data.hospital_id){
            this.$message.info('添加成功')
            this.dialogVisible =false
            EventBus.$emit("crush")
              this.value.forEach(item => {
                if(Array.isArray(item.label )){
                  item.label =[];
                }else{
                  item.label = '';
                }
              // item.label = '';
            });
            // console.log(this.value,'778899')
          }else{
            this.$message.info('请完善患者信息')
            this.dialogVisible = false
            this.$router.push({
              path:'/oneselfinfomation',
              query: {
                 room: this.value[0].label,
                    },
            })
          }
        }else{
      this.$message.error(res.message.Instructions)
    }
      }else{
        this.$message.error("医院ID不允许超出11位长度")
      }
      
      },
    tocase() {
      // this.dialogVisible = true
      this.$store.commit("showinputmethod",false)
      this.$store.commit("changeshowbtn", "false");
      this.$store.commit("changemedicalname", "完善患者详细信息");
      this.$router.push("/oneselfinfomation").catch(err=>{

}); 
      
    },
    backcase() {
      this.$store.commit("changeshowbtn", "true");
      this.$store.commit("changemedicalname", "手术信息列表");
      this.$store.commit("showinputmethod",'true')
      this.$router.push("/list").catch(err=>{

      });
    },
  },
};
</script>

<style >
.medical {
  height: 100%;
  width: 100%;
  /* background-color: red; */
}
.medicalcontainer {
  width: 70%;
  height: 100%;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 5px;
  /* padding: 0px 10px; */
}
.toptab {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 66px;
  box-sizing: border-box;
  padding: 0px 15px;
  border-bottom: 1px solid #f3f4f6;
}
.bg-purple-light {
    background: #e5e9f2;
  }
.medicalrouter {
  height: var(--height);
  width: 100%;
  background-color: white;
  box-sizing: border-box;
  padding-bottom: 10px;
  overflow: auto;
  padding: 15px 10px;
  /* overflow: hidden; */
}
.toptabmin{
  display: none;
}
@media screen and (max-width: 480px){
  .medicalcontainer{
    width: 90%;
  }
  .toptab{
    display: none;
    flex-wrap: wrap;
    height: fit-content;
  }
  .toptabmin{
    flex-wrap: wrap;
    height: fit-content;
    display: flex;
    /* flex-direction: column; */
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  padding: 0px 15px;
  border-bottom: 1px solid #f3f4f6;
}
}
</style>