<template>
  <div class="list">
<!-- <el-dialog title="患者信息" :visible.sync="patientInfo1" :width="diawid" class="huanzexinxi">

<el-descriptions class="margin-top" :column="columns" border>
  
  <el-descriptions-item>
    <template slot="label"><i class="el-icon-warning-outline"></i> 医院ID</template>{{ gridData.hospital_id }}
  </el-descriptions-item>

  <el-descriptions-item>
    <template slot="label"><i class="el-icon-takeaway-box"></i> 费用类型</template>{{ gridData.pay_type }}
  </el-descriptions-item>
  <el-descriptions-item>
    <template slot="label"><i class="el-icon-c-scale-to-original"></i> 手术位置</template>{{ gridData.operation_site }}
  </el-descriptions-item>
  <el-descriptions-item>
    <template slot="label"><i class="el-icon-warning-outline"></i> 手术日期</template>{{ gridData.hospital_date }}
  </el-descriptions-item>
  <el-descriptions-item>
    <template slot="label"><i class="el-icon-user"></i> 患者姓名</template>{{ gridData.patients_name }}
  </el-descriptions-item>
  <el-descriptions-item>
    <template slot="label"><i class="el-icon-male"></i> 性别</template>{{ gridData.gender }}
  </el-descriptions-item>
  <el-descriptions-item>
    <template slot="label"><i class="el-icon-warning-outline"></i> 婚姻状况</template>{{ gridData.marital_status }}
  </el-descriptions-item>
  <el-descriptions-item>
    <template slot="label"><i class="el-icon-warning-outline"></i> 年龄</template>{{ gridData.age }}
  </el-descriptions-item>
  <el-descriptions-item>
    <template slot="label"><i class="el-icon-date"></i> 出生日期</template>{{ gridData.birth_date }}
  </el-descriptions-item>
  <el-descriptions-item>
    <template slot="label"><i class="el-icon-warning-outline"></i> 民族</template>{{ gridData.nation }}
  </el-descriptions-item>
  <el-descriptions-item>
    <template slot="label"><i class="el-icon-mobile-phone"></i> 联系方式</template>{{ gridData.phone| filterphone }}
  </el-descriptions-item>
  <el-descriptions-item>
    <template slot="label"><i class="el-icon-warning-outline"></i> 身份证号码</template>{{ gridData.id_card }}
  </el-descriptions-item>
  <el-descriptions-item>
    <template slot="label"><i class="el-icon-place"></i> 工作单位</template>{{ gridData.work_unit }}
  </el-descriptions-item>
  <el-descriptions-item>
    <template slot="label"><i class="el-icon-warning-outline"></i> 职业</template>{{ gridData.occupation }}
  </el-descriptions-item>

  <el-descriptions-item>
    <template slot="label"><i class="el-icon-cloudy"></i> 出生当日天气情况</template>{{ gridData.birth_weather }}
  </el-descriptions-item>
  
  <el-descriptions-item>
    <template slot="label"><i class="el-icon-cloudy"></i> 出生时的季节</template>{{ gridData.birth_seasons }}
  </el-descriptions-item>

  <el-descriptions-item>
    <template slot="label"><i class="el-icon-document"></i> 身高&体重</template>{{ gridData.stature }}CM ; {{ gridData.weight }}KG (BMI: {{ gridData.bmi }})
  </el-descriptions-item>
</el-descriptions>
<span slot="footer" class="dialog-footer">
  <el-button type="primary" @click="patientInfo1 = false">关闭</el-button>
</span>
</el-dialog> -->
    <!-- 修改弹窗 -->
    <!-- <el-dialog
  title="修改"
  :visible.sync="modifydialog"
  width="80%"
 >
    <modify  @modifyemit="getmodify" :message1="modifyvalue"></modify>
  <span slot="footer" class="dialog-footer">
    <el-button type="warning" @click="modifydialog = false">取 消</el-button>
    <el-button type="danger" @click="tosubmit">提交修改</el-button>
  </span>
</el-dialog> -->
<!-- 添加手术信息弹窗 -->
    <!-- <el-dialog
      title="添加手术信息"
      :visible.sync="dialogVisible"
      :width="diawid"
      >
  <el-row :gutter="10" v-for="(item,index) in Surgical" :key="index">
      <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4"><div class="grid-content bg-purple">{{ item.label }}</div></el-col>
      <el-col :xs="20" :sm="20" :md="20" :lg="20" :xl="20"  v-if="item.type=='input'">
        <div class="grid-content">
        <el-col  :xs="20" :sm="20" :md="20" :lg="20" :xl="20"><el-input v-model="value[index].label" /></el-col>
        <el-col  :xs="4" :sm="4" :md="4" :lg="4" :xl="4">{{item.dw}}</el-col>
        </div>
      </el-col>
      <el-col :xs="20" :sm="20" :md="20" :lg="20" :xl="20"  v-else-if="item.type=='select'">
        <div class="grid-content">
        <el-col  :xs="20" :sm="20" :md="20" :lg="20" :xl="20">
          <el-select v-model="value[index].label" placeholder="请选择">
            <el-option
              v-for="item in item.dw"
              :key="item.label"
              :label="item.label"
              :value="item.value"
              >
            </el-option>
          </el-select>  
        </el-col>
        <el-col  :xs="4" :sm="4" :md="4" :lg="4" :xl="4"></el-col>
        </div>
      </el-col>
      <el-col :key="20" :sm="20" :md="20" :lg="20" :xl="20"   v-else-if="item.type=='time'"><div class="grid-content">
        <el-col  :xs="20" :sm="20" :md="20" :lg="20" :xl="20">
          <el-date-picker
            v-model="value[index].label"
            type="date"
            placeholder="选择日期"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-col>
        <el-col  :xs="4" :sm="4" :md="4" :lg="4" :xl="4"></el-col>
      </div></el-col>
      <el-col :xs="20" :sm="20" :md="20" :lg="20" :xl="20"  v-else-if="item.type=='checkbox'"><div class="grid-content">
        <el-col  :xs="20" :sm="20" :md="20" :lg="20" :xl="20">
          <el-checkbox-group 
             v-model="value[index].label"
             :min="0"
             :max="1">
           <el-checkbox v-for="city in item.dw" :label="city" :key="city">{{city}}</el-checkbox>
         </el-checkbox-group>
        </el-col>
        <el-col  :xs="4" :sm="4" :md="4" :lg="4" :xl="4"></el-col>
      </div>
    </el-col>
  </el-row>
  <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="confirm">确 定</el-button>
  </span>
</el-dialog> -->
<el-row :gutter="10">
  <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12" v-for=" (item, index) in list" :key="index">
    <el-card class="box-card list-card" >
      <div slot="header" class="clearfix" >
        <span style="float: left;margin-top: 3px;">编号：{{ item.serial_number }}</span>
        <el-button-group style="float: right;" >
          <el-button type="primary" size="mini" icon="el-icon-edit-outline" @click="modify(item.operation_id)"><span  class="but_text">修改</span></el-button>
          <el-popconfirm title="您确定删除该病例吗？删除后无法还原" @confirm = "dele(item.operation_id)">
            <el-button type="danger" size="mini" icon="el-icon-delete" slot="reference"><span class="but_text">删除</span></el-button>
          </el-popconfirm>
        </el-button-group>
      </div>
      <div  class="" style="padding: 10px !important; text-align: left;">
        <p><i class="el-icon-connection" style="color: #1b72e4;" /> 医院ID：{{ item.hospital_id }}</p>
        <p>
            <i class="el-icon-user" style="color:#1b72e4;" /> 姓名：{{ item.patients_name }}
            <el-tooltip class="item" effect="dark" :content="item.gender"  placement="top">
              <i class="el-icon-male" style="color: rgb(250, 0, 0); font-weight: bold;" v-if="item.gender == '女'"></i>
              <i class="el-icon-female" style="color: rgb(9, 6, 204); font-weight: bold;" v-if="item.gender == '男'"></i>
            </el-tooltip>
            <el-tag type="" effect="plain" size="small" style="margin-left: 10px;">{{ item.age }} 岁</el-tag>
        </p>
        <p><i class="el-icon-warning-outline" style="color: rgb(151, 12, 12);" /> 患病关节：{{ item.operation_site }}</p>
        <p><i class="el-icon-collection-tag" style="color: rgb(3, 192, 19);" /> 费用类型：{{ item.pay_type }}</p>
        <p><i class="el-icon-coin" style="color: rgba(224, 191, 1, 0.829);" /> 身高体重：{{ item.stature }}CM | {{ item.weight }}KG（BMI：{{ item.bmi }}）</p>
      </div>
      <div class="bottom " style="padding-top: 10px;">
        <el-button-group>
          
          <el-button type="primary" icon="el-icon-document-remove" size="small" :disabled="item.patients_name==null" @click="jump({a1:item.operation_id,a2:item.operation_site,isshowbreadcrumb:'2'})">术前信息</el-button>
          <el-button type="primary" icon="el-icon-document" size="small" :disabled="item.patients_name==null" @click="jump({a1:item.operation_id,a2:item.operation_site,isshowbreadcrumb:'3'})">检验信息</el-button>
          <el-button type="primary" icon="el-icon-tickets" size="small" :disabled="item.patients_name==null" @click="jump({a1:item.operation_id,a2:item.operation_site,isshowbreadcrumb:'1'})">手术详情</el-button>
          <el-button type="primary" icon="el-icon-document-copy" size="small" :disabled="item.patients_name==null" @click="jump({a1:item.operation_id,a2:item.operation_site,isshowbreadcrumb:'4'})">检查信息</el-button>

          <!-- <el-button type="primary" icon="el-icon-data-analysis" size="small" :disabled="item.patients_name==null" @click="jump({a1:item.operation_id,a2:item.operation_site,isshowbreadcrumb:'4'})">评分记录</el-button>
          <el-button type="primary" icon="el-icon-picture-outline" size="small" :disabled="item.patients_name==null" @click="jump({a1:item.operation_id,a2:item.operation_site,isshowbreadcrumb:'4'})">影像记录</el-button> -->

        </el-button-group>
      </div>
    </el-card>
  </el-col>
</el-row>
      <!-- <el-table
      v-show="false"
        :data="list"
        :cell-style="{ textAlign: 'center' }"
      :header-cell-style="{ textAlign: 'center' }"
        border
        stripe
        >
        <el-table-column
          label="编号"
          fixed
          max-width="100" min-width="60"
          prop="serial_number"
          ></el-table-column>
        <el-table-column
        max-width="100"
        min-width="60"
          label="医院ID"
          prop="hospital_id"
          ></el-table-column>
        <el-table-column
        max-width="100"
        min-width="60"
          label="类型"
          prop="pay_type"
          ></el-table-column>
        <el-table-column
          label="姓名"
          max-width="100"
          min-width="60"
          prop="patients_name"
          ></el-table-column>
        <el-table-column
          label="性别"
          width="80"
         >
          <template slot-scope="item">
            <el-tag size="small" type="danger" v-if="item.row.gender == '女'">{{ item.row.gender }}</el-tag>
            <el-tag size="small"  v-if="item.row.gender == '男'">{{ item.row.gender }}</el-tag>
            <el-tag size="small"  v-if="item.row.gender == null ">未填写</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          label="患病关节"
          width="80"
          >
          <template slot-scope="item">
            <el-tag size="small" effect="plain" type="success">{{ item.row.operation_site }}</el-tag>
          </template>
        </el-table-column>

        <el-table-column  label="操作"  fixed="right" max-width="300" min-width="100">
          <template slot-scope="item">
            <el-button size="mini"  @click="patientInfo(item.row)"   v-show="item.row.patients_name!=null"   type="info" plain style="margin-left: auto;">患者信息</el-button>
            <el-button size="mini"  @click="toinfo(item.row.hospital_id)"   v-show="item.row.patients_name==null"   type="success" plain style="margin-left: auto;">完善信息</el-button>
            <el-button size="mini" 
              :disabled="item.row.patients_name==null" 
              type="primary"
              @click="jump({a1:item.row.operation_id,a2:item.row.operation_site,isshowbreadcrumb:'1'})" plain 
              style="margin-left: auto;"
              >手术详情
            </el-button>
            <el-button size="mini" 
              :disabled="item.row.patients_name==null" 
              type="primary"
              @click="jump({a1:item.row.operation_id,a2:item.row.operation_site,isshowbreadcrumb:'2'})" plain 
              style="margin-left: auto;"
              >术前信息
            </el-button>
            <el-button size="mini" 
              :disabled="item.row.patients_name==null" 
              type="primary"
              @click="jump({a1:item.row.operation_id,a2:item.row.operation_site,isshowbreadcrumb:'3'})" plain 
              style="margin-left: auto;"
              >检验信息
            </el-button>
            <el-button size="mini" 
              :disabled="item.row.patients_name==null" 
              type="primary"
              @click="jump({a1:item.row.operation_id,a2:item.row.operation_site,isshowbreadcrumb:'4'})" plain 
              style="margin-left: auto;"
              >检查信息
            </el-button>
          
            <el-button size="mini"  @click="modify(item.row.operation_id)" icon="el-icon-edit" type="warning" plain :disabled="item.row.patients_name==null" style="margin-left: auto;"></el-button>
            <el-popconfirm
                  title="您确定删除该病例吗？删除后无法还原"
                  style="width: 20%;"
                  @confirm = "dele(item.row.operation_id)"
                >
                  <el-button style="margin-left: auto;" size="mini"  type="danger" plain slot="reference"  icon="el-icon-delete"></el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table> -->
  </div>
</template>
<script>
import EventBus from "@/util/EventBus";
// import modify from "../modify/modify.vue";
export default {
  data() {
    return {
      diawid:'30%',
      columns:2,
      list: [],
      gridData:[],
      dialogVisible:false,
      patientInfo1:false,
      Surgical:[
        {type:"input",label:"医院ID",dw:''},
        {type:"time",label:"手术日期",dw:''},
        {type:"select",label:"费用类型",
        dw:[
          {label:"省内异地医保",value:'省内异地医保'},
          {label:"跨省异地医保",value:'跨省异地医保'},
          {label:"广州医保",value:'广州医保'},
          {label:"居民医保",value:'居民医保'},
          {label:"市直医保",value:'市直医保'},
          {label:"外地医保",value:'外地医保'},
          {label:"自费",value:'自费'},
          {label:"商业医保",value:'商业医保'},
          {label:"职工医保",value:'职工医保'},
          {label:"公医",value:'公医'},
          {label:"其他",value:'其他'},
        ]
      },
        {type:"checkbox",label:"手术名称",dw:["膝关节内侧单髁置换","膝关节置换","髋关节置换"]},
        {type:"checkbox",label:"手术关节",dw:['髋',"左膝","右膝"]},
        {type:"input",label:"体重",dw:'KG'},
        {type:"input",label:"身高",dw:'cm'},
      ],
      value:[
        {label:''},
        {label:''},
        {label:''},
        {label:[]},
        {label:[]},
        {label:''},
        {label:''},
      ],
      // modifydialog:false,
      // modifyvalue:[
      //   { hospital_id: "" },
      //   { patient__patients_name: "" },
      //   { id_card: "" },
      //   { phone_family: "" },
      //   { patient__phone: "" },
      //   { nation: "" },
      //   { native: [] },
      //   { pay_type: [] },
      //   { patient__gender: [] },
      //   { marital_status: [] },
      //   { work_unit: "" },
      //   { occupation: "" },
      //   { patient__birth_date: "" },
      //   { birth_weather: "" },
      //   { birth_seasons: [] },
      //   { hospital_date: [] },
      //   {home_address:{add:'',value:[]}},
      //   {operation_id:''}
      // ],
      modifysubmitvalue:[],
      numbering:''
    };
  },
  watch:{
  
  },
  components:{
 
  },
  filters:{
    filterphone(value){
      let arr = []
      for(let key in value){
        if(value[key] !=''){
           arr.push(value[key])
        }
      }
      return arr.toString()
    }
  },
  mounted() {
    let wid = window.innerWidth
    // this.modifydialog=false
    this.diawid = wid<=480 ? "90%":wid>=1200 ? "40%":"80%"
    this.columns = wid<=480 ? 1:wid>=1200 ? 2:2
    this.Currentsurgicalinformation();
    EventBus.$on("crush",()=>{
    this.Currentsurgicalinformation()
    })
    EventBus.$on('querymethod',(value)=>{
      this.Currentsurgicalinformation(value)
    })
  },
  methods: {
    toinfo(value){
      this.$store.commit("showinputmethod",false)

      this.$router.push({
              path:'/oneselfinfomation',
              query: {
                 room: value
                    },
            })
            this.$store.commit("changeshowbtn", "false");
      this.$store.commit("changemedicalname", "完善患者详细信息");
    },
   async confirm(){
        const {data:res} = await this.$http.post("/operation/addOperationInfo",{
          "hospital_id":this.value[0].label,
          "hospital_date":this.value[1].label,
          "pay_type":this.value[2].label,
          "operation_name":this.value[3].label[0],
          "operation_site":this.value[4].label[0],
          "weight":this.value[5].label,
          "stature":this.value[6].label,
        })
        if(res.status =="1"){
          if(res.data.hospital_id){
              this.Currentsurgicalinformation()
          }else{
            this.$router.push({
              path:'/oneselfinfomation',
              query: {
                 room: this.value[0].label,
                    },
            })
          }
        }else{
      this.$message.error(res.message.Instructions)
    }
      },
    addSurgical(){
      this.dialogVisible = true
    },
    jump(value) {
      let jumparr = ['/insurgery','/presentHistory','/preinspdata','/preCheckData']
      this.$store.commit("changeshowbtn", "false");
      this.$store.commit("changemedicalname", "病历详细信息");
      this.$store.commit("showinputmethod",false)
      this.$store.commit('changelevel1',0)
      this.$store.commit('changelevel2',0)
      this.$store.commit('changebreadcrumb',value.isshowbreadcrumb)
      this.$router.push({
        path: jumparr[value.isshowbreadcrumb-1],
        query: {
          room: value.a1,
          site: value.a2
        },
      });
      // console.log(value)
    },

    async dele(value) {
      const { data: res } = await this.$http.get('/Operation/deleOperationInfo/'+ value);
      // console.log(res.data);
      if(res.status){
        this.$notify({
          title: '成功',
          message: '成功删除该数据',
          type: 'success',
          duration: 2000
        });
        this.Currentsurgicalinformation()
      }else{
        this.$notify.error({
          title: '失败',
          message: '删除数据时，出现错误',
          duration: 2000
        });
      }
    },
    // 获取当前登录者的手术信息
    async Currentsurgicalinformation(value) {
      let resvalue = value ? value:''
      // console.log(resvalue,'7898798')
      try{
      const { data: res } = await this.$http.get("/operation/getOperationInfo",{
        params:{
          'searchinfo':resvalue
        }
      }
      );
      // console.log(res)
      if ((res.status = "获取成功")) {
        this.list = res.data;
      }
    }catch(e){

    }
    },
    modify(value){
      this.$store.commit("changeshowbtn", "false");
      this.$store.commit("changemedicalname", "修改患者信息");
      this.$store.commit("showinputmethod",'false')
      this.$router.push({
              path:'/modify',
              query: {
                 room: value
                    },
            })
      // this.modifydialog=true
      // console.log(value)
      // setTimeout(()=>{
      // let val=value
      //       for(let i=0;i<this.modifyvalue.length;i++){
      //         let obj = this.modifyvalue[i]
      //         for(let key in obj){
      //         for(let key2 in val){
      //           if(key == key2){
      //             let determine = this.modifyvalue[i][key]
      //             if(!Array.isArray(determine) && typeof determine ==='object'){
      //               this.modifyvalue[i][key].add = val[key].add
      //               this.modifyvalue[i][key].value = val[key].value
      //             }else{
      //               if(typeof val[key] === 'object' && val[key]!=null){
                     
      //                 this.modifyvalue[i][key] =Object.values(val[key])
      //               }else{
      //                 this.modifyvalue[i][key] = val[key] == null ? '无':val[key]
      //               }
                   
      //             }
      //           }
      //         }
      //         }
      //       }
      // },100)
    },
    // getmodify(value){
    //   this.modifysubmitvalue=[]
    //   this.modifysubmitvalue = value
    // },
  // async  tosubmit(){
  //     const {data:res} =await this.$http.put('/patient/editPatientInfo',{
  //       "hospital_id":this.modifysubmitvalue[0].value,
  //           "patients_name":this.modifysubmitvalue[1].value,
  //           "id_card":this.modifysubmitvalue[2].value,
  //           "phone_family":this.modifysubmitvalue[3].value,
  //           "phone":this.modifysubmitvalue[4].value,
  //           "nation":this.modifysubmitvalue[5].value,
  //           "native":this.modifysubmitvalue[6].value,
  //           "pay_type":this.modifysubmitvalue[7].value,
  //           "gender":this.modifysubmitvalue[8].value[0],
  //           "marital_status":this.modifysubmitvalue[9].value,
  //           "work_unit":this.modifysubmitvalue[10].value,
  //           "occupation":this.modifysubmitvalue[11].value,
  //           "birth_date":this.modifysubmitvalue[12].value,
  //           "birth_weather":this.modifysubmitvalue[13].value,
  //           "birth_seasons":this.modifysubmitvalue[14].value[0],
  //           "hospital_date":this.modifysubmitvalue[15].value,
  //           "home_address":this.modifysubmitvalue[16],
  //           "operation_id":this.modifysubmitvalue[17].value,
            
  //         })
  //         console.log(res)
  //         if(res.status=="1"){
  //           this.Currentsurgicalinformation()
  //           // this.modifydialog=false
  //         }else{
  //           this.$message.error('提交出错，请联系管理员')
  //         }
  //   },
    // 获取患者信息
    async patientInfo(value) {
      this.gridData = value;
      this.patientInfo1 = true;
    },

  },
};
</script>

<style >
.list {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  display: flex;
  padding-bottom: 30px;
  flex-wrap: wrap;
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  overflow-x: hidden;
  overflow-y: auto;
}
.list-card{
  margin-top: 15px;

}
.listcontainer {
  box-sizing: border-box;
  min-height: 130px;
  width: 100%;
  background: #f8fafc;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  border: 1px solid #e6e7e9;
}
.listtop {
  width: 100%;
  height: 45px;
  /* background-color: antiquewhite; */
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e6e7e9;
  justify-content: space-between;
  padding: 0 8px;
}
.listname {
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 8px;
}
.bottom {
  /* height: 56px; */
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  border-top: 1px solid #e6e7e9;
}
.bottom span{
  width: fit-content !important;
}
.bottom .el-button{
  margin-left: 0px !important;
}
.listnamelist {
  /* flex: 1; */
  width: fit-content;
  /* padding: 0px 8px; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  padding: 5px 0px;
}
.listnamelist label {
  margin-right: 8px;
}

.el-row {
  flex: 1;
}
.el-card__body {
  padding: 10px !important;
}
.addSurgical{
  /* background: red; */
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.dw{
  width:40px;
}
.huanzexinxi .el-dialog{
  box-shadow: 0 1px 3px rgb(255, 255, 255) !important;
}
@media screen and (max-width: 680px) {
  .but_text{display: none;}
}
</style>