<!-- 传统手术组件traditionalsurgery -->
<template>
  <div class="traditional">
    <el-row :gutter="10">
      <!-- 股骨远端截骨定位方法始 -->
      <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6"
        ><div class="grid-content bg-purple">股骨远端截骨定位方法</div></el-col
      >
      <el-col :sm="24" :xm="24" :md="24" :lg="18" :xl="18">
        <div class="grid-content" style="border: solid 1px rgba(134, 134, 134, 0.507)">
          <el-checkbox-group v-model="checkvalue[0].value1" :min="0" :max="1">
            <el-checkbox
              v-for="chech1 in checkbox1"
              :key="chech1"
              :label="chech1"
              :disabled="othermodify"
            >
              {{ chech1 }}
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </el-col>
      <!-- 选择机器人会弹出 -->
      <el-col
        :xs="4"
        :sm="4"
        :md="4"
        :lg="4"
        :xl="4"
        v-if="checkvalue[0].isshow1"
        ><div class="grid-content bg-purple">机器人</div></el-col
      >
      <el-col
        :xs="20"
        :sm="20"
        :md="20"
        :lg="20"
        :xl="20"
        v-if="checkvalue[0].isshow1"
        ><div class="grid-content" style="border: solid 1px rgba(134, 134, 134, 0.507)">
          <el-checkbox-group v-model="checkvalue[0].value2" :min="0" :max="1">
            <el-checkbox  :disabled="othermodify" label="MaKo">MaKo </el-checkbox>
            <el-checkbox :disabled="othermodify" label="和华"> 和华</el-checkbox>
            <el-checkbox :disabled="othermodify" label="其他"> 其他</el-checkbox>
          </el-checkbox-group>
        </div></el-col
      >
      <el-col :xs="24" :sm="24" :md="24" :lg="42" :xl="42"  v-if="checkvalue[0].isshow1">
        <el-input :disabled="othermodify" v-model="checkvalue[0].other2" v-show="checkvalue[0].isshow2">
          <template slot="prepend">其他</template>
        </el-input>
      </el-col>
      <!-- 选择机器人会弹出 -->
      <!-- 选择其他会弹出 -->

      <el-col :xs="24" :sm="24" :md="24" :lg="42" :xl="42"  v-if="checkvalue[0].isshow3">
        <div class="grid-content">
          <el-input :disabled="othermodify" v-model="checkvalue[0].other1"><template slot="prepend">其他</template></el-input>
        </div>
      </el-col>
      <!-- 选择其他会弹出 -->
      <!-- 股骨远端截骨定位方法终 -->
      <!-- 股骨远端截骨导向器设置 -->
      <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6"><div class="grid-content bg-purple">
          股骨远端截骨导向器设置
            </div>
      </el-col>
      <el-col :xs="24" :sm="24" :md="24" :lg="18" :xl="18" style="padding: 0;">
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <el-input :disabled="othermodify" v-model="checkvalue[1].inputvalue1">
            <template slot="prepend">外翻</template>
            <template slot="append">°</template>
          </el-input>
        </el-col>    
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">            
          <el-input :disabled="othermodify" v-model="checkvalue[1].inputvalue2">
            <template slot="prepend">外翻截骨</template>
            <template slot="append">mm</template>
          </el-input>
        </el-col>
      </el-col>
      <!-- 股骨远端截骨导向器设置 -->
      <!-- 测量结果 -->
      <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6"><div class="grid-content bg-purple">
        测量结果
            </div>
      </el-col>

      <el-col :xs="24" :sm="24" :md="24" :lg="18" :xl="18" style="padding: 0;">
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <el-input :disabled="othermodify" v-model="checkvalue[2].inputvalue1">
            <template slot="prepend">外髁</template>
            <template slot="append">mm</template>
          </el-input>
        </el-col>    
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">            
          <el-input :disabled="othermodify" v-model="checkvalue[2].inputvalue2">
            <template slot="prepend">内髁</template>
            <template slot="append">mm</template>
          </el-input>
        </el-col>
      </el-col>


      <!-- 测量结果 -->
            <!-- 股骨近端截骨定位方法始 -->
            <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6"
        ><div class="grid-content bg-purple">股骨近端截骨定位方法</div></el-col
      >
      <el-col :sm="24" :xm="24" :md="24" :lg="18" :xl="18">
        <div class="grid-content" style="border: solid 1px rgba(134, 134, 134, 0.507)">
          <el-checkbox-group v-model="checkvalue[3].value1" :min="0" :max="1">
            <el-checkbox
            :disabled="othermodify"
              v-for="chech1 in checkbox1"
              :key="chech1"
              :label="chech1"
            >
              {{ chech1 }}
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </el-col>
      <!-- 选择机器人会弹出 -->
      <el-col
        :xs="4"
        :sm="4"
        :md="4"
        :lg="6"
        :xl="6"
        v-if="checkvalue[3].isshow1"
        ><div class="grid-content bg-purple">机器人</div></el-col
      >
      <el-col
        :xs="20"
        :sm="20"
        :md="20"
        :lg="18"
        :xl="18"
        v-if="checkvalue[3].isshow1"
        ><div class="grid-content" style="border: solid 1px rgba(134, 134, 134, 0.507)">
          <el-checkbox-group v-model="checkvalue[3].value2" :min="0" :max="1">
            <el-checkbox :disabled="othermodify" label="MaKo">MaKo </el-checkbox>
            <el-checkbox :disabled="othermodify" label="和华"> 和华</el-checkbox>
            <el-checkbox :disabled="othermodify" label="其他"> 其他</el-checkbox>
          </el-checkbox-group>
        </div></el-col
      >

      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" v-if="checkvalue[3].isshow1">
        <el-input :disabled="othermodify" v-model="checkvalue[3].other2" v-show="checkvalue[3].isshow2">
          <template slot="prepend">其他</template>
        </el-input>
      </el-col>

      
      <!-- 选择机器人会弹出 -->
      <!-- 选择其他会弹出 -->
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"  v-if="checkvalue[3].isshow3">
        <div class="grid-content">
          <el-input :disabled="othermodify" v-model="checkvalue[3].other1"><template slot="prepend">其他</template></el-input>
        </div>
      </el-col>

   
      <!-- 选择其他会弹出 -->
      <!-- 股骨近端截骨定位方法终 -->
           <!-- 胫骨截骨 -->
           <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6"><div class="grid-content bg-purple">
          胫骨截骨
            </div>
      </el-col>
      <el-col :xs="24" :sm="24" :md="24" :lg="18" :xl="18" style="padding: 0;">
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-input  :disabled="othermodify" v-model="checkvalue[4].inputvalue1">
              <template slot="prepend">后倾角度</template>
              <template slot="append">°</template>
            </el-input>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">  
            <el-input :disabled="othermodify" v-model="checkvalue[4].inputvalue2">
              <template slot="prepend">截骨厚度</template>
              <template slot="append">mm</template>
            </el-input>
          </el-col>
      </el-col>
      <!-- 胫骨截骨 -->
       <!-- 测量结果 -->
       <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"><div class="grid-content bg-purple">
          测量结果
            </div>
      </el-col>
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <el-input :disabled="othermodify" v-model="checkvalue[5].inputvalue1">
            <template slot="prepend">最厚处</template>
            <template slot="append">mm</template>
          </el-input>
          <el-input :disabled="othermodify" v-model="checkvalue[5].inputvalue2">
            <template slot="prepend">外侧脊</template>
            <template slot="append">mm</template>
          </el-input>
          <el-input :disabled="othermodify" v-model="checkvalue[5].inputvalue3">
            <template slot="prepend">外侧脊</template>
            <template slot="append">mm</template>
          </el-input>
      </el-col>
      <!-- 测量结果 -->
      <!-- 测试甚至间隙 -->
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"><div class="grid-content bg-purple">
        测试伸直间隙
            </div>
      </el-col>
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <el-input :disabled="othermodify" v-model="checkvalue[6].inputvalue1">
            <template slot="prepend">间隙</template>
            <template slot="append">mm</template>
          </el-input>
          <el-input :disabled="othermodify" v-model="checkvalue[6].inputvalue2">
            <template slot="prepend">垫片</template>
            <template slot="append">mm</template>
          </el-input>
      </el-col>

         
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"><div class="grid-content" style="border: solid 1px rgba(134, 134, 134, 0.507)">
            <el-checkbox-group v-model="checkvalue[6].value1" :min="0" :max="1">
            <el-checkbox :disabled="othermodify" label="内外侧平衡良好">内外侧平衡良好 </el-checkbox>
            <el-checkbox :disabled="othermodify" label="内侧狭窄"> 内侧狭窄</el-checkbox>
            <el-checkbox :disabled="othermodify" label="外侧狭窄"> 外侧狭窄</el-checkbox>
          </el-checkbox-group>
      </div></el-col>

      
      <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4" v-if="checkvalue[6].isshow1"><div class="grid-content bg-purple"> 内侧狭窄</div></el-col>
      <el-col :xs="24" :sm="24" :md="20" :lg="20" :xl="20" v-if="checkvalue[6].isshow1"><div class="grid-content" style="border: solid 1px rgba(134, 134, 134, 0.507)">
            <el-checkbox-group v-model="checkvalue[6].value2" :min="0" :max="1">
            <el-checkbox :disabled="othermodify" label="继续内侧松弛">继续内侧松弛 </el-checkbox>
            <el-checkbox :disabled="othermodify" label="Pic-crusting"> Pic-crusting</el-checkbox>
            <el-checkbox :disabled="othermodify" label="胫骨缩容"> 胫骨缩容</el-checkbox>
            <el-checkbox :disabled="othermodify" label="袖套松解"> 袖套松解</el-checkbox>
          </el-checkbox-group>
            </div>
      </el-col>
      <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4"  v-if="checkvalue[6].isshow2"><div class="grid-content bg-purple" > 外侧狭窄</div></el-col>
      <el-col :xs="24" :sm="24" :md="20" :lg="20" :xl="20" v-if="checkvalue[6].isshow2"><div class="grid-content" style="border: solid 1px rgba(134, 134, 134, 0.507)">
            <el-checkbox-group v-model="checkvalue[6].value3" :min="0" :max="1">
            <el-checkbox :disabled="othermodify" label="枕头松解髁胫束">枕头松解髁胫束 </el-checkbox>
            <el-checkbox :disabled="othermodify" label="切断髁胫束"> 切断髁胫束</el-checkbox>
            <el-checkbox  :disabled="othermodify" label="其他"> 其他</el-checkbox>
          </el-checkbox-group>
            </div>
      </el-col>
      <el-col
        :xs="9"
        :sm="9"
        :md="3"
        :lg="3"
        :xl="3"
        v-if="checkvalue[6].isshow3"
        ><div class="grid-content bg-purple">其他</div></el-col
      >
      <el-col
        :xs="15"
        :sm="15"
        :md="21"
        :lg="21"
        :xl="21"
        v-if="checkvalue[6].isshow3"
        ><div class="grid-content">
          <el-input :disabled="othermodify" v-model="checkvalue[6].other1"></el-input></div
      ></el-col>
      <!-- 测试深直间隙 -->
      <!-- 股骨假体 -->
      <el-col :xs="8" :sm="4" :md="4" :lg="4" :xl="4"><div class="grid-content bg-purple">股骨假体</div></el-col>
      <el-col :xs="16" :sm="20" :md="20" :lg="20" :xl="20"><div class="grid-content" style="border: solid 1px rgba(134, 134, 134, 0.507)">
        <el-checkbox-group v-model="checkvalue[7].value1" :min="0" :max="1">
          <el-checkbox :disabled="othermodify" label="前参">前参</el-checkbox>
          <el-checkbox  :disabled="othermodify" label="后参">后参</el-checkbox>
        </el-checkbox-group>
      </div>
    </el-col>
     <!-- 股骨假体 --> 

     <!-- 测骨假体大小为 -->
    <el-col :xs="24" :sm="24" :md="6" :lg="4" :xl="4"><div class="grid-content bg-purple">测骨假体大小为</div></el-col>
      <el-col :xs="24" :sm="24" :md="18" :lg="20" :xl="20"><div class="grid-content" style="border: solid 1px rgba(134, 134, 134, 0.507)">
        <el-checkbox-group v-model="checkvalue[7].value2"  :min="0" :max="1">
          <el-checkbox :disabled="othermodify" label="明确假体大小">明确假体大小</el-checkbox>
          <el-checkbox :disabled="othermodify" label="不明确假体大小">不明确假体大小</el-checkbox>
        </el-checkbox-group>
      </div>
    </el-col>
    <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8" v-show="checkvalue[7].isshow1"><div class="grid-content bg-purple">假体大小</div></el-col>
      <el-col :xs="16" :sm="16" :md="16" :lg="16" :xl="16"  v-show="checkvalue[7].isshow1"><div class="grid-content">
       <el-input :disabled="othermodify" v-model="checkvalue[7].inputvalue1"><template slot="append">号</template></el-input>
      </div>
    </el-col>
    <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4" v-show="checkvalue[7].isshow2"><div class="grid-content bg-purple">假体大小</div></el-col>
      <el-col :xs="24" :sm="24" :md="20" :lg="20" :xl="20"  v-show="checkvalue[7].isshow2">
        <el-col :xs="24" :sm="16" :md="16" :lg="16" :xl="16" class="grid-content">
          <el-input :disabled="othermodify" v-model="checkvalue[7].inputvalue2">
            <template slot="append">号到</template>
          </el-input>
          <el-input :disabled="othermodify" v-model="checkvalue[7].inputvalue3">
            <template slot="append" >号之间</template>
          </el-input>
        </el-col>
        <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
          <el-input :disabled="othermodify" v-model="checkvalue[7].inputvalue4">
            <template slot="prepend">选择</template>
            <template slot="append">号</template>
          </el-input>
        </el-col>
    </el-col>
      <!-- 测骨假体大小为 -->
           <!-- 股骨假体旋转 -->
     <el-col :xs="24" :sm="6" :md="4" :lg="4" :xl="4"><div class="grid-content bg-purple">股骨假体后髁线</div></el-col>
      <el-col :xs="24" :sm="18" :md="20" :lg="20" :xl="20"><div class="grid-content"  style="border: solid 1px rgba(134, 134, 134, 0.507)">
        <el-checkbox-group v-model="checkvalue[9].value1" :min="0" :max="1">
          <el-checkbox :disabled="othermodify" label="旋转">旋转</el-checkbox>
          <el-checkbox :disabled="othermodify" label="测量截骨">测量截骨</el-checkbox>
          <el-checkbox :disabled="othermodify" label="Tcensioner">Tensioner</el-checkbox>
        </el-checkbox-group>
      </div>
    </el-col>
    <el-col :xs="24" :sm="4" :md="4" :lg="4" :xl="4" v-show="checkvalue[9].isshow2"><div class="grid-content bg-purple">测量截骨</div></el-col>
      <el-col :xs="24" :sm="20" :md="20" :lg="20" :xl="20"  v-show="checkvalue[9].isshow2"><div class="grid-content"  style="border: solid 1px rgba(134, 134, 134, 0.507)">
        <el-checkbox-group v-model="checkvalue[9].value2" :min="0" :max="1">
          <el-checkbox :disabled="othermodify" label="后髁">后髁</el-checkbox>
          <el-checkbox :disabled="othermodify" label="通髁线">通髁线</el-checkbox>
          <el-checkbox :disabled="othermodify" label="Whiteside">Whiteside</el-checkbox>
        </el-checkbox-group>
      </div>
    </el-col>
    <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4"  v-show="checkvalue[9].isshow1"><div class="grid-content bg-purple">旋转</div></el-col>
      <el-col :xs="20" :sm="20" :md="20" :lg="20" :xl="20" v-show="checkvalue[9].isshow1"><div class="grid-content">
       <el-input :disabled="othermodify" v-model="checkvalue[9].inputvalue1" />
      </div>
    </el-col>
   <!-- 股骨假体旋转 -->
      <!-- 测试屈伸间隙 -->
      <el-col :xs="24" :sm="6" :md="4" :lg="4" :xl="4"><div class="grid-content bg-purple">测试屈伸间隙</div></el-col>
      <el-col :xs="24" :sm="18" :md="20" :lg="20" :xl="20" ><div class="grid-content" style="border: solid 1px rgba(134, 134, 134, 0.507)">
        <el-checkbox-group v-model="checkvalue[8].value1"  :min="0" :max="1">
          <el-checkbox :disabled="othermodify" v-for="(item,index) in checkbox2" :key="index" :label="item">{{item}}</el-checkbox>
        </el-checkbox-group>
      </div>
    </el-col>
    <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4" v-show="checkvalue[8].isshow1"><div class="grid-content bg-purple">后髁截骨厚度</div></el-col>
      <el-col :xs="24" :sm="24" :md="20" :lg="20" :xl="20" v-show="checkvalue[8].isshow1">
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-input :disabled="othermodify" v-model="checkvalue[8].inputvalue1">
            <template slot="prepend">内后髁</template>
            <template slot="append">mm</template>
          </el-input>
        </el-col>    
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-input :disabled="othermodify" v-model="checkvalue[8].inputvalue2">
            <template slot="prepend">外后髁</template>
            <template slot="append">mm</template>
          </el-input>
        </el-col>    
    </el-col>
    <el-col :xs="24" :sm="24" :md="24" :lg="4" :xl="4" v-show="checkvalue[8].isshow2"><div class="grid-content bg-purple"><label>屈曲>伸直</label></div></el-col>
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" v-show="checkvalue[8].isshow2">
        <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
          <el-input :disabled="othermodify" v-model="checkvalue[8].inputvalue3">
            <template slot="prepend">增加股骨远端截骨</template>
            <template slot="append">mm</template>
          </el-input>
        </el-col>  
        <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
          <el-input :disabled="othermodify" v-model="checkvalue[8].inputvalue4">
            <template slot="prepend">内后髁</template>
            <template slot="append">mm</template>
          </el-input>
        </el-col>  
        <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">  
          <el-input :disabled="othermodify" v-model="checkvalue[8].inputvalue5">
            <template slot="prepend">外后髁</template>
            <template slot="append">mm</template>
          </el-input>
        </el-col>
      </el-col>
    <el-col :xs="24" :sm="6" :md="4" :lg="4" :xl="4" v-show="checkvalue[8].isshow3"><div class="grid-content bg-purple"><label>屈曲<伸直</label></div></el-col>
      <el-col :xs="24" :sm="18" :md="20" :lg="20" :xl="20" v-show="checkvalue[8].isshow3"><div class="grid-content" style="border: solid 1px rgba(134, 134, 134, 0.507)">
        <el-checkbox-group v-model="checkvalue[8].value2" >
          <el-checkbox :disabled="othermodify" label="选小一号股骨假体">选小一号股骨假体</el-checkbox>
          <el-checkbox :disabled="othermodify" label="加胫骨">加胫骨</el-checkbox>
        </el-checkbox-group>
        </div>
      </el-col>

    <el-col :xs="8" :sm="6" :md="4" :lg="4" :xl="4" v-show="checkvalue[8].isshow4"><div class="grid-content bg-purple">加胫骨后倾约度数</div></el-col>
      <el-col :xs="16" :sm="18" :md="20" :lg="20" :xl="20" v-show="checkvalue[8].isshow4"><div class="grid-content">
       <el-input :disabled="othermodify" v-model="checkvalue[8].inputvalue8"><template slot="append">°</template></el-input>
      </div>
    </el-col>

    <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" v-show="checkvalue[8].isshow4">
      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
        <el-input :disabled="othermodify" v-model="checkvalue[8].inputvalue6">
          <template slot="prepend">外后髁</template>
          <template slot="append">mm</template>
        </el-input>
      </el-col>  
      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
        <el-input :disabled="othermodify" v-model="checkvalue[8].inputvalue7">
          <template slot="prepend">外后髁</template>
          <template slot="append">mm</template>
        </el-input>
      </el-col>    
    </el-col>        

      <!-- 测试屈伸间隙 -->
    </el-row>
  </div>
</template>

<script>
export default {
  props:["message","othermodify"],
  data() {
    return {
      checkbox1: ["髓内", "髓外", "导航", "PSI", "机器人", "其他"],
      checkbox2:["申屈间隙平衡良好","屈曲>伸直","屈曲<伸直"],
      checkvalue: [
        {
          value1: [],
          value2: [],
          other1: "",
          other2: "",
          isshow1: false,
          isshow2: false,
          isshow3: false,
        },
        {
          inputvalue1:'',
          inputvalue2:''
        } ,
        {
          inputvalue1:'',
          inputvalue2:''
        },
        {
          value1: [],
          value2: [],
          other1: "",
          other2: "",
          isshow1: false,
          isshow2: false,
          isshow3: false,
        }, {
          inputvalue1:'',
          inputvalue2:''
        } ,
        {
          inputvalue1:'',
          inputvalue2:'',
          inputvalue3:''
        } ,   
        {
          value1: [],
          value2: [],
          value3: [],
          inputvalue1: "",
          inputvalue2: "",
          other1:'',
          isshow1: false,
          isshow2: false,
          isshow3: false,
        },{
          value1:[], value2:[],inputvalue1:'',inputvalue2:'',inputvalue3:'',inputvalue4:'',isshow1:false,isshow2:false
        },
        {
          value1:[],value2:[],inputvalue1:'',inputvalue2:'',inputvalue3:'',inputvalue4:'',inputvalue5:'',inputvalue6:'',inputvalue7:'',inputvalue8:'',isshow1:false,isshow2:false,isshow3:false,isshow4:false
        },{
          value1:[],value2:[],inputvalue1:'',isshow1:false,isshow2:false
        }
      ],
    };
  },
  watch: {
    message:{
      handler(newvalue,old){
        for(let i =0;i<newvalue.length;i++){
          let obj  = newvalue[i]
          // console.log(obj,'obj')
          for(let key in obj){
            // console.log(obj[key],'objkey')
            this.checkvalue[i][key]=obj[key]
          }
        }
      },deep:true
    },
    checkvalue: {
      handler(newvalue, oldvalue) {
        let a11 = newvalue[0].value1;
        let a12 = newvalue[0].value2;
        let a31 = newvalue[3].value1;
        let a32 = newvalue[3].value2;
        let a62 = newvalue[6].value1;
        let a61 = newvalue[6].value2;
        let a63 = newvalue[6].value3;
        // let a71 = newvalue[7].value1;
        let a72 = newvalue[7].value2;
        let a81 = newvalue[8].value1;
        let a82 = newvalue[8].value2;
        let a91 = newvalue[9].value1;
        // 股骨假体旋转
        if (a91.includes("旋转")) {
          newvalue[9].isshow1 = true;
        } else  if (a91.includes("测量截骨")) {
          newvalue[9].isshow2 = true;
        } else{
          newvalue[9].isshow1 = false;
          newvalue[9].isshow2 = false;
        }
        // 股骨假体旋转
        // 测试屈伸间隙
        if (a81.includes("申屈间隙平衡良好")) {
          newvalue[8].isshow1 = true;
        } else if (a81.includes("屈曲>伸直")) {
          newvalue[8].isshow2 = true;
        }else if (a81.includes("屈曲<伸直")) {
          newvalue[8].isshow3 = true;
        } else {
          newvalue[8].isshow1 = false;
          newvalue[8].isshow2 = false;
          newvalue[8].isshow3 = false;
        }
        if (a82.includes("加胫骨")) {
          newvalue[8].isshow4 = true;
        } else {
          newvalue[8].isshow4 = false;
        }
        // 测试屈伸间隙
        // 测骨假体大小为
        if (a72.includes("明确假体大小")) {
          newvalue[7].isshow1 = true;
        } else if (a72.includes("不明确假体大小")) {
          newvalue[7].isshow2 = true;
        } else {
          newvalue[7].inputvalue1=''
          newvalue[7].inputvalue2=''
          newvalue[7].inputvalue3=''
          newvalue[7].isshow1 = false;
          newvalue[7].isshow2 = false;
        }
        // 测骨假体大小为
        // 测试伸直间隙
        if (a62.includes("内侧狭窄")) {
          newvalue[6].isshow1 = true;
        } else if (a62.includes("外侧狭窄")) {
          newvalue[6].isshow2 = true;
        } else {
          while (a61.length > 0) {
            newvalue[6].value2.pop();
          }
          newvalue[6].isshow1 = false;
          newvalue[6].isshow2 = false;
        }
        if (a63.includes("其他")) {
          newvalue[6].isshow3 = true;
        } else {
          newvalue[6].other1 = "";
          newvalue[6].isshow3 = false;
        }
        // 测试伸直间隙
        // 股骨远端
        if (a11.includes("机器人")) {
          newvalue[0].isshow1 = true;
        } else if (a11.includes("其他")) {
          newvalue[0].isshow3 = true;
        } else {
          while (a12.length > 0) {
            newvalue[0].value2.pop();
          }
          newvalue[0].other1 = "";
          newvalue[0].isshow1 = false;
          newvalue[0].isshow3 = false;
        }
        if (a12.includes("其他")) {
          newvalue[0].isshow2 = true;
        } else {
          newvalue[0].other2 = "";
          newvalue[0].isshow2 = false;
        }
        // 股骨近端
        if (a31.includes("机器人")) {
          newvalue[3].isshow1 = true;
        } else if (a31.includes("其他")) {
          newvalue[3].isshow3 = true;
        } else {
          while (a32.length > 0) {
            newvalue[3].value2.pop();
          }
          newvalue[3].other1 = "";
          newvalue[3].isshow1 = false;
          newvalue[3].isshow3 = false;
        }
        if (a32.includes("其他")) {
          newvalue[3].isshow2 = true;
        } else {
          newvalue[3].other2 = "";
          newvalue[3].isshow2 = false;
        }
        this.$emit('trandition',newvalue)
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.inputvalue{
    max-width: 160px;
}
</style>