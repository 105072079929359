<template>
  <div class="review">
    <!-- 弹窗执行步骤填写部分 -->
    <el-dialog :title="titlevalue" :visible.sync="dialogVisible" :width="diawid">
      <el-progress :percentage="percentage" :color="customColors"></el-progress>
      <el-form :v-model="selectForm" class="systemform">
        <el-form-item
          :label="item.name"
          v-for="(item, index) in selectForm"
          v-show="index + 1 == next"
          :key="index"
          label-width="60px"
        >
          <el-checkbox-group
            v-model="checkedCities[index].value"
            @change="handleChange"
          >
            <el-checkbox
              v-for="(listname, index) in item.list"
              :label="listname"
              :key="index"
              >{{ listname }}</el-checkbox
            >
          </el-checkbox-group>
          <el-input
            v-model="checkedCities[8].inputvalue"
            v-show="other.show"
            placeholder="请填写除梅毒，艾滋外的内容"
          ></el-input>
        </el-form-item>
        <el-row :gutter="10" class="drugname" v-show="showdrug">
          <el-col :span="12"
            ><div class="grid-content bg-purple">药物</div></el-col
          >
          <el-col :span="12"
            ><div class="grid-content bg-purple">剂量</div></el-col
          >
        </el-row>
        <el-form-item
          v-for="(item, index3) in drug"
          :key="'s' + index3"
          class="drugclass"
          v-show="showdrug"
        >
          <el-row :gutter="10">
            <el-col :span="12"
              ><div class="grid-content bg-purple">
                <el-input v-model="item.drugname" /></div
            ></el-col>
            <el-col :span="12"
              ><div class="grid-content bg-purple">
                <el-input v-model="item.drugdosage" /></div
            ></el-col>
          </el-row>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="back" type="warning">上一步</el-button>
        <el-button @click="nextlist" type="primary" v-show="nextbtn"
          >下一步</el-button
        >
        <el-button @click="nextdrug" type="primary" v-show="!nextbtn"
          >前往填写服用药物</el-button
        >
        <el-button type="danger" @click="closedialog">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 正文全部显示 -->
    <!-- 显示系统回顾部分 -->
    <el-form :v-model="selectForm" v-show="!showbtn" class="systemform">
      <div class="titlename">系统回顾</div>
      <el-form-item
        :label="item.name"
        v-for="(item, index) in selectForm"
        :key="index"
        label-width="70px"
      >
        <el-checkbox-group
          v-model="checkedCities[index].value"
          @change="handleChange"
        >
          <el-checkbox
            v-for="(listname, index2) in item.list"
            :label="listname"
            :key="index2 + 'a'"
            :disabled="modify"
            >{{ listname }}</el-checkbox
          >
        </el-checkbox-group>
      </el-form-item>
      <!-- 显示当前正在服用的药物 -->
      <el-input
        v-model="checkedCities[8].inputvalue"
        v-show="other.show"
        placeholder="请填写除梅毒，艾滋外的内容"
        :disabled="modify"
      ></el-input>

      <div class="titlename" style="margin-top: 10px;">目前正在服用的药物</div>
      <el-row :gutter="10" class="drugname">
        <el-col :span="12"
          ><div class="grid-content bg-purple">药物</div></el-col
        >
        <el-col :span="12"
          ><div class="grid-content bg-purple">剂量</div></el-col
        >
      </el-row>
      <el-form-item
        v-for="(item, index3) in drug"
        :key="'s' + index3"
        class="drugclass"
      >
        <el-row :gutter="10">
          <el-col :span="12"
            ><div class="grid-content bg-purple">
              <el-input v-model="item.drugname" :disabled="modify" /></div
          ></el-col>
          <el-col :span="12"
            ><div class="grid-content bg-purple">
              <el-input v-model="item.drugdosage" :disabled="modify" /></div
          ></el-col>
        </el-row>
      </el-form-item>
      <el-form-item class="drugclass">
        <el-row :gutter="10">
          <el-col :span="24"
            ><div class="grid-content">
              <el-button type="warning" @click="adddrug" :disabled="modify"
                >增加</el-button
              ><el-button type="danger" @click="movedrug" :disabled="modify"
                >删除</el-button
              >
            </div></el-col
          >
        </el-row>
      </el-form-item>
    </el-form>
    <el-button type="primary" @click="Gofillin" v-show="showbtn">去填写</el-button>
    <div class="modifybtn" v-show="!showbtn">
      <el-button type="primary" icon="el-icon-edit" circle @click="tomodify" v-show="modify"></el-button>
      <!-- <el-button type="warning" @click="tomodify" v-show="modify"
        >修改</el-button
      > -->
      <div v-show="!modify" >
        <el-row>
          <el-button type="success" icon="el-icon-check" circle @click="sure"></el-button>
        </el-row>
        <el-row>
          <el-button type="danger" icon="el-icon-close" circle @click="cancel"></el-button>
        </el-row>
      </div>

      <!-- <el-button type="success" @click="sure" v-show="!modify">确定</el-button>
      <el-button type="danger" @click="cancel" v-show="!modify">取消</el-button> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      diawid:'40%',
      operation_id: "",
      titlevalue: "填写系统回顾",
      next: 1,
      showbtn: true,
      selectForm: [
        {
          name: "系统",
          list: ["疲劳", "体重改变","发热"],
          checkedCities: [],
        },
        {
          name: "肠胃",
          list: ["恶心/呕吐", "肠溃疡", "腹泻", "便血", "便秘"],
          checkedCities: [],
        },
        {
          name: "神经",
          list: ["偏头疼", "麻木刺痛", "抽搐", "头晕"],
          checkedCities: [],
        },
        {
          name: "皮肤",
          list: ["皮疹/疮", "皮肤溃疡", "癌痒/烧感"],
          checkedCities: [],
        },
        {
          name: "心血管",
          list: ["胸痛", "心慌", "高血压", "小腿/脚踝水肿"],
          checkedCities: [],
        },
        {
          name: "血液",
          list: ["贫血", "易起瘀斑", "出血"],
          checkedCities: [],
        },
        {
          name: "肌骨",
          list: ["关节痛", "关节僵", "关节肿", "背痛"],
          checkedCities: [],
        },
        {
          name: "呼吸",
          list: ["气短", "呼吸困难", "喘息/哮喘", "慢性咳嗽"],
          checkedCities: [],
        },
        {
          name: "其他",
          list: ["梅毒", "艾滋", "其他"],
          checkedCities: [],
        },
      ],
      drug: [
        { drugname: "无", drugdosage: "无" },
        { drugname: "无", drugdosage: "无" },
        { drugname: "无", drugdosage: "无" },
      ],
      other: { show: false, value: "" },
      checkedCities: [
        { value: [] },
        { value: [] },
        { value: [] },
        { value: [] },
        { value: [] },
        { value: [] },
        { value: [] },
        { value: [] },
        { value: [], inputvalue: "" },
      ],
      dialogVisible: false,
      nextbtn: true,
      percentage: 10,
      customColors: [
        { color: "#1989fa", percentage: 80 },
        { color: "#6f7ad3", percentage: 100 },
      ],
      showdrug: false,
      modify: true,
      
    };
  },
  // watch:{
  //   checkedCities:{
  //     handler(value,old){
  //         console.log(value)
  //     },
  //     deep:true
  //   }
  // },
  created() {
    this.operation_id = this.$route.query.room;
    
    // console.log()
  },
  mounted(){
    this.getinfo();
    let wid = window.innerWidth
    this.diawid = wid <=480 ? '90%':wid >=1200 ? "40%" : '80%'
  },
  methods: {
    async getinfo() {
      try{
      const { data: res } = await this.$http.get(
        "/Operation/getPreOperationInfo/" + this.operation_id,
        {
          params: {
            pre_type: "系统回顾",
          },
        }
      );
      // console.log(res.data, "arr");
      if (res.status == 1) {
        if (res.data.system_review.length !=0) {
          this.showbtn = false;
          let arr = res.data.system_review;
         
          for (let i = 0; i < arr.length; i++) {
            let obj = arr[i];
            this.checkedCities[i].value = obj.value;
            if (obj.inputvalue) {
              this.checkedCities[i].inputvalue = obj.inputvalue;
              if (obj.inputvalue != "") {
                this.other.show = true;
              }
            }
          }
          let drug = res.data.medicine_take,
            druglength = this.drug.length;
          let reslength =
            druglength > drug.length
              ? druglength
              : res.data.medicine_take.length;
          for (let d = 0; d < reslength; d++) {
            if (drug.length < this.drug.length && d >= drug.length) {
              this.drug.splice(this.drug.length - 1, 1);
            } else if (drug.length < druglength && d < drug.length) {
              this.drug[d].drugname = drug[d].drugname;
              this.drug[d].drugdosage = drug[d].drugdosage;
            } else if (drug.length > druglength && d + 1 > druglength) {
              this.drug.push(drug[d]);
            } else if (drug.length > druglength && d + 1 <= druglength) {
              this.drug[d].drugname = drug[d].drugname;
              this.drug[d].drugdosage = drug[d].drugdosage;
            }
          }
        } else {
        }
      }
    }catch(err){

    }
      // console.log(res, "asdasd");
    },
    //点击修改
    tomodify() {
      this.modify = !this.modify;
    },
    // 点击确定
    async sure() {
      this.modify = !this.modify;
      const { data: res } = await this.$http.post(
        "/Operation/addPreOperationInfo/" + this.operation_id,
        {
          system_review: this.checkedCities,
          medicine_take: this.drug,
        }
      );
      if (res.status == "1") {
        this.$message.success("提交成功")
        await this.getinfo();
      }
    },
    cancel() {
      this.modify = !this.modify;
    },
    // 点击填写下一步
    nextlist() {
      let selectvalue = this.selectForm;
      this.next =
        this.next < selectvalue.length ? this.next + 1 : selectvalue.length;
      if (this.next == selectvalue.length) {
        this.nextbtn = false;
        this.percentage = this.percentage + 10;
      } else {
        this.percentage = this.percentage + 10;
      }
    },
    // 点击返回填写上一步
    back() {
      this.titlevalue =
        this.titlevalue == "目前正在服用的药物"
          ? "填写系统回顾"
          : this.titlevalue;
      this.next = this.next - 1 <= 1 ? 1 : this.next - 1;
      this.percentage = this.percentage > 10 ? this.percentage - 10 : 10;
      this.showdrug = false;
      this.nextbtn = this.next >= 9 ? false : true;
    },
    // 点击进行步骤填写
    Gofillin() {
      this.titlevalue = "填写系统回顾";
      this.dialogVisible = true;
    },
    // 关闭弹窗和进行多选项的显示
    async closedialog() {
     
      let value = JSON.stringify(this.checkedCities);
      const { data: res } = await this.$http.post(
        "/Operation/addPreOperationInfo/" + this.operation_id,
        {
          system_review: this.checkedCities,
          medicine_take: this.drug,
        }
      );
      if(res.status=='1'){
        this.$message.success("提交成功")
        this.dialogVisible = false;
      this.showbtn = false;
      }
      // console.log(res, this.checkedCities);
    },
    // 点击获取多选项的值
    handleChange(value) {
      if (value.includes("其他")) {
        this.other.show = true;
      } else {
        this.other.show = false;
      }
    },
    // 填写服用药物
    nextdrug() {
      this.titlevalue = "目前正在服用的药物";
      this.showdrug = true;
      this.next = 10;
      this.percentage = this.percentage >=100 ? 100: this.percentage+ 10;
    },
    // 增加正在服用药物列表
    adddrug() {
      this.drug.push({ drugname: "无", drugdosage: "无" });
    },
    movedrug() {
      let value = this.drug;
      this.drug.splice(value.length - 1, 1);
    },
  },
};
</script>

<style >
.review {
  /* position: relative; */
  height: fit-content;
  box-sizing: border-box;
  padding: 10px;
  /* background-color: aqua; */
}
.systemform .el-form-item {
  /* height: 43px; */
  margin-top: 10px !important;
  margin-bottom: 0px !important;
  text-align: start;
  border: 1px solid #e7e8e9;
}
/* .el-form-item__content {
  margin-left: 10px !important;
} */
.systemform .el-form-item__label {
  line-height: 43px;
  width: fit-content !important;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #d4dce5;
  border-radius: 5px;
  background-color: #d4dce5;
  padding: 0 10px;
  
  /* margin-right: 15px; */
}
.systemform .el-checkbox-group{
  margin-left: 15px;
}
.el-progress {
  margin-bottom: 15px;
}
.titlename {
  height: 40px;
  width: 100%;
  background: #616876;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  color: white;
  box-sizing: border-box;
  padding: 0 15px;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.grid-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.drugclass .el-form-item__content {
  margin-left: 0px !important;
}
.drugname {
  margin-bottom: 10px;
}
.modifybtn {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: fit-content;
  box-sizing: border-box;
  /* padding: 0px 10px; */
  border-radius: 4px;
  right: 15%;
  top: 60%;
}
.modifybtn .el-button{
  margin-bottom: 5px;
}
@media screen and (max-width: 600px) {
  .modifybtn {
  right: 3%;
  top: 75%;
 
}
}
</style>