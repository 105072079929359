<template>
  <div class="infomation">
    <div class="toproom">
      <div>编号：{{ list.serial_number }}</div>
      <div>医院ID：{{ list.hospital_id }}</div>
    </div>
    <div class="maininfomation">
      <div class="lists">
        <label><i class="el-icon-user" style="color: rgb(87 157 248);" /> {{ list.patient__patients_name }}</label>
        <i class="el-icon-male" style="color: rgb(250, 0, 0); font-weight: bold;" v-if="list.patient__gender == '女'"></i>
        <i class="el-icon-female" style="color: rgb(9, 6, 204); font-weight: bold;" v-if="list.patient__gender == '男'"></i>
        <el-tag type="" effect="plain" size="small" style="margin-left: 10px;">{{ list.age }}岁</el-tag>
      </div>
      <el-row :gutter="10" style="text-align: left;">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <div class="flex flexstart">
            <p><i class="el-icon-warning-outline" style="color: rgb(151, 12, 12);" /> 患病关节：{{ list.operation_site }}</p>
            <p><i class="el-icon-collection-tag" style="color: rgb(3, 192, 19);" /> 费用类型：{{ list.pay_type }}</p>
            <p><i class="el-icon-price-tag" style="color: rgb(87 157 248);" /> 职业：{{ list.patient__occupation }}</p>
          </div>  
        </el-col>

        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <div class="flex flexstart">
            <p><i class="el-icon-phone-outline" style="color: rgb(87 157 248);" /> 联系电话：{{ list.patient__phone|filterphone }}</p>
            <p><i class="el-icon-coin" style="color: rgb(87 157 248);" /> 身高体重：{{ list.stature }}CM | {{ list.weight }}KG（BMI：{{ list.bmi }}）</p>
          </div>  
        </el-col>

      </el-row>
    </div>
    <div  class="seldiv" v-show="$store.state.isshowbreadcrumb == '1'">
      <b-breadcrumb style="margin-bottom: auto;">
        <b-nav-item 
        :class="[index == issel ? 'sel' : '', 'seldivs']"
            v-for="(item, index) in sellist" :key="index">
            <!--   v-show="!(list.operation_name == '髋关节置换' && item == '手术信息' )" -->
        <div  @click="selbtn(index)" >{{ item }}</div>
      </b-nav-item>
      </b-breadcrumb>
    </div>
    <!-- 检验信息一级面包屑 -->
    <div  class="seldiv" v-show="$store.state.isshowbreadcrumb == '3'">
      <b-breadcrumb style="margin-bottom: auto;">
        <b-nav-item class="seldivs">
          检验信息
      </b-nav-item>
      </b-breadcrumb>
    </div>
  <!-- {{ list }} -->
    <div  v-show="$store.state.isshowbreadcrumb == '2'">
      <b-breadcrumb class="responsive-tabs"  v-show="issel == 0" >
        <b-nav-item 
        :class="[index == secissel ? 'sel' : '', 'seldivs']"
        v-for="(item, index) in secseclist" :key="'secseclist'+index" :label="item.name"  v-show="list.operation_site == '髋' ? item.name!='步行能力测定':item.name!='术前诊断及手术方式'">
        <b-nav-item  @click="secselbtn({ index: index, path: item.path })" >{{ item.name }}</b-nav-item>
      </b-nav-item>
      </b-breadcrumb>
    </div>
    <!-- 围手术期术前术后 -->
    <!-- <div >
      <b-breadcrumb class="responsive-tabs"  v-show="issel == 1" >
        <b-nav-item 
        :class="[index == secissel ? 'sel' : '', 'seldivs']"
        v-for="(item, index) in periodlist" :key="index" :label="item.name">
        <b-nav-item  @click="secselbtn({ index: index, path: item.path })" >{{ item.name }}</b-nav-item>
      </b-nav-item>
      </b-breadcrumb>
    </div> -->
    <!-- 检验信息术前术后 -->
    <div v-show="$store.state.isshowbreadcrumb == '3'">
      <b-breadcrumb class="responsive-tabs">
        <b-nav-item 
        :class="[index == secissel ? 'sel' : '', 'seldivs']"
        v-for="(item, index) in jianyanlist" :key="index" :label="item.name">
        <b-nav-item  @click="secselbtn({ index: index, path: item.path })" >{{ item.name }}</b-nav-item>
      </b-nav-item>
      </b-breadcrumb>
    </div>
    <!-- 检查信息 -->
    <div v-show="$store.state.isshowbreadcrumb == '4'">
      <b-breadcrumb class="responsive-tabs"  >
        <b-nav-item 
        :class="[index == secissel ? 'sel' : '', 'seldivs']"
        v-for="(item, index) in jianchalist" :key="index" :label="item.name">
        <b-nav-item  @click="secselbtn({ index: index, path: item.path })" >{{ item.name }}</b-nav-item>
      </b-nav-item>
      </b-breadcrumb>
    </div>

    <div class="infrouter">
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
export default {
  data() {
    return {
      aaa:'阿是大师课就',
      operation_id: "",
      list: {
        room: "778456",
        roomdata: "2023-08-30",
        name: "测试",
        sex: 0,
        age: "78",
        type: "居民医保",
        phone: "14715813223",
        loca: "",
        profession: "农民",
        weight: "55",
        hei: "160",
        bim: "21.48",
        bir: "1951-11-17",
      },
      sellist: [ "手术信息","评分记录", "影像资料",],
      sellistvalue:["/insurgery","/ScoringRecords","/imagedata",],
      secseclist: [
        { name: "现病史", path: "/presentHistory" },
        { name: "既往史", path: "/PastHistory" },
        { name: "个人史", path: "/personhistory" },
        { name: "家族史", path: "/familyhistory" },
        { name: "系统回顾", path: "/systemreview" },
        { name: "专科体查", path: "/physical" },
        { name: "步行能力测定", path: "/walking" },
      ],
      // periodlist:[
      //   { name: "术前", path: "/Preoperative" },
      //   { name: "术后", path: "/Postoperative" },
      // ],
      jianyanlist:[
        { name: "术前", path: "/preinspdata" },
        { name: "术后", path: "/Postoperative" },
      ],
      jianchalist:[
        { name: "检查信息",path: "/preCheckData" },
      ],
      issel: 0,
      secissel: 0,
      tobreadcrumb:true
    };
  },
  filters:{
    filterphone(value){
      let arr = []
      for(let key in value){
        arr.push(value[key])
      }
      // console.log(value)
      return arr.toString()
    }
  },
  computed: {
    ...mapState(["level1", "level2"]),
  },
  watch:{
    level1(){
      this.issel = this.$store.getters.getlevel1
      this.secissel = this.$store.getters.getlevel2
      this.tobreadcrumb = this.$store.getters.getisshowbreadcrumb
    }
  },
  created() {
    this.operation_id = this.$route.query.room;
    this.issel = this.$store.getters.getlevel1
      this.secissel = this.$store.getters.getlevel2
      this.tobreadcrumb = this.$store.getters.getisshowbreadcrumb
      this.getinfo();
      // console.log( this.tobreadcrumb)
  },
  mounted() {
    // this.getinfo();
    if(this.tobreadcrumb=='true'){
      this.$store.commit('changelevel1',0)

      // this.$router
      //   .push({
      //     path:'/insurgery',
      //     query: {
      //       room: this.operation_id,
      //       site: this.list.operation_site
      //     },
      //   })
    }
    // console.log(sessionStorage.getItem('issel'))
  },
  methods: {
    selbtn(value) {
      let site = this.list.operation_site
      console.log(value,site);
      this.$store.commit('changelevel1',value)
      this.$store.commit('changelevel2',0)
      if(value=='1' && site =='膝关节内侧单髁置换'){
        // console.log(1);
        this.$router
        .push({
          path: "/insutgerypage",
          query: {
            room: this.operation_id,
          },
        })
        .catch((e) => {
        });

      // }else if(value=='1' && site=='髋'){
      //   // console.log(2);
      //   this.$router
      //   .push({
      //     path:'/pdasm',
      //     query: {
      //       room: this.operation_id,
      //       site: this.list.operation_site
      //     },
      //   })
      //   .catch((e) => {
      //   });

      } else if(value=='3'){
        // console.log(3);
        if(site=='髋'){
          this.$router
        .push({
          path: '/jouinview',
          query: {
            room: this.operation_id,
            site: this.list.operation_site
          },
        }).catch((e) => {
        });
        }else{
        // console.log('else1');
           this.$router
        .push({
          path: this.sellistvalue[value],
          query: {
            room: this.operation_id,
            site: this.list.operation_site
          },
        }).catch((e) => {
        });
        }
       
      } else{
        // console.log('else2');
        this.$router
        .push({
          path: this.sellistvalue[value],
          query: {
            room: this.operation_id,
            site: this.list.operation_site,
          },
        })
        .catch((e) => {
        });
      }
    },
    secselbtn(value) {
      this.secissel = value.index;
      this.$store.commit('changelevel2',value.index)

      if(value.index==2){ //跳转个人史
        this.$router
        .push({
          path: "/personhistory",
          query: {
            room: this.operation_id,
            gender:this.list.patient__gender,
            site: this.list.operation_site
          },
        })
        .catch((e) => {
        });
      }else{
      this.$router
        .push({
          path: value.path,
          query: {
            room: this.operation_id,
            site: this.list.operation_site
          },
        })
        .catch((e) => {
        });
      }
    },
    // 获取相关信息
    async getinfo() {
    const { data: res } = await this.$http.get(
      "/Operation/getOperationPatientInfo/" + this.operation_id
    );
    if(res.status == '1'){
      this.list=res.data
    }else{
        this.$message.error(res.message)
    }
      },
        },
 
};
</script>

<style scoped>
.infomation {
  overflow: auto;
  width: 100%;
  min-height: calc(100% - 10px);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  border: 1px solid #e6e7e9;
  margin-bottom: 10px;
  border-radius: 4px;
  box-sizing: border-box;
}
.toproom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 10px;
  height: 50px;
  /* width: 100%; */
  border-bottom: 1px solid #e6e7e9;
}
.lists {
  height: 24px;
  width: 100%;
  display: flex;
  /* border: 1px solid red; */
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
  flex-wrap: wrap;
}
.maininfomation {
  height: fit-content;
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
}
.maininfomation label {
  margin-right: 10px;
}
.seldiv {
  width: 100%;
  min-height: 38px;
  border: 1px solid #e6e7e9;
  border-radius: 4px;
  display: flex;
  margin-bottom: 5px;
}
.seldivs {
  border: 1px solid transparent;
  width: fit-content;
  min-height: 38px;
  border-radius: 4px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.seldivs:hover {
  border: 1px solid #e7e8e9;
}
.sel {
  background-color: #f4f4f5;
  border: 1px solid #e7e8e9;
}
.seldivlist {
  box-sizing: border-box;
  border: 1px solid transparent;
  width: fit-content;
  height: 38%;
  border-radius: 4px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.infrouter {
  height: fit-content;
  width: 100%;
  box-sizing: border-box;
}
.flex{
  display: flex;
  align-items: center;
}
.flexstart{
  justify-content: flex-start;
}
.flexstart p{
  margin-right: 20px;
}
.flexend{
  justify-content: flex-end;
}
.flexmiddle{
  justify-content: center;
}
@media screen and (max-width: 680px) {
  .flex{
    display: contents;
  }
  .flexstart p{
    margin-right: 0px;
  }
}
</style>