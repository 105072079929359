<template>
  <div class="joinview">
    <el-dialog
        title="填写评分"
        :visible.sync="dialogVisible"
        :width="diawidth"
        >
       <div class="addrecorddia">
        <el-input v-model="addrecords[0].name">
            <template slot="prepend">评估医生</template>
        </el-input>
        <el-row>
            <el-col :xs="3" :sm="3" :md="3" :lg="3" :xl="3"><div class="grid-content bg-purple-light">手术</div></el-col>
            <el-col :xs="21" :sm="21" :md="21" :lg="21" :xl="21"><div class="grid-content">
                <el-radio-group v-model="addrecords[1].shoushu" @change="addrecordshoushu">
                    <el-radio v-for="(item,index) in shoushu" :label="item" :key="index">{{item}}</el-radio>
                </el-radio-group>
            </div></el-col>
            <el-col v-show="addrecords[1].shoushu=='部分翻修'" :xs="24" :sm="24" :md="24" :lg="24" :xl="24"><div class="grid-content">
                <el-radio-group v-model="addrecords[1].shoushuer" @change="addrecordshoushu">
                    <el-radio v-for="(item,index) in onepart" :label="item" :key="index">{{item}}</el-radio>
                </el-radio-group>
            </div></el-col>
            <el-col :xs="3" :sm="3" :md="3" :lg="3" :xl="3"><div class="grid-content bg-purple-light">评估日期</div></el-col>
            <el-col :xs="21" :sm="21" :md="21" :lg="21" :xl="21"><div class="grid-content">
                <el-date-picker
                    v-model="addrecords[2].datavalue"
                    style="width: 100%;"
                    type="date"
                    placeholder="选择日期"
                    format="yyyy 年 MM 月 dd 日"
                    value-format="yyyy-MM-dd">
                    </el-date-picker>
            </div></el-col>
        </el-row>
       </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="toaddrecords">确 定</el-button>
        </span>
    </el-dialog>
     <!-- 填写评分记录 -->
     <el-dialog
      title="填写评分"
      :visible.sync="scoredialog"
      :width="diawidth"
      >
        <vas @vasemit="getvasvalue" v-show="next==1"></vas>
        <hoosVue @hoosemit="gethoosvalue"  v-show="next==2"></hoosVue>
        <harris @harrisemit="getharrisvallue" v-show="next==3"></harris>
        <SF12 @sf12emit="getsf12value" v-show="next==4"> </SF12>
        <Promise @promiseemit="getpromisevalue" v-show="next==5"></Promise>
      <span slot="footer" class="dialog-footer">
        <el-button @click="tolast" :disabled="next=='1'">上一步</el-button>
            <el-button @click="tonext" :disabled="next=='5'">下一步</el-button>
        <el-button type="danger" @click="toscoredialog" :disabled="next !=5">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 查看评分记录 -->
    <el-dialog
        title="评分记录"
        :visible.sync="checkdialog"
        :width="diawidth"
       >
        <vas :message="vasprop"></vas>
        <hoosVue :message="hoosprop"></hoosVue>
        <harris :message="harrisprop"></harris>
        <SF12 :message="sf12prop"></SF12>
        <Promise :message="promisprop"></Promise>
        <span slot="footer" class="dialog-footer">
            <el-button @click="checkdialog = false">取 消</el-button>
            <el-button type="primary" @click="checkdialog = false">确 定</el-button>
        </span>
        </el-dialog>
    <el-button type="primary" @click="addrecord">追加评分记录</el-button>
    <el-row class="titrow">
        <el-col :xs="6" :sm="4" :md="4" :lg="4" :xl="4"><div class="grid-content bg-purple">评估医师</div></el-col>
        <el-col :xs="14" :sm="16" :md="16" :lg="16" :xl="16"><div class="grid-content bg-purple-light">
            <div class="collapsetitle">评估时间</div>
            <div class="collapsetitle">评估时间</div>
            <!-- <div class="collapsetitle">操作</div> -->
        </div></el-col>
        <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4"><div class="grid-content bg-purple">操作</div></el-col>
    </el-row>
    <div >
    <el-collapse v-model="activename" >
    <el-collapse-item v-for="(item,index) in activevalue" :name="index" :key="index">
    <template slot="title">
        <el-row class="slottitlerow">
        <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4"><div class="grid-content ">{{ item.assessor }}</div></el-col>
        <el-col :xs="16" :sm="16" :md="16" :lg="16" :xl="16"><div class="grid-content">
            <div class="collapse-itemp">{{ item.date }}</div>
            <div class="collapse-itemp">{{item.timestamp}}</div>
          
        </div></el-col>
        <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4"><div class="grid-content">
            <div v-if="showscords[index]" class="collapse-itemp"><el-button type="primary" size="small" @click.native.stop="tocheck(item.scoring_id)">评分记录</el-button></div>
            <div v-else class="collapse-itemp"><el-button type="warning" size="small" @click="scoredialogbtn(item.scoring_id)">评分</el-button></div>
        </div></el-col>
    </el-row>
    </template>
    <el-row v-show="showscords[index]">
        <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6"><div class="grid-content bg-purple-light">VAS</div></el-col>
        <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6"><div class="grid-content ">{{item | vas2scord}}分</div></el-col>

        <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6"><div class="grid-content bg-purple-light">HOOS JR</div></el-col>
        <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6"><div class="grid-content ">{{item.Mental==null ? 0 :item.hoos_jr_scoring}}分</div></el-col>

        <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6"><div class="grid-content bg-purple-light">Harris</div></el-col>
        <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6"><div class="grid-content ">{{item.Mental==null ? 0 :item.harris_scoring}}分</div></el-col>

        <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6"><div class="grid-content bg-purple-light">Mental(PROMIS)</div></el-col>
        <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6"><div class="grid-content ">{{item.Mental==null ? 0 :item.Mental}}分</div></el-col>

        <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6"><div class="grid-content bg-purple-light">	Physical(PROMIS)</div></el-col>
        <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6"><div class="grid-content ">{{item.Physical==null ? 0 :item.Physical}}分</div></el-col>

        <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6"><div class="grid-content bg-purple-light">MCS(SF-12)    </div></el-col>
        <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6"><div class="grid-content ">{{item.sf_MCS_scoring==null ? 0 :item.sf_MCS_scoring}}分</div></el-col>

        <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6"><div class="grid-content bg-purple-light">	PCS(SF-12)</div></el-col>
        <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6"><div class="grid-content ">{{item.sf_PCS_scoring==null ? 0 :item.sf_PCS_scoring}}分</div></el-col>
    </el-row>

  </el-collapse-item>
 </el-collapse>
 </div>
  </div>
</template>

<script>

import vas from './vas.vue'
import hoosVue from './hoos.vue'
import harris from './Harris.vue'
import SF12 from './SF12.vue'
import Promise from './Promise.vue'
// import hoosVue
export default {
 data(){
    return{
        recordid:'',
        diawidth:"40%",
        dialogVisible:false,
        operationid:'',
        addrecords:[
            {name:''},
            {shoushu:'',shoushuer:''},
            {datavalue:''}
        ],
        shoushu:["THA","BHA","THA翻修","部分翻修",],
        onepart:["髋臼","股骨头","内衬","股骨柄"],
        activevalue:[],
        showscords:[],
        activename:[],
        scoredialog:false,
        next:1,
        // 接收组件传递过来的值
        getvas:[],
        getharris:[],
        gethoos:[],
        getsf12:[],
        getpromise:[],
        checkdialog:false,
        vasprop:[],//传递vas的评分详情
        hoosprop:[],//传递hoos的评分详情
        harrisprop:[],//传递harris的评分详情
        sf12prop:[],//传递sf12的评分详情
        promisprop:[],//传递promis的评分详情
    }
 },
 created(){
    this.operationid = this.$route.query.room
    this.getrecords()
    let winwidth = window.innerWidth
    this.diawidth = winwidth<=480 ? "90%":winwidth>=1200 ? "50%":"80%"

 },
 filters:{
    vas2scord(value){
        let resscrod=0
        if(value.vas2 !=null){
            let arr =Object.values(value.vas2);
            resscrod =arr[0].sliderValue
        }
        return resscrod
    }
 },
 components:{
    vas,hoosVue,harris,SF12,Promise
 },
 watch:{
    activevalue:{
        handler(newvalue,oldvalue){
            this.showscords=[]
            for(let i=0;i<newvalue.length;i++){
                let obj=newvalue[i]
                if(obj.whether=='未填写'){
                    this.showscords.push(false)
                }else{
                    this.showscords.push(true)
                    this.activename.push(i)
                }
            }
        },deep:true
    }
 },
 methods:{
  async  tocheck(value){

        const {data:res} = await this.$http.get('/Scoring/getScoringInfo/'+value)
        if(res.status==1){
            let vas = Object.values(res.data.vas2),hoos = Object.values(res.data.hoos_jr),
            promis = Object.values(res.data.promis),sf = Object.values(res.data.sf),harris = Object.values(res.data.harris)
            setTimeout(()=>{
                this.vasprop = vas
                this.hoosprop = hoos[1]
                this.promisprop = promis[1]
                this.sf12prop=sf[1]
                this.harrisprop = harris[1]
            },200)
            this.checkdialog = true
        }
        
    },  
   async toscoredialog(){
        // console.log(this.getvas,this.gethoos,this.getharris,this.getsf12,this.getpromise)
        const {data:res} =await this.$http.post('/Scoring/addScoringInfo/'+this.recordid,{
            "vas":this.getvas,
            "hoos_jr":this.gethoos,
            "harris":this.getharris,
            "sf":this.getsf12,
            "promis":this.getpromise
        })
        if(res.status=='1'){
            this.$message.success(res.message)
            this.getrecords()
            this.scoredialog=false
            
        }else{
            this.$message.error(res.message)
        }
        console.log(res)
    },
    // vas的emit
    getvasvalue(value){
        this.getvas=[]
            this.getvas = value
    },
    //hoos的emit
    gethoosvalue(value){
        this.gethoos=[]
        this.gethoos = value
    },
    // harris的emit
    getharrisvallue(value){
        this.getharris=[]
        this.getharris=value
    },
    // sf12的emit
    getsf12value(value){
        this.getsf12=[]
        this.getsf12=value
    },
    // promise的emit
    getpromisevalue(value){
        this.getpromise=[]
        this.getpromise=value
    },
    // 添加评分弹窗
    addrecord(){
        this.dialogVisible = true
    },
    addrecordshoushu(value){
        // console.log(value)
    },
    // 提交评分记录
    async toaddrecords(){
        const {data:res} = await this.$http.post("/Scoring/addScoring/"+this.operationid,{
            "assessor":this.addrecords[0].name,
            "operation":JSON.stringify(this.addrecords[1]),
            "date":this.addrecords[2].datavalue
        })
        // console.log(res)
        if(res.status=='1'){
            this.$message.success(res.message)
            this.dialogVisible=false
            setTimeout(()=>{
                this.getrecords()
            },100)
            
        }
    },
    // 获取评分记录
   async getrecords(){
        const {data:res} = await this.$http.get('/Scoring/getScoring/'+this.operationid)
        if(res.status=='1'){
            this.activevalue=[]
             this.activevalue = res.data
        }
        // console.log(res)
    },
    // 填写评分弹窗
    scoredialogbtn(value){
        this.recordid=value
        this.scoredialog = true
    },
    // 执行下一步
    tonext(){
        this.next = this.next >=5 ? 5 :this.next+1
    },
    // 执行上一步
    tolast(){
        this.next = this.next <=1 ? 1: this.next-1
    }
 }
}
</script>

<style scoped>
.joinview{
  box-sizing: border-box;
  overflow: hidden;
}
.addrecorddia{
    display: flex;
    flex-direction: column;

}
.bg-purple-light {
    background: #e5e9f2;
  }
  .bg-purple-llight {
    background: #fafafa;
  }
  /* #fafafa */
  .collapse-itemp{
    padding-left: 20px;
    width: 90px;
    height: 100%;
  }
  .collapsetitle{
    width: 90px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: aqua; */
  }
  .el-collapse .el-col {
    margin-top: 5px;
    border-bottom: 1px solid #f3f4f6;
  }
  .titrow{
    margin-top:5px
  }
  /* .slottitlerow{
    border-bottom: 1px solid #f3f4f6;
  } */
</style>